<div (clickOutside)="clickOutside()">
  <div class="label">Количество ночей</div>
  <div class="days-list">
    <div *ngFor="let daysCount of availableNightsInDirection()"
         [class.active]="daysCount === nightsFrom || daysCount === nightsTo"
         [class.range]="isInRange(daysCount)"
         (click)="selectDaysCount(daysCount)"
         (mouseenter)="onMouseEnter(daysCount)"
         (mouseleave)="onMouseLeave()"
         class="day">{{ daysCount }}
    </div>
  </div>
</div>
