import { CurrencyPipe, DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  DestroyRef,
  inject,
  input,
  OnDestroy,
  signal,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HotelRoom } from '@api-clients/api-client';
import { SafeHtmlPipe } from '../../../../../../shared/pipes/safe-html.pipe';
import { ImageViewerComponent } from '../../../../../chat-timeline/components/image-viewer/image-viewer.component';
import { ContentCreatorModalControlService } from '../../../../../content-creator/services/content-creator-modal-control.service';
import { PriceCurrencyPipe } from '../../../../pipes/price-currency.pipe';
import { SearchResultsResponseTour } from '../../../../services/search/websocket-tours.model';
import { RoomTours } from '../search-tour-rooms.component';

@Component({
  selector: 'app-search-tour-room',
  templateUrl: './search-tour-room.component.html',
  styleUrls: ['./search-tour-room.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SafeHtmlPipe, CurrencyPipe, DecimalPipe, PriceCurrencyPipe],
})
export class SearchTourRoomComponent implements OnDestroy {
  roomTours = input.required<RoomTours>();
  isShowFullDescription = signal<boolean>(false);

  private destroyRef = inject(DestroyRef);
  private galleryComponentRef: ComponentRef<ImageViewerComponent>;

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly contentCreatorModalControlService: ContentCreatorModalControlService,
  ) {}

  ngOnDestroy(): void {
    this.galleryComponentRef?.destroy();
  }

  get room(): HotelRoom {
    return this.roomTours().room;
  }

  get mainPhotoUrl(): string {
    return this.room.photos[0];
  }

  get description(): string | null {
    if (this.room.description) {
      return this.room.description.replace(/\n/g, '<br/>');
    }
    return null;
  }

  showFullDescription(): void {
    this.isShowFullDescription.set(true);
  }

  sendTour(tour: SearchResultsResponseTour): void {
    this.contentCreatorModalControlService.showManagerOfferModalByTourId(tour.id);
  }

  showGallery(): void {
    this.galleryComponentRef = this.viewContainerRef.createComponent(ImageViewerComponent);
    this.galleryComponentRef.instance.images = this.room.photos.map(photoLink => {
      return {
        contentLink: photoLink,
        isVideo: false,
        text: this.room.name,
      };
    });
    this.galleryComponentRef.instance.closed.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.galleryComponentRef.destroy();
    });
  }
}
