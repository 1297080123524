<div class="modal manager-offers-popup rounded-border">
  <div class="manager-offers-header">
    <div class="manager-offers-title">{{ 'MODALS.MANAGER_OFFERS.TITLE' | translate }}</div>
    <a (click)="closeModal()" class="close-icon">
      <img alt="" src="../../../../../assets/icons/close-round-outline.svg" />
      <span>{{ 'COMMON.CLOSE' | translate }} </span>
    </a>
  </div>
  <div class="manager-offers-content">
    <div class="manager-offers-table">
      <div class="manager-offers-table-header rounded-border">
        <span>
          <span class="table-header-icon"
            ><img src="../../../../../assets/icons/date-calendar.svg" alt=""
          /></span>
          <span class="table-header-title">{{ 'MODALS.MANAGER_OFFERS.SEND_DATE' | translate }} </span></span
        >
        <span>
          <span class="table-header-icon"
            ><img alt="" src="../../../../../assets/icons/map-point.svg"
          /></span>
          <span class="table-header-title">{{ 'MODALS.MANAGER_OFFERS.REGION' | translate }} </span></span
        >
        <span>
          <span class="table-header-icon"><img alt="" src="../../../../../assets/icons/hotel.svg" /></span>
          <span class="table-header-title">{{ 'MODALS.MANAGER_OFFERS.HOTEL' | translate }} </span></span
        >
        <span>
          <span class="table-header-icon"
            ><img alt="" src="../../../../../assets/icons/date-calendar.svg"
          /></span>
          <span class="table-header-title">{{ 'MODALS.MANAGER_OFFERS.DATE' | translate }} </span></span
        >
        <span>
          <span class="table-header-icon"
            ><img alt="" src="../../../../../assets/icons/star-icon.svg"
          /></span>
        </span>
        <span>
          <span class="table-header-icon"><img alt="" src="../../../../../assets/icons/meal.svg" /></span>
        </span>
        <span>
          <span class="table-header-icon">
            <img alt="" src="assets/icons/manager-offers-modal/users.svg" />
          </span>
          <span class="table-header-title">
            {{ 'MODALS.MANAGER_OFFERS.TOURISTS_COUNT' | translate }}
          </span>
        </span>
        <span>
          <span class="table-header-icon">
            <img alt="" src="../../../../../assets/icons/wallet.svg" />
          </span>
          <span class="table-header-title">
            {{ 'MODALS.MANAGER_OFFERS.PRICE' | translate }}
          </span>
        </span>
        <span> </span>
      </div>
      <div *ngIf="managerOfferTours$ | async as offerTours" class="manager-offers-table-body">
        <div *ngFor="let tour of offerTours" class="manager-offers-table-row" [class.viewed]="tour.isViewed">
          <span class="manager">{{ tour?.tourSendDate | date: dateFormat }}</span>
          <span class="region">{{ tour?.region }}</span>
          <span class="hotel">
            <span>{{ tour?.hotel?.name }}</span>
          </span>
          <span class="date">
            <ng-container *ngIf="tour?.departureDate && tour?.returnDate; else empty">
              <span class="date-range">
                {{ tour?.departureDate | date: dateFormat }} - {{ tour?.returnDate | date: dateFormat }}
              </span>
              <span class="nights"> ({{ tour?.totalNights }} ночей) </span>
            </ng-container>
            <ng-template #empty>---</ng-template>
          </span>
          <span class="star">
            <span>{{ tour?.hotel?._class }}</span>
          </span>
          <span class="meal">
            {{ tour?.mealShort }}
          </span>
          <span class="tourists-count">
            {{ tour?.adults }} взр
            <ng-container *ngIf="tour?.children"
              >+{{ tour.children }} дет
              <ng-container *ngIf="tour?.childrenAges?.length > 0">
                ({{ tour.childrenAges.join(',') }})
              </ng-container>
            </ng-container>
          </span>
          <span class="sum">
            {{ tour?.price?.forTour | mask: 'separator' }} Т<br />
            <a (click)="showCalendar(tour)">Пересчет</a>
          </span>
          <span class="view-status">
            @if (tour?.isViewed) {
              <div class="row">
                <span class="views-count" title="Количество просмотров">
                  <img class="eye-icon" alt="" src="assets/icons/manager-offers-modal/eye.svg" />
                  {{ tour?.viewCount }}
                </span>
                <a class="show-message" (click)="navigateToMessage(tour)"> Открыть </a>
              </div>
              <div class="row" title="Дата последнего просмотра">
                <span>{{ tour?.lastViewDate | date: 'dd.MM.YYYY, HH:mm' }}</span>
              </div>
            } @else {
              <div class="row">
                <a class="show-message" (click)="navigateToMessage(tour)"> Открыть </a>
              </div>
            }
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

@if (isLoading$ | async) {
  <app-preloader />
}
