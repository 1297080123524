<div
  class="chat-footer"
  [class.disabled]="isScheduledMessagesListOpen$ | async"
  [attr.title]="(isScheduledMessagesListOpen$ | async) ? 'Чтобы написать сообщение, вернитесь к чату' : null"
>
  <app-reply-to-preview
    *ngIf="currentReplyMessage$ | async as message"
    [message]="message"
  ></app-reply-to-preview>
  <app-whatsapp-send-message
    class="send-message"
    [screenType]="screenType()"
    [phoneOrChatId]="currentPhoneOrChatId"
    [dealId]="dealId"
    [dealStatus]="dealStatus"
    [cardId]="crmCardId"
    [lastSearchRequest]="lastSearchRequest"
  ></app-whatsapp-send-message>
</div>
