import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  inject,
  input,
  OnDestroy,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ManagerSearchHotel, ManagerSearchHotelTemplateList, ToursCalendar } from '@api-clients/api-client';
import { SearchFormParams, SearchFormParamsRegion } from '@api-clients/api-client/dist/models';
import { SearchFormParamsCountry } from '@api-clients/api-client/models/search-form-params-country';
import { SearchRequest } from '@api-clients/crm-api-client/models/search-request';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { WorkerStateService } from '../../../../../../../../core/services';
import { PluralizePipe } from '../../../../../../../../helpers/pipes/plural/pluralize.pipe';
import { PopupService } from '../../../../../../../../shared/services/popup-service.service';
import { SlideToggleModule } from '../../../../../../../../ui-components/slide-toggle/slide-toggle.module';
import { SearchHotel } from '../../../../../../../search/search.model';
import { FavoriteHotelsSearchFormParams } from '../../favorites-hotels.model';
import { SearchHotelTagsService } from '../../services/search-hotel-tags.service';
import { SearchToursProgressService } from '../../services/search-tours-progress.service';
import { SearchFormCalendarComponent } from './calendar/search-form-calendar.component';
import {
  CheckboxItem,
  SearchFormCheckboxListComponent,
} from './checkbox-list/search-form-checkbox-list.component';
import { SearchFormCountriesComponent } from './countries/search-form-countries.component';
import { SearchFormDepartCitiesComponent } from './depart-cities/search-form-depart-cities.component';
import { SearchFormNightsComponent } from './nights/search-form-nights.component';
import { SearchFormNotGdsComponent } from './not-gds/search-form-not-gds.component';
import { SearchFormService } from './search-form.service';
import { SearchFormStarsListComponent } from './stars-list/search-form-stars-list.component';
import { SearchFormTemplatesComponent } from './templates/search-form-templates.component';
import { SearchFormTouristComponent } from './tourists/search-form-tourists.component';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SearchFormCalendarComponent,
    SearchFormNightsComponent,
    SearchFormStarsListComponent,
    SearchFormCheckboxListComponent,
    ReactiveFormsModule,
    SearchFormDepartCitiesComponent,
    NgIf,
    SearchFormCountriesComponent,
    SearchFormTemplatesComponent,
    PluralizePipe,
    AsyncPipe,
    SlideToggleModule,
    SearchFormNotGdsComponent,
    SearchFormTouristComponent,
  ],
  providers: [PopupService],
})
export class SearchFormComponent implements OnInit, OnDestroy {
  formParams = input.required<SearchFormParams>();
  showBackground = input<boolean>(false);
  defaultCountryId = input<number>();
  crmCardLastSearchRequest = input<SearchRequest>();
  searchInProgress$ = this.searchToursState.searchInProgress$;
  loadingHotelsList$ = this.searchFormService.loadingHotelsList$;

  close = output<void>();
  submit = output<void>();
  previewParams = output<FavoriteHotelsSearchFormParams>();

  form: FormGroup;
  mealsVariants = computed<CheckboxItem[]>(() => {
    return this.formParams().meals.map(meal => {
      const item: CheckboxItem = { id: meal.id, name: meal.name, selected: false };

      return item;
    });
  });
  operatorsVariants = computed<CheckboxItem[]>(() => {
    return this.formParams().tourOperators.map(operator => {
      const item: CheckboxItem = { id: operator.id, name: operator.name, selected: false };

      return item;
    });
  });
  airlinesVariants = computed<CheckboxItem[]>(() => {
    return this.formParams().airlines.map(airline => {
      const item: CheckboxItem = { id: airline.code, name: airline.name, selected: false };

      return item;
    });
  });

  countries = signal<SearchFormParamsCountry[]>([]);
  regions = signal<SearchFormParamsRegion[]>([]);
  priceCalendars = signal<ToursCalendar | undefined>(undefined);
  availableNightsInDirection = signal<number[]>([]);
  hotelTemplateList = signal<ManagerSearchHotelTemplateList>(undefined);

  private allHotels = signal<ManagerSearchHotel[]>([]);

  private destroyRef = inject(DestroyRef);

  private previousFormValue: FavoriteHotelsSearchFormParams;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly fb: FormBuilder,
    private readonly searchFormService: SearchFormService,
    private readonly tagsService: SearchHotelTagsService,
    private readonly workerState: WorkerStateService,
    private readonly searchToursState: SearchToursProgressService,
  ) {}

  ngOnInit() {
    this.createForm();
    this.registerFormEvents();
    this.populateForm();
  }

  ngOnDestroy() {}

  searchTours(): void {
    if (!this.form.valid) {
      return;
    }
    const searchParams: FavoriteHotelsSearchFormParams = this.form.value;
    this.searchFormService.saveLastSearchParams(searchParams);

    this.searchFormService.startSearch(searchParams);
    this.submit.emit();
  }

  closeForm(): void {
    this.close.emit();
  }

  private createForm(): void {
    const dateFrom = new Date(this.formParams().defaultValues.dateFrom);
    const dateTo = new Date(this.formParams().defaultValues.dateFrom);
    dateTo.setDate(dateTo.getDate() + 3);

    // setHours нужен, чтобы убрать часовые пояса
    dateFrom.setHours(0);
    dateTo.setHours(0);

    this.form = this.fb.group({
      tourists: [{ adults: 2, childAges: [], splitRooms: null }],
      nights: [
        {
          from: this.formParams().defaultValues.nights.from,
          to: this.formParams().defaultValues.nights.to,
        },
      ],
      dates: [
        {
          from: dateFrom,
          to: dateTo,
        },
      ],
      direction: {
        countryId: null,
        regionIds: [],
      },
      departCityId: new FormControl<number | null>(null, {
        validators: [Validators.required],
      }),
      mealIds: new FormControl<number[]>([]),
      operatorIds: new FormControl<number[]>([]),
      airlineIds: new FormControl<string[]>([]),
      hotelIds: new FormControl<number[]>([]),
      stars: new FormControl<number[]>([]),
      templateId: new FormControl<number>(0),
      notGDS: [true],
      combined: ['0'],
    });
  }

  private populateForm(): void {
    const lastFormParam = this.searchFormService.getLastSearchParams();
    if (lastFormParam) {
      console.log(lastFormParam);
      this.form.setValue(lastFormParam);
    } else {
      if (this.crmCardLastSearchRequest()) {
        const formValue = this.getFormValueFromCrmCardLastSearch();
        if (formValue) {
          this.form.setValue(formValue);
        } else {
          this.setDefaultFormParams();
        }
      } else {
        this.setDefaultFormParams();
      }
    }
  }

  private setDefaultFormParams(): void {
    const departCityId = this.workerState.currentDepartCityId;
    const countryId = this.defaultCountryId()
      ? this.defaultCountryId()
      : this.formParams().defaultValues.countryId;
    const direction = {
      countryId,
      regionIds: [],
    };
    const defaultFormValue = this.form.value;
    const formValue = { ...defaultFormValue, ...{ departCityId, direction } };
    this.form.setValue(formValue);
  }

  private getFormValueFromCrmCardLastSearch() {
    if (!this.crmCardLastSearchRequest()?.departCity?.id) {
      return null;
    }

    const departCityId = this.crmCardLastSearchRequest().departCity.id;
    const countryId = this.crmCardLastSearchRequest().country.id;
    const direction = { countryId, regionIds: [] };
    if (this.crmCardLastSearchRequest().regions.length) {
      direction.regionIds = this.crmCardLastSearchRequest().regions.map(region => region.id);
    }

    const nights = {
      from: this.crmCardLastSearchRequest().nightsFrom,
      to: this.crmCardLastSearchRequest().nightsTo,
    };

    let hotelIds = [];
    if (this.crmCardLastSearchRequest().hotels.length) {
      hotelIds = this.crmCardLastSearchRequest().hotels.map(hotel => hotel.id);
    }

    let mealIds = [];
    if (this.crmCardLastSearchRequest().hotels.length) {
      mealIds = this.crmCardLastSearchRequest().meals.map(meal => meal.id);
    }

    const tourists = {
      adults: this.crmCardLastSearchRequest().adult,
      childAges: this.crmCardLastSearchRequest().childAges,
      splitRooms: false,
    };

    const extFormValue: any = { departCityId, direction, nights, hotelIds, mealIds, tourists };

    const dateFrom = new Date(this.crmCardLastSearchRequest().dateFrom);
    const dateTo = new Date(this.crmCardLastSearchRequest().dateTo);
    dateFrom.setHours(0);
    dateTo.setHours(0);
    const dates = { from: dateFrom, to: dateTo };

    if (dateFrom >= new Date()) {
      extFormValue.dates = dates;
    }

    const defaultFormValue = this.form.value;

    return {
      ...defaultFormValue,
      ...extFormValue,
    };
  }

  private updateCountries(departCityId: number): void {
    const countryIds = [];
    this.formParams().directions.map(direction => {
      if (direction.departCityId === departCityId) {
        countryIds.push(direction.countryId);
      }
    });

    const countries = this.formParams().countries.filter(country => countryIds.indexOf(country.id) !== -1);
    const regions = this.formParams().regions.filter(region => countryIds.indexOf(region.countryId) !== -1);

    this.countries.update(() => countries);
    this.regions.update(() => regions);
  }

  private updateFormHotels(formValue: FavoriteHotelsSearchFormParams) {
    const hotels = this.getFormHotels(formValue);
    let hotelIds = hotels.map(v => v.id);

    hotelIds = formValue.templateId === 0 ? [] : hotelIds;
    this.form.get('hotelIds').setValue(hotelIds, { emitEvent: false });

    const searchHotels: SearchHotel[] = hotels.map(hotel => {
      return {
        id: hotel.id,
        stars: hotel.stars,
        name: hotel.name,
        photo: hotel.photo,
        region: hotel.regionName,
        salesCount: hotel.salesCount,
        bookingRating: hotel.bookingRating,
        bookingLink: hotel.bookingUrl,
        bookingReviewsCount: hotel.bookingReviewsCount,
        latitude: hotel.latitude,
        longitude: hotel.longitude,
        beachType: hotel.beachType,
        seaLine: hotel.seaLine,
        videoStories: hotel.videoStories,
        conversionOnlyAdults: hotel.conversionOnlyAdults,
        conversionWithChildren: hotel.conversionWithChildren,
      };
    });
    this.searchFormService.setHotelsOnSearch(searchHotels);
  }

  private getFormHotels(formValue: FavoriteHotelsSearchFormParams): ManagerSearchHotel[] {
    const starsList: number[] = formValue.stars.map(stars => Number(stars));
    const regionIds: number[] = formValue.direction.regionIds;
    const templateId = formValue.templateId;
    const hotelsInTemplate = this.getHotelIdsByTemplateId(templateId);

    return this.allHotels().filter(hotel => {
      if (hotelsInTemplate.length) {
        if (!hotelsInTemplate.includes(hotel.id)) {
          return false;
        }
      }

      if (starsList.length) {
        if (starsList.indexOf(Number(hotel.stars)) === -1) {
          return false;
        }
      }
      if (regionIds.length) {
        if (regionIds.indexOf(hotel.regionId) === -1) {
          return false;
        }
      }
      return true;
    });
  }

  private getHotelIdsByTemplateId(templateId: number): number[] {
    if (templateId === 0) {
      return [];
    }

    const allTemplates = this.hotelTemplateList().basicTemplates.concat(
      this.hotelTemplateList().managerTemplates,
    );

    return allTemplates.find(template => template.id === templateId).hotelsList.map(hotel => hotel.id);
  }

  private updatePriceCalendar(
    departCityId: number,
    countryId: number,
    nightsFrom: number,
    nightsTo: number,
  ): void {
    this.searchFormService
      .loadFormCalendar$(departCityId, countryId, nightsFrom, nightsTo)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(priceCalendar => {
        this.priceCalendars.update(() => priceCalendar);
      });
  }

  private updateAvailableNightsInDirection(departCityId: number, countryId: number): void {
    if (departCityId && countryId) {
      const directionOptions = this.formParams().directions.find(
        direction => direction.departCityId === departCityId && direction.countryId === countryId,
      );
      if (directionOptions.nights.length) {
        this.availableNightsInDirection.set(directionOptions.nights);
      } else {
        this.availableNightsInDirection.set([...Array(18)].map((_, i) => i + 3));
      }
    }
  }

  private preloadTags(countryId: number): void {
    this.tagsService.loadTags$({ countryId }).subscribe();
  }

  private registerFormEvents(): void {
    this.form.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((value: FavoriteHotelsSearchFormParams) => {
          const departCityId = value.departCityId;
          const countryId = value.direction.countryId;

          if (this.previousFormValue) {
            if (this.previousFormValue.departCityId !== departCityId) {
              this.updateCountries(departCityId);
              this.updateAvailableNightsInDirection(departCityId, countryId);
            }
            if (this.previousFormValue.direction.countryId !== countryId) {
              this.preloadTags(countryId);
            }
            if (
              this.previousFormValue.departCityId !== departCityId ||
              this.previousFormValue.direction.countryId !== countryId
            ) {
              this.updateAvailableNightsInDirection(departCityId, countryId);
              this.updatePriceCalendar(departCityId, countryId, value.nights.from, value.nights.to);
            }
          } else {
            this.updateCountries(departCityId);
            this.updateAvailableNightsInDirection(departCityId, countryId);
            this.updatePriceCalendar(departCityId, countryId, value.nights.from, value.nights.to);
            this.preloadTags(countryId);
          }
        }),
        switchMap((value: FavoriteHotelsSearchFormParams) => {
          const countryId = value.direction.countryId;
          if (this.previousFormValue?.direction?.countryId !== countryId) {
            const sources = [
              this.searchFormService.loadHotelsList$(countryId),
              this.searchFormService.loadHotelsTemplates$(countryId),
              of(value),
            ];
            return forkJoin(sources).pipe(takeUntilDestroyed(this.destroyRef));
          } else {
            return of([this.allHotels(), this.hotelTemplateList(), value]);
          }
        }),
      )
      .subscribe((response: any) => {
        this.allHotels.set(response[0]);
        this.hotelTemplateList.set(response[1]);

        const formValue: FavoriteHotelsSearchFormParams = response[2];

        const countryId = formValue.direction.countryId;

        if (this.previousFormValue?.direction?.countryId !== countryId) {
          const selectedCountry = this.formParams().countries.find(country => country.id === countryId);
          this.searchFormService.setSelectedCountry(selectedCountry);
        }

        if (
          this.previousFormValue?.direction?.countryId !== countryId ||
          this.previousFormValue?.stars.sort().join('-') !== formValue.stars.sort().join('-') ||
          this.previousFormValue.direction?.regionIds?.sort().join('-') !==
            formValue.direction?.regionIds?.sort().join('-') ||
          this.previousFormValue.templateId !== formValue.templateId
        ) {
          this.updateFormHotels(formValue);
        }

        this.previousFormValue = formValue;
        if (this.form.valid) {
          this.previewParams.emit(this.form.value);
        }

        this.cdRef.detectChanges();
      });
  }
}
