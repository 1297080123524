import { HotelTagWithPriority } from '../../../../../favorites-hotels.model';

export function prepareBookingRating(value?: number): string {
  if (!value) {
    return '';
  }

  if (Number.isInteger(value) && !isNaN(value) && value !== 10) {
    return `${value}.0`;
  }

  return value.toString();
}

export function isStarsHotel(str?: string): boolean {
  const stringStars = Number(str);
  return !Number.isNaN(stringStars) && stringStars !== 0;
}

export function prepareTourPrice(value: number): string {
  return Math.ceil(value / 1000).toString() + ' т';
}

export function getTagColor(priority: number): string {
  if (priority === 0) {
    return '#FF6969';
  }
  if (priority === 1) {
    return '#F8D57D';
  }

  return '#3396F3';
}

export function getTagsPercentColor(value: number): string {
  const colors: { [key: number]: string } = {
    0: '#B0C4DE',
    10: '#C4E0E5',
    20: '#D0F0C0',
    30: '#F5F5DC',
    40: '#FAD6A5',
    50: '#F4A460',
    60: '#FF8C00',
    70: '#FF6347',
    80: '#FF4500',
    90: '#FF0000',
    100: '#C8102E',
  };

  const steps: number[] = Object.keys(colors).map(Number);

  for (let i = 0; i < steps.length - 1; i++) {
    if (value >= steps[i] && value < steps[i + 1]) {
      return colors[steps[i]];
    }
  }

  return colors[100];
}

export function svgMarkerFull(
  rating: number,
  hotelStars: string,
  price: number,
  tags: HotelTagWithPriority[],
): string {
  const svgRatingValue = prepareBookingRating(rating);
  const svgHotelStarsValue = hotelStars;
  const svgHotelStarsPathValue = isStarsHotel(hotelStars)
    ? '<path d="M89.2888 11.956C89.5821 11.3608 90.4306 11.3608 90.7239 11.956L91.5242 13.5797C91.6408 13.8162 91.8663 13.98 92.1272 14.0178L93.9001 14.2745C94.5556 14.3694 94.8184 15.174 94.3455 15.6376L93.0522 16.905C92.8649 17.0885 92.7795 17.3523 92.8236 17.6108L93.1284 19.3975C93.24 20.0519 92.5519 20.5497 91.9652 20.2389L90.3809 19.3995C90.1466 19.2754 89.8661 19.2754 89.6318 19.3995L88.0475 20.2389C87.4608 20.5497 86.7727 20.0519 86.8843 19.3975L87.1889 17.612C87.2331 17.3528 87.1472 17.0884 86.9589 16.9048L85.6612 15.6389C85.1864 15.1757 85.4491 14.3691 86.1056 14.2744L87.8853 14.0176C88.1463 13.98 88.372 13.8161 88.4886 13.5795L89.2888 11.956Z" fill="#FFC700"/>'
    : '';
  const svgTourPriceValue = prepareTourPrice(price);

  const tagsCount = tags.length;
  const firstTagColor = tags[0] ? getTagColor(tags[0].priority) : null;
  const secondTagColor = tags[1] ? getTagColor(tags[1].priority) : null;
  const thirdTagColor = tags[2] ? getTagColor(tags[2].priority) : null;

  const baseTemplateColor = () => {
    if (tagsCount) {
      const percent = tags[0].tag.usePercent;
      return getTagsPercentColor(percent);
    }
    return '#fff';
  };

  const templateColor = baseTemplateColor();

  const svgContentContainer = () => {
    if (tagsCount === 3) {
      return `<path fill-rule="evenodd" clip-rule="evenodd" d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26802 30 16 30H75.7309L79.0792 34.991C79.9817 36.3363 82.2961 36.3363 83.1685 34.991L86.4048 30H146C153.732 30 160 23.732 160 16C160 8.26801 153.732 2 146 2H16Z" fill="${templateColor}"/>`;
    }
    if (tagsCount === 2) {
      return `<path fill-rule="evenodd" clip-rule="evenodd" d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30H65.7309L69.0792 34.991C69.9817 36.3363 72.2961 36.3363 73.1685 34.991L76.4048 30H126C133.732 30 140 23.732 140 16C140 8.26801 133.732 2 126 2H16Z" fill="${templateColor}"/>`;
    }
    if (tagsCount === 1) {
      return `<path fill-rule="evenodd" clip-rule="evenodd" d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30H55.7309L59.0792 34.991C59.9817 36.3363 62.2961 36.3363 63.1685 34.991L66.4048 30H106C113.732 30 120 23.732 120 16C120 8.26801 113.732 2 106 2H16Z" fill="${templateColor}"/>`;
    }
    return `<path fill-rule="evenodd" clip-rule="evenodd" d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30H44.7309L48.0792 34.991C48.9817 36.3363 51.2961 36.3363 52.1685 34.991L55.4048 30H84C91.732 30 98 23.732 98 16C98 8.26801 91.732 2 84 2H16Z" fill="${templateColor}"/>`;
  };

  const svgPriceContainer = () => {
    let textX = '51%';
    if (tagsCount === 3) {
      textX = '29%';
    } else if (tagsCount === 1 || tagsCount === 2) {
      textX = '35%';
    }

    const showCirclePointer = price < 1_000_000;
    let svg = '';
    if (showCirclePointer) {
      svg += '<circle cx="33" cy="16" r="1" fill="#E1E9F5"/>';
    }
    svg += `
    <text x="${textX}" y="20" font-family="Gilroy, sans-serif" font-size="12" font-weight="600" fill="#21304E">
      <tspan text-anchor="middle">${svgTourPriceValue}</tspan>
    </text>`;
    if (showCirclePointer) {
      svg += '<circle cx="70" cy="16" r="1" fill="#E1E9F5"/>';
    }

    return svg;
  };

  const svgBaseTemplate = (width: number) => `
  <svg width="${width}" height="57" viewBox="0 0 ${width} 57" fill="${templateColor}" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d)">${svgContentContainer()}</g>
  <rect class="booking-rating" x="4" y="4" width="24" height="24" rx="12" fill="#21304E"/>
  <text x="8" y="20" font-family="Gilroy, sans-serif" font-size="12" fill="#fff">
    <tspan dx="8" text-anchor="middle">${svgRatingValue}</tspan>
  </text>
  ${svgPriceContainer()}
  <text x="${svgHotelStarsValue === 'APT' ? '72' : '76'}" y="20" font-family="Gilroy, sans-serif" font-size="12" font-weight="500" fill="#21304E">${svgHotelStarsValue}</text>
  ${svgHotelStarsPathValue}`;

  const svgTagTemplate = (x: number, color: string, tagIndex: number) => {
    let pathD = '';
    if (tagIndex === 1) {
      pathD =
        'M104.75 13.5C104.551 13.5 104.36 13.421 104.22 13.2803C104.079 13.1397 104 12.9489 104 12.75C104 12.5511 104.079 12.3603 104.22 12.2197C104.36 12.079 104.551 12 104.75 12C104.949 12 105.14 12.079 105.28 12.2197C105.421 12.3603 105.5 12.5511 105.5 12.75C105.5 12.9489 105.421 13.1397 105.28 13.2803C105.14 13.421 104.949 13.5 104.75 13.5ZM112.705 15.79L108.205 11.29C108.025 11.11 107.775 11 107.5 11H104C103.445 11 103 11.445 103 12V15.5C103 15.775 103.11 16.025 103.295 16.205L107.79 20.705C107.975 20.885 108.225 21 108.5 21C108.775 21 109.025 20.885 109.205 20.705L112.705 17.205C112.89 17.025 113 16.775 113 16.5C113 16.22 112.885 15.97 112.705 15.79Z';
    } else if (tagIndex === 2) {
      pathD =
        'M124.75 13.5C124.551 13.5 124.36 13.421 124.22 13.2803C124.079 13.1397 124 12.9489 124 12.75C124 12.5511 124.079 12.3603 124.22 12.2197C124.36 12.079 124.551 12 124.75 12C124.949 12 125.14 12.079 125.28 12.2197C125.421 12.3603 125.5 12.5511 125.5 12.75C125.5 12.9489 125.421 13.1397 125.28 13.2803C125.14 13.421 124.949 13.5 124.75 13.5ZM132.705 15.79L128.205 11.29C128.025 11.11 127.775 11 127.5 11H124C123.445 11 123 11.445 123 12V15.5C123 15.775 123.11 16.025 123.295 16.205L127.79 20.705C127.975 20.885 128.225 21 128.5 21C128.775 21 129.025 20.885 129.205 20.705L132.705 17.205C132.89 17.025 133 16.775 133 16.5C133 16.22 132.885 15.97 132.705 15.79Z';
    } else {
      pathD =
        'M144.75 13.5C144.551 13.5 144.36 13.421 144.22 13.2803C144.079 13.1397 144 12.9489 144 12.75C144 12.5511 144.079 12.3603 144.22 12.2197C144.36 12.079 144.551 12 144.75 12C144.949 12 145.14 12.079 145.28 12.2197C145.421 12.3603 145.5 12.5511 145.5 12.75C145.5 12.9489 145.421 13.1397 145.28 13.2803C145.14 13.421 144.949 13.5 144.75 13.5ZM152.705 15.79L148.205 11.29C148.025 11.11 147.775 11 147.5 11H144C143.445 11 143 11.445 143 12V15.5C143 15.775 143.11 16.025 143.295 16.205L147.79 20.705C147.975 20.885 148.225 21 148.5 21C148.775 21 149.025 20.885 149.205 20.705L152.705 17.205C152.89 17.025 153 16.775 153 16.5C153 16.22 152.885 15.97 152.705 15.79Z';
    }
    return `
  <rect x="${x}" y="8" width="16" height="16" rx="8" fill="#F4F8FE"/>
  <path d="${pathD}" fill="${color}"/>
  `;
  };

  const svgPointer = (width: number) => {
    const x1 = 47 + tagsCount * 10;
    const x2 = 49 + tagsCount * 10;
    const filterX = 42.5 + tagsCount * 10;

    return `
  <g filter="url(#filter1_d)">
    <rect x="${x1}" y="40" width="8" height="8" rx="4" fill="#21304E" stroke="#21304E"/>
    <rect x="${x2}" y="42" width="4" height="4" rx="2" fill="white"/>
  </g>
  <defs>
    <filter id="filter0_d" x="0" y="0" width="${width + 2}" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.234031 0 0 0 0 0.452962 0 0 0 0.58 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
    <filter id="filter1_d" x="${filterX}" y="39.5" width="17" height="17" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.235294 0 0 0 0 0.454902 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
  </defs>
</svg>`;
  };

  if (tagsCount === 3) {
    return (
      svgBaseTemplate(180) +
      svgTagTemplate(100, firstTagColor, 1) +
      svgTagTemplate(120, secondTagColor, 2) +
      svgTagTemplate(140, thirdTagColor, 3) +
      svgPointer(180)
    );
  } else if (tagsCount === 2) {
    return (
      svgBaseTemplate(150) +
      svgTagTemplate(100, firstTagColor, 1) +
      svgTagTemplate(120, secondTagColor, 2) +
      svgPointer(150)
    );
  } else if (tagsCount === 1) {
    return svgBaseTemplate(150) + svgTagTemplate(100, firstTagColor, 1) + svgPointer(150);
  } else {
    return svgBaseTemplate(100) + svgPointer(100);
  }
}

export function svgMarkerRatingAndHotelStars(
  rating: number,
  hotelStars: string,
  tags: HotelTagWithPriority[],
) {
  const svgRatingValue = prepareBookingRating(rating);
  const svgHotelStarsValue = hotelStars;
  const svgHotelStarsPathValue = isStarsHotel(hotelStars)
    ? '<path d="M52.2888 11.956C52.5821 11.3608 53.4306 11.3608 53.7239 11.956L54.5242 13.5797C54.6408 13.8162 54.8663 13.98 55.1272 14.0178L56.9001 14.2745C57.5556 14.3694 57.8184 15.174 57.3455 15.6376L56.0522 16.905C55.8649 17.0885 55.7795 17.3523 55.8236 17.6108L56.1284 19.3975C56.24 20.0519 55.5519 20.5497 54.9652 20.2389L53.3809 19.3995C53.1466 19.2754 52.8661 19.2754 52.6318 19.3995L51.0475 20.2389C50.4608 20.5497 49.7727 20.0519 49.8843 19.3975L50.1889 17.612C50.2331 17.3528 50.1472 17.0884 49.9589 16.9048L48.6612 15.6389C48.1864 15.1757 48.4491 14.3691 49.1056 14.2744L50.8853 14.0176C51.1463 13.98 51.372 13.8161 51.4886 13.5795L52.2888 11.956Z" fill="#FFC700"/>'
    : '';
  const tagsCount = tags.length;
  const firstTagColor = tags[0] ? getTagColor(tags[0].priority) : null;
  const secondTagColor = tags[1] ? getTagColor(tags[1].priority) : null;
  const thirdTagColor = tags[2] ? getTagColor(tags[2].priority) : null;

  const baseTemplateColor = () => {
    if (tagsCount) {
      const percent = tags[0].tag.usePercent;
      return getTagsPercentColor(percent);
    }
    return '#fff';
  };

  const templateColor = baseTemplateColor();
  const svgContentContainer = () => {
    if (tagsCount === 3) {
      return `<path fill-rule="evenodd" clip-rule="evenodd" d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30H56.7309L60.0792 34.991C60.9817 36.3363 63.2961 36.3363 64.1685 34.991L67.4048 30H109C116.732 30 123 23.732 123 16C123 8.26801 116.732 2 109 2H16Z" fill="${templateColor}"/>`;
    }
    if (tagsCount === 2) {
      return `<path fill-rule="evenodd" clip-rule="evenodd" d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26802 30 16 30H46.7309L50.0792 34.991C50.9817 36.3363 53.2961 36.3363 54.1685 34.991L57.4048 30H89C96.732 30 103 23.732 103 16C103 8.26801 96.732 2 89 2H16Z" fill="${templateColor}"/>`;
    }
    if (tagsCount === 1) {
      return `<path fill-rule="evenodd" clip-rule="evenodd" d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30H37.7309L41.0792 34.991C41.9817 36.3363 44.2961 36.3363 45.1685 34.991L48.4048 30H70C77.732 30 84 23.732 84 16C84 8.26801 77.732 2 70 2H16Z" fill="${templateColor}"/>`;
    }
    return `<path fill-rule="evenodd" clip-rule="evenodd" d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30H26.7309L30.0792 34.991C30.9817 36.3363 33.2961 36.3363 34.1685 34.991L37.4048 30H49C56.732 30 63 23.732 63 16C63 8.26801 56.732 2 49 2H16Z" fill="${templateColor}"/>`;
  };

  let svgHotelStarsValueX = 38;
  if (svgHotelStarsValue === 'APT') {
    svgHotelStarsValueX = 34;
  } else if (svgHotelStarsValue === 'VILLA') {
    svgHotelStarsValueX = 29;
  }

  const svgBaseTemplate = (width: number) => `
  <svg width="${width}" height="57" viewBox="0 0 ${width} 57" fill="${templateColor}" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_15493_57314)">${svgContentContainer()}</g>
  <rect class="booking-rating" x="4" y="4" width="24" height="24" rx="12" fill="#21304E"/>
  <text x="8" y="20" font-family="Gilroy, sans-serif" font-size="12" fill="#fff">
    <tspan dx="8" text-anchor="middle">${svgRatingValue}</tspan>
  </text>
  <text x="${svgHotelStarsValueX}" y="20" font-family="Gilroy, sans-serif" font-size="12" font-weight="500" fill="#21304E">${svgHotelStarsValue}</text>
  ${svgHotelStarsPathValue}`;

  const svgPointer = (width: number) => {
    const x1 = 28.5 + tagsCount * 10;
    const x2 = 30.5 + tagsCount * 10;
    const filterX = 24 + tagsCount * 10;

    return `
  <g filter="url(#filter1_d_15493_57314)">
    <rect x="${x1}" y="40" width="8" height="8" rx="4" fill="#21304E" stroke="#21304E"/>
    <rect x="${x2}" y="42" width="4" height="4" rx="2" fill="white"/>
  </g>
  <defs>
    <filter id="filter0_d_15493_57314" x="0" y="0" width="${width}" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.234031 0 0 0 0 0.452962 0 0 0 0.58 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15493_57314"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15493_57314" result="shape"/>
    </filter>
    <filter id="filter1_d_15493_57314" x="${filterX}" y="39.5" width="17" height="17" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.235294 0 0 0 0 0.454902 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15493_57314"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15493_57314" result="shape"/>
    </filter>
  </defs>
</svg>`;
  };

  const svgTagTemplate = (x: number, color: string, tagIndex: number) => {
    let pathD = '';
    if (tagIndex === 1) {
      pathD =
        'M67.75 13.5C67.5511 13.5 67.3603 13.421 67.2197 13.2803C67.079 13.1397 67 12.9489 67 12.75C67 12.5511 67.079 12.3603 67.2197 12.2197C67.3603 12.079 67.5511 12 67.75 12C67.9489 12 68.1397 12.079 68.2803 12.2197C68.421 12.3603 68.5 12.5511 68.5 12.75C68.5 12.9489 68.421 13.1397 68.2803 13.2803C68.1397 13.421 67.9489 13.5 67.75 13.5ZM75.705 15.79L71.205 11.29C71.025 11.11 70.775 11 70.5 11H67C66.445 11 66 11.445 66 12V15.5C66 15.775 66.11 16.025 66.295 16.205L70.79 20.705C70.975 20.885 71.225 21 71.5 21C71.775 21 72.025 20.885 72.205 20.705L75.705 17.205C75.89 17.025 76 16.775 76 16.5C76 16.22 75.885 15.97 75.705 15.79Z';
    } else if (tagIndex === 2) {
      pathD =
        'M87.75 13.5C87.5511 13.5 87.3603 13.421 87.2197 13.2803C87.079 13.1397 87 12.9489 87 12.75C87 12.5511 87.079 12.3603 87.2197 12.2197C87.3603 12.079 87.5511 12 87.75 12C87.9489 12 88.1397 12.079 88.2803 12.2197C88.421 12.3603 88.5 12.5511 88.5 12.75C88.5 12.9489 88.421 13.1397 88.2803 13.2803C88.1397 13.421 87.9489 13.5 87.75 13.5ZM95.705 15.79L91.205 11.29C91.025 11.11 90.775 11 90.5 11H87C86.445 11 86 11.445 86 12V15.5C86 15.775 86.11 16.025 86.295 16.205L90.79 20.705C90.975 20.885 91.225 21 91.5 21C91.775 21 92.025 20.885 92.205 20.705L95.705 17.205C95.89 17.025 96 16.775 96 16.5C96 16.22 95.885 15.97 95.705 15.79Z';
    } else {
      pathD =
        'M107.75 13.5C107.551 13.5 107.36 13.421 107.22 13.2803C107.079 13.1397 107 12.9489 107 12.75C107 12.5511 107.079 12.3603 107.22 12.2197C107.36 12.079 107.551 12 107.75 12C107.949 12 108.14 12.079 108.28 12.2197C108.421 12.3603 108.5 12.5511 108.5 12.75C108.5 12.9489 108.421 13.1397 108.28 13.2803C108.14 13.421 107.949 13.5 107.75 13.5ZM115.705 15.79L111.205 11.29C111.025 11.11 110.775 11 110.5 11H107C106.445 11 106 11.445 106 12V15.5C106 15.775 106.11 16.025 106.295 16.205L110.79 20.705C110.975 20.885 111.225 21 111.5 21C111.775 21 112.025 20.885 112.205 20.705L115.705 17.205C115.89 17.025 116 16.775 116 16.5C116 16.22 115.885 15.97 115.705 15.79Z';
    }
    return `
      <rect x="${x}" y="8" width="16" height="16" rx="8" fill="#F4F8FE"/>
      <path d="${pathD}" fill="${color}"/>
  `;
  };

  if (tagsCount === 3) {
    return (
      svgBaseTemplate(125) +
      svgTagTemplate(63, firstTagColor, 1) +
      svgTagTemplate(83, secondTagColor, 2) +
      svgTagTemplate(103, thirdTagColor, 3) +
      svgPointer(125)
    );
  } else if (tagsCount === 2) {
    return (
      svgBaseTemplate(105) +
      svgTagTemplate(63, firstTagColor, 1) +
      svgTagTemplate(83, secondTagColor, 2) +
      svgPointer(105)
    );
  } else if (tagsCount === 1) {
    return svgBaseTemplate(86) + svgTagTemplate(63, firstTagColor, 1) + svgPointer(85);
  } else {
    return svgBaseTemplate(65) + svgPointer(65);
  }
}

export function svgMarkerRatingAndPrice(rating: number, price: number, tags: HotelTagWithPriority[]): string {
  const svgRatingValue = prepareBookingRating(rating);
  const svgTourPriceValue = prepareTourPrice(price);
  const tagsCount = tags.length;
  const firstTagColor = tags[0] ? getTagColor(tags[0].priority) : null;
  const secondTagColor = tags[1] ? getTagColor(tags[1].priority) : null;
  const thirdTagColor = tags[2] ? getTagColor(tags[2].priority) : null;

  const baseTemplateColor = () => {
    if (tagsCount) {
      const percent = tags[0].tag.usePercent;
      return getTagsPercentColor(percent);
    }
    return '#fff';
  };

  const templateColor = baseTemplateColor();

  const svgContentContainer = () => {
    if (tagsCount === 3) {
      return `<path fill-rule="evenodd" clip-rule="evenodd" d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30H59.7309L63.0792 34.991C63.9817 36.3363 66.2961 36.3363 67.1685 34.991L70.4048 30H115C122.732 30 129 23.732 129 16C129 8.26801 122.732 2 115 2H16Z" fill="${templateColor}"/>`;
    }
    if (tagsCount === 2) {
      return `<path fill-rule="evenodd" clip-rule="evenodd" d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26802 30 16 30H49.7309L53.0792 34.991C53.9817 36.3363 56.2961 36.3363 57.1685 34.991L60.4048 30H95C102.732 30 109 23.732 109 16C109 8.26801 102.732 2 95 2H16Z" fill="${templateColor}"/>`;
    }
    if (tagsCount === 1) {
      return `<path fill-rule="evenodd" clip-rule="evenodd" d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30H40.7309L44.0792 34.991C44.9817 36.3363 47.2961 36.3363 48.1685 34.991L51.4048 30H75C82.732 30 89 23.732 89 16C89 8.26801 82.732 2 75 2H16Z" fill="${templateColor}"/>`;
    }
    return `<path fill-rule="evenodd" clip-rule="evenodd" d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30H30.7309L34.0792 34.991C34.9817 36.3363 37.2961 36.3363 38.1685 34.991L41.4048 30H55C62.732 30 69 23.732 69 16C69 8.26801 62.732 2 55 2H16Z" fill="${templateColor}"/>`;
  };
  const svgPriceContainer = () => {
    const textX = '48';
    return `
    <text x="${textX}" y="20" font-family="Gilroy, sans-serif" font-size="12" font-weight="600" fill="#21304E">
      <tspan text-anchor="middle">${svgTourPriceValue}</tspan>
    </text>`;
  };

  const svgBaseTemplate = (width: number) => `
    <svg width="${width}" height="57" viewBox="0 0 ${width} 57" fill="${templateColor}" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)">${svgContentContainer()}</g>
    <rect class="booking-rating" x="4" y="4" width="24" height="24" rx="12" fill="#21304E"/>
    <text x="8" y="20" font-family="Gilroy, sans-serif" font-size="12" fill="#fff">
      <tspan dx="8" text-anchor="middle">${svgRatingValue}</tspan>
    </text>
    ${svgPriceContainer()}`;

  const svgTagTemplate = (x: number, color: string, tagIndex: number) => {
    let pathD = '';
    if (tagIndex === 1) {
      pathD =
        'M73.75 13.5C73.5511 13.5 73.3603 13.421 73.2197 13.2803C73.079 13.1397 73 12.9489 73 12.75C73 12.5511 73.079 12.3603 73.2197 12.2197C73.3603 12.079 73.5511 12 73.75 12C73.9489 12 74.1397 12.079 74.2803 12.2197C74.421 12.3603 74.5 12.5511 74.5 12.75C74.5 12.9489 74.421 13.1397 74.2803 13.2803C74.1397 13.421 73.9489 13.5 73.75 13.5ZM81.705 15.79L77.205 11.29C77.025 11.11 76.775 11 76.5 11H73C72.445 11 72 11.445 72 12V15.5C72 15.775 72.11 16.025 72.295 16.205L76.79 20.705C76.975 20.885 77.225 21 77.5 21C77.775 21 78.025 20.885 78.205 20.705L81.705 17.205C81.89 17.025 82 16.775 82 16.5C82 16.22 81.885 15.97 81.705 15.79Z';
    } else if (tagIndex === 2) {
      pathD =
        'M93.75 13.5C93.5511 13.5 93.3603 13.421 93.2197 13.2803C93.079 13.1397 93 12.9489 93 12.75C93 12.5511 93.079 12.3603 93.2197 12.2197C93.3603 12.079 93.5511 12 93.75 12C93.9489 12 94.1397 12.079 94.2803 12.2197C94.421 12.3603 94.5 12.5511 94.5 12.75C94.5 12.9489 94.421 13.1397 94.2803 13.2803C94.1397 13.421 93.9489 13.5 93.75 13.5ZM101.705 15.79L97.205 11.29C97.025 11.11 96.775 11 96.5 11H93C92.445 11 92 11.445 92 12V15.5C92 15.775 92.11 16.025 92.295 16.205L96.79 20.705C96.975 20.885 97.225 21 97.5 21C97.775 21 98.025 20.885 98.205 20.705L101.705 17.205C101.89 17.025 102 16.775 102 16.5C102 16.22 101.885 15.97 101.705 15.79Z';
    } else {
      pathD =
        'M113.75 13.5C113.551 13.5 113.36 13.421 113.22 13.2803C113.079 13.1397 113 12.9489 113 12.75C113 12.5511 113.079 12.3603 113.22 12.2197C113.36 12.079 113.551 12 113.75 12C113.949 12 114.14 12.079 114.28 12.2197C114.421 12.3603 114.5 12.5511 114.5 12.75C114.5 12.9489 114.421 13.1397 114.28 13.2803C114.14 13.421 113.949 13.5 113.75 13.5ZM121.705 15.79L117.205 11.29C117.025 11.11 116.775 11 116.5 11H113C112.445 11 112 11.445 112 12V15.5C112 15.775 112.11 16.025 112.295 16.205L116.79 20.705C116.975 20.885 117.225 21 117.5 21C117.775 21 118.025 20.885 118.205 20.705L121.705 17.205C121.89 17.025 122 16.775 122 16.5C122 16.22 121.885 15.97 121.705 15.79Z';
    }
    return `
  <rect x="${x}" y="8" width="16" height="16" rx="8" fill="#F4F8FE"/>
  <path d="${pathD}" fill="${color}"/>
  `;
  };

  const svgPointer = (width: number) => {
    const x1 = 31.5 + tagsCount * 10;
    const x2 = 33.5 + tagsCount * 10;
    const filterX = 27 + tagsCount * 10;

    return `
  <g filter="url(#filter1_d)">
    <rect x="${x1}" y="40" width="8" height="8" rx="4" fill="#21304E" stroke="#21304E"/>
    <rect x="${x2}" y="42" width="4" height="4" rx="2" fill="white"/>
  </g>
  <defs>
    <filter id="filter0_d" x="0" y="0" width="${width + 2}" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.234031 0 0 0 0 0.452962 0 0 0 0.58 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
    <filter id="filter1_d" x="${filterX}" y="39.5" width="17" height="17" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.235294 0 0 0 0 0.454902 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
  </defs>
</svg>`;
  };

  if (tagsCount === 3) {
    return (
      svgBaseTemplate(131) +
      svgTagTemplate(69, firstTagColor, 1) +
      svgTagTemplate(89, secondTagColor, 2) +
      svgTagTemplate(109, thirdTagColor, 3) +
      svgPointer(131)
    );
  } else if (tagsCount === 2) {
    return (
      svgBaseTemplate(111) +
      svgTagTemplate(69, firstTagColor, 1) +
      svgTagTemplate(89, secondTagColor, 2) +
      svgPointer(111)
    );
  } else if (tagsCount === 1) {
    return svgBaseTemplate(91) + svgTagTemplate(69, firstTagColor, 1) + svgPointer(91);
  } else {
    return svgBaseTemplate(71) + svgPointer(71);
  }
}
