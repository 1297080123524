import { DatePipe, DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { ManagerTourCalendarItem } from '@api-clients/api-client/models/manager-tour-calendar-item';
import { PriceCurrencyPipe } from '../../../pipes/price-currency.pipe';
import { SearchTourDetailsFlightsComponent } from './flights/search-tour-calendar-tour-detail-flights.component';

@Component({
  selector: 'app-search-tour-calendar-tour-detail',
  templateUrl: './search-tour-calendar-tour-detail.component.html',
  styleUrl: './search-tour-calendar-tour-detail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DecimalPipe, PriceCurrencyPipe, DatePipe, SearchTourDetailsFlightsComponent],
})
export class SearchTourCalendarTourDetailComponent {
  tour = input.required<ManagerTourCalendarItem>();

  nightsLabel = computed<string>(() => {
    const tour = this.tour();
    let nightsLabel = tour.nights.toString();
    if (tour.nightsOnWay) {
      nightsLabel += `+${tour.nightsOnWay}`;
    }

    return `${nightsLabel} н.`;
  });
}
