import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { SearchFormParams } from '@api-clients/api-client/dist/models';
import { CrmCardItem } from '@api-clients/crm-api-client';
import { SearchRequest } from '@api-clients/crm-api-client/models/search-request';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ChatsState } from '../../../../../../+state/chats/chats.reducer';
import { selectCurrentChatContactCrmCard } from '../../../../../../+state/chats/chats.selectors';
import { AuthenticationService } from '../../../../../../core/services';
import { PopupService } from '../../../../../../shared/services/popup-service.service';
import {
  AlertLabelComponent,
  AlertLabelType,
} from '../../../../../../ui-components/alert-label/alert-label.component';
import { LineProgressComponent } from '../../../../../../ui-components/line-progress/line-progress.component';
import { SearchHotelComponent } from '../../../../../search/components/hotel/search-hotel.component';
import { ToursSearchConnectionService } from '../../../../../search/services/search/tours-search-connection.service';
import { ChatDragAndDropService } from '../../services/chat-drag-and-drop.service';
import { SearchToursFiltersAndSortComponent } from './components/hotels-filters-and-sort/search-tours-filters-and-sort.component';
import { FavoriteHotelsListComponent } from './components/hotels-list/favorite-hotels-list.component';
// tslint:disable-next-line:max-line-length
import { SearchFormService } from './components/search-form/search-form.service';
import { SkeletonComponent } from './components/skeleton/favorite-hotels-tab-skeleton.component';
import { FavoritesHotelsSortsComponent } from './components/sorts/favorite-hotels-sorts.component';
import { SearchMode } from './favorites-hotels.model';
import { MultipleSearchComponent } from './modules/multiple-search/multiple-search.component';
import { SingleSearchComponent } from './modules/single-search/single-search.component';
import { SearchUiState, SearchUiStateService } from './services/search-ui-state.service';

@Component({
  selector: 'app-favorites-hotels-tab-content',
  templateUrl: './favorites-hotels-tab-content.component.html',
  styleUrls: ['./favorites-hotels-tab-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    FavoriteHotelsListComponent,
    AsyncPipe,
    SkeletonComponent,
    NgIf,
    FavoritesHotelsSortsComponent,
    NgForOf,
    SearchHotelComponent,
    LineProgressComponent,
    AlertLabelComponent,
    SearchToursFiltersAndSortComponent,
    MatTooltip,
    SingleSearchComponent,
    MultipleSearchComponent,
  ],
  providers: [ChatDragAndDropService, PopupService],
})
export class FavoritesHotelsTabContentComponent implements OnInit {
  @Input() crmCardItem: CrmCardItem;
  @Input() crmCardLastSearchRequest: SearchRequest;

  uiState = signal<SearchUiState>(undefined);
  activeSearchMode = signal<SearchMode>(SearchMode.singleCountrySearch);
  hasEmployeeToken = signal<boolean>(false);

  showLoadingFormParams = signal<boolean>(true);
  formParams = signal<SearchFormParams>(undefined);

  readonly hasEmployeeTokenAlertType = AlertLabelType.danger;
  readonly searchModeVariant = SearchMode;

  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly store: Store<ChatsState>,
    private readonly authService: AuthenticationService,
    private readonly searchFormService: SearchFormService,
    private readonly webSocketConnection: ToursSearchConnectionService,
    private readonly uiStateService: SearchUiStateService,
  ) {}

  ngOnInit() {
    this.hasEmployeeToken.set(!!this.authService.employeeToken);

    this.store
      .select(selectCurrentChatContactCrmCard)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(crmCardViewItem => {
        if (crmCardViewItem) {
          this.searchFormService.setActiveCrmCardItem(crmCardViewItem.card);
          this.crmCardItem = crmCardViewItem.card;
          this.crmCardLastSearchRequest = crmCardViewItem.lastSearchRequest;

          const uiState = this.uiStateService.getStateByCrmId(crmCardViewItem.card.id);
          if (uiState) {
            this.changeSearchMode(uiState.searchMode);
            this.uiState.set(uiState);
          }
        }
      });

    this.loadFormParams();
    this.webSocketConnection.getConnectionStatus().subscribe(() => {});

    if (this.crmCardItem) {
      this.searchFormService.setActiveCrmCardItem(this.crmCardItem);
      const uiState = this.uiStateService.getStateByCrmId(this.crmCardItem.id);
      if (uiState) {
        this.changeSearchMode(uiState.searchMode);
        this.uiState.set(uiState);
      }
    }
  }

  changeSearchMode(searchMode: SearchMode): void {
    this.activeSearchMode.set(searchMode);
  }

  toggleSearchForm() {
    this.searchFormService.toggleSearchForm();
  }

  private loadFormParams(): void {
    this.searchFormService
      .loadFormParams$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(formParams => {
        this.formParams.set(formParams);
        this.showLoadingFormParams.set(false);
        this.searchFormService.openSearchForm();
      });
  }
}
