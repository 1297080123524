@if (tour()) {
  <div class="tour-detail-container">
    <div class="detail-values">
      <div class="detail-value">
        <span>Вылет:</span> <span>{{ tour().date | date:'dd.MM' }}</span>
      </div>
      <div class="detail-value">
        <span>Ночи:</span> <span>{{ nightsLabel() }}</span>
      </div>
      <div class="detail-value">
        <span>Питание:</span> <span>{{ tour().mealNameShort }}</span>
      </div>
      <div class="detail-value">
        <span>Цена:</span>
        <span>{{ tour().price | number: '1.0-0' }} {{ tour().currency | priceCurrency }}</span>
      </div>
      <div class="flights-detail">
        <app-search-tour-detail-flights [tourId]="tour().tourId" />
      </div>
    </div>
  </div>
}
