import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  DestroyRef,
  inject,
  Injector,
  Input,
  OnInit,
  output,
  signal,
  ViewChild,
  viewChild,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  GoogleMap,
  MapAdvancedMarker,
  MapInfoWindow,
  MapMarker,
  MapMarkerClusterer,
} from '@angular/google-maps';
import { TopHotelListRequest } from '@api-clients/api-client/models';
import { MarkerClusterer, SuperClusterViewportAlgorithm } from '@googlemaps/markerclusterer';
import { SuperClusterOptions } from '@googlemaps/markerclusterer/dist/algorithms/supercluster';
import { merge, Observable, of, Subject, switchMap } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { SearchHotel } from '../../../../../../../../../search/search.model';
import { SearchResultService } from '../../../../../../../../../search/services/search-result.service';
import { InitSearchRequest } from '../../../../../../../../../search/services/search/websocket-tours.model';
import { HotelTagWithPriority, SearchResultGroup } from '../../../../favorites-hotels.model';
import { SearchHotelTagsService } from '../../../../services/search-hotel-tags.service';
import { SearchToursProgressService } from '../../../../services/search-tours-progress.service';
import { SearchFormService } from '../../../search-form/search-form.service';
import { SearchResultFiltersService } from '../../services/search-result-filters.service';
import { SearchResultUiService } from '../../services/search-result-ui.service';
import { SearchResultMapInfoWindowComponent } from './info-window/search-result-map-info-window.component';
import { options } from './map-options';
import {
  svgMarkerFull,
  svgMarkerRatingAndHotelStars,
  svgMarkerRatingAndPrice,
} from './marker/marker-svg.functions';
import createInfoWindow from './overlay/info-window.overlay';
import { SearchResultMapSearchOnPlaceComponent } from './search-on-place/search-result-map-search-on-place.component';

const LARGE_CLUSTER_RADIUS = 280;
const SMALL_CLUSTER_RADIUS = 160;

const LARGE_CLUSTER_MAX_ZOOM = 18;
const SMALL_CLUSTER_MAX_ZOOM = 16;

const HOTELS_COUNT_FOR_CHANGE_SIZE_CLUSTER = 3000;

@Component({
  selector: 'app-search-result-map',
  templateUrl: './search-result-map.component.html',
  styleUrl: './search-result-map.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    GoogleMap,
    MapAdvancedMarker,
    MapMarker,
    SearchResultMapSearchOnPlaceComponent,
    MapMarkerClusterer,
    MapInfoWindow,
    SearchResultMapInfoWindowComponent,
  ],
})
export class SearchResultMapComponent implements OnInit, AfterViewInit {
  @Input() resultGroups$: Observable<SearchResultGroup[]>;
  @Input() initSearchRequest: InitSearchRequest;

  @ViewChild('infoWindowContainer', { read: ViewContainerRef, static: true })
  viewContainerRef!: ViewContainerRef;

  windowWidth = signal<number>(0);
  windowHeight = signal<number>(0);

  map = viewChild(GoogleMap);
  mapCluster: MarkerClusterer;
  clusterMarkers: google.maps.marker.AdvancedMarkerElement[] = [];

  filterHotelStarsList = signal<string[]>([]);
  filterHotelRatingList = signal<number[]>([]);
  filterTourMinPrice = signal<number>(0);
  filterTourMaxPrice = signal<number>(0);
  filterTags = signal<Map<number, HotelTagWithPriority[]>>(new Map<number, HotelTagWithPriority[]>());
  filerOnlyWithPrice = signal<boolean>(false);

  boundsHotelIds = output<number[]>();

  mapCenter: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  mapOptions: google.maps.MapOptions = options;

  private firstTourChunkReceived = false;
  private resultGroups: SearchResultGroup[] = [];
  private destroyRef = inject(DestroyRef);
  private isResultsVisible = false;
  private tempCountryHotels: SearchHotel[] = [];
  private countryLatLngBounds: google.maps.LatLngBounds;
  private tagIds: number[] = [];
  private previousTagsRequest: TopHotelListRequest | null = null;
  private currentTagsRequest$: Observable<any> | null = null;
  private loadTagsSubject = new Subject<TopHotelListRequest>();
  private clusterRadius = SMALL_CLUSTER_RADIUS;
  private clusterMaxZoom = LARGE_CLUSTER_MAX_ZOOM;
  private countryTotalHotelsCount = 0;
  private markerDataMap = new WeakMap<
    google.maps.marker.AdvancedMarkerElement,
    {
      hotelId: number;
      hasTour: boolean;
      hasTags: boolean;
      tagIds: string;
      price: number;
    }
  >();

  constructor(
    private readonly injector: Injector,
    private readonly uiService: SearchResultUiService,
    private readonly searchFormService: SearchFormService,
    private readonly searchResultFiltersService: SearchResultFiltersService,
    private readonly searchResultService: SearchResultService,
    private readonly searchTagsService: SearchHotelTagsService,
    private readonly searchProgressService: SearchToursProgressService,
  ) {}

  ngAfterViewInit(): void {
    this.setMapSmallSize();
  }

  ngOnInit(): void {
    this.searchFormService.hotelsOnSearch$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(hotels => !!hotels.length),
      )
      .subscribe(hotels => {
        this.countryLatLngBounds = new google.maps.LatLngBounds();

        setTimeout(() => {
          hotels
            .filter(hotel => hotel.latitude && hotel.longitude)
            .forEach(hotel => {
              this.tempCountryHotels.push(hotel);

              const position = new google.maps.LatLng(hotel.latitude, hotel.longitude);
              this.countryLatLngBounds.extend(position);
            });
        });
      });

    this.searchFormService.startSearch$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.countryTotalHotelsCount = this.tempCountryHotels.length;
      this.firstTourChunkReceived = false;
      this.clearCluster();

      if (this.countryTotalHotelsCount > HOTELS_COUNT_FOR_CHANGE_SIZE_CLUSTER) {
        this.clusterRadius = LARGE_CLUSTER_RADIUS;
        this.clusterMaxZoom = LARGE_CLUSTER_MAX_ZOOM;
      } else {
        this.clusterRadius = SMALL_CLUSTER_RADIUS;
        this.clusterMaxZoom = SMALL_CLUSTER_MAX_ZOOM;
      }

      if (this.mapCluster) {
        this.mapCluster.onRemove();
        this.mapCluster = undefined;
      }

      this.tempCountryHotels = [];
      this.resultGroups = [];

      if (this.isResultsVisible) {
        this.fitBoundsMap();

        if (!this.mapCluster) {
          this.initializeCluster();
        }
      }
    });

    this.searchProgressService.searchCompleted$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.isResultsVisible && this.mapCluster) {
        this.updateMarkersOnMap();
      }
    });

    this.resultGroups$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(v => !!v.length),
        debounceTime(500),
      )
      .subscribe(resultGroups => {
        this.resultGroups = resultGroups.filter(
          resultGroup => resultGroup.hotel.latitude && resultGroup.hotel.longitude,
        );
        if (this.isResultsVisible && this.mapCluster) {
          this.updateMarkersOnMap();
        }

        if (!this.firstTourChunkReceived) {
          this.firstTourChunkReceived = true;
        }
      });

    this.searchResultService.showResultComponent$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(visible => {
        this.isResultsVisible = visible;
        if (this.isResultsVisible) {
          this.fitBoundsMap();

          if (!this.mapCluster) {
            this.initializeCluster();
          } else if (!this.clusterMarkers.length && this.resultGroups.length && this.clusterMarkers.length) {
            this.createClusterMarkersFromResultGroups();
          }
        }
      });

    this.uiService.toursVisibleUpdated$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(visible => {
      if (visible) {
        this.setMapSmallSize();
      } else {
        this.setMapFullSize();
      }

      google.maps.event.trigger(this.map().googleMap, 'resize');
    });

    this.searchResultService.showHotelOnMap$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(hotelId => {
      const resultGroup = this.resultGroups.find(marker => marker.hotel.id === hotelId);
      if (resultGroup) {
        const position = new google.maps.LatLng(resultGroup.hotel.latitude, resultGroup.hotel.longitude);

        this.map().googleMap.panTo(position);
        this.map().googleMap.setZoom(this.clusterMaxZoom);

        const clusterMarker = this.clusterMarkers.find(clusterMarker => {
          const metaData = this.markerDataMap.get(clusterMarker);

          return metaData && metaData.hotelId === hotelId;
        });

        if (clusterMarker) {
          let animated = true;
          clusterMarker.content = this.getMarkerIcon(resultGroup, animated);

          setTimeout(() => {
            animated = false;
            clusterMarker.content = this.getMarkerIcon(resultGroup, animated);
          }, 3200);
        }
      }
    });

    this.searchResultFiltersService.filterTagIds$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(tagIds => {
        this.tagIds = tagIds;
      });

    this.loadTagsSubject
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(100),
        switchMap(request => this.searchTagsService.loadHotelIdsByTags$(request)),
      )
      .subscribe(() => {
        this.updateMarkersOnMap();
        this.currentTagsRequest$ = null;
      });

    this.subscribeToFiltersEvents();
  }

  onZoomChanged(): void {
    if (this.mapCluster && this.countryTotalHotelsCount > HOTELS_COUNT_FOR_CHANGE_SIZE_CLUSTER) {
      const zoom = this.map().googleMap.getZoom();
      if (
        (zoom <= SMALL_CLUSTER_MAX_ZOOM && this.clusterRadius !== LARGE_CLUSTER_RADIUS) ||
        (zoom > SMALL_CLUSTER_MAX_ZOOM && this.clusterRadius !== SMALL_CLUSTER_RADIUS)
      ) {
        this.mapCluster.clearMarkers();
        this.mapCluster.onRemove();
        this.mapCluster = undefined;
        this.clusterRadius = zoom <= SMALL_CLUSTER_MAX_ZOOM ? LARGE_CLUSTER_RADIUS : LARGE_CLUSTER_RADIUS;

        this.initializeCluster();
        this.mapCluster.addMarkers(this.clusterMarkers);
        setTimeout(() => {
          this.onMapChanged();
        }, 200);
      }
    }
    this.onMapChanged();
  }

  onMapChanged(): void {
    if (this.tagIds.length) {
      const resultGroups = this.getResultGroupsByMapBounce();
      const request: TopHotelListRequest = {
        countryId: this.initSearchRequest.params.params.countryId,
        tagIds: this.tagIds,
        hotelIds: resultGroups.map(result => result.hotel.id).sort(),
        // @ts-ignore
        expand: ['tags'],
        disableFiltration: true,
      };

      if (this.isSameTagRequest(request)) {
        if (this.currentTagsRequest$) {
          this.currentTagsRequest$.subscribe(() => {
            this.updateMarkersOnMap();
          });
        }
      } else {
        this.previousTagsRequest = request;
        this.currentTagsRequest$ = of(request);
        this.loadTagsSubject.next(request);
      }
    } else {
      this.updateMarkersOnMap();
    }
  }

  private isSameTagRequest(request: TopHotelListRequest): boolean {
    return JSON.stringify(this.previousTagsRequest) === JSON.stringify(request);
  }

  getMarkerIcon(resultGroup: SearchResultGroup, animated = false) {
    const hotel = resultGroup.hotel;
    const tour = resultGroup.tours[0] || undefined;

    let svgString = '';
    const tags = this.filterTags().get(hotel.id) || [];
    if (tour) {
      const price = tour.brandPrice.value;
      if (hotel.bookingRating && hotel.stars) {
        svgString = svgMarkerFull(hotel.bookingRating, hotel.stars, price, tags);
      } else if (hotel.bookingRating) {
        svgString = svgMarkerRatingAndPrice(hotel.bookingRating, price, tags);
      } else {
        svgString = svgMarkerFull(0, hotel.stars, price, tags);
      }
    } else {
      svgString = svgMarkerRatingAndHotelStars(hotel.bookingRating, hotel.stars, tags);
    }

    const div = document.createElement('div');
    div.classList.add('map-marker');
    div.innerHTML = svgString;

    if (animated) {
      div.classList.add('marker-bounce-animation');
    }

    return div;
  }

  private updateMarkersOnMap(): void {
    if (!this.map() || !this.map().googleMap || !this.isResultsVisible) {
      return;
    }

    let resultGroups = this.getResultGroupsByMapBounce();
    resultGroups = this.applyFilters(resultGroups);

    const hotelIds = resultGroups.map(result => result.hotel.id);
    if (!hotelIds.length) {
      this.boundsHotelIds.emit([]);
      return;
    }

    const resultGroupsOutsideCluster: SearchResultGroup[] = [];
    const hotelIdsInsideCluster: number[] = [];
    resultGroups.forEach(resultGroup => {
      const clusterMarker = this.clusterMarkers.find(
        marker => this.markerDataMap.get(marker).hotelId === resultGroup.hotel.id,
      );
      if (clusterMarker) {
        const markerMetaData = this.markerDataMap.get(clusterMarker);

        hotelIdsInsideCluster.push(resultGroup.hotel.id);
        let needUpdate = false;

        const hotelTags = this.filterTags().get(resultGroup.hotel.id) || [];
        const hasTags = !!hotelTags.length;
        const hotelTagIds = hotelTags
          .map(tag => tag.tag.id)
          .sort()
          .join('+');
        const hasTour = !!resultGroup.tours?.length;
        const tourPrice = hasTour ? resultGroup.tours[0].brandPrice.value : 0;

        if (markerMetaData.hasTour !== hasTour) {
          needUpdate = true;
        } else {
          if (markerMetaData.price !== tourPrice) {
            needUpdate = true;
          } else if (markerMetaData.hasTags !== hasTags) {
            needUpdate = true;
          } else if (hasTags) {
            if (markerMetaData.tagIds !== hotelTagIds) {
              needUpdate = true;
            }
          }
        }

        if (needUpdate) {
          clusterMarker.content = this.getMarkerIcon(resultGroup);

          markerMetaData.price = tourPrice;
          markerMetaData.hasTour = hasTour;
          markerMetaData.hasTags = hasTags;
          markerMetaData.tagIds = hotelTagIds;

          this.markerDataMap.set(clusterMarker, markerMetaData);
        }
      } else {
        resultGroupsOutsideCluster.push(resultGroup);
      }
    });

    if (hotelIdsInsideCluster.length) {
      const markersForDelete = [];
      this.clusterMarkers.forEach(clusterMarker => {
        const markerMetaData = this.markerDataMap.get(clusterMarker);
        if (!hotelIdsInsideCluster.includes(markerMetaData.hotelId)) {
          markersForDelete.push(clusterMarker);
        }
      });
      this.clusterMarkers = this.clusterMarkers.filter(clusterMarker => {
        const markerMetaData = this.markerDataMap.get(clusterMarker);
        return hotelIdsInsideCluster.includes(markerMetaData.hotelId);
      });
      this.mapCluster.removeMarkers(markersForDelete);
    }

    if (resultGroupsOutsideCluster.length) {
      const newClusterMarkers = resultGroupsOutsideCluster
        .filter(result => result.hotel.latitude && result.hotel.longitude)
        .map(result => {
          return this.createClusterMarker(result);
        });
      this.clusterMarkers = this.clusterMarkers.concat(newClusterMarkers);
      try {
        this.mapCluster.addMarkers(newClusterMarkers);
      } catch (error) {
        console.error(error);
        console.log(newClusterMarkers);
      }
    }

    this.boundsHotelIds.emit(hotelIds);
  }

  private initializeCluster(): void {
    const options: SuperClusterOptions = {
      maxZoom: this.clusterMaxZoom,
      minPoints: 5,
      radius: this.clusterRadius,
    };

    this.mapCluster = new MarkerClusterer({
      markers: this.clusterMarkers,
      map: this.map().googleMap,
      algorithm: new SuperClusterViewportAlgorithm(options),
    });
  }

  private createClusterMarkersFromResultGroups(): void {
    const clusterMarkers = [];
    this.resultGroups.forEach(result => {
      clusterMarkers.push(this.createClusterMarker(result));
    });

    this.clusterMarkers = clusterMarkers;
    this.mapCluster.addMarkers(this.clusterMarkers);
  }

  private createClusterMarker(result: SearchResultGroup): google.maps.marker.AdvancedMarkerElement {
    const hotel = result.hotel;
    const tour = result.tours[0] || undefined;

    const position = new google.maps.LatLng(hotel.latitude, hotel.longitude);

    const clusterMarker = new google.maps.marker.AdvancedMarkerElement({
      position,
      content: this.getMarkerIcon(result),
    });

    const markerData = {
      hotelId: hotel.id,
      hasTour: !!tour,
      hasTags: this.filterTags().has(hotel.id),
      tagIds: '',
      price: tour ? tour.brandPrice.value : 0,
    };
    this.markerDataMap.set(clusterMarker, markerData);

    clusterMarker.addListener('click', () => {
      this.viewContainerRef.clear();
      const componentRef: ComponentRef<SearchResultMapInfoWindowComponent> =
        this.viewContainerRef.createComponent(SearchResultMapInfoWindowComponent, {
          injector: this.injector,
        });
      componentRef.instance.hotel = hotel;
      componentRef.instance.tour =
        this.resultGroups.find(result => result.hotel.id === hotel.id)?.tours[0] || undefined;
      componentRef.instance.initSearchRequest = this.initSearchRequest;
      componentRef.instance.tags = [];
      if (this.filterTags().has(hotel.id)) {
        componentRef.instance.tags = this.filterTags().get(hotel.id);
      }
      componentRef.changeDetectorRef.detectChanges();

      const popup = createInfoWindow({
        position: clusterMarker.position,
        content: componentRef.location.nativeElement,
      });
      popup.setMap(this.map().googleMap);

      componentRef.instance.close.subscribe(() => {
        popup.setMap(null);
      });
    });

    return clusterMarker;
  }

  private getResultGroupsByMapBounce(): SearchResultGroup[] {
    const bounds = this.map().googleMap.getBounds();
    if (!bounds) {
      return [];
    }

    return this.resultGroups.filter(resultGroup => {
      const position = new google.maps.LatLng(resultGroup.hotel.latitude, resultGroup.hotel.longitude);
      return bounds.contains(position);
    });
  }

  private setMapSmallSize(): void {
    this.windowWidth.set(window.innerWidth - 400);
    this.windowHeight.set(window.innerHeight - 50);
  }

  private setMapFullSize(): void {
    this.windowWidth.set(window.innerWidth);
    this.windowHeight.set(window.innerHeight - 50);
  }

  private applyFilters(resultGroups: SearchResultGroup[]): SearchResultGroup[] {
    if (this.filerOnlyWithPrice()) {
      resultGroups = resultGroups.filter(result => result.tours.length > 0);
    }

    if (this.filterHotelStarsList().length) {
      resultGroups = resultGroups.filter(result => this.filterHotelStarsList().includes(result.hotel.stars));
    }

    if (this.filterHotelRatingList().length) {
      resultGroups = resultGroups.filter(result => {
        let success = false;
        for (const rating of this.filterHotelRatingList()) {
          if (result.hotel.bookingRating >= rating) {
            success = true;
            break;
          }
        }

        return success;
      });
    }

    if (this.filterTourMinPrice()) {
      resultGroups = resultGroups
        .filter(result => !!result.tours?.length)
        .filter(result => {
          return result.tours[0].brandPrice.value >= this.filterTourMinPrice();
        });
    }

    if (this.filterTourMaxPrice()) {
      resultGroups = resultGroups
        .filter(result => !!result.tours?.length)
        .filter(result => {
          const correctionPrice = this.filterTourMaxPrice() <= 1000000 ? 10000 : 20000;
          return result.tours[0].brandPrice.value <= this.filterTourMaxPrice() + correctionPrice;
        });
    }

    if (this.filterTags().size) {
      resultGroups = resultGroups.filter(result => {
        return this.filterTags().has(result.hotel.id);
      });
    }

    return resultGroups;
  }

  private fitBoundsMap(): void {
    setTimeout(() => {
      this.map().googleMap.fitBounds(this.countryLatLngBounds);
    }, 100);
  }

  private clearCluster(): void {
    this.clusterMarkers.forEach(marker => {
      google.maps.event.clearInstanceListeners(marker);
    });

    this.mapCluster?.clearMarkers();
    this.clusterMarkers = [];
  }

  private subscribeToFiltersEvents(): void {
    const starsList$ = this.searchResultFiltersService.filterHotelStarsList$.pipe(
      takeUntilDestroyed(this.destroyRef),
    );
    const ratingList$ = this.searchResultFiltersService.filterHotelRatingList$.pipe(
      takeUntilDestroyed(this.destroyRef),
    );
    const prices$ = this.searchResultFiltersService.filterPrices$.pipe(takeUntilDestroyed(this.destroyRef));
    const tags$ = this.searchTagsService.tags$.pipe(takeUntilDestroyed(this.destroyRef));
    const onlyWithPrice$ = this.searchResultFiltersService.filterOnlyWithPrice$.pipe(
      takeUntilDestroyed(this.destroyRef),
    );

    starsList$.subscribe(starsList => {
      this.filterHotelStarsList.set(starsList);
    });

    ratingList$.subscribe(ratingList => {
      this.filterHotelRatingList.set(ratingList);
    });

    prices$.subscribe(prices => {
      this.filterTourMinPrice.set(prices.from);
      this.filterTourMaxPrice.set(prices.to);
    });

    tags$.subscribe(tags => {
      this.filterTags.set(tags);
    });

    onlyWithPrice$.subscribe(onlyWithPrice => {
      this.filerOnlyWithPrice.set(onlyWithPrice);
    });

    merge(starsList$, ratingList$, prices$, tags$, onlyWithPrice$)
      .pipe(
        debounceTime(100),
        filter(() => this.firstTourChunkReceived),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.updateMarkersOnMap();
      });
  }
}
