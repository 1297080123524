import { NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-form-nights',
  templateUrl: './search-form-nights.component.html',
  styleUrls: ['./search-form-nights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, NgForOf, NgClickOutsideDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SearchFormNightsComponent),
    },
  ],
})
export class SearchFormNightsComponent implements OnInit, OnDestroy, ControlValueAccessor {
  availableNightsInDirection = input<number[]>();

  hoveredDays: number;

  form: FormGroup;

  private selectedNightsFrom: number;
  private selectedNightsTo: number;

  private formValueChangesSubscription: Subscription;

  constructor(private readonly fb: FormBuilder) {}

  ngOnInit() {
    const fomOpts = {
      validators: [Validators.required],
      nonNullable: true,
    };
    this.form = this.fb.group({
      from: new FormControl<number>(3, fomOpts),
      to: new FormControl<number>(5, fomOpts),
    });

    this.selectedNightsFrom = this.nightsFrom;
    this.selectedNightsTo = this.nightsTo;

    this.formValueChangesSubscription = this.form.valueChanges.subscribe(value => {
      this.onChange(value);
      this.onTouched();
    });
  }

  ngOnDestroy() {
    this.formValueChangesSubscription?.unsubscribe();
  }

  onChange = (value: any) => {};
  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  writeValue(value: any): void {
    if (value) {
      this.form.setValue(value, { emitEvent: false });
      this.selectedNightsFrom = value.from;
      this.selectedNightsTo = value.to;
    } else {
      this.form.reset();
    }
  }

  get nightsFrom(): number {
    return this.selectedNightsFrom;
  }

  get nightsTo(): number {
    return this.selectedNightsTo;
  }

  selectDaysCount(daysCount: number): void {
    if (this.selectedNightsFrom && this.selectedNightsTo) {
      this.selectedNightsFrom = undefined;
      this.selectedNightsTo = undefined;
    }

    if (this.selectedNightsFrom) {
      if (daysCount < this.selectedNightsFrom) {
        this.selectedNightsTo = this.selectedNightsFrom;
        this.selectedNightsFrom = daysCount;
      } else {
        this.selectedNightsTo = daysCount;
      }
    } else {
      this.selectedNightsFrom = daysCount;
    }

    if (this.selectedNightsFrom && this.selectedNightsTo) {
      this.form.setValue({
        from: this.selectedNightsFrom,
        to: this.selectedNightsTo,
      });
    }
  }

  clickOutside(): void {
    if (this.selectedNightsFrom && !this.selectedNightsTo) {
      this.selectedNightsTo = this.selectedNightsFrom;
      this.form.setValue({
        from: this.selectedNightsFrom,
        to: this.selectedNightsTo,
      });
    }
  }

  onMouseEnter(daysCount: number): void {
    this.hoveredDays = daysCount;
  }

  onMouseLeave(): void {
    this.hoveredDays = null;
  }

  isInRange(daysCount: number): boolean {
    if (this.nightsFrom && !this.nightsTo && this.hoveredDays) {
      return (
        (this.nightsFrom < daysCount && daysCount <= this.hoveredDays) ||
        (this.nightsFrom > daysCount && daysCount >= this.hoveredDays)
      );
    } else if (this.nightsFrom && this.nightsTo) {
      return (
        (this.nightsFrom < daysCount && daysCount < this.nightsTo) ||
        (this.nightsFrom > daysCount && daysCount > this.nightsTo)
      );
    }
    return false;
  }
}
