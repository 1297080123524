import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CrmCardViewItem } from '@api-clients/crm-api-client/dist/models';
import { DealItem } from '@api-clients/crm-api-client';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { dateDiff } from '../../../../../../helpers/dateDiff';
import { ClientActionsModalService } from '../../../client-actions-on-site/services/client-actions-modal.service';
import { DealSource } from '../../../../../../models';
import { ConfigsService, ElectronService } from '../../../../../../core/services';
import {
  AUTO_TOUCHES_STATUS_SET,
  DEAL_OPEN_IN_SITE,
  DEAL_SEARCH_FOR_TOURS,
} from '../../../../../../core/services/amplitude/amplitudeEvents';
import { AmplitudeTrackService } from '../../../../../../core/services/amplitude/amplitude-track.service';
import { DealViewService } from '../../services/deal-view.service';
import { AddDealPopupService } from '../../../add-deal/services/add-deal-popup.service';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SlideToggleModule } from 'app/ui-components/slide-toggle/slide-toggle.module';

const DATE_FORMAT = 'dd.MM.yyyy';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, SlideToggleModule],
})
export class StatisticsComponent implements OnInit, OnDestroy {
  public readonly screenType: ScreenTypes = ScreenTypes.DEAL_VIEW;
  public inWorkSince: string;
  public lastInfoUpdate: Date | null;
  public callsCount = 0;
  public DATE_FORMAT = DATE_FORMAT;
  public dealSource: DealSource;
  public dealSourcesSubscription: Subscription;
  public adminUrl: string;
  @Input() deal: CrmCardViewItem;
  @Input() clientSourceId: number;

  constructor(
    private clientActionsModalService: ClientActionsModalService,
    private configsService: ConfigsService,

    private dealViewService: DealViewService,
    private amplitudeTrackService: AmplitudeTrackService,
    private electron: ElectronService,
    private configService: ConfigsService,
    private addDealPopupService: AddDealPopupService,
  ) {
    this.adminUrl = this.configService.adminUrl;
  }

  ngOnInit(): void {
    if (this.clientSourceId) {
      this.dealSourcesSubscription = this.configsService.dealSources$.subscribe(sources => {
        this.dealSource = sources.find(source => source.id === this.clientSourceId);
      });
    }

    if (this.deal && this.deal.deal && this.deal.card) {
      const dealId = this.deal?.deal?.id;
      this.lastInfoUpdate = new Date(this.deal.card.lastInfoUpdate) || null;
      const { startWorkDate }: DealItem = this.deal?.deal;
      if (startWorkDate) {
        this.inWorkSince = dateDiff(new Date(startWorkDate));
      }

      this.callsCount = this.deal.calls.filter(call => call.dealId === dealId).length;
    }
  }

  showModal(actionsCount: number, crmCardId: number, name: string) {
    if (actionsCount > 0) {
      this.clientActionsModalService.showModal(crmCardId, name);
    }
  }

  ngOnDestroy(): void {
    if (this.dealSourcesSubscription) {
      this.dealSourcesSubscription.unsubscribe();
    }
  }
  searchForTours(): void {
    this.amplitudeTrackService.trackEvent(DEAL_SEARCH_FOR_TOURS, { screenType: this.screenType });
    const crmCardId: number = this.deal.card.id;
    const url = `${this.adminUrl}/search/manager?crm_id=${crmCardId}`;
    this.electron.shell.openExternal(url).then();
  }
  openOnSite(): void {
    this.amplitudeTrackService.trackEvent(DEAL_OPEN_IN_SITE, { screenType: this.screenType });
    const crmCardId: number = this.deal.card.id;
    const url = `${this.adminUrl}/crm/crmUser/view/id/${crmCardId}`;
    this.electron.shell.openExternal(url).then();
  }

  autoActionsDisabledChanged(event: boolean) {
    this.amplitudeTrackService.trackEvent(AUTO_TOUCHES_STATUS_SET, {
      value: event ? 'on' : 'off',
      screenType: this.screenType,
    });
    this.dealViewService.updateAutoActionsDisabled(this.deal.deal.id, event).pipe(first()).subscribe();
  }

  showAddDealPopup() {
    this.addDealPopupService.showAddDealPopup(this.deal?.card?.phone);
  }
}
