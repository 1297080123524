<div class="row mb">
  <app-hot-offers-filter [departCityId]="departCityId"
    [type]="filterType.COUNTRIES"
    [name]="'Направление'"
    [showCounter]="true"
    [values$]="filterByCountries$" />
</div>

<div class="row row-other-filters mb">
  <app-hot-offers-filter [departCityId]="departCityId"
    [type]="filterType.NIGHTS"
    [name]="'Ночи'"
    [values$]="filterByNights$" />

  <app-hot-offers-filter [departCityId]="departCityId"
    [type]="filterType.BOOKING_RATING"
    [name]="'Рейтинг'"
    [values$]="filterByRatings$" />

  <app-hot-offers-filter [departCityId]="departCityId"
    [type]="filterType.HOTEL_STARS"
    [name]="'Звездность'"
    [values$]="filterByHotelStars$" />
</div>

<div class="row mb">
  <app-hot-offers-filter-calendar [departCityId]="departCityId"
    [type]="filterType.DEPART_DATE"
    [values$]="filterByDepartDates$" />
</div>

<div class="row-active-filters">
  <app-hot-offers-short-offers-modal [departCityId]="departCityId" />

  @if (hasActiveFilters$ | async) {
    <app-hot-offers-active-flush-all
      (click)="flushFilters()" />
  }

  @if (activeFiltersByCountries$ | async; as countries) {
    @for (country of countries; track country) {
      <app-hot-offers-active-filter-value
        [label]="country.labelFilter"
        (click)="removeFilter(country)" />
    }
  }

  @if (activeFiltersByNights$ | async; as nights) {
    @for (night of nights; track night) {
      <app-hot-offers-active-filter-value
        [label]="night.label + ' н'"
        (click)="removeFilter(night)" />
    }
  }

  @if (activeFiltersByHotelRating$ | async; as ratings) {
    @for (rating of ratings; track rating) {
      <app-hot-offers-active-filter-value
        [label]="rating.label"
        (click)="removeFilter(rating)" />
    }
  }

  @if (activeFiltersByHotelStars$ | async; as starsList) {
    @for (stars of starsList; track stars) {
      <app-hot-offers-active-filter-value
        [label]="stars.value + ' звезд '"
        (click)="removeFilter(stars)" />
    }
  }

  @if (activeFiltersByDepartDates$ | async; as departDates) {
    <app-hot-offers-active-filter-value [label]="departDates.labelFilter"
      (click)="removeDepartDatesFilter()" />
  }
</div>
