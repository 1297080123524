import { ChangeDetectionStrategy, Component, DestroyRef, inject, input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ChatSendMessageRequest, PhoneItem, ChatRecommendationType } from '@api-clients/crm-api-client';
import { ChatRecommendationMessage } from '@api-clients/crm-api-client/models/chat-recommendation-message';
import { ChatMessagesFacade } from '../../../../+state/chat-messages/chat-messages.facade';
import { ChatsFacade } from '../../../../+state/chats/chats.facade';
import { AmplitudeTrackService } from '../../../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../../../core/services/amplitude/amplitudeEventData';
import {
  AmplitudeEventsType,
  CHAT_RECOMMENDATION_MESSAGE_DELETE,
  CHAT_RECOMMENDATION_MESSAGE_EDIT,
  CHAT_RECOMMENDATION_MESSAGE_OPEN_EDIT_MODAL,
  CHAT_RECOMMENDATION_MESSAGE_SEND,
  CHAT_RECOMMENDATION_VIEW,
} from '../../../../core/services/amplitude/amplitudeEvents';
import { WhatsappMessageQueueService } from '../../../whatsapp/services/whatsapp-message-queue.service';
import { ChatRecommendation } from '../../interfaces/chat.interface';
import { ChatRecommendationsApiService } from '../../services/chat-recommendations-api.service';
import { EditMessageRecommendationModalService } from '../../services/edit-message-recommendation-modal.service';

@Component({
  selector: 'app-message-recommendation',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './message-recommendation.component.html',
  styleUrls: ['./message-recommendation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageRecommendationComponent implements OnInit {
  /**
   * Текущий номер телефона в активном чате
   */
  private currentPhoneItem: PhoneItem;

  public recommendation = input<ChatRecommendation>();
  public hasTodayMessages = toSignal(this.chatsMessagesFacade.chatHasTodayMessages$);
  private destroyRef = inject(DestroyRef);

  constructor(
    private editMessageRecommendationModalService: EditMessageRecommendationModalService,
    private whatsappMessageQueueService: WhatsappMessageQueueService,
    private chatsFacade: ChatsFacade,
    private chatsMessagesFacade: ChatMessagesFacade,
    private chatRecommendationsApiService: ChatRecommendationsApiService,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {
    this.chatsFacade.currentChatContact$.pipe(takeUntilDestroyed()).subscribe(contactListItem => {
      this.currentPhoneItem = contactListItem.contact?.phone;
    });
  }

  private trackEvent(
    event: AmplitudeEventsType,
    message: ChatRecommendationMessage = null,
    changedText: string = null,
  ): void {
    const eventParams = {
      'Recommendation ID': this.recommendation().id,
    };
    if (message) {
      eventParams['Message ID'] = message.id;
      eventParams['Message text'] = message.text;
    }
    if (changedText || event === CHAT_RECOMMENDATION_MESSAGE_EDIT) {
      eventParams['Changed message text'] = changedText;
    }
    this.amplitudeTrackService.trackEvent(event, eventParams);
  }

  ngOnInit(): void {
    this.chatRecommendationsApiService
      .markAsViewed(this.recommendation().id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();

    this.trackEvent(CHAT_RECOMMENDATION_VIEW);
  }

  public deleteMessage(message: ChatRecommendationMessage): void {
    this.chatsFacade.markRecommendationMessageAsDeleted(message);

    this.trackEvent(CHAT_RECOMMENDATION_MESSAGE_DELETE, message);
  }

  public editMessage(message: ChatRecommendationMessage): void {
    this.trackEvent(CHAT_RECOMMENDATION_MESSAGE_OPEN_EDIT_MODAL, message);
    const messageText = this.getMessageText(message);
    this.editMessageRecommendationModalService
      .showModal(messageText)
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(modalResult => {
        if (modalResult?.action === 'sendMessage') {
          const changedMessageText = modalResult.messageText !== messageText ? modalResult.messageText : null;
          this.trackEvent(CHAT_RECOMMENDATION_MESSAGE_EDIT, message, changedMessageText);

          this.sendMessage(message, changedMessageText);
        }
      });
  }

  get reason(): string {
    switch (this.recommendation().type) {
      case ChatRecommendationType.FrequentOfferViews:
        return (
          'Эта рекомендация показана потому что клиент активно просматривал ваши подборки. ' +
          'А этот отель больше остальных!'
        );
      default:
        return 'Коснитесь, закройте или перенесите клиента';
    }
  }

  getMessageText(message: ChatRecommendationMessage): string {
    // Если это первое сообщение за сегодня, то нужно поздороваться
    const isFirstRecommendationMessage = this.recommendation().messages[0].id === message.id;
    return (isFirstRecommendationMessage && !this.hasTodayMessages() ? 'Здравствуйте! ' : '') + message.text;
  }

  public sendMessage(message: ChatRecommendationMessage, changedText: string = null): void {
    this.chatsFacade.markRecommendationMessageAsSent(message, changedText);
    const text = changedText || this.getMessageText(message);
    const chatMessage: ChatSendMessageRequest = {
      phoneOrChatId: `${this.currentPhoneItem.code}${this.currentPhoneItem.phone}`,
      text,
      place: ScreenTypes.MESSAGE_RECOMMENDATION,
    };
    if (message.sourceChatMessageId) {
      chatMessage.quotedMessageId = message.sourceChatMessageId;
    }
    this.whatsappMessageQueueService.queueMessage(this.currentPhoneItem, chatMessage);

    this.trackEvent(CHAT_RECOMMENDATION_MESSAGE_SEND, message, changedText);
  }
}
