<ng-container *ngIf="isLoading">
  <div class="timeline-loader">
    <app-frontend-basic-loader [height]="32" [width]="32"> </app-frontend-basic-loader>
  </div>
</ng-container>

<ng-container *ngIf="isChatTimelineInitialized">
  <ng-content></ng-content>
  <div
    class="timeline"
    [ngClass]="{ 'no-tasks': isNoTasks }"
    *ngIf="chatTimeLineDayItems?.length; else voidChatTimeline"
    #timeline
  >
    <app-whatsapp-timeline-chat
      [style.visibility]="isShowWhatsappChat ? 'visible' : 'hidden'"
      (maximized)="onMaximized($event)"
      (messagesChanged)="this.whatsappMessagesChanged($event)"
      [phoneItemOrChatId]="currentPhoneOrChatId"
    >
    </app-whatsapp-timeline-chat>
    <div *ngFor="let item of chatTimeLineDayItems; let i = index">
      <app-chat-timeline-day
        #messageItem
        [isLastDay]="i === 0"
        [dayItem]="item"
        (maximized)="onMaximized($event)"
      >
        <app-label-date [date]="item.sortDate"></app-label-date>
      </app-chat-timeline-day>
    </div>
  </div>
  @if (chatRecommendation$ | async; as recommendation) {
    <app-message-recommendation [recommendation]="recommendation" />
  }
</ng-container>

<ng-template #voidChatTimeline>
  <div class="timeline no-records">
    <span [style.visibility]="isShowWhatsappChat && isFirstMessagesInitialized ? 'hidden' : 'visible'"
      >Отсутствуют записи</span
    >

    <app-whatsapp-timeline-chat
      [style.visibility]="isShowWhatsappChat ? 'visible' : 'hidden'"
      (maximized)="onMaximized($event)"
      (messagesChanged)="this.whatsappMessagesChanged($event)"
      [phoneItemOrChatId]="currentPhoneOrChatId"
    >
    </app-whatsapp-timeline-chat>
  </div>
</ng-template>

<app-image-viewer
  (closed)="closeImageViewer()"
  [images]="images"
  [currentImageIdx]="currentImageIdx"
  *ngIf="images?.length && isMaximized"
>
</app-image-viewer>
