import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { WhatsappMessage } from '@api-clients/crm-api-client/dist/models';
import { Subscription } from 'rxjs';
import { PhoneItem } from '@api-clients/crm-api-client';
import { map } from 'rxjs/operators';
import { ChatMessagesPayload } from '../../../../+state/chat-messages/chat-messages.interface';
import { ChatTimeLineItem } from '../../interfaces/chat-timeline.interface';
import { ChatTimelineService } from '../../services/chat-timeline.service';
import { ChatMessagesFacade } from '../../../../+state/chat-messages/chat-messages.facade';
import { isPhoneOrChatIdChanged } from '../../../../+state/chat-messages/chat-messages.helper';

@Component({
  selector: 'app-whatsapp-timeline-chat',
  templateUrl: './whatsapp-timeline-chat.component.html',
  styleUrls: ['./whatsapp-timeline-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatsappTimelineChatComponent implements OnInit, OnDestroy, OnChanges {
  public chatTimeLineItems: ChatTimeLineItem[];
  public chatMessagesSub: Subscription;
  public chatMessages$ = this.chatMessagesFacade.chatMessages$.pipe(
    map(messages => {
      const items: ChatTimeLineItem[] = messages.map(message => this.generateChatTimeLineItem(message));
      this.addLastInGroupFlag(items);
      return items;
    }),
  );
  @Input() phoneItemOrChatId: PhoneItem | string;
  @Output() maximized: EventEmitter<string> = new EventEmitter();
  @Output() messagesChanged = new EventEmitter<ChatTimeLineItem[]>();
  constructor(
    private chatMessagesFacade: ChatMessagesFacade,
    private chatTimelineService: ChatTimelineService,
    private cdRef: ChangeDetectorRef,
  ) {}

  setChatMessages() {
    const chatMessagesStatePayload: ChatMessagesPayload = {
      messages: [],
      contactPhoneItemOrChatId: this.phoneItemOrChatId,
    };
    this.chatMessagesFacade.setChatMessagesState(chatMessagesStatePayload);
  }

  ngOnInit(): void {
    this.setChatMessages();
    this.chatMessagesSub = this.chatMessagesFacade.chatMessages$.subscribe((messages: WhatsappMessage[]) => {
      if (!messages.length) {
        this.chatTimeLineItems = [];
        this.cdRef.detectChanges();
      }
      if (messages && messages.length) {
        this.chatTimeLineItems =
          this.chatTimelineService.generateChatTimeLineItemsByWhatsappMessages(messages);
        this.messagesChanged.emit(this.chatTimeLineItems);
        this.cdRef.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.chatMessagesSub) {
      this.chatMessagesSub.unsubscribe();
    }
    this.chatMessagesFacade.resetChat();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.phoneItemOrChatId) {
      const previousValue = changes.phoneItemOrChatId?.previousValue;
      const currentValue = changes.phoneItemOrChatId?.currentValue;

      if (isPhoneOrChatIdChanged(previousValue, currentValue)) {
        return this.setChatMessages();
      }
    }
  }

  generateChatTimeLineItem(item: WhatsappMessage) {
    return this.chatTimelineService.generateChatTimeLineItemByWhatsappMessage(item);
  }

  trackByFunction(index, item) {
    return `${item.data.id}-${item.data.status}-${item.data.isDeleted}`;
  }

  public addLastInGroupFlag(items: ChatTimeLineItem[]) {
    return this.chatTimelineService.addFirstAndLastInGroupFlag(items);
  }
}
