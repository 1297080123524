import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  computed,
  DestroyRef,
  inject,
  input,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { SearchFormParams } from '@api-clients/api-client/dist/models';
import { SearchFormParamsCountry } from '@api-clients/api-client/models/search-form-params-country';
import { CrmCardItem } from '@api-clients/crm-api-client';
import { SearchRequest as CrmCardSearchRequest } from '@api-clients/crm-api-client/models/search-request';
import { BehaviorSubject, switchMap } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { PopupService } from '../../../../../../../../shared/services/popup-service.service';
import { AlertLabelComponent } from '../../../../../../../../ui-components/alert-label/alert-label.component';
import { BrxButtonComponent } from '../../../../../../../../ui-components/hermes/button/brx-button.component';
import { LineProgressComponent } from '../../../../../../../../ui-components/line-progress/line-progress.component';
import { convertToursToStorageSearchGroupResult } from '../../../../../../../search/helpers/search-tour-group.helper';
import { SEARCH_DATE_FORMAT, SearchHotel } from '../../../../../../../search/search.model';
import { SearchResultService } from '../../../../../../../search/services/search-result.service';
import { ToursStorageService } from '../../../../../../../search/services/search/tours-storage.service';
import { WebsocketToursSearchService } from '../../../../../../../search/services/search/websocket-tours-search.service';
import {
  InitSearchRequest,
  InitSearchRequestMethod,
  InitSearchRequestOptionsGroupResult,
  InitSearchRequestParams,
  InitSearchRequestType,
} from '../../../../../../../search/services/search/websocket-tours.model';
import { SearchToursFiltersAndSortComponent } from '../../components/hotels-filters-and-sort/search-tours-filters-and-sort.component';
import { FavoriteHotelsListComponent } from '../../components/hotels-list/favorite-hotels-list.component';
import { SearchFormComponent } from '../../components/search-form/search-form.component';
import { SearchFormService } from '../../components/search-form/search-form.service';
import { SearchResultFiltersService } from '../../components/search-result/services/search-result-filters.service';
import { SkeletonComponent } from '../../components/skeleton/favorite-hotels-tab-skeleton.component';
import {
  FavoriteHotelsDefaultSortDirection,
  FavoriteHotelsSearchFormParams,
  SearchMode,
  SearchResultGroup,
  SearchResultSortDirection,
} from '../../favorites-hotels.model';
import { filterHotelsBySearchQueryFunctions } from '../../functions/filter-hotels-by-search-query.functions';
import { sortResultGroup } from '../../functions/sort-result-group.functions';
import { SearchToursProgressService } from '../../services/search-tours-progress.service';
import { SearchUiState, SearchUiStateService } from '../../services/search-ui-state.service';

@Component({
  selector: 'app-single-search',
  templateUrl: './single-search.component.html',
  styleUrl: './single-search.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AlertLabelComponent,
    AsyncPipe,
    FavoriteHotelsListComponent,
    LineProgressComponent,
    NgIf,
    SearchToursFiltersAndSortComponent,
    SkeletonComponent,
    BrxButtonComponent,
  ],
  providers: [PopupService],
})
export class SingleSearchComponent implements OnInit, OnDestroy {
  formParams = input.required<SearchFormParams>();
  crmCardItem = input<CrmCardItem>();
  crmCardLastSearchRequest = input<CrmCardSearchRequest>();
  uiState = input<SearchUiState>();

  showSearchResult = signal<boolean>(false);
  showToursNotFound = signal<boolean>(false);
  showMapButton = signal<boolean>(false);
  restoreUIHandled = signal<boolean>(false);
  allowRestoreUI = computed<boolean>(() => {
    return !!this.uiState();
  });
  showRestoreUIButton = computed<boolean>(() => {
    return this.allowRestoreUI() && !this.restoreUIHandled();
  });

  loadingHotelsList$ = this.searchFormService.loadingHotelsList$;

  searchInProgress = toSignal(this.searchToursProgressService.searchInProgress$);
  hotelsSort = signal<SearchResultSortDirection>(FavoriteHotelsDefaultSortDirection);
  searchQuery = signal<string>('');
  searchResultHotels = signal<SearchResultGroup[]>([]);
  visibleHotels = computed<SearchResultGroup[]>(() => {
    const results: SearchResultGroup[] = [];

    const sortDirection = this.hotelsSort();
    const searchText = this.searchQuery();
    const searchResultsHotels = this.searchResultHotels();

    let hotels = this.allHotelsInCountry();
    hotels = filterHotelsBySearchQueryFunctions(hotels, searchText);

    for (const countryHotel of hotels) {
      const result: SearchResultGroup = {
        hotel: countryHotel,
        tours: [],
      };
      for (const searchResultsHotel of searchResultsHotels) {
        if (searchResultsHotel.hotel.id === countryHotel.id) {
          result.tours = searchResultsHotel.tours;
          break;
        }
      }
      results.push(result);
    }
    return [...results].sort((a, b) => sortResultGroup(sortDirection, a, b));
  });
  toursCount = signal<number>(0);
  selectedCountry = signal<SearchFormParamsCountry>(undefined);

  lastSearchFormParams: FavoriteHotelsSearchFormParams;
  lastSearchInitRequest = signal<InitSearchRequest>(undefined);
  previewSearchInitRequest = signal<InitSearchRequest>(undefined);
  initSearchRequest = computed<InitSearchRequest>(() => {
    return this.lastSearchInitRequest() || this.previewSearchInitRequest();
  });

  private allHotelsInCountry = signal<SearchHotel[]>([]);
  private mainSearchId = '';
  private mainSearchId$ = new BehaviorSubject(this.mainSearchId);
  private componentSearchFormRef: ComponentRef<SearchFormComponent>;
  private isSearchResultOpen = false;
  private isSearchFormOpen = false;
  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly datePipe: DatePipe,
    private readonly popupService: PopupService,
    private readonly searchToursService: WebsocketToursSearchService,
    private readonly searchResultFiltersService: SearchResultFiltersService,
    private readonly toursStorage: ToursStorageService,
    private readonly searchResultService: SearchResultService,
    private readonly searchToursProgressService: SearchToursProgressService,
    private readonly searchFormService: SearchFormService,
    private readonly uiStateService: SearchUiStateService,
  ) {}

  ngOnInit(): void {
    this.searchFormService.showSearchForm$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(showSearchForm => {
        if (showSearchForm) {
          this.openSearchForm();
        } else {
          this.closeSearchForm();
        }
      });

    this.searchFormService.hotelsOnSearch$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(hotels => !!hotels.length),
      )
      .subscribe(hotels => {
        this.allHotelsInCountry.set(hotels);
      });

    this.searchFormService.selectedCountry$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(v => !!v),
      )
      .subscribe(selectedCountry => {
        this.mainSearchId = WebsocketToursSearchService.generateId();
        this.mainSearchId$.next(this.mainSearchId);

        if (!this.isSearchResultOpen) {
          this.showToursNotFound.set(false);
          if (this.selectedCountry()?.id !== selectedCountry.id) {
            this.searchToursProgressService.searchCompleted();

            this.selectedCountry.set(selectedCountry);
            this.showSearchResult.set(false);
            this.showMapButton.set(false);
          }
        }
      });

    this.searchResultService.showResultComponent$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(isSearchResultOpen => {
        this.isSearchResultOpen = isSearchResultOpen;
        if (this.isSearchResultOpen && this.isSearchFormOpen) {
          this.searchFormService.closeSearchForm();
        }
      });

    this.mainSearchId$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(v => !!v),
        switchMap(searchId => {
          return this.toursStorage.getSearchResults$(searchId).pipe(filter(tours => !!tours.length));
        }),
      )
      .subscribe(tours => {
        const groups = convertToursToStorageSearchGroupResult(
          tours,
          InitSearchRequestOptionsGroupResult.byHotel,
        );
        const groupsByHotels: { [key: number]: SearchResultGroup } = {};
        this.allHotelsInCountry().forEach(hotel => {
          const tours = groups.hasOwnProperty(hotel.id) ? groups[hotel.id] : [];
          if (tours.length) {
            hotel.conversionPercent = tours[0].hotel.conversionPercent;
          }
          groupsByHotels[hotel.id] = {
            hotel,
            tours,
          };
        });

        this.showMapButton.set(true);

        const groupsByHotelsValues: SearchResultGroup[] = Object.values(groupsByHotels);
        if (groupsByHotelsValues.length) {
          this.searchResultHotels.set(groupsByHotelsValues);
          this.searchResultService.setSearchResultGroups(groupsByHotelsValues);

          this.uiStateService.setResultGroups(this.crmCardItem().id, groupsByHotelsValues);

          this.showSearchResult.set(true);
        }
      });

    this.searchFormService.startSearch$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => {
          if (this.mainSearchId) {
            this.toursStorage.flushSearchResults(this.mainSearchId);
          }

          this.mainSearchId = WebsocketToursSearchService.generateId();
          this.mainSearchId$.next(this.mainSearchId);

          this.showToursNotFound.set(false);
          this.showSearchResult.set(false);

          this.emitHotelsInSearchResult();

          this.searchToursProgressService.searchCompleted();
          setTimeout(() => {
            this.searchToursProgressService.searchStarted();
          }, 100);

          this.searchResultHotels.set([]);

          this.searchResultFiltersService.flushFilters();
        }),
        switchMap(searchParams => {
          const initSearchRequest = this.prepareSearchRequest(searchParams);
          this.lastSearchFormParams = searchParams;
          this.lastSearchInitRequest.set(initSearchRequest);

          const newState: SearchUiState = {
            ...(this.uiStateService.getDefaultState() as any),
            searchMode: SearchMode.singleCountrySearch,
            crmId: this.crmCardItem().id,
          };
          this.uiStateService.setStateByCrmId(newState);

          this.searchFormService.setCurrentInitSearchRequest(initSearchRequest);

          return this.searchToursService.searchTours(initSearchRequest);
        }),
      )
      .subscribe(result => {
        this.toursCount.set(result.totalTours);

        if (result.searchIsDone) {
          this.searchToursProgressService.searchCompleted();

          if (!result.hasTours) {
            this.showToursNotFound.set(true);
            this.showSearchResult.set(false);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.closeSearchForm();
  }

  updateSort(sort: SearchResultSortDirection) {
    if (this.crmCardItem()) {
      this.uiStateService.setSortDirection(this.crmCardItem().id, sort);
    }

    this.hotelsSort.update(() => sort);
  }

  onSearchUpdated(sq: string) {
    this.searchQuery.set(sq);
  }

  restoreUI(): void {
    this.restoreUIHandled.set(true);
    this.showSearchResult.set(true);
    this.hotelsSort.update(() => this.uiState().sortDirection);
    this.searchResultHotels.set(this.uiState().resultGroups);
  }

  startNewSearchUI(): void {
    this.restoreUIHandled.set(true);
    if (this.crmCardItem()) {
      this.uiStateService.removeStateByCrmId(this.crmCardItem().id);
    }
  }

  private emitHotelsInSearchResult(): void {
    const groupsByHotels: SearchResultGroup[] = [];
    this.allHotelsInCountry().forEach(hotel => {
      groupsByHotels.push({ hotel, tours: [] });
    });
    this.searchResultService.setSearchResultGroups(groupsByHotels);
  }

  private prepareSearchRequest(searchParams: FavoriteHotelsSearchFormParams): InitSearchRequest {
    const params: InitSearchRequestParams = {
      adults: searchParams.tourists.adults,
      childrenAges: searchParams.tourists.childAges.map(age => Number(age)),
      splitRooms: searchParams.tourists.splitRooms,
      combined: Number(searchParams.combined),
      countryId: searchParams.direction.countryId,
      dateFrom: this.datePipe.transform(searchParams.dates.from, SEARCH_DATE_FORMAT),
      dateTo: this.datePipe.transform(searchParams.dates.to, SEARCH_DATE_FORMAT),
      departCityId: searchParams.departCityId,
      hotels: searchParams.hotelIds,
      nightsFrom: searchParams.nights.from,
      nightsTo: searchParams.nights.to,
      notGDS: searchParams.notGDS,
      onlyHotels: false,
    };
    if (searchParams.direction.regionIds.length) {
      params.regions = searchParams.direction.regionIds;
    }
    if (searchParams.mealIds.length) {
      params.meals = searchParams.mealIds;
    }
    if (searchParams.operatorIds.length) {
      params.operators = searchParams.operatorIds;
    }
    if (searchParams.airlineIds.length) {
      params.airlines = searchParams.airlineIds;
    }
    if (searchParams.stars.length) {
      params.starsList = searchParams.stars;
    }

    const initSearchRequest: InitSearchRequest = {
      id: this.mainSearchId,
      params: {
        params,
        options: {
          type: InitSearchRequestType.main,
          groupResults: InitSearchRequestOptionsGroupResult.byHotel,
          allowParallel: true,
        },
      },
      method: InitSearchRequestMethod.search,
    };

    if (this.crmCardItem()?.id) {
      initSearchRequest.params.historyParams = {};
      initSearchRequest.params.historyParams.crmID = this.crmCardItem().id;
    }

    return initSearchRequest;
  }

  private closeSearchForm(): void {
    this.isSearchFormOpen = false;
    if (this.componentSearchFormRef) {
      this.componentSearchFormRef?.destroy();
      this.componentSearchFormRef = undefined;
    }
  }

  private openSearchForm(): void {
    this.isSearchFormOpen = true;

    this.componentSearchFormRef = this.popupService.showPopup(
      SearchFormComponent,
      {},
      {
        position: {
          top: 50,
          left: 490,
        },
        transparentBackdrop: true,
        hasBackdrop: false,
      },
    );
    this.componentSearchFormRef.setInput('showBackground', true);
    this.componentSearchFormRef.setInput('formParams', this.formParams());
    this.componentSearchFormRef.setInput('crmCardLastSearchRequest', this.crmCardLastSearchRequest());
    if (this.selectedCountry()) {
      this.componentSearchFormRef.setInput('defaultCountryId', this.selectedCountry().id);
    }
    this.componentSearchFormRef.instance.close.subscribe(() => {
      this.searchFormService.closeSearchForm();
    });
    this.componentSearchFormRef.instance.submit.subscribe(() => {
      this.searchFormService.closeSearchForm();
    });
    this.componentSearchFormRef.instance.previewParams.subscribe(searchParams => {
      this.previewSearchInitRequest.set(this.prepareSearchRequest(searchParams));
    });
  }
}
