import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  effect,
  inject,
  Injector,
  input,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlightVariant } from '@api-clients/api-client';
import { BehaviorSubject, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LineProgressComponent } from '../../../../../../ui-components/line-progress/line-progress.component';
import { SearchResultMapInfoWindowFlightDirectionComponent } from '../../../../../deals/modules/deal-view/modules/favorites-hotels/components/search-result/components/map/info-window/flights/direction/search-result-map-info-window-flight-direction.component';
import { TourFlightSeatsComponent } from '../../../../../deals/modules/deal-view/modules/favorites-hotels/components/search-result/components/map/info-window/flights/seats/tour-flight-seats.component';
import { SearchTourFlightsService } from '../../../../services/search-tour-flights.service';
import { SearchTourDetailsFlightDirectionComponent } from './direction/search-tour-calendar-tour-detail-flight-direction.component';

@Component({
  selector: 'app-search-tour-detail-flights',
  templateUrl: './search-tour-calendar-tour-detail-flights.component.html',
  styleUrl: 'search-tour-calendar-tour-detail-flights.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LineProgressComponent,
    SearchResultMapInfoWindowFlightDirectionComponent,
    TourFlightSeatsComponent,
    SearchTourDetailsFlightDirectionComponent,
  ],
})
export class SearchTourDetailsFlightsComponent implements OnInit {
  tourId = input.required<string>();

  showLoader = signal<boolean>(true);
  showVariants = signal<boolean>(false);
  showNoResults = signal<boolean>(false);
  showErrors = signal<boolean>(false);
  variant = signal<FlightVariant>(undefined);

  private destroyRef = inject(DestroyRef);
  private tourId$ = new BehaviorSubject<string>('');

  constructor(
    private readonly injector: Injector,
    private readonly flightService: SearchTourFlightsService,
    private readonly cdRed: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    effect(
      () => {
        this.showVariants.set(false);
        this.showNoResults.set(false);

        this.tourId$.next(this.tourId());
      },
      { injector: this.injector, allowSignalWrites: true },
    );

    this.tourId$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(tourId => !!tourId),
        switchMap(tourId => {
          this.showLoader.set(true);
          return this.flightService.load$(tourId).pipe(takeUntilDestroyed(this.destroyRef));
        }),
      )
      .subscribe({
        next: variants => {
          this.showLoader.set(false);
          if (variants.length) {
            this.variant.set(variants[0]);
            this.showVariants.set(true);
          } else {
            this.variant.set(undefined);
            this.showNoResults.set(true);
          }

          this.cdRed.detectChanges();
        },
        error: () => {
          this.variant.set(undefined);
          this.showLoader.set(false);
          this.showErrors.set(true);
        },
      });
  }
}
