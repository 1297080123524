import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkerStateService } from '../../../../../../../core/services';
import { Worker } from '../../../../../../../models';

type Group = {
  ids: number[];
  active: boolean;
};

// TODO: REFACTOR - Move to shared services
@Injectable({
  providedIn: 'root',
})
export class FeatureToggleService {
  private readonly officeIds: number[] = [
    7, // Samal
  ];
  private readonly employeeIds: number[] = [];
  private readonly groups: { [key: string]: Group };

  constructor(private workerState: WorkerStateService) {
    this.groups = {
      developers: { ids: [1527], active: true },
      testers: { ids: [79, 739, 1279, 1396], active: true },
      smallTestGroup: { ids: [1087, 1219], active: false },
      largeTestGroup: { ids: [75, 93, 240, 535, 921, 1087, 1141, 1219, 1279], active: true },
      hotOffersGroup: {
        ids: [
          5, // Обидник Сергей
          167, // banin
          183, // nepstor
          1279, // Роман Салех
          1527, // vladjl3
          75, // vik
          79, // gulmira9
          1396, // Lunara9
          588, // mariyam11
          739, //megaaida12
        ],
        active: true,
      },
      quickAnswersOfficesGroup: {
        ids: [],
        active: false,
      },
    };
    this.employeeIds = [
      5, // serj5
      588, // mariyam11
    ];
  }

  public shouldDisplayHotOffers$: Observable<boolean> = this.workerState.currentWorker$.pipe(
    map((worker: Worker | null) =>
      worker
        ? this.groups.hotOffersGroup.ids.includes(worker.employeeId) || this.isWorkerInEnabledOffice(worker)
        : false,
    ),
  );

  public shouldDisplayChats$: Observable<boolean> = this.workerState.currentWorker$.pipe(
    map((worker: Worker | null) =>
      worker
        ? this.isWorkerInDevelopersGroup(worker) ||
          this.isWorkerInTestersGroup(worker) ||
          this.isWorkerInEnabledOffice(worker) ||
          this.isWorkerEnabledIndividually(worker)
        : false,
    ),
  );

  public shouldOpenQuickAnswersOnNewDeals$: Observable<boolean> = this.workerState.currentWorker$.pipe(
    map((worker: Worker | null) =>
      worker
        ? this.groups.quickAnswersOfficesGroup.active &&
          this.isWorkerOfficeIncluded(worker, this.groups.quickAnswersOfficesGroup.ids)
        : false,
    ),
  );

  public shouldDisplayNewFeature$: Observable<boolean> = this.workerState.currentWorker$.pipe(
    map((worker: Worker | null) => (worker ? this.determineFeatureEligibility(worker) : false)),
  );

  private determineFeatureEligibility(worker: Worker): boolean {
    return (
      this.isWorkerInEnabledOffice(worker) ||
      this.isWorkerEnabledIndividually(worker) ||
      this.isWorkerInActiveGroup(worker)
    );
  }

  private isWorkerInEnabledOffice(worker: Worker): boolean {
    return worker && this.isWorkerOfficeIncluded(worker, this.officeIds);
  }

  private isWorkerOfficeIncluded(worker: Worker, officeIds: number[]): boolean {
    return officeIds.includes(worker.office.id);
  }

  private isWorkerEnabledIndividually(worker: Worker): boolean {
    return worker && this.employeeIds.includes(worker.employeeId);
  }

  private isWorkerInActiveGroup(worker: Worker): boolean {
    return (
      worker &&
      Object.values(this.groups).some(group => group.active && group.ids.includes(worker.employeeId))
    );
  }

  private isWorkerInTestersGroup(worker: Worker): boolean {
    return worker && this.groups.testers.active && this.groups.testers.ids.includes(worker.employeeId);
  }
  private isWorkerInDevelopersGroup(worker: Worker): boolean {
    return worker && this.groups.developers.active && this.groups.developers.ids.includes(worker.employeeId);
  }
}
