import { ChangeDetectionStrategy, Component, input, OnInit, output, signal } from '@angular/core';
import { SearchFormParamsCountry } from '@api-clients/api-client/models/search-form-params-country';
import { NgClickOutsideDirective } from 'ng-click-outside2';

@Component({
  selector: 'app-multi-search-form-countries-dropdown',
  templateUrl: './multiple-search-form-countries-dropdown.component.html',
  styleUrl: './multiple-search-form-countries-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClickOutsideDirective],
})
export class MultipleSearchFormCountriesDropdownComponent implements OnInit {
  countries = input.required<SearchFormParamsCountry[]>();
  initSelectedCountryIds = input<number[]>();

  closeAndEmitSelectedCountryIds = output<number[]>();

  selectedCountryIds = signal<number[]>([]);

  ngOnInit(): void {
    if (this.initSelectedCountryIds().length) {
      this.selectedCountryIds.set(this.initSelectedCountryIds());
    }
  }

  selectCountry(id: number): void {
    const selectedCountryIds = this.selectedCountryIds();
    if (selectedCountryIds.includes(id)) {
      const index = selectedCountryIds.findIndex(selectedCountryId => selectedCountryId === id);
      selectedCountryIds.splice(index, 1);
    } else {
      selectedCountryIds.push(id);
    }

    this.selectedCountryIds.set(selectedCountryIds);
  }

  close(): void {
    this.closeAndEmitSelectedCountryIds.emit(this.selectedCountryIds());
  }
}
