import { Injectable } from '@angular/core';
import {
  ChatContactsListItem,
  CrmCardViewItem,
  WhatsappMessage,
  WhatsappNewMessage,
} from '@api-clients/crm-api-client';
import { ChatRecommendationMessage } from '@api-clients/crm-api-client/models/chat-recommendation-message';
import { Store } from '@ngrx/store';
import { debounceTime, filter, first, map, Observable, take, tap } from 'rxjs';
import { INewMessages, IStatuses } from '../../../app/models/whatsapp';
import { WorkerStateService } from '../../core/services';
import { AmplitudeTrackService } from '../../core/services/amplitude/amplitude-track.service';
import { ChatListFilterTypes } from '../../core/services/amplitude/amplitudeEventData';
import { CHAT_LIST_FILTER_SET } from '../../core/services/amplitude/amplitudeEvents';
import {
  ChatListExclusiveFilterKey,
  ChatListInclusiveFilterKeys,
  ChatListSortType,
  ChatListStageFilterKeys,
  ChatListTouchTodayFilterKeys,
  ChatsWithDealsResult,
} from '../../modules/chats/chats';
import { ChatRecommendation } from '../../modules/chats/interfaces/chat.interface';
import { DealsFacade } from '../deals/deals.facade';
import { UpdateUnreadChatsCount } from '../whatsapp-contacts/whatsapp-contacts.actions';
import * as ChatsActions from './chats.actions';
import { ChatsState } from './chats.reducer';
import {
  selectAllChatContacts,
  selectChatContactsError,
  selectChatContactsLoading,
  selectChatRecommendation,
  selectCurrentChatContact,
  selectCurrentChatContactCrmCard,
  selectCurrentChatContactCrmCardLoading,
  selectCurrentChatContactNextTaskLoading,
  selectCurrentExclusiveFilter,
  selectCurrentInclusiveFilters,
  selectCurrentSort,
  selectCurrentStageFilter,
  selectExclusiveFilteredChatsWithDeals,
  selectExclusiveFilteredChatsWithDealsCountBy,
  selectHasAnyInclusiveFiltersEnabled,
  selectHasForgotten,
  selectHasGroups,
  selectHasUnreadChats,
  selectHasUnreadGroups,
  selectInclusiveFilterCounters,
  selectIsSearching,
  selectIsTouchTodayFilterEnabled,
  selectSearchResults,
  selectStageFilteredChatsWithDealsCountBy,
  selectTouchTodayAllCount,
  selectTouchTodayFilteredChatContactsCountBy,
} from './chats.selectors';

@Injectable({
  providedIn: 'root',
})
export class ChatsFacade {
  chatContactsAll$: Observable<ChatContactsListItem[]> = this.store.select(selectAllChatContacts);
  chatsWithDealsExclusiveFiltered$: Observable<ChatsWithDealsResult> = this.store.select(
    selectExclusiveFilteredChatsWithDeals,
  );
  chatContactsExclusiveFilteredCountBy$ = (filter: ChatListExclusiveFilterKey): Observable<number> =>
    this.store.select(selectExclusiveFilteredChatsWithDealsCountBy(filter));
  chatContactsStageFilteredCountBy$ = (stage: ChatListStageFilterKeys): Observable<number> =>
    this.store.select(selectStageFilteredChatsWithDealsCountBy(stage));
  loading$: Observable<boolean> = this.store.select(selectChatContactsLoading);
  error$: Observable<string> = this.store.select(selectChatContactsError);
  currentChatContact$: Observable<ChatContactsListItem> = this.store.select(selectCurrentChatContact);
  currentChatContactCrmCard$: Observable<CrmCardViewItem> = this.store.select(
    selectCurrentChatContactCrmCard,
  );
  currentChatContactCrmCardLoading$: Observable<boolean> = this.store.select(
    selectCurrentChatContactCrmCardLoading,
  );
  currentChatContactNextTaskLoading$: Observable<boolean> = this.store.select(
    selectCurrentChatContactNextTaskLoading,
  );
  chatRecommendation$: Observable<ChatRecommendation> = this.store.select(selectChatRecommendation);
  searchResults$: Observable<ChatContactsListItem[]> = this.store.select(selectSearchResults);
  isSearching$: Observable<boolean> = this.store.select(selectIsSearching);
  currentInclusiveFilter$: Observable<ChatListInclusiveFilterKeys[]> = this.store.select(
    selectCurrentInclusiveFilters,
  );
  currentExclusiveFilter$: Observable<ChatListExclusiveFilterKey> = this.store.select(
    selectCurrentExclusiveFilter,
  );
  hasAnyInclusiveFiltersEnabled$: Observable<boolean> = this.store.select(
    selectHasAnyInclusiveFiltersEnabled,
  );
  inclusiveFilterCounters$: Observable<Record<ChatListInclusiveFilterKeys, number>> = this.store.select(
    selectInclusiveFilterCounters,
  );
  hasForgotten$: Observable<boolean> = this.store.select(selectHasForgotten);
  hasGroups$: Observable<boolean> = this.store.select(selectHasGroups);
  hasUnreadChats$: Observable<boolean> = this.store.select(selectHasUnreadChats);
  hasUnreadGroups$: Observable<boolean> = this.store.select(selectHasUnreadGroups);

  currentStageFilter$: Observable<ChatListStageFilterKeys> = this.store.select(selectCurrentStageFilter);

  currentSort$: Observable<ChatListSortType> = this.store.select(selectCurrentSort);

  isTouchTodayFilterEnabled$: Observable<boolean> = this.store.select(selectIsTouchTodayFilterEnabled);
  touchTodayAllCount$: Observable<number> = this.store.select(selectTouchTodayAllCount);
  touchedTodayCount$: Observable<number> = this.store.select(
    selectTouchTodayFilteredChatContactsCountBy(ChatListTouchTodayFilterKeys.TOUCHED_TODAY),
  );

  private lastMessageIsFromMe = false;

  constructor(
    private store: Store<ChatsState>,
    private workerStateService: WorkerStateService,
    private dealsFacade: DealsFacade,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {
    // Пока только для Бекжанова Аида megaaida12 (739)
    /*this.workerStateService.currentWorker$
      .pipe(
        filter(worker => worker?.employeeId === 739),
        switchMap(() => this.dealsFacade.dealsList$),
        // Ожидаем, пока список сделок не перестанет изменяться в течение 1 секунды.
        // Т.к. там сортировка идет отдельно через эффекты и нельзя использовать первый же результат
        // списка сделок и генерировать на основе него zenmodeList
        debounceTime(1000),
      )
      .subscribe(() => {
        // При обновлении сделок надо посмотреть не потеряла ли актуальность существующая рекомендация
        this.store.dispatch(ChatsActions.checkChatRecommendation());
      });*/
  }

  loadChatContacts() {
    this.store.dispatch(ChatsActions.loadChatContacts());
    // Пока только для Бекжанова Аида megaaida12 (739)
    /*if (this.workerStateService.employeeId === 739) {
      this.store.dispatch(ChatsActions.loadChatRecommendation());
    }*/
  }

  searchContacts(searchQuery: string): void {
    this.store.dispatch(ChatsActions.searchContacts({ searchQuery }));
  }

  clearSearch(): void {
    this.store.dispatch(ChatsActions.clearSearchResults());
  }

  chooseChatContact(chatContact: ChatContactsListItem) {
    this.store.dispatch(ChatsActions.chooseChatContact({ chatContact }));
  }

  updateChatContact(chatContact: ChatContactsListItem) {
    this.store.dispatch(ChatsActions.updateChatContact({ chatContact }));
  }

  loadDealViewForChat(crmCardId: number) {
    this.store.dispatch(ChatsActions.loadCrmCardViewItemForChat({ crmCardId }));
  }

  setCrmCardViewItemForChat(crmCardViewItem: CrmCardViewItem) {
    this.store.dispatch(ChatsActions.setCrmCardViewItemForChat({ crmCardViewItem }));
  }

  loadDealNextTask(crmCardId: number) {
    this.store.dispatch(ChatsActions.loadDealViewNextTask({ crmCardId }));
  }

  loadChatContact(contactId: string) {
    this.store.dispatch(ChatsActions.loadChatContact({ contactId }));
  }

  resetCurrentChatContact() {
    this.store.dispatch(ChatsActions.resetCurrentChatContact());
  }

  resetCurrentChatState() {
    this.store.dispatch(ChatsActions.resetCurrentChatState());
  }

  readChat(contactId: string, fromPlace: string) {
    if (!this.lastMessageIsFromMe) {
      this.store.dispatch(ChatsActions.readChat({ contactId, fromPlace }));
    }
  }

  updateChatContactsList(newMessages: INewMessages) {
    newMessages.messages.forEach((newMessage: WhatsappNewMessage) => {
      const contactId = newMessage.contact.id;
      const message = newMessage.message;
      this.checkAndUpdateContact(contactId, newMessage.message);

      this.lastMessageIsFromMe = message.isFromMe;

      if (!message.isFromMe) {
        this.currentChatContact$
          .pipe(
            take(1),
            filter(currentChatContact => currentChatContact?.contact.id === contactId),
            debounceTime(1000),
          )
          .subscribe(() => {
            this.readChat(contactId, 'hermes_new_message_received');
          });
      }
    });
  }

  updateUnreadChatsCounter(newUnreadChatsCount: number) {
    this.store.dispatch(new UpdateUnreadChatsCount({ unreadChatCount: newUnreadChatsCount }));
  }

  refreshStatusOfMessages(statuses: IStatuses) {
    this.store.dispatch(ChatsActions.refreshMessageStatuses({ statuses }));
  }

  markRecommendationMessageAsDeleted(message: ChatRecommendationMessage): void {
    this.store.dispatch(ChatsActions.markRecommendationMessageAsDeleted(message));
  }

  markRecommendationMessageAsSent(message: ChatRecommendationMessage, changedText: string = null): void {
    this.store.dispatch(ChatsActions.markRecommendationMessageAsSent({ message, changedText }));
  }

  setStageChatListFilter(stage: ChatListStageFilterKeys): void {
    this.store.dispatch(ChatsActions.setStageFilter({ stage }));
  }

  setExclusiveChatListFilter(filter: ChatListExclusiveFilterKey): void {
    this.store.dispatch(ChatsActions.setExclusiveFilter({ filter }));
  }

  setTempInclusiveChatListFiltersForCounters(filters: ChatListInclusiveFilterKeys[]): void {
    this.store.dispatch(ChatsActions.setTempInclusiveFiltersForCounters({ filters }));
  }

  resetTempInclusiveChatListFiltersForCounters(): void {
    this.store.dispatch(ChatsActions.resetTempInclusiveFiltersForCounters());
  }

  setInclusiveChatListFilters(filters: ChatListInclusiveFilterKeys[]): void {
    this.store.dispatch(ChatsActions.setInclusiveFilters({ filters }));
  }

  resetInclusiveChatListFilters(): void {
    this.store.dispatch(ChatsActions.resetInclusiveFilters());
  }

  resetStageChatListFilter(): void {
    this.store.dispatch(ChatsActions.resetStageFilter());
  }

  setSort(sort: ChatListSortType): void {
    this.store.dispatch(ChatsActions.setSort({ sort }));
  }

  resetSort(): void {
    this.store.dispatch(ChatsActions.resetSort());
  }

  toggleTouchTodayFilter(): void {
    this.isTouchTodayFilterEnabled$.pipe(first()).subscribe(value => {
      if (!value) {
        this.amplitudeTrackService.trackEvent(CHAT_LIST_FILTER_SET, {
          Type: ChatListFilterTypes.TOUCH_TODAY,
        });
      }
    });
    this.store.dispatch(ChatsActions.toggleTouchTodayFilter());
  }

  resetTouchTodayFilter(): void {
    this.store.dispatch(ChatsActions.resetTouchTodayFilter());
  }

  private checkAndUpdateContact(contactId: string, message: WhatsappMessage) {
    this.chatContactsAll$
      .pipe(
        take(1),
        // Проверяем есть контакт из сообщения в нашем текущем списке
        map(chatContacts => chatContacts.find(contact => contact.contact.id === contactId)),
        tap(existingContact => {
          if (existingContact) {
            // Если контакт есть, то обновляем у него last message и сортируем список
            this.updateExistingContact(existingContact, message);
          } else {
            // В противном случае загружаем контакт и добавляем в список
            this.loadNewContact(contactId);
          }
        }),
      )
      .subscribe();
  }

  private updateExistingContact(existingContact: ChatContactsListItem, message: WhatsappMessage) {
    const { unreadMessageCount } = existingContact.contact;
    const updatedUnreadMessageCount = message.isFromMe ? unreadMessageCount : unreadMessageCount + 1;

    this.store.dispatch(
      ChatsActions.updateChatContact({
        chatContact: {
          ...existingContact,
          contact: {
            ...existingContact.contact,
            unreadMessageCount: updatedUnreadMessageCount,
          },
          lastMessage: message,
        },
      }),
    );
  }

  private loadNewContact(contactId: string) {
    this.store.dispatch(ChatsActions.loadChatContact({ contactId }));
  }
}
