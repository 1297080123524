<form [formGroup]="form">
  <div class="tourist-container open">
    <button (click)="close.emit()" class="close-button" type="button">
      <img alt="" src="/assets/icons/close-icon.svg" />
    </button>

    <div class="tourist-container-row">
      <app-search-form-tourists formControlName="tourists" />
    </div>

    <div class="direction-hotels-container">
      <div class="direction-container">
        <app-search-form-depart-cities formControlName="departCityId"
                                       [departCities]="formParams().departCities" />

        @if (countriesVariants().length) {
          <app-multiple-search-form-countries formControlName="countryIds" [countries]="countriesVariants()" />
        }
      </div>
    </div>
  </div>
  <div class="params-container">
    <div class="params-form">
      <div class="main-params">
        <app-search-form-calendar formControlName="dates" />
        <app-search-form-stars-list formControlName="stars" />
      </div>
      <div class="extends-params">
        <div class="extends-param-container">
          <app-search-form-nights formControlName="nights" />
        </div>
        <div class="extends-param-container">
          <app-search-form-checkbox-list formControlName="mealIds"
                                         [visibleCount]="0"
                                         [label]="'Питание'"
                                         [items]="mealsVariants()" />
        </div>
        <div class="extends-param-container">
          <app-search-form-checkbox-list formControlName="operatorIds"
                                         [visibleCount]="0"
                                         [label]="'Операторы'"
                                         [items]="operatorsVariants()" />
        </div>
        <div class="extends-param-container">
          <app-search-form-checkbox-list formControlName="airlineIds"
                                         [visibleCount]="0"
                                         [label]="'Авиакомпании'"
                                         [items]="airlinesVariants()" />
        </div>
        <div class="extends-param-container">
          <div class="combined-container">
            <div class="label">Комби туры</div>
            <div class="combined">
              <div class="radio">
                <label><input type="radio" value="0" formControlName="combined">Любые</label>
              </div>
              <div class="radio">
                <label><input type="radio" value="1" formControlName="combined">Только комби</label>
              </div>
              <div class="radio">
                <label><input type="radio" value="2" formControlName="combined">Только не комби</label>
              </div>
            </div>
          </div>
        </div>
        <div class="extends-param-container">
          <app-search-form-not-gds formControlName="notGDS" />
        </div>
      </div>
    </div>

    <button type="button" class="btn-search-tours" (click)="searchTours()">
      <div class="btn-search-default">
        <img src="assets/icons/favorite-hotels/btn-search-icon.svg">
        Найти туры
      </div>
    </button>
  </div>
</form>
