import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  input,
  OnDestroy,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SearchFormParams, SearchFormParamsRegion } from '@api-clients/api-client/dist/models';
import { SearchFormParamsCountry } from '@api-clients/api-client/models/search-form-params-country';
import { WorkerStateService } from '../../../../../../../../../../core/services';
import { SearchFormCalendarComponent } from '../../../../components/search-form/calendar/search-form-calendar.component';
import {
  CheckboxItem,
  SearchFormCheckboxListComponent,
} from '../../../../components/search-form/checkbox-list/search-form-checkbox-list.component';
import { SearchFormCountriesComponent } from '../../../../components/search-form/countries/search-form-countries.component';
import { SearchFormDepartCitiesComponent } from '../../../../components/search-form/depart-cities/search-form-depart-cities.component';
import { SearchFormNightsComponent } from '../../../../components/search-form/nights/search-form-nights.component';
import { SearchFormNotGdsComponent } from '../../../../components/search-form/not-gds/search-form-not-gds.component';
import { SearchFormStarsListComponent } from '../../../../components/search-form/stars-list/search-form-stars-list.component';
import { SearchFormTemplatesComponent } from '../../../../components/search-form/templates/search-form-templates.component';
import { SearchFormTouristComponent } from '../../../../components/search-form/tourists/search-form-tourists.component';
import { MultipleSearchFormParams } from '../../../../favorites-hotels.model';
import { MultipleSearchFormCountriesComponent } from './countries/multiple-search-form-countries.component';

@Component({
  selector: 'app-multi-search-form',
  templateUrl: './multiple-search-form.component.html',
  styleUrl: './multiple-search-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SearchFormTouristComponent,
    NgIf,
    SearchFormCountriesComponent,
    SearchFormDepartCitiesComponent,
    SearchFormTemplatesComponent,
    SearchFormCheckboxListComponent,
    AsyncPipe,
    SearchFormCalendarComponent,
    SearchFormNightsComponent,
    SearchFormNotGdsComponent,
    SearchFormStarsListComponent,
    MultipleSearchFormCountriesComponent,
  ],
})
export class MultipleSearchFormComponent implements OnInit, OnDestroy {
  formParams = input.required<SearchFormParams>();
  close = output<void>();
  submit = output<MultipleSearchFormParams>();

  form: FormGroup;

  mealsVariants = computed<CheckboxItem[]>(() => {
    return this.formParams().meals.map(meal => {
      const item: CheckboxItem = { id: meal.id, name: meal.name, selected: false };

      return item;
    });
  });
  operatorsVariants = computed<CheckboxItem[]>(() => {
    return this.formParams().tourOperators.map(operator => {
      const item: CheckboxItem = { id: operator.id, name: operator.name, selected: false };

      return item;
    });
  });
  airlinesVariants = computed<CheckboxItem[]>(() => {
    return this.formParams().airlines.map(airline => {
      const item: CheckboxItem = { id: airline.code, name: airline.name, selected: false };

      return item;
    });
  });
  countriesVariants = signal<SearchFormParamsCountry[]>([]);
  regions = signal<SearchFormParamsRegion[]>([]);

  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly fb: FormBuilder,
    private readonly workerState: WorkerStateService,
  ) {}

  ngOnInit(): void {
    this.createSearchForm();
    this.registerFormEvents();
    this.populateForm();
  }

  ngOnDestroy(): void {}

  searchTours(): void {
    if (!this.form.valid) {
      return;
    }
    this.submit.emit(this.form.value);
  }

  private createSearchForm(): void {
    const dateFrom = new Date(this.formParams().defaultValues.dateFrom);
    const dateTo = new Date(this.formParams().defaultValues.dateFrom);
    dateTo.setDate(dateTo.getDate() + 3);

    // setHours нужен, чтобы убрать часовые пояса
    dateFrom.setHours(0);
    dateTo.setHours(0);

    this.form = this.fb.group({
      tourists: [{ adults: 2, childAges: [], splitRooms: false }],
      nights: [
        {
          from: this.formParams().defaultValues.nights.from,
          to: this.formParams().defaultValues.nights.to,
        },
      ],
      dates: [
        {
          from: dateFrom,
          to: dateTo,
        },
      ],
      countryIds: new FormControl<number[]>([]),
      departCityId: new FormControl<number | null>(null, {
        validators: [Validators.required],
      }),
      mealIds: new FormControl<number[]>([]),
      operatorIds: new FormControl<number[]>([]),
      airlineIds: new FormControl<string[]>([]),
      hotelIds: new FormControl<number[]>([]),
      stars: new FormControl<number[]>([]),
      notGDS: [true],
      combined: ['0'],
    });
  }

  private updateCountries(departCityId: number): void {
    const countryIds = [];
    this.formParams().directions.map(direction => {
      if (direction.departCityId === departCityId) {
        countryIds.push(direction.countryId);
      }
    });

    const countries = this.formParams().countries.filter(country => countryIds.indexOf(country.id) !== -1);
    this.countriesVariants.update(() => countries);
  }

  private populateForm(): void {
    const departCityId = this.workerState.currentDepartCityId;
    const defaultFormValue = this.form.value;
    const formValue = { ...defaultFormValue, ...{ departCityId } };

    this.form.setValue(formValue);
  }

  private registerFormEvents(): void {
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: any) => {
      const departCityId = value.departCityId;
      this.updateCountries(departCityId);
    });
  }
}
