import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ChatSendMessageRequest } from '@api-clients/crm-api-client';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ChatService } from '../../../core/services/chat/chat.service';
import { ChatTimeLineItem } from '../../chat-timeline/interfaces/chat-timeline.interface';
import { ContentCreatorComponent } from '../content-creator.component';
import { ContentCreatorContentType } from '../interfaces/content-creator.interface';

/**
 * Сервис для управления модальным окном создания контента (для избавления от циклической зависимости)
 * Т.к. из одного создателя контента можно вызвать другой, то нужно избавиться от циклической зависимости
 */
@Injectable({
  providedIn: 'root',
})
export class ContentCreatorModalControlService {
  private showModalSubject = new Subject<{
    chatTimeLineItem: ChatTimeLineItem | null;
    initialContentTypes: ContentCreatorContentType[] | null;
    initialContentPlaceholderTypes: ContentCreatorContentType[] | null;
    isOpenedFromPlaceholder: boolean;
  }>();
  showModal$ = this.showModalSubject.asObservable();

  private openedModalsSubject = new BehaviorSubject<
    MatDialogRef<ContentCreatorComponent, Observable<ChatSendMessageRequest[]>>[]
  >([]);

  private lastOpenedModalSubject = new BehaviorSubject<MatDialogRef<
    ContentCreatorComponent,
    Observable<ChatSendMessageRequest[]>
  > | null>(null);
  public lastOpenedModal$ = this.lastOpenedModalSubject.asObservable();

  constructor(private chatService: ChatService) {}

  public showModal(
    chatTimeLineItem: ChatTimeLineItem | null,
    initialContentTypes: ContentCreatorContentType[] | null = null,
    initialContentPlaceholderTypes: ContentCreatorContentType[] | null = null,
    isOpenedFromPlaceholder = false,
  ): void {
    const allowedOpenedModalsCount = isOpenedFromPlaceholder ? 1 : 0;
    if (this.openedModalsSubject.value.length > allowedOpenedModalsCount) {
      if (isOpenedFromPlaceholder) {
        // Если открывают из плейсхолдера, то закрываем последнее открытое окно.
        // Т.к. там другая модалка из плейсхолдера
        this.openedModalsSubject.value[this.openedModalsSubject.value.length - 1].close(
          of([] as ChatSendMessageRequest[]),
        );
      } else {
        // Если открывают основное окно, но есть другие открытые, то закрываем все открытые окна
        this.openedModalsSubject.value.forEach(dialog => dialog.close(of([] as ChatSendMessageRequest[])));
      }
    }

    this.showModalSubject.next({
      chatTimeLineItem,
      initialContentTypes,
      initialContentPlaceholderTypes,
      isOpenedFromPlaceholder,
    });
  }

  public showMultipleContentModalByTourId(
    tourId: string,
    contentCreatorType: ContentCreatorContentType | undefined,
  ) {
    this.showModal(
      this.chatService.createChatTimelineItemByText(`номер тура: ${tourId}`),
      [
        contentCreatorType,
        ContentCreatorContentType.Photos,
        ContentCreatorContentType.Videos,
        ContentCreatorContentType.AudioPresentation,
      ],
      [ContentCreatorContentType.ManagerComments, ContentCreatorContentType.Reviews],
    );
  }

  public showManagerOfferModalByTourId(tourId: string) {
    this.showMultipleContentModalByTourId(tourId, ContentCreatorContentType.ManagerOffer);
  }

  public addOpenedDialog(
    dialog: MatDialogRef<ContentCreatorComponent, Observable<ChatSendMessageRequest[]>>,
  ): void {
    this.lastOpenedModalSubject.next(dialog);
    this.openedModalsSubject.next([...this.openedModalsSubject.value, dialog]);
    // Удаляем при закрытии
    dialog
      .afterClosed()
      .pipe(first())
      .subscribe(() => {
        this.openedModalsSubject.next(
          this.openedModalsSubject.value.filter(openedDialog => openedDialog !== dialog),
        );
      });
  }
}
