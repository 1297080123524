import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, output } from '@angular/core';
import { SearchFormParamsRegion } from '@api-clients/api-client/dist/models';
import { SearchFormParamsCountry } from '@api-clients/api-client/models/search-form-params-country';
import { FavoriteHotelsSearchFormSelectedCountry } from '../../../../favorites-hotels.model';
import { SearchFormCountriesDropdownItemComponent } from './item/search-form-countries-dropdown-item.component';

@Component({
  selector: 'app-search-form-countries-dropdown',
  templateUrl: './search-form-countries-dropdown.component.html',
  styleUrls: ['./search-form-countries-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SearchFormCountriesDropdownItemComponent, NgIf],
})
export class SearchFormCountriesDropdownComponent implements OnInit {
  @Input() activeCountryId: number;
  @Input() countries: SearchFormParamsCountry[] = [];
  @Input() regions: SearchFormParamsRegion[] = [];
  @Input() selectedRegionIds: number[] = [];

  selected = output<FavoriteHotelsSearchFormSelectedCountry>();
  closed = output<void>();

  currentActiveCountryId: number;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.currentActiveCountryId = this.activeCountryId;
  }

  selectedCountry(value: FavoriteHotelsSearchFormSelectedCountry) {
    if (this.currentActiveCountryId !== value.country.id) {
      this.selectedRegionIds = [];
    }
    this.currentActiveCountryId = value.country.id;

    this.cdRef.detectChanges();
    this.selected.emit(value);
  }

  closedDropdown() {
    this.closed.emit();
  }
}
