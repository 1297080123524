import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  WhatsappMessage,
  WhatsappMessageMetaHotel,
  WhatsappMessageSender,
} from '@api-clients/crm-api-client';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { setCurrentReplyMessage } from '../../../../+state/chat-timeline/chat-timeline.actions';
import { ChatTimelineState } from '../../../../+state/chat-timeline/chat-timeline.reducer';
import { ChatService } from '../../../../core/services/chat/chat.service';
import { ContentCreatorContentType } from '../../../content-creator/interfaces/content-creator.interface';
import { ContentCreatorModalControlService } from '../../../content-creator/services/content-creator-modal-control.service';
import { IndicatorType } from '../../../deals/modules/deal-view/models/indicator-payload.model';
import { IndicatorEventService } from '../../../deals/modules/deal-view/services/indicator-event.service';
import {
  ChatTimeLineItem,
  ChatTimelineItemTypeEnum,
  WhatsappMessageStatusList,
  WhatsappMessageTypeList,
} from '../../interfaces/chat-timeline.interface';
import { ChatTimelineService } from '../../services/chat-timeline.service';

@Component({
  selector: 'app-chat-time-line-item',
  templateUrl: './chat-time-line-item.component.html',
  styleUrls: ['./chat-time-line-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatTimeLineItemComponent implements OnDestroy, OnInit {
  // @HostBinding('@highlightAnimation') highlightState = 'normal';
  @ViewChild('messageBox') messageBox: ElementRef;

  @Input() chatTimeLineItem: ChatTimeLineItem;
  @Input() isLastOfGroup: boolean;
  @Input() isFirstOfGroup: boolean;
  @Input() isLastTimeLineItem: boolean;
  @Output() maximized: EventEmitter<string> = new EventEmitter();
  public isFromClient = false;
  public chatTimelineItemTypeEnum = ChatTimelineItemTypeEnum;
  public messageTime: string;
  public isAudio = false;
  public messageCurrentStatus: WhatsappMessageStatusList = WhatsappMessageStatusList.Unknown;
  public statusIconPath: string;
  public statusIconAltText: string;
  public hotel: WhatsappMessageMetaHotel | null = null;
  public sender: WhatsappMessageSender | null = null;
  public isGroup = false;
  public isDeleted = false;

  public isTextSelected = false;
  public selectedText = '';
  public selectedTextButtonPosition = { top: '0px', left: '0px' };
  @ViewChild('textContainer', { static: true }) textContainer!: ElementRef;

  private readonly statusIconMap: Partial<{
    [key in WhatsappMessageStatusList]: { path: string; alt: string };
  }> = {
    [WhatsappMessageStatusList.Read]: {
      path: './assets/icons/whatsapp/message-status/read.svg',
      alt: 'Прочитано',
    },
    [WhatsappMessageStatusList.Sent]: {
      path: './assets/icons/whatsapp/message-status/sent.svg',
      alt: 'Отправлено',
    },
    [WhatsappMessageStatusList.Received]: {
      path: './assets/icons/whatsapp/message-status/received.svg',
      alt: 'Доставлено',
    },
    [WhatsappMessageStatusList.InFlight]: {
      path: './assets/icons/whatsapp/message-status/in-flight.svg',
      alt: 'Отправляется',
    },
    [WhatsappMessageStatusList.Unknown]: {
      path: './assets/icons/whatsapp/message-status/in-flight.svg',
      alt: 'Неизвестно',
    },
  };
  private unsubscribe$ = new Subject<void>();

  constructor(
    private contentCreatorModalControlService: ContentCreatorModalControlService,
    private renderer: Renderer2,
    private chatTimelineService: ChatTimelineService,
    private chatService: ChatService,
    private cdRef: ChangeDetectorRef,
    private indicatorEventService: IndicatorEventService,
    private store: Store<ChatTimelineState>,
  ) {}

  ngOnInit(): void {
    this.initValues(this.chatTimeLineItem);
    // Если в другом сообщении вызовут меню для выделения текста, то мы должны скрыть его в текущем сообщении
    this.chatTimelineService.messageTextSelected$.pipe(takeUntil(this.unsubscribe$)).subscribe(component => {
      if (component !== this) {
        this.isTextSelected = false;
        this.cdRef.detectChanges();
      }
    });
  }

  initValues(chatTimeLineItem: ChatTimeLineItem) {
    this.hotel = (chatTimeLineItem.data as WhatsappMessage)?.meta?.hotel;
    this.isGroup = (chatTimeLineItem.data as WhatsappMessage)?.isGroup;
    this.isDeleted = (chatTimeLineItem.data as WhatsappMessage)?.isDeleted;
    this.sender = (chatTimeLineItem.data as WhatsappMessage)?.sender;
    this.isFromClient = this.chatTimelineService.getIsFromClient(chatTimeLineItem);
    this.messageTime = this.getMessageTimeString(chatTimeLineItem);
    this.messageCurrentStatus = this.chatTimelineService.getMessageStatus(chatTimeLineItem);
    this.updateStatusIcon();
    this.cdRef.detectChanges();
    if (this.chatTimeLineItem.type === ChatTimelineItemTypeEnum.message) {
      const messageType = this.chatTimeLineItem.data.type;
      if (messageType === WhatsappMessageTypeList.Ptt || messageType === WhatsappMessageTypeList.Audio) {
        this.isAudio = true;
      }
    }
    if (this.isLastTimeLineItem) {
      // this.indicatorEventService.indicatorEventObservable
      //   .pipe(
      //     takeUntil(this.unsubscribe$),
      //     tap((type: IndicatorType) => {
      //       if (type === IndicatorType.NewMessage) {
      //         this.highlight();
      //       }
      //     }),
      //     delay(5000),
      //     tap((type: IndicatorType) => {
      //       if (type === IndicatorType.NewMessage) {
      //         this.resetAnimation();
      //       }
      //     }),
      //   )
      //   .subscribe();
    }
    this.cdRef.detectChanges();
  }

  private getMessageTimeString(chatTimeLineItem: ChatTimeLineItem) {
    const messageTime = this.chatTimelineService.getMessageTime(chatTimeLineItem.sortDate);
    return chatTimeLineItem.type === ChatTimelineItemTypeEnum.scheduledMessage
      ? `${messageTime}`
      : messageTime;
  }

  get sendErrorTooltipTextLangKey(): string {
    let langKey = 'PAGES.DEALS.COMMUNICATION_HISTORY.TIMELINE.MESSAGE_SEND_ERROR.';

    langKey += this.chatTimeLineItem.data.status;
    return langKey;
  }

  get isErrorSend() {
    if (this.chatTimeLineItem.type !== ChatTimelineItemTypeEnum.message) {
      return false;
    }

    if (this.chatTimeLineItem.data.isFromClient) {
      return false;
    }

    return (
      this.chatTimeLineItem.data.status === WhatsappMessageStatusList.NoApp ||
      this.chatTimeLineItem.data.status === WhatsappMessageStatusList.UnknownError ||
      this.chatTimeLineItem.data.status === WhatsappMessageStatusList.DialogExpired
    );
  }

  highlight() {
    this.renderer.addClass(this.messageBox.nativeElement, 'highlight-animation');
  }

  resetAnimation() {
    this.renderer.removeClass(this.messageBox.nativeElement, 'highlight-animation');
  }

  private updateStatusIcon() {
    const statusInfo =
      this.statusIconMap[this.messageCurrentStatus] || this.statusIconMap[WhatsappMessageStatusList.Unknown];
    this.statusIconPath = statusInfo.path;
    this.statusIconAltText = statusInfo.alt;
  }

  public reply() {
    this.store.dispatch(
      setCurrentReplyMessage({ replyMessage: this.chatTimeLineItem.data as WhatsappMessage }),
    );
  }

  handleMouseUp(event: MouseEvent): void {
    // Видимо снятие выделения срабатывает не сразу и нам нужна задержка
    setTimeout(() => {
      const selection = window.getSelection();
      if (selection && selection.toString().length > 0) {
        this.selectedText = selection.toString();
        this.isTextSelected = true;

        const containerRect = this.textContainer.nativeElement.getBoundingClientRect();
        const scrollTop = this.textContainer.nativeElement.scrollTop as number;
        const scrollLeft = this.textContainer.nativeElement.scrollLeft as number;

        let top = event.clientY - containerRect.top + scrollTop;
        let left = event.clientX - containerRect.left + scrollLeft;

        // Получаем размеры кнопки
        const buttonWidth = 120;
        const buttonHeight = 50;
        // Проверяем, чтобы кнопка не выходила за правую границу контейнера
        if (left + buttonWidth > containerRect.width) {
          left = containerRect.width - buttonWidth - 30;
        }
        // Проверяем, чтобы кнопка не выходила за нижнюю границу контейнера
        if (top + buttonHeight > containerRect.height) {
          top = containerRect.height - buttonHeight - 30;
        }

        this.selectedTextButtonPosition = {
          top: `${top}px`,
          left: `${left}px`,
        };
      } else {
        this.isTextSelected = false;
      }
      this.chatTimelineService.messageTextSelected(this);
      this.cdRef.detectChanges();
    }, 0);
  }

  openGptForSelectedText(): void {
    // Клонируем chatTimeLineItem, чтобы оставить в нем только выделенный текст
    let chatTimeLineItem: ChatTimeLineItem;
    if (this.chatTimeLineItem.type === ChatTimelineItemTypeEnum.message) {
      chatTimeLineItem = JSON.parse(JSON.stringify(this.chatTimeLineItem)) as ChatTimeLineItem;
      chatTimeLineItem.data.text = this.selectedText;
    } else {
      chatTimeLineItem = this.chatService.createChatTimelineItemByText(this.selectedText);
    }
    this.contentCreatorModalControlService.showModal(chatTimeLineItem, [ContentCreatorContentType.Gpt]);
    this.isTextSelected = false;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
