import { AsyncPipe, SlicePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Observable, Subscription, tap } from 'rxjs';
import { ContentCreatorContentType } from '../../../../../../../../../content-creator/interfaces/content-creator.interface';
import { SearchHotelComponent } from '../../../../../../../../../search/components/hotel/search-hotel.component';
import { SearchTourSelectMode } from '../../../../../../../../../search/search.model';
import { HotOfferListItemCountryHotel } from '../../../../hot-offers.model';
import { HotOffersService } from '../../../../hot-offers.service';

@Component({
  selector: 'app-hot-offers-country-hotels',
  templateUrl: './hot-offers-hotels.component.html',
  styleUrls: ['./hot-offers-hotels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, SlicePipe, SearchHotelComponent],
})
export class HotOffersHotelsComponent implements OnInit, OnDestroy {
  @Input() departCityId!: number;
  @Input() countryId!: number;

  visibleCount = 3;
  visibleCountInc = 3;
  totalHotelsCount = 0;

  readonly contentCreatorContentType = ContentCreatorContentType;
  readonly searchTourSelectMode = SearchTourSelectMode;

  private hotelsSubscription: Subscription;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly hotOffersService: HotOffersService,
  ) {}

  get hotelsAndTours$(): Observable<HotOfferListItemCountryHotel[]> {
    return this.hotOffersService.hotelsByCountryId$(this.departCityId, this.countryId).pipe(
      tap(hotels => {
        this.totalHotelsCount = hotels.length;
      }),
    );
  }

  showMore(): void {
    if (this.visibleCount + this.visibleCountInc <= this.totalHotelsCount) {
      this.visibleCount += this.visibleCountInc;
    } else {
      this.visibleCount = this.totalHotelsCount;
    }
  }

  hide() {
    this.visibleCount = this.visibleCountInc;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.hotelsSubscription?.unsubscribe();
  }
}
