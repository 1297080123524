import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  PhoneItem,
  WhatsappMessage,
  WhatsappMessageStatusList,
  WhatsappMessageTypeList,
  WhatsappNewMessage,
  WhatsappSendMessageRequest,
} from '@api-clients/crm-api-client';
import { ChatMessagesFacade } from '../../../+state/chat-messages/chat-messages.facade';
import { generatePendingMessageId } from '../../../+state/chat-messages/chat-messages.helper';
import { UpdateChatMessagesPayload } from '../../../+state/chat-messages/chat-messages.interface';
import { ElectronService } from '../../../core/services';
import { AmplitudeTrackService } from '../../../core/services/amplitude/amplitude-track.service';
import { DEAL_VIEW_OPENED } from '../../../core/services/amplitude/amplitudeEvents';
import { INewMessages, IStatuses } from '../../../models/whatsapp';
import { ContentCreatorModalControlService } from '../../content-creator/services/content-creator-modal-control.service';
import { ChatsFacade } from '../../../+state/chats/chats.facade';

/**
 * TODO: Перенести методы в ChatService и удалить этот сервис
 */
@Injectable({
  providedIn: 'root',
})
export class WhatsappService {
  constructor(
    private chatMessagesFacade: ChatMessagesFacade,
    private chatsFacade: ChatsFacade,
    private router: Router,
    private electron: ElectronService,
    private amplitudeTrackService: AmplitudeTrackService,
    private readonly contentCreatorModalControlService: ContentCreatorModalControlService,
  ) {}

  determineMessageBelongsCurrentChat(newMessages: INewMessages) {
    this.chatMessagesFacade.deletePendingMessage({
      messages: newMessages.messages,
    });
    this.chatMessagesFacade.updateChatMessages({
      messages: newMessages.messages,
    });
  }

  public parseShortMessage(message: WhatsappMessage, length = 20): string {
    switch (message.type) {
      case 'image':
        return 'Фотография 📸';
      case 'video':
        return 'Видео 📹';
      case 'document':
        return 'Документ 🗎';
      case 'ptt':
        return 'Голосовое сообщение 🎙';
      case 'audio':
        return 'Аудио файл 🎵';
      case 'location':
        return 'Геолокация 📍';
      case 'sticker':
        return 'Стикер';
      case 'vcard':
        return 'Визитная карточка';
      case 'multi_vcard':
        return 'Несколько визитных карточек';
      case 'revoked':
        return message.text.slice(0, length);
      case 'notification':
        return 'Уведомление';
      case 'notification_template':
        return 'Шаблон уведомления';
      case 'e2e_notification':
        return 'e2e уведомление';
      case 'ciphertext':
        return 'Зашифрованный текст';
      case 'call_log':
        return 'Информация о пропущенном звонкее';
      case 'unsupported':
        return message.text;
      case 'chat':
        return this.sliceMessage(message.text, length);
      default:
        break;
    }
  }

  public sliceMessage(longText: string, length: number): string {
    let shortText = longText.slice(0, length);
    if (!!longText[length - 1] && longText[length - 1] !== ' ') {
      shortText = `${shortText}...`;
    }
    return shortText;
  }
  public refreshStatusOfMessage(messagesWithNewStatus: IStatuses) {
    if (messagesWithNewStatus) {
      this.chatMessagesFacade.refreshStatus(messagesWithNewStatus);
      this.chatsFacade.refreshStatusOfMessages(messagesWithNewStatus);
    }
  }

  getPendingMessageType(file) {
    if (file) {
      if (file.type.toString().slice(0, 5) === 'image') {
        return WhatsappMessageTypeList.Image;
      }
      if (file.type.toString().slice(0, 5) === 'video') {
        return WhatsappMessageTypeList.Video;
      }
      if (file.type.toString().slice(0, 5) === 'audio') {
        return WhatsappMessageTypeList.Audio;
      }

      return WhatsappMessageTypeList.Document;
    }
    return null;
  }

  public generateContactId(phone: number): string {
    return `${phone}@c.us`.replace('+', '');
  }

  public generateContactIdByPhoneItemOrChatId(phoneItemOrChatId: PhoneItem | string): string {
    if (typeof phoneItemOrChatId === 'string') {
      return phoneItemOrChatId;
    }

    return this.generateContactIdByPhoneItem(phoneItemOrChatId);
  }

  public generateContactIdByPhoneItem(phoneItem: PhoneItem): string {
    return `${phoneItem.code}${phoneItem.phone}@c.us`.replace('+', '');
  }

  public isGroupChatId(chatId: string | null): boolean {
    return chatId && chatId.endsWith('@g.us');
  }

  public formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  private getFileNameFromSendRequest(messageSendRequest: WhatsappSendMessageRequest): string {
    let fileName: string = messageSendRequest?.file?.name || '';
    if (!fileName && messageSendRequest.s3FileUrls && messageSendRequest.s3FileUrls.length) {
      // Берем имя файла из первого s3FileUrl
      fileName = messageSendRequest.s3FileUrls[0].split('/').pop();
    }
    return fileName || '';
  }

  private generatePendingMessageIdByRequest(messageSendRequest: WhatsappSendMessageRequest) {
    return generatePendingMessageId(
      messageSendRequest.text || this.getFileNameFromSendRequest(messageSendRequest) || '',
    );
  }

  public showPendingMessage(
    messageSendRequest,
    phoneItem: PhoneItem | null,
    chatId: string | null = null,
  ): WhatsappNewMessage {
    const messageType = this.getPendingMessageType(messageSendRequest.file);
    const date = new Date();
    const pendingId = this.generatePendingMessageIdByRequest(messageSendRequest);

    const pendingMessage: WhatsappNewMessage = {
      message: {
        id: pendingId,
        instanceId: -1,
        quotedMessageId: null,
        userId: null,
        chatId: this.generateContactId(messageSendRequest.phone),
        contactId: '',
        isFromMe: true,
        text: messageSendRequest.text || 'Сообщение отправляется...',
        status: WhatsappMessageStatusList.Unknown,
        type: messageType || WhatsappMessageTypeList.Chat,
        media: null,
        receivedAt: this.formatDate(date),
        createdAt: this.formatDate(date),
      },
      contact: {
        id: chatId || 'pending',
        name: 'pending',
        phone: phoneItem,
        isGroup: this.isGroupChatId(chatId),
      },
    };

    const newMessages: UpdateChatMessagesPayload = {
      messages: [pendingMessage],
    };
    this.chatMessagesFacade.showPendingMessage(newMessages);

    return pendingMessage;
  }

  public deletePendingMessage(pendingMessage: WhatsappNewMessage) {
    this.chatMessagesFacade.deletePendingMessage({
      messages: [pendingMessage],
    });
  }

  navigateToChat(crmCardId: number, place: string, openContentCreatorByTourId?: string) {
    this.amplitudeTrackService.trackEvent(DEAL_VIEW_OPENED, { screenType: place });
    if (!crmCardId) {
      return;
    }

    this.router.navigate(['/deals/view', crmCardId]).then(() => {
      this.electron.ipcRenderer.send('focus-on-window');
      if (openContentCreatorByTourId) {
        this.contentCreatorModalControlService.showManagerOfferModalByTourId(openContentCreatorByTourId);
      }
    });
  }

  navigateToMessageWithTour(crmCardId: number, place: string, tourId: string) {
    this.amplitudeTrackService.trackEvent(DEAL_VIEW_OPENED, { screenType: place });
    if (!crmCardId) {
      return;
    }

    this.router.navigate(['/deals/view', crmCardId, tourId]).then(() => {
      this.electron.ipcRenderer.send('focus-on-window');
    });
  }
}
