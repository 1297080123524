<div class="search-progress-container">
  @if (searchInProgress()) {
    <app-line-progress [animationDuration]="'10s'" />
  }
</div>

@if (showRestoreUIButton()) {
  <div class="restore-ui-container">
    <div class="restore-ui-controls">
      <button brx-button color="green" type="button" (click)="startNewSearchUI()">
        <img src="assets/icons/favorite-hotels/btn-search-icon.svg">
        Начать новый поиск
      </button>
      <button brx-button type="button" (click)="restoreUI()">
        Восстановить поиск
      </button>
    </div>
  </div>
}

<app-search-tours-filters-and-sort [searchResultVisible]="showSearchResult()"
                                   [initSortDirection]="hotelsSort()"
                                   (selectedSort)="updateSort($event)"
                                   (onSearchUpdated)="onSearchUpdated($event)" />

@if (showToursNotFound()) {
  <div class="tours-not-found">
    <app-alert-label>
      <p>Туры не найдены, попробуйте изменить параметры поиска</p>
    </app-alert-label>
  </div>
}

@if ((loadingHotelsList$ | async) === false) {
  <app-favorites-hotels-list [showMapButton]="showMapButton()"
                             [allowReCalculate]="!!lastSearchInitRequest()"
                             [allowResearch]="!!initSearchRequest()"
                             [initSearchRequest]="initSearchRequest()"
                             [toursCount]="toursCount()"
                             [selectedCountry]="selectedCountry()"
                             [hotels]="visibleHotels()" />
}

@if ((loadingHotelsList$ | async) === true) {
  <div class="skeleton-countries-templates">
    <app-favorite-hotels-tab-skeleton />
  </div>
  <div class="skeleton-container">
    <app-favorite-hotels-tab-skeleton />
    <app-favorite-hotels-tab-skeleton />
    <app-favorite-hotels-tab-skeleton />
  </div>
}
