import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LoginResult, WorkerLoginRequest } from '@api-clients/api-client/dist/models';
import { switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { AppConfig } from '../../../../environments/environment';
import { apiResponsePipe } from '../../../api-response.pipe';
import { ApiResponse } from '../../../../../../backend/src/models/models';
import { WorkerStateService } from '../worker/worker-state.service';
import { WebsocketService } from '../websocket/websocket.service';
import { DealsState } from '../../../+state/deals/deals.interface';

const LOCAL_KEY = 'auth-data';
export class Logout implements Action {
  readonly type = 'LOGOUT';
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private workerState: WorkerStateService,
    private websocketService: WebsocketService,
    private store: Store<DealsState>,
  ) {}

  login(workerLoginRequest: WorkerLoginRequest): Observable<LoginResult> {
    return this.http
      .post<ApiResponse<LoginResult>>(`${AppConfig.apiUrl}/auth/login`, workerLoginRequest)
      .pipe(
        apiResponsePipe,
        tap((result: LoginResult) => {
          if (!result.success) {
            throw new Error('Неправильный логин или пароль');
          }
        }),
        switchMap((result: LoginResult) => {
          this.authData = result;
          return this.workerState.getInfo(result.id, result.token);
        }),
      );
  }

  set authData(authData) {
    const { id, token, employeeToken } = authData;
    localStorage.setItem(LOCAL_KEY, JSON.stringify({ id, token, employeeToken }));
  }

  get authData(): LoginResult | null {
    return JSON.parse(localStorage.getItem(LOCAL_KEY));
  }

  get token(): LoginResult | null {
    const localData = JSON.parse(localStorage.getItem(LOCAL_KEY));
    return localData.token;
  }

  get employeeToken(): string | null {
    const localData = JSON.parse(localStorage.getItem(LOCAL_KEY));
    return localData?.employeeToken || null;
  }

  logout() {
    localStorage.clear();
    this.store.dispatch(new Logout());
    this.workerState.clearWorkerState();
    this.websocketService.closeWebsocketConnection();
  }
}
