import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { HotelPhoto } from '@api-clients/api-client';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CheckboxRoundedComponent } from '../../../../../ui-components/checkbox-rounded/checkbox-rounded.component';
import { ImageViewerComponent } from '../../../../chat-timeline/components/image-viewer/image-viewer.component';
import { ImageItem } from '../../../../chat-timeline/interfaces/chat-timeline.interface';
import { AudioModule } from '../../../../deals/modules/deals-list/modules/deals-list-content/deals-item/audio/audio.module';
import {
  ContentCreatorItemComponent,
  ContentCreatorItemMessage,
} from '../../../interfaces/content-creator.interface';

interface HotelPhotoWithPreview extends HotelPhoto {
  previewUrl: string;
  s3Url: string;
  imageForViewer: ImageItem;
}

@Component({
  selector: 'app-tour-content-photos',
  standalone: true,
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AudioModule, ImageViewerComponent, CheckboxRoundedComponent],
})
export class PhotosComponent implements ContentCreatorItemComponent, OnInit, OnDestroy {
  @Input() public tourId: string;
  @Input() public tourContent: TourContent;
  @Input() public disableSelection = false;

  public photos: HotelPhotoWithPreview[] = [];
  public visiblePhotos: HotelPhotoWithPreview[] = [];
  public hiddenPhotosCount: number;
  public selectedPhotos = new Set<HotelPhotoWithPreview>();
  public isGalleryOpen = false;

  public galleryComponentRef: ComponentRef<ImageViewerComponent>;
  private destroy$ = new Subject<void>();

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.photos = this.tourContent.hotel.photos.map(photo => ({
      ...photo,
      previewUrl: 'https://smart-like.com/img/h/sm4x3/' + photo.url,
      s3Url: 'https://smart-like.com/img/h/big/' + photo.url,
      imageForViewer: {
        previewUrl: 'https://smart-like.com/img/h/sm4x3/' + photo.url,
        contentLink: 'https://smart-like.com/img/h/big/' + photo.url,
      },
    }));
    // Выделим первые 5 фото
    this.selectedPhotos = new Set(this.photos.slice(0, 3));
    // Показываем 13 фото. Это два ряда
    this.visiblePhotos = this.photos.slice(0, 13);
    this.hiddenPhotosCount = this.photos.length - this.visiblePhotos.length;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.galleryComponentRef?.destroy();
  }

  togglePhotoSelection(photo: HotelPhotoWithPreview): void {
    if (this.isPhotoSelected(photo)) {
      this.selectedPhotos.delete(photo);
    } else {
      this.selectedPhotos.add(photo);
    }
  }

  isPhotoSelected(photo: HotelPhotoWithPreview): boolean {
    return this.selectedPhotos.has(photo);
  }

  selectPhoto(isChecked: boolean, photo: HotelPhotoWithPreview): void {
    if (isChecked) {
      this.selectedPhotos.add(photo);
    } else {
      this.selectedPhotos.delete(photo);
    }
  }

  showMorePhotos(): void {
    this.visiblePhotos = this.photos;
    this.hiddenPhotosCount = 0;
  }

  showGallery(photo: HotelPhotoWithPreview): void {
    const photoIndex = this.photos.indexOf(photo);
    this.galleryComponentRef = this.viewContainerRef.createComponent(ImageViewerComponent);
    this.galleryComponentRef.instance.images = this.getImagesForViewer(this.photos);
    this.galleryComponentRef.instance.currentImageIdx = photoIndex;
    this.galleryComponentRef.instance.selectedImages = new Set(
      this.getImagesForViewer([...this.selectedPhotos]),
    );
    this.galleryComponentRef.instance.closed.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.galleryComponentRef.destroy();
      this.isGalleryOpen = false;
    });
    this.galleryComponentRef.instance.imagesSelected.pipe(takeUntil(this.destroy$)).subscribe(images => {
      this.selectedPhotos = new Set(this.photos.filter(basePhoto => images.has(basePhoto.imageForViewer)));
      this.cdr.detectChanges();
    });
    document.body.appendChild((this.galleryComponentRef.hostView as any).rootNodes[0] as HTMLElement);
    this.isGalleryOpen = true;
  }

  private getImagesForViewer(photos: HotelPhotoWithPreview[]): ImageItem[] {
    return photos.map(photo => photo.imageForViewer);
  }

  getMessagesForSend(): Observable<ContentCreatorItemMessage[]> {
    const s3FileUrls = Array.from(this.selectedPhotos).map(photo => photo.s3Url);
    if (!s3FileUrls.length) {
      return of([] as ContentCreatorItemMessage[]);
    }
    return of(
      s3FileUrls.map(s3FileUrl => ({
        component: this,
        message: { fileUrl: s3FileUrl },
      })),
    );
  }
}
