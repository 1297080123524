import { Pipe, PipeTransform } from '@angular/core';
import { HotOffersToursSortVariant } from '../../../../hot-offers.model';

@Pipe({
  name: 'hotOffersSorts',
  standalone: true,
})
export class HotOffersSortPipe implements PipeTransform {
  transform(value: any, ...args): any {
    switch (value) {
      case HotOffersToursSortVariant.BY_DISCOUNT:
        return 'По скидке';

      case HotOffersToursSortVariant.BY_PRICE:
        return 'По цене';

      case HotOffersToursSortVariant.BY_RATING:
        return 'По рейтингу';

      case HotOffersToursSortVariant.BY_SALES_COUNT:
        return 'По популярности';

      default:
        return value;
    }
  }
}
