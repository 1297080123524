<div class="countries-dropdown"
     (clickOutside)="close()"
     [delayClickOutsideInit]="true">
  <div class="dropdown-label-container">
    <div class="dropdown-label">Куда</div>
  </div>
  <div class="dropdown-items">
    @for (country of countries(); track country.id) {
      <button (click)="selectCountry(country.id)"
              [class.active]="selectedCountryIds().includes(country.id)"
              type="button"
              class="dropdown-item">
        @if (selectedCountryIds().includes(country.id)) {
          <img src="assets/icons/favorite-hotels/search-form-check.svg" class="icon-active" />
        }
        <div class="country-name">{{ country.name }}</div>
      </button>
    }
  </div>
</div>
