import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { ChatsFacade } from 'app/+state/chats/chats.facade';
import { WhatsappContactsFacade } from 'app/+state/whatsapp-contacts/whatsapp-contacts.facade';
import { ChatListStageFilterKeys } from 'app/modules/chats/chats';
import { CurrentStageEnum } from 'app/modules/deals/modules/deal-view/modules/sales-funnel-stages/sales-funnel-stages';
import { FeatureToggleService } from 'app/modules/deals/modules/deals-list/modules/deals-list-content/helpers/feature-toggle.service';
import { filter, Observable } from 'rxjs';
import { DealItemsCounterService } from '../../../deals/services/deal-items-counter.service';
import { ZenmodeAbTestService } from '../../services/zenmode-ab-test.service';
import { TourSelectionFacade } from 'app/+state/tour-selection/tour-selection.facade';

@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideMenuComponent implements OnInit {
  public dealsTotalNumber$ = this.dealItemsCounterService.dealsNumber$;
  public unreadChatsCount$ = this.whatsappContactsFacade.unreadChats$;
  public isOnlyZenmode$ = this.zenmodeAbTestService.isOnlyZenmodeForWorker$;

  public shouldDisplayChats$ = this.featureToggleService.shouldDisplayChats$;
  public currentStageChatListFilter$ = this.chatsFacade.currentStageFilter$;
  public getStageCounterBy$ = this.chatsFacade.chatContactsStageFilteredCountBy$;
  public currentUrl = signal(this.router.url);
  public isChatsRoute = computed(() => {
    return this.currentUrl().startsWith('/chats');
  });
  public daysFiltersOpened = signal<boolean>(false);
  public readonly currentStageEnum = CurrentStageEnum;
  public readonly chatListStageFilterKeys = ChatListStageFilterKeys;

  private destroyRef = inject(DestroyRef);

  constructor(
    private zenmodeAbTestService: ZenmodeAbTestService,
    private dealItemsCounterService: DealItemsCounterService,
    private whatsappContactsFacade: WhatsappContactsFacade,
    private readonly featureToggleService: FeatureToggleService,
    private chatsFacade: ChatsFacade,
    private router: Router,
    private tourSelectionFacade: TourSelectionFacade,
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.currentUrl.set(this.router.url);
      });
  }

  public setStageFilter(stage: ChatListStageFilterKeys): void {
    this.tourSelectionFacade.closeTourSelection();
    this.chatsFacade.setStageChatListFilter(stage);
  }

  public resetStageFilter(): void {
    this.chatsFacade.resetStageChatListFilter();
  }

  public toggleDaysFilters() {
    this.daysFiltersOpened.update(value => !value);
  }
}
