<div class="search-progress-container">
  @if (searchInProgress()) {
    <app-line-progress [animationDuration]="'10s'" />
  }
</div>

<div class="tours">
  <virtual-scroller #scroll [items]="resultGroups()" [checkResizeInterval]="2000">
    @for (item of scroll.viewPortItems; track trackGroupFn) {
      <app-search-hotel [hotel]="item.hotel" [initSearchTours]="item.tours" />
    }
  </virtual-scroller>
</div>
