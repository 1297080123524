@for (monthCalendar of monthCalendars; track monthCalendar) {
  <div
    class="month-container">
    <h6 class="month-name">{{ monthCalendar.monthName }}</h6>
    <table class="month-days-container">
      <tr>
        <th>Пн</th>
        <th>Вт</th>
        <th>Ср</th>
        <th>Чт</th>
        <th>Пт</th>
        <th>Сб</th>
        <th>Вс</th>
      </tr>
      @for (row of monthCalendar.rows; track row) {
        <tr>
          @for (day of row; track day) {
            <td
              (click)="selectDate(day)"
              class="day-cell">
              <div class="day"
             [class.range-start]="(selectedDateFromIndex === day.index) ||
                                  (startDragIndex > stopDragIndex && stopDragIndex === day.index)"
             [class.range-stop]="(selectedDateToIndex === day.index) ||
                                 (startDragIndex < stopDragIndex && stopDragIndex === day.index)"
             [class.range]="
                (startDragIndex <= day.index && day.index <= stopDragIndex) ||
                (startDragIndex && stopDragIndex && startDragIndex > stopDragIndex && stopDragIndex <= day.index && day.index <= startDragIndex) ||
                (
                  (selectedDateFromIndex <= day.index && day.index <= selectedDateToIndex) &&
                  (!startDragIndex && !stopDragIndex)
                )"
                >
                <div class="day-value"
                  (mouseenter)="mouseenter(day.index)"
               [class.selected]="
                  (selectedDateFromIndex <= day.index && day.index <= selectedDateToIndex) ||
                  (selectedDateFromIndex === day.index) ||
                  (selectedDateToIndex === day.index) ||
                  (day.count > 0 && startDragIndex < day.index && day.index < stopDragIndex && !day.isEmpty)"
               [class.has-tours]="day.count > 0">
                  @if (!day.isEmpty) {
                    {{ day.dayNumber }}
                  }
                </div>
                @if (day.count) {
                  <span class="tours-count">{{ day.count }}</span>
                }
              </div>
            </td>
          }
        </tr>
      }
    </table>
  </div>
}
