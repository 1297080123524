import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DefaultResponse } from '@api-clients/api-client';
import {
  CrmCardItem,
  CrmCardUpdateRequest,
  CrmCardViewItem,
  CrmTaskItem,
  CrmTaskType,
  CurrentCallItem,
  PhoneItem,
  QuestionnaireItem,
  SearchRequest,
} from '@api-clients/crm-api-client';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { Subject, Subscription, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, take, takeUntil } from 'rxjs/operators';
import { CurrentCallPayload } from '../../../../+state/calls/call.interface';
import { CallsFacade } from '../../../../+state/calls/calls.facade';
import { CurrentCallState } from '../../../../+state/calls/calls.state';
import { DealsFacade } from '../../../../+state/deals/deals.facade';
import { PopupFacade } from '../../../../+state/popup/popup.facade';
import { PopupStatusState } from '../../../../+state/popup/popup.interface';
import { TourSelectionFacade } from '../../../../+state/tour-selection/tour-selection.facade';
import { WorkerStateService } from '../../../../core/services';
import { AmplitudeTrackService } from '../../../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../../../core/services/amplitude/amplitudeEventData';
import { DEAL_UPDATED } from '../../../../core/services/amplitude/amplitudeEvents';
import { FeatureId } from '../../../../shared/services/feature-id.enum';
import { FeatureIntroService } from '../../../../shared/services/feature-intro.service';
import { PopupService } from '../../../../shared/services/popup-service.service';
import { TourSelectionTabs } from '../../../tour-selection/tour-selection.model';
import { WhatsappApiService } from '../../../whatsapp/services/whatsapp-api.service';
import { AvatarMode } from '../deals-list/deal-list';
import { FeatureToggleService } from '../deals-list/modules/deals-list-content/helpers/feature-toggle.service';
import { CreateTaskModalPayload } from './models/create-task-modal.model';
import { IndicatorData, IndicatorPayload } from './models/indicator-payload.model';
import { CreateTaskModalComponent } from './modules/create-task-modal/create-task-modal/create-task-modal.component';
import { CreateTaskService } from './modules/create-task-modal/services/create-task.service';
import { CurrentStageEnum } from './modules/sales-funnel-stages/sales-funnel-stages';
import { CardUpdaterService } from './services/card-updater.service';
import { ChatScrollerService } from './services/chat-scroller.service';
import { CrmCardUpdateService } from './services/crm-card-update.service';
import { DealIndicatorService } from './services/deal-indicator.service';
import { ON_START_CONVERSATION, DealViewService } from './services/deal-view.service';
import { HeightService } from './services/height.service';
import { NotifyHelperService } from './services/notify-helper.service';

enum TabType {
  BASE = 'BASE',
  SETTINGS = 'SETTINGS',
}

const CardNameUpdatedLangKey = 'PAGES.DEALS.CLIENT_CARD.UPDATED.NAME';
const CardPhonesUpdatedLangKey = 'PAGES.DEALS.CLIENT_CARD.UPDATED.PHONES';

@Component({
  selector: 'app-deal-view',
  templateUrl: './deal-view.component.html',
  styleUrls: ['./deal-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealViewComponent extends OnDestroyMixin implements OnInit, OnDestroy {
  public readonly TAB_TYPES = TabType;
  public readonly POST_SALE_STATUS = 2;
  public readonly screenType: ScreenTypes = ScreenTypes.DEAL_VIEW;

  public currentStage: CurrentStageEnum;
  public queryPhone: string;
  public activeTab: TabType;
  public crmCardViewItem: CrmCardViewItem;
  public form: UntypedFormGroup;
  public inputReadonly = true;
  public currentCrmCardId: number;
  public crmId: number;
  public emptyDeal = false;
  public isDealAfterCall = false;
  public phonesWithCountryCodes: string[];
  public currentCall: CurrentCallItem;
  public questionnaire: QuestionnaireItem;
  public searchOption: SearchRequest;
  public isLoading = true;
  public callId: number;
  public callUuid: string;
  public avatarMode: AvatarMode = AvatarMode.dealView;
  public selectedPhone: PhoneItem;
  @ViewChild('cardNameInput') cardNameInput: ElementRef;
  @ViewChild('taskBlock') taskBlock: ElementRef;
  @ViewChild('chatScroller') chatScroller: ElementRef;
  public isNameEditing = false;
  public previousName: string;
  public isNameEditCancelled = false;
  public updateMessage = {
    name: '',
    phones: '',
  };
  NEW_STATUS = 0;
  public phonesSub: Subscription;
  public nameSub: Subscription;
  public popupSub: Subscription;
  public dealViewRefreshed$ = this.dealViewService.dealViewRefreshed$;
  public workerId = this.workerState.workerId;
  public employeeId = this.workerState.currentWorkerValue.employeeId;
  public tasks: CrmTaskItem[];
  isSmall = false;
  isLarge = false;
  public url: string;
  public nextTask: CrmTaskItem;
  public isZenmodeOn$ = this.dealsFacade.zenmodeOn$;
  private destroy$ = new Subject<void>();
  public indicatorData: IndicatorData;
  public isAutoScrollToChatBottom = true;
  public isInitialScrollDone = false;
  private navigatedFrom = '';
  public showScrollButton = false;

  tourSelectionTabs = TourSelectionTabs;
  tourSelectionTab$ = this.tourSelectionFacade.currentTab$;
  isTourSelectionOpen$ = this.tourSelectionFacade.isTourSelectionOpen$;

  constructor(
    private route: ActivatedRoute,
    private dealViewService: DealViewService,
    private crmCardUpdateService: CrmCardUpdateService,
    private featureIntroService: FeatureIntroService,
    private fb: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef,
    private callsFacade: CallsFacade,
    private workerState: WorkerStateService,
    private notifyHelperService: NotifyHelperService,
    private translate: TranslateService,
    private amplitudeTrackService: AmplitudeTrackService,
    private whatsappApiService: WhatsappApiService,
    private popupFacade: PopupFacade,
    private popupService: PopupService,
    private dealsFacade: DealsFacade,
    private createTaskService: CreateTaskService,
    private heightService: HeightService,
    private dealIndicatorService: DealIndicatorService,
    private chatScrollerService: ChatScrollerService,
    private cardUpdaterService: CardUpdaterService,
    private readonly tourSelectionFacade: TourSelectionFacade,
  ) {
    super();
    this.changeTab(this.TAB_TYPES.BASE);
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.closeTourSelection();
      this.currentCrmCardId = +params['id'];
      if (this.currentCrmCardId) {
        this.getDealViewById(this.currentCrmCardId);
      }
    });

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(queryParams => {
      this.navigatedFrom = queryParams['from'];
    });

    // Не надо прокручивать чат вниз, если есть ссылка на сообщение к которому нужно прокрутить
    this.isAutoScrollToChatBottom = !this.route.snapshot?.params?.messageTourId;

    this.queryPhone = this.route.snapshot.queryParamMap.get('phone');
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: [''],
      phones: this.fb.array([], Validators.required),
    });

    this.translate
      .get(CardNameUpdatedLangKey)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: string) => {
        this.updateMessage.name = res;
      });
    this.translate
      .get(CardPhonesUpdatedLangKey)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: string) => {
        this.updateMessage.phones = res;
      });
    this.popupService.popupOpenSource.pipe(takeUntil(this.destroy$)).subscribe(status => {
      if (!status.isOpen && status.closedByBackdropClick) {
        this.createTaskService.reset();
      }
    });
  }

  getQueryPhoneItem(phone: string): PhoneItem | null {
    return (
      this.crmCardViewItem.card.mobilePhones.find((phoneItem: PhoneItem) => {
        return phoneItem.phone === phone;
      }) || null
    );
  }

  ngOnInit(): void {
    this.tourSelectionFacade.setCurrentTab(undefined);

    this.dealViewService.resetDealViewRefreshed();

    if (this.navigatedFrom === ON_START_CONVERSATION) {
      this.popupFacade.popupStatus$.pipe(takeUntil(this.destroy$)).subscribe((status: PopupStatusState) => {
        if (status.popupShow) {
          const payload = this.getCreateNextTaskPayloadByDealViewItem(this.crmCardViewItem);
          this.popupService.showPopup(CreateTaskModalComponent, { ...payload });
        }
      });

      this.callsFacade.currentCall$
        .pipe(takeUntil(this.destroy$))
        .subscribe((callState: CurrentCallState) => {
          if (callState.deal) {
            this.currentCrmCardId = callState.deal.card?.id;
            this.callId = callState.callId;
            this.callUuid = callState.callUuid;
            const isConversionExclude = callState.deal.card?.isConversionExclude;

            if (this.callUuid && !isConversionExclude && !callState.isSearchOpened) {
              const isSearchOpened = this.dealViewService.openSearchTour(callState?.deal?.card?.id);
              if (isSearchOpened) {
                this.callsFacade.setSearchOpened({ isOpened: true });
              }
            }

            this.isDealAfterCall = true;
            this.dealData = callState.deal;
            this.cdRef.detectChanges();
          }
        });
    }
    this.dealViewRefreshed$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value) {
        this.getDealViewById(this.currentCrmCardId);
      }
    });

    // TODO: REFACTOR for using store approach instead
    this.cardUpdaterService.cardUpdates$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ cardId, name, info, operatorComment }) => {
        const card = this.crmCardViewItem?.card;
        if (card?.id === cardId) {
          this.crmCardViewItem = {
            ...this.crmCardViewItem,
            card: {
              ...card,
              name: name !== undefined ? name : card.name,
              info: info !== undefined ? info : card.info,
              operatorComment: operatorComment !== undefined ? operatorComment : card.operatorComment,
            },
          };

          if (name !== undefined) {
            this.form.get('name').patchValue(name);
          }

          this.cdRef.detectChanges();
        }
      });
  }

  formChanged(): void {
    this.phonesSub = this.phones.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(1500), distinctUntilChanged())
      .subscribe(() => {
        if (this.phones.dirty) {
          this.crmCardUpdateService
            .crmCardUpdate(this.currentCrmCardId, {
              phones: this.phonesWithCountryCodes,
              name: this.form.get('name').value,
            })
            .pipe(first())
            .subscribe((res: DefaultResponse) => {
              if (res.success) {
                this.notifyHelperService.notify(this.updateMessage.phones, true);
              } else {
                this.notifyHelperService.notify(this.updateMessage.phones, false);
              }
            });
        }
      });

    this.nameSub = this.form
      .get('name')
      .valueChanges.pipe(takeUntil(this.destroy$), debounceTime(2000), distinctUntilChanged())
      .subscribe(() => {
        if (this.isNameEditCancelled) {
          this.isNameEditCancelled = false;
          return;
        }
        this.updateCrmCardName();
        this.inputReadonly = false;
      });
  }

  setCurrentStage(stage: string) {
    this.currentStage = stage as CurrentStageEnum;
  }

  public isCurrentWorkerEqualsToResponsibleWorker(workerId: number): boolean {
    return workerId === this.workerState.employeeId;
  }

  removeEmptyPhoneControls(phones: string[]) {
    return phones.filter(phone => phone !== '' && phone !== '+7');
  }

  public get phones(): UntypedFormArray {
    return this.form.get('phones') as UntypedFormArray;
  }

  getDealViewById(crmCardId: number): void {
    this.dealViewService
      .getDealView(crmCardId)
      .pipe(first())
      .subscribe((deal: CrmCardViewItem) => {
        this.dealData = deal;
        this.chatScrollerService.setScrollContainer(this.chatScroller.nativeElement);
      });
  }

  initNextTask(dealViewItem: CrmCardViewItem): void {
    this.nextTask = this.extractInitialTask(dealViewItem);

    if (this.isPostSaleStatus(dealViewItem)) {
      this.processPostSaleNextTask(dealViewItem);
    }

    this.cdRef.markForCheck();
  }

  private extractInitialTask(dealViewItem: CrmCardViewItem): any {
    const urgentOrMissedTask =
      dealViewItem.tasks.find(task => task.type === CrmTaskType.Urgent || task.type === CrmTaskType.Missed) ||
      undefined;

    return urgentOrMissedTask || dealViewItem.nextTask || dealViewItem.tasks?.[0];
  }

  private isPostSaleStatus(dealViewItem: CrmCardViewItem): boolean {
    return dealViewItem?.deal?.status === this.POST_SALE_STATUS;
  }

  private processPostSaleNextTask(dealViewItem: CrmCardViewItem): void {
    if (!this.isMissedOrUrgentTask(this.nextTask)) {
      this.nextTask = null;
    }

    const firstTask = dealViewItem.tasks?.[0];
    if (this.isMissedOrUrgentTask(firstTask)) {
      this.nextTask = firstTask;
    }
  }

  private isMissedOrUrgentTask(task: any): boolean {
    return task?.type === CrmTaskType.Missed || task?.type === CrmTaskType.Urgent;
  }

  set dealData(dealViewItem: CrmCardViewItem) {
    this.nameSub?.unsubscribe();
    this.phonesSub?.unsubscribe();
    this.setCurrentStage(dealViewItem.deal?.stage);
    // hardcode until new api (stage)
    if (dealViewItem.deal?.stage === 'nextCall') {
      this.setCurrentStage('new');
      dealViewItem.deal.stage = 'new';
    }
    this.crmCardViewItem = dealViewItem;

    const indicatorPayload: IndicatorPayload = {
      conversationStatus: dealViewItem?.deal?.conversationStatus ?? null,
      task: dealViewItem?.nextTask ?? null,
      status: dealViewItem?.deal?.status ?? null,
      calls: dealViewItem?.calls ?? null,
    };

    this.indicatorData = this.dealIndicatorService.getIndicatorData(indicatorPayload);
    this.selectedPhone = this.getSelectedPhone();
    if (this.queryPhone) {
      this.crmCardViewItem.card.whatsappPhone = this.getQueryPhoneItem(this.queryPhone);
    }
    this.questionnaire = this.crmCardViewItem?.questionnaire;
    this.searchOption = this.crmCardViewItem?.searchRequests[0];

    this.crmId = dealViewItem?.card?.id;
    if (!this.currentCall) {
      this.currentCall = dealViewItem?.currentCall;
    }
    this.patchFormValues(this.crmCardViewItem?.card);
    this.initNextTask(dealViewItem);

    this.emptyDeal = !!this.crmCardViewItem;

    this.isLoading = false;

    this.formChanged();

    const isShownIntroZenmode = this.featureIntroService.isFeatureIntroduced(FeatureId.ZENMODE);

    if (!isShownIntroZenmode) {
      this.isZenmodeOn$.pipe(first()).subscribe((isZenmodeOn: boolean) => {
        if (isZenmodeOn) {
          this.dealsFacade.showZenModeIntro(false);
        }
      });
    }

    this.cdRef.detectChanges();

    this.heightService.updateHeight(this.taskBlock.nativeElement.clientHeight);
  }

  patchFormValues(card: CrmCardItem): void {
    if (card) {
      const nameInputValue = card?.name?.length ? card?.name : card?.phone;
      this.form.get('name').patchValue(nameInputValue, { emitEvent: false, onlySelf: true });

      this.phones.clear();
      if (!this.phones.length) {
        card.phones.forEach((phone: PhoneItem) => {
          const newPhoneControl = this.fb.control(phone?.phone);
          this.phones.push(newPhoneControl);
          newPhoneControl.patchValue(phone?.phone, { emitEvent: false });
        });
      }

      this.cdRef.detectChanges();
    }
  }

  changeTab(tab: TabType): void {
    this.activeTab = tab;
  }

  updateCallState(): void {
    // Обновим имя, и номера если они обновлены
    this.crmCardViewItem.card.name = this.form.value.name;
    const changedPhones: Array<PhoneItem> = [];
    this.form.value.phones.forEach(phoneNumber => {
      const phoneItem: PhoneItem = {
        code: '+7',
        phone: phoneNumber,
      };
      changedPhones.push(phoneItem);
    });
    this.crmCardViewItem.card.phones = changedPhones;
    [
      this.crmCardViewItem.card.phone,
      this.crmCardViewItem.card.mobilePhone,
      this.crmCardViewItem.card.phone3,
      this.crmCardViewItem.card.phone4,
      this.crmCardViewItem.card.phone5,
      this.crmCardViewItem.card.phone6,
      this.crmCardViewItem.card.phone7,
    ] = this.form.value.phones;
    const payload: CurrentCallPayload = {
      deal: this.crmCardViewItem,
      callId: this.callId,
      callUuid: this.callUuid,
    };
    this.callsFacade.updateCurrentCall(payload);
  }

  updateCrmCardName(): void {
    if (this.form.invalid && this.crmId) {
      this.isLoading = false;
      return;
    }

    const crmCardRequest: CrmCardUpdateRequest = {
      ...this.form.value,
    };

    if (this.phonesWithCountryCodes) {
      crmCardRequest.phones = this.removeEmptyPhoneControls(this.phonesWithCountryCodes);
    }

    this.crmCardUpdateService
      .crmCardUpdate(this.crmId, crmCardRequest)
      .pipe(first())
      .subscribe(() => {
        const message = this.updateMessage.name;
        this.amplitudeTrackService.trackEvent(DEAL_UPDATED, { field: 'name' });
        this.notifyHelperService.notify(message, true);

        this.updateCallState();
        timer(5000)
          .pipe(take(1))
          .subscribe(() => {
            this.cdRef.markForCheck();
          });
        this.cdRef.markForCheck();
      });
  }

  inputFocusOn(event): void {
    event.stopPropagation();
    this.manuallyEditInput();
  }

  manualRefreshDealView(): void {
    if (this.crmId) {
      this.getDealViewById(this.crmId);
      this.cdRef.detectChanges();
    }
  }

  change($event: string[]): void {
    this.phonesWithCountryCodes = $event;
  }

  manuallyEditInput() {
    this.previousName = this.form.value?.name;
    this.inputReadonly = false;
    this.cardNameInput.nativeElement.focus();
    this.isNameEditing = true;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    super.ngOnDestroy();
  }

  toggleTab() {
    if (this.activeTab === TabType.BASE) {
      this.activeTab = TabType.SETTINGS;
    } else {
      this.activeTab = TabType.BASE;
    }
  }

  getSelectedPhone(): PhoneItem {
    if (this.crmCardViewItem?.card?.whatsappPhone) {
      return this.crmCardViewItem.card.whatsappPhone;
    }
    if (this.crmCardViewItem?.card?.mobilePhones.length) {
      return this.crmCardViewItem.card.mobilePhones[0];
    }

    return this.crmCardViewItem.card.phones[0];
  }

  changeSelectedPhone(event: PhoneItem) {
    this.selectedPhone = event;
    const phoneAsString = event.code + event.phone;
    this.whatsappApiService.changeWhatsappPhone(phoneAsString, this.crmId).pipe(first()).subscribe();
  }

  saveName() {
    this.isNameEditing = false;
    this.inputReadonly = true;
    this.previousName = this.form.value?.name;
  }

  cancelNameEditing() {
    this.isNameEditing = false;
    this.inputReadonly = true;
    this.form.get('name').patchValue(this.previousName);
    this.isNameEditCancelled = true;
  }

  getIsNewDeal(): boolean {
    return this.crmCardViewItem?.deal?.status === this.NEW_STATUS;
  }

  getCreateNextTaskPayloadByDealViewItem(dealViewItem): CreateTaskModalPayload {
    return {
      task: dealViewItem?.lastTasks[0],
      crmCardId: dealViewItem?.card?.id,
      dealId: dealViewItem?.deal?.id,
      dealStage: dealViewItem?.deal?.stage,
      phone: `${dealViewItem?.card?.phones[0]?.code}${dealViewItem?.card?.phones[0]?.phone}`,
      dealStatus: dealViewItem?.deal?.status,
      isAfterCall: true,
      isNewClient: dealViewItem?.deal.status === this.NEW_STATUS,
    };
  }

  onScroll(event: any) {
    const { scrollTop, scrollHeight } = event.target;
    if (scrollTop === 0) {
      this.chatScrollerService.saveScrollPosition(scrollTop, scrollHeight);
      this.chatScrollerService.notifyScrollTopReached();
    }
    this.maybeShowScrollToBottomButton(event);
  }

  public onScrollToBottomButtonClick() {
    this.chatScrollerService.notifyScrollBottomNeeded();
  }

  private maybeShowScrollToBottomButton(event: any) {
    const threshold = 100;
    const position =
      this.chatScroller.nativeElement.scrollHeight - event.target.scrollTop - event.target.clientHeight;
    this.showScrollButton = position > threshold;
  }

  onInitialScrollDone() {
    this.isInitialScrollDone = true;
  }

  closeTourSelection(): void {
    this.tourSelectionFacade.setCurrentTab(undefined);
    this.tourSelectionFacade.closeTourSelection();
  }
}
