import { AsyncPipe, DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { TourSelectionFacade } from '../../../../../../../+state/tour-selection/tour-selection.facade';
import { SearchFormService } from '../../../../../../deals/modules/deal-view/modules/favorites-hotels/components/search-form/search-form.service';
import { HotOffersService } from '../../../../../../deals/modules/deal-view/modules/hot-offers/hot-offers.service';
import { GoogleMapsLoaderService } from '../../../../../../search/services/google-maps-loader.service';
import { TourSelectionTabs } from '../../../../../../tour-selection/tour-selection.model';

@Component({
  selector: 'app-chat-send-tours-popup',
  templateUrl: './send-tours-popup.component.html',
  styleUrl: './send-tours-popup.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClickOutsideDirective, AsyncPipe, DecimalPipe],
})
export class SendToursPopupComponent implements OnInit {
  close = output<void>();

  googleMapsReady$ = this.googleMapsLoader.libraryLoaded$;
  minPrice$ = this.hotOffersService.minPrice$;
  isTourSelectionOpen$ = this.tourSelectionFacade.isTourSelectionOpen$;

  private isTourSelectionOpen: boolean;
  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly tourSelectionFacade: TourSelectionFacade,
    private readonly googleMapsLoader: GoogleMapsLoaderService,
    private readonly hotOffersService: HotOffersService,
    private readonly searchFormService: SearchFormService,
  ) {}

  ngOnInit(): void {
    this.isTourSelectionOpen$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(isTourSelectionOpen => {
      this.isTourSelectionOpen = isTourSelectionOpen;
    });
  }

  showSearchTours(): void {
    this.openTourSelection(TourSelectionTabs.SEARCH_TOURS);
  }

  showHotOffers(): void {
    this.openTourSelection(TourSelectionTabs.HOT_OFFERS);
  }

  private openTourSelection(tab: TourSelectionTabs): void {
    this.tourSelectionFacade.setCurrentTab(tab);
    this.tourSelectionFacade.openTourSelection();

    if (this.isTourSelectionOpen) {
      this.searchFormService.openSearchForm();
    }

    this.close.emit();
  }
}
