import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  computed,
  DestroyRef,
  inject,
  input,
  OnInit,
  signal,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { SearchFormParams } from '@api-clients/api-client/dist/models';
import { CrmCardItem } from '@api-clients/crm-api-client';
import { SearchRequest as CrmCardSearchRequest } from '@api-clients/crm-api-client/models/search-request';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { TranslateModule } from '@ngx-translate/core';
import { merge, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PopupService } from '../../../../../../../../shared/services/popup-service.service';
import { LineProgressComponent } from '../../../../../../../../ui-components/line-progress/line-progress.component';
import { SearchHotelComponent } from '../../../../../../../search/components/hotel/search-hotel.component';
import { SEARCH_DATE_FORMAT, SearchHotel } from '../../../../../../../search/search.model';
import { WebsocketToursSearchService } from '../../../../../../../search/services/search/websocket-tours-search.service';
import {
  InitSearchRequest,
  InitSearchRequestMethod,
  InitSearchRequestOptionsGroupResult,
  InitSearchRequestType,
  SearchResult,
  SearchResultsResponseTour,
} from '../../../../../../../search/services/search/websocket-tours.model';
import { ChatDragAndDropService } from '../../../../services/chat-drag-and-drop.service';
import { SearchFormService } from '../../components/search-form/search-form.service';
import { MultipleSearchFormParams, SearchResultGroup } from '../../favorites-hotels.model';
import { SearchToursProgressService } from '../../services/search-tours-progress.service';
import { MultipleSearchFormComponent } from './components/search-form/multiple-search-form.component';

@Component({
  selector: 'app-multiple-search',
  templateUrl: './multiple-search.component.html',
  styleUrl: './multiple-search.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatTooltip, TranslateModule, LineProgressComponent, SearchHotelComponent, VirtualScrollerModule],
  providers: [ChatDragAndDropService, PopupService],
})
export class MultipleSearchComponent implements OnInit {
  formParams = input.required<SearchFormParams>();
  crmCardItem = input<CrmCardItem>();
  crmCardLastSearchRequest = input<CrmCardSearchRequest>();
  searchInProgress = toSignal(this.searchToursProgressService.searchInProgress$);

  tours = signal<{ [key: number]: SearchResultsResponseTour }>({});
  resultGroups = computed<SearchResultGroup[]>(() => {
    const tours = Object.values(this.tours());

    return tours.map(tour => {
      const hotel: SearchHotel = {
        id: tour.hotel.id,
        stars: tour.hotel.class,
        name: tour.hotel.name,
        photo: tour.hotel.photo,
        region: tour.region.name,
        salesCount: tour.hotel.salesCount,
        bookingRating: tour.hotel.rating,
      };
      const group: SearchResultGroup = {
        hotel,
        tours: [tour],
      };

      return group;
    });
  });

  private lastSearchParam: MultipleSearchFormParams;
  private componentSearchFormRef: ComponentRef<MultipleSearchFormComponent>;
  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly datePipe: DatePipe,
    private readonly searchService: WebsocketToursSearchService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly chatDDService: ChatDragAndDropService,
    private readonly searchFormService: SearchFormService,
    private readonly searchToursProgressService: SearchToursProgressService,
  ) {}

  ngOnInit(): void {
    this.searchFormService.showSearchForm$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(showSearchForm => {
        if (showSearchForm) {
          this.openSearchForm();
        } else {
          this.closeSearchForm();
        }
      });
  }

  trackGroupFn(group: SearchResultGroup): string {
    return group.hotel.id + '-' + group.tours[0].brandPrice.value;
  }

  private closeSearchForm(): void {
    this.componentSearchFormRef?.destroy();
    this.componentSearchFormRef = undefined;

    this.chatDDService.closeArea();
  }

  private openSearchForm(): void {
    this.createSearchFormComponent();

    this.chatDDService.showArea({
      showCloseButton: false,
      showTourDropArea: false,
      closeOnBgClick: true,
      customComponentRef: this.componentSearchFormRef,
    });
  }

  private startSearch(searchParams: MultipleSearchFormParams): void {
    const initSearchRequests = [];
    searchParams.countryIds.forEach(countryId => {
      const initSearchRequest = this.getInitSearchRequest(countryId, searchParams);
      initSearchRequests.push(initSearchRequest);
    });

    const observables: Observable<SearchResult>[] = [];
    initSearchRequests.forEach(initSearchRequest => {
      const result$ = this.searchService
        .searchTours(initSearchRequest, { isMainSearch: false, addToursInMainResult: false })
        .pipe(
          catchError(() =>
            of<SearchResult>({ searchIsDone: true, totalTours: 0, hasTours: false, tours: [] }),
          ),
        );
      observables.push(result$);
    });

    merge(...observables).subscribe({
      next: (result: SearchResult) => {
        if (result.hasTours) {
          const newTours = result.tours;
          const currentTours = Object.values(this.tours());
          newTours.forEach(tour => {
            const currentTourIndex = currentTours.findIndex(
              resultTour => resultTour.hotel.id === tour.hotel.id,
            );
            const currentTour = currentTours[currentTourIndex];
            if (currentTour) {
              if (currentTour.brandPrice.value > tour.brandPrice.value) {
                currentTours.splice(currentTourIndex, 1);
              }
            } else {
              currentTours.push(tour);
            }
          });

          currentTours.sort((a, b) => {
            return a.brandPrice.value - b.brandPrice.value;
            // const salesCount1 = a.hotel.salesCount;
            // const salesCount2 = b.hotel.salesCount;
            //
            // return salesCount2 - salesCount1;
          });
          this.tours.set({ ...currentTours });
        }
      },
    });
  }

  private getInitSearchRequest(countryId: number, searchParams: MultipleSearchFormParams): InitSearchRequest {
    const initSearchRequest: InitSearchRequest = {
      id: WebsocketToursSearchService.generateId(),
      method: InitSearchRequestMethod.search,
      params: {
        params: {
          adults: searchParams.tourists.adults,
          childrenAges: searchParams.tourists.childAges.map(age => Number(age)),
          splitRooms: searchParams.tourists.splitRooms,
          combined: Number(searchParams.combined),
          countryId,
          dateFrom: this.datePipe.transform(searchParams.dates.from, SEARCH_DATE_FORMAT),
          dateTo: this.datePipe.transform(searchParams.dates.to, SEARCH_DATE_FORMAT),
          departCityId: searchParams.departCityId,
          nightsFrom: searchParams.nights.from,
          nightsTo: searchParams.nights.to,
          notGDS: searchParams.notGDS,
          onlyHotels: false,
        },
        options: {
          type: InitSearchRequestType.main,
          groupResults: InitSearchRequestOptionsGroupResult.byHotel,
          allowParallel: true,
        },
      },
    };
    if (searchParams.hotelIds.length) {
      initSearchRequest.params.params.hotels = searchParams.hotelIds;
    }

    if (searchParams.airlineIds.length) {
      initSearchRequest.params.params.airlines = searchParams.airlineIds;
    }

    if (searchParams.stars.length) {
      initSearchRequest.params.params.starsList = searchParams.stars;
    }

    if (searchParams.mealIds.length) {
      initSearchRequest.params.params.meals = searchParams.mealIds;
    }

    if (searchParams.operatorIds.length) {
      initSearchRequest.params.params.operators = searchParams.operatorIds;
    }

    return initSearchRequest;
  }

  private createSearchFormComponent(): void {
    if (this.componentSearchFormRef) {
      this.closeSearchForm();
      return;
    }

    this.componentSearchFormRef = this.viewContainerRef.createComponent(MultipleSearchFormComponent);
    this.componentSearchFormRef.setInput('formParams', this.formParams());
    this.componentSearchFormRef.instance.close.subscribe(() => {
      this.closeSearchForm();
    });
    this.componentSearchFormRef.instance.submit.subscribe(searchParams => {
      this.lastSearchParam = searchParams;
      this.startSearch(searchParams);

      this.closeSearchForm();
    });
    this.componentSearchFormRef.instance.ngOnDestroy = () => {
      this.closeSearchForm();
      this.chatDDService.closeArea();
    };
  }
}
