<div appDraggable [dragHandle]="handle" class="container">
  <div #handle class="drag-handle"></div>

  <div class="header">Календарь цен</div>
  <button (click)="closeModal()" class="close-button" type="button">
    <img alt="" src="/assets/icons/close-icon.svg" />
  </button>

  @if (calendarCreateInProgress) {
    <div class="loader-container">
      <h4>Строим календарь</h4>
      <app-line-progress [animationDuration]="'30s'" />
    </div>
  } @else {
    <app-alert-label type="warning">{{ hotelName }}</app-alert-label>
    <div class="filters-container">
      <form [formGroup]="filterForm" class="filters-form">
        <div class="filter-form">
          <app-search-form-checkbox-list formControlName="mealIds"
                                         [label]="'Питание'"
                                         [visibleCount]="0"
                                         [items]="mealVariants" />
        </div>
        <div class="filter-form">
          <app-search-form-checkbox-list formControlName="airlineIds"
                                         [label]="'Авиакомпании'"
                                         [visibleCount]="0"
                                         [items]="airlineVariants" />
        </div>
        <div class="filter-form">
          <app-search-form-checkbox-list formControlName="operatorIds"
                                         [label]="'Операторы'"
                                         [visibleCount]="0"
                                         [items]="operatorVariants" />
        </div>
        <button type="button"
                class="filter-form-submit-btn" (click)="updateCalendar()">
        </button>
      </form>
    </div>

    @if (showUpdateCalendarProgress) {
      <div class="loader-container">
        <h4>Перестраиваем календарь</h4>
        <app-line-progress [animationDuration]="'30s'" />
      </div>
    } @else {
      <div class="table-calendar-container">
        <table class="table-calendar">
          <thead>
          <tr>
            <th class="date"></th>
            @for (date of dates; track date; let first = $first; let last = $last) {
              <th class="date">
                <div class="cell">
                  @if (first) {
                    <span class="prev-date" (click)="prevCalendarDate()">
                    <img class="prev-date-icon" src="assets/icons/search/calendar-chevron-right.svg" />
                  </span>
                  }

                  @if (last) {
                    <span class="next-date" (click)="nextCalendarDate()">
                    <img class="next-date-icon" src="assets/icons/search/calendar-chevron-right.svg" />
                  </span>
                  }

                  {{ date | date: 'dd.MM' }}
                </div>
              </th>
            }
          </tr>
          </thead>
          <tbody>
            @for (night of nights; track night) {
              <tr [ngClass]="{'highlight-row': isRowHighlighted(night)}">
                <td class="nights" [ngClass]="{'highlight-col': isRowHighlighted(night)}">
                  <div class="cell">{{ night | pluralize: 'ночь,ночи,ночей' }}</div>
                </td>
                @for (date of dates; track date) {
                  <td [class.highlight-col]="isColHighlighted(date)"
                      [class.highlight-cell]="isCellHighlighted(date, night) && tableData[night][date]"
                      (mouseover)="highlightCell(date, night)"
                      (mouseleave)="unhighlightCell()">
                    @if (tableData[night][date]) {
                      <div class="cell available"
                           [class.is-best]="tableData[night][date].isBest"
                           [class.current-tour]="tableData[night][date].tourId === viewedTour()?.tourId"
                           (click)="selectTour(tableData[night][date])">
                        <div class="tour-container">
                          <div class="price-value" [class.best]="tableData[night][date].isBest">
                            {{ tableData[night][date].price | number: '1.0-0' }} {{ tableData[night][date].currency | priceCurrency }}
                          </div>
                          <div class="operator">
                            {{ tableData[night][date].operatorName }}

                            @if (tableData[night][date].isGDS) {
                              <span class="is-gds">GDS</span>
                            }
                          </div>

                          @if (tableData[night][date].isBest && tableData[night][date].bestIndex) {
                            <span class="best-index">{{ tableData[night][date].bestIndex }}</span>
                          }

                          @if (tableData[night][date].mealNameShort && tableData[night][date].mealNameShort) {
                            <span class="meal-name">{{ tableData[night][date].mealNameShort }}</span>
                          }
                        </div>
                      </div>
                    } @else {
                      <div class="cell">&nbsp;</div>
                    }
                  </td>
                }
              </tr>
            }
          </tbody>
        </table>
        <div class="tour-detail">
          @if (viewedTour()) {
            <app-search-tour-calendar-tour-detail [tour]="viewedTour()" />
            <button brx-button color="blue" type="button"
                    (click)="selectedTour.emit(viewedTour().tourId)">
              <img src="assets/icons/favorite-hotels/map-info-send-tour.svg" class="icon">
              Отправить
            </button>
          }
        </div>
      </div>
    }

    @if (showError) {
      <app-alert-label [type]="errorTypeLabel">
        Не смогли построить календарь цен
      </app-alert-label>
    }
  }
</div>
