import { ChatContactsListItem, CrmCardViewItem } from '@api-clients/crm-api-client';
import { CurrentStageEnum } from '../deals/modules/deal-view/modules/sales-funnel-stages/sales-funnel-stages';
import { SalesFunnelCategoryEnum } from '../deals/modules/deals-list/deal-list';

export type ChatListExclusiveFilterKey = 'forgotten' | 'unread' | 'groups' | 'all';

export enum ChatListStageFilterKeys {
  ALL = 'all',
  NEW = 'newLeads',
  MISSED_AND_URGENT = 'urgentConnect',
  IN_PROGRESS = 'workInProgress',
  MEETING = CurrentStageEnum.meeting,
  READY_FOR_BOOKING = CurrentStageEnum.readyForBooking,
  POSTPONED = SalesFunnelCategoryEnum.postponed,
  COLD_TOUCH = CurrentStageEnum.coldTouch,
  AUTO_TOUCH = CurrentStageEnum.autoTouch,
  POST_SALE = SalesFunnelCategoryEnum.postSale,
  FIRST_DAY = 'daysStage1',
  SECOND_DAY = 'daysStage2',
  THIRD_DAY = 'daysStage3',
  FOURTH_TO_SEVENTH_DAYS = 'daysStage4',
  MORE_THAN_SEVEN_DAYS = 'daysStage5',
}

export enum ChatListInclusiveFilterKeys {
  IS_FORGOTTEN = 'isForgotten',
  IS_NEARBY_DATES = 'isNearbyDates',
  IS_RETURN_TOURIST = 'isReturnTourist',
  IS_MOBILE_APP = 'isMobileApp',
  IS_HIGH_CHECK = 'isHighCheck',
}

export enum ChatListSortType {
  LAST_TOUCH = 'last_touch',
  ZENMODE = 'zenmode',
}

export interface ChatWithDealListItem {
  chat: ChatContactsListItem;
  deal: CrmCardViewItem | null;
}

export interface ChatsWithDealsResult {
  chats: ChatWithDealListItem[];
  deals: CrmCardViewItem[];
}

export enum ChatListTouchTodayFilterKeys {
  LEFT_TO_TOUCH = 'left_to_touch',
  TOUCHED_TODAY = 'touched_today',
}

export interface InclusiveFiltersCounters{
    isForgotten: number;
    isNearbyDates: number;
    isReturnTourist: number;
    isMobileApp: number;
    isHighCheck: number;
}
