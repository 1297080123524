export enum InitSearchRequestMethod {
  search = 'search',
}

export enum InitSearchRequestType {
  main = 0,
  calc = 2,
}

export enum InitSearchRequestOptionsGroupResult {
  byHotel = 1,
  noGroup = 3,
}

export interface InitSearchRequestOptions {
  pages?: number;
  type?: InitSearchRequestType;
  groupResults?: InitSearchRequestOptionsGroupResult;
  timeout?: number;
  allowParallel?: boolean;
}

export interface InitSearchRequestHistoryParams {
  cdrID?: number;
  crmID?: number;
}

export interface InitSearchRequestParams {
  adults: number;
  airlines?: string[];
  andromedaOperatorId?: number;
  childrenAges: number[];
  combined?: number;
  countryId: number;
  currency?: string;
  dateFrom?: string;
  dateTo?: string;
  departCityId?: number;
  groupMode?: number;
  hotels?: number[];
  meals?: number[];
  nightsFrom: number;
  nightsTo: number;
  notGDS: boolean;
  onlyHotels: boolean;
  operators?: number[];
  priceMax?: number;
  priceMin?: number;
  regions?: number[];
  splitRooms?: boolean;
  starsList?: number[];
}

export interface InitSearchRequest {
  id: string;
  method: InitSearchRequestMethod;
  params: {
    params: InitSearchRequestParams;
    options: InitSearchRequestOptions;
    historyParams?: InitSearchRequestHistoryParams;
  };
}

export interface SearchResultsResponseCity {
  id: number;
  name: string;
}

export interface SearchResultsResponseCountry {
  id: number;
  name: string;
}

export interface SearchResultsResponseRegion {
  id?: number;
  name: string;
}

export interface SearchResultsResponseHotel {
  id?: number;
  name: string;
  photo?: string;
  class: string;
  rating: number;
  salesCount: number;
  conversionPercent?: number;
}

export interface SearchResultsResponseMeal {
  id?: number;
  code?: string;
  name?: string;
}

export interface SearchResultsResponseOperator {
  id: number;
  code: string;
}

export interface SearchResultsResponseAirline {
  code: string;
  name: string;
  hasIcon: boolean;
}

export interface SearchResultsResponsePrice {
  value: number;
  currency: string;
}

export interface SearchResultsResponseTourists {
  adults: number;
  childAges?: number[];
}

export interface SearchResultsResponseRoom {
  id?: number;
  name: string;
}

export interface SearchResultsResponseTour {
  id: string;
  departCity?: SearchResultsResponseCity; // Не будет для отелей
  country: SearchResultsResponseCountry;
  region?: SearchResultsResponseRegion; // Может не быть если не распознан
  hotel?: SearchResultsResponseHotel; // Может не быть если не распознан
  date: string;
  nights: number;
  nightsOnWay?: number; // Не будет для отелей
  isGDS?: boolean; // Не будет для отелей
  isCombined?: boolean; // Не будет для отелей
  meal?: SearchResultsResponseMeal; // Может не быть если не распознан
  operator: SearchResultsResponseOperator;
  airlines?: SearchResultsResponseAirline[]; // Не будет для отелей
  discount: number;
  price: SearchResultsResponsePrice;
  brandPrice: SearchResultsResponsePrice;
  tourists: SearchResultsResponseTourists;
  rooms?: SearchResultsResponseRoom[];
  bookURL?: string;
  freights: SearchResultsResponseFreights;
  expiredAt?: Date;
}

export interface SearchResultsResponseFreightsSeats {
  economy: SearchResultsResponseFreightsSeatDirection;
  business: SearchResultsResponseFreightsSeatDirection;
}

export interface SearchResultsResponseFreightsSeatDirection {
  forward: SearchResultsResponseSeatAvailable;
  backward: SearchResultsResponseSeatAvailable;
}

export enum SearchResultsResponseSeatAvailable {
  yes = 'Y',
  no = 'N',
  few = 'F',
  request = 'R',
}

export interface SearchResultsResponseFreights {
  airlines: SearchResultsResponseAirline[];
  seats: SearchResultsResponseFreightsSeats;
}

export interface SearchResultsResponseData {
  tours: SearchResultsResponseTour[];
}

export enum SearchResultsResponseResultType {
  searchResults = 'search_results',
  searchCompleted = 'search_completed',
}

export interface SearchResultsResponseResult {
  type: SearchResultsResponseResultType;
  data: SearchResultsResponseData | null;
}

export interface SearchResultsResponse {
  id: string;
  result?: SearchResultsResponseResult;
  error?: { code: number; message: string };
}

export interface SearchResult {
  searchIsDone: boolean;
  totalTours: number;
  hasTours: boolean;
  tours: SearchResultsResponseTour[];
}

export interface SearchConfig {
  isMainSearch?: boolean;
  addToursInMainResult?: boolean;
  groupMode?: InitSearchRequestOptionsGroupResult;
}

export type StorageSearchGroupResult = { [key: number]: SearchResultsResponseTour[] };
