import { CurrencyPipe, DatePipe, DecimalPipe, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  computed,
  DestroyRef,
  ElementRef,
  inject,
  input,
  Input,
  OnDestroy,
  OnInit,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { ManagerSearchTourCalendarRequest } from '@api-clients/api-client';
import { DndModule } from 'ngx-drag-drop';
import { Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { PopupService } from '../../../../shared/services/popup-service.service';
import { BrxButtonComponent } from '../../../../ui-components/hermes/button/brx-button.component';
import { ContentCreatorContentType } from '../../../content-creator/interfaces/content-creator.interface';
import { ContentCreatorModalControlService } from '../../../content-creator/services/content-creator-modal-control.service';
import { ChatDragAndDropService } from '../../../deals/modules/deal-view/services/chat-drag-and-drop.service';
import { PriceCurrencyPipe } from '../../pipes/price-currency.pipe';
import { SearchTouristsRecalc, SearchTourSelectMode } from '../../search.model';
import { SearchTourCalendarService } from '../../services/search-tour-calendar.service';
import { WebsocketToursSearchService } from '../../services/search/websocket-tours-search.service';
import {
  InitSearchRequest,
  InitSearchRequestMethod,
  InitSearchRequestOptionsGroupResult,
  InitSearchRequestParams,
  InitSearchRequestType,
  SearchResult,
  SearchResultsResponseTour,
} from '../../services/search/websocket-tours.model';
import { SearchTourCalendarComponent } from '../calendar/search-tour-calendar.component';
import { SearchResultLabelCombiComponent } from './label-combi/search-result-label-combi.component';
import { SearchResultHotelConversionComponent } from './label-conversion/search-result-hotel-conversion.component';
import { SearchTourLabelGdsComponent } from './label-gds/search-tour-label-gds.component';
import { SearchTourLabelSplitRoomsComponent } from './label-split-rooms/search-tour-label-split-rooms.component';
import { SearchTourMenuComponent } from './menu/search-tour-menu.component';

enum TourState {
  notRecalc = 'not-recalc',
  notFound = 'not-found',
  recalcInProgress = 'recalc-in-progress',
  recalced = 'recalced',
  error = 'error',
}

@Component({
  selector: 'app-search-tour',
  templateUrl: './search-tour.component.html',
  styleUrls: ['./search-tour.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    DatePipe,
    DecimalPipe,
    CurrencyPipe,
    NgSwitch,
    NgSwitchCase,
    PriceCurrencyPipe,
    DndModule,
    SearchTourLabelSplitRoomsComponent,
    SearchTourLabelGdsComponent,
    SearchResultHotelConversionComponent,
    MatTooltip,
    BrxButtonComponent,
    SearchResultLabelCombiComponent,
  ],
  providers: [PopupService],
})
export class SearchTourComponent implements OnInit, OnDestroy {
  tour = input.required<SearchResultsResponseTour>();
  initSearchRequest = input<InitSearchRequest>();
  contentCreatorType = input<ContentCreatorContentType>(ContentCreatorContentType.CustomMessage);
  tourSelectMode = input<SearchTourSelectMode>(SearchTourSelectMode.drop);
  exactSearch = input<boolean>(false);
  showDiscount = input<boolean>(true);
  showConversion = input<boolean>(false);
  showTourMenu = input<boolean>(true);
  showPrice = input<boolean>(true);

  tourExpired = signal<boolean>(false);
  isSplit = signal<boolean>(false);
  recalcPrice = signal<number>(0);
  recalcTour = signal<SearchResultsResponseTour>(undefined);
  airlines = computed<string>(() => {
    return this.tour()
      .airlines.map(v => v.code)
      .join('+');
  });

  operatorCode = computed<string>(() => {
    return this.tour().operator.code;
  });

  mealName = computed<string>(() => {
    return this.tour().meal.code || this.tour().meal.name;
  });

  tourNights = computed<string>(() => {
    const nights = this.tour().nights;
    let text = nights.toString();
    if (this.tour().nightsOnWay > 0) {
      text += `+${this.tour().nightsOnWay}`;
    }

    return text;
  });

  tourMenuRef = viewChild<ElementRef>('tourMenu');

  @Input() emitRecalcTour$: Observable<SearchTouristsRecalc>;

  tourSelectModeVariants = SearchTourSelectMode;

  stateVariants = TourState;
  tourState = TourState.notRecalc;

  draggableData: any;

  private canShowRecalc = true;
  private recalcTouristsSubscription: Subscription;
  private emitRecalcTourSubscription: Subscription;
  private searchSubscription: Subscription;
  private searchStopFunction: () => void;
  private tourCalendarComponentRef: ComponentRef<SearchTourCalendarComponent>;
  private tourMenuComponentRef: ComponentRef<SearchTourMenuComponent>;
  private destroyRef = inject(DestroyRef);
  private timeoutResearchId: NodeJS.Timeout;
  private timeoutTourExpired: NodeJS.Timeout;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly chatDDService: ChatDragAndDropService,
    private readonly searchTourCalendar: SearchTourCalendarService,
    private readonly searchToursService: WebsocketToursSearchService,
    private readonly contentCreatorModalControlService: ContentCreatorModalControlService,
    private readonly popupService: PopupService,
  ) {}

  ngOnInit() {
    const tour = this.tour();
    this.isSplit.set(this.tour()?.rooms?.length > 1);

    this.draggableData = {
      tourId: tour.id,
      hotelName: tour.hotel.name,
      contentCreatorType: this.getContentCreatorType(),
    };

    if (this.tourSelectMode() === SearchTourSelectMode.drop) {
      this.draggableData.calendarInitRequest = this.getCreateCalendarRequest();
    }

    this.emitRecalcTourSubscription = this.emitRecalcTour$
      ?.pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => {
          this.tourState = TourState.recalcInProgress;
          this.cdRef.detectChanges();
        }),
        switchMap(tourists => {
          const params: Partial<InitSearchRequestParams> = {
            adults: tourists.adults,
            childrenAges: tourists.childAges,
            splitRooms: tourists.splitRooms,
          };
          return of(params);
        }),
      )
      .subscribe(this.recalcFn.bind(this));

    if (this.tour().expiredAt) {
      this.processTourExpire();
      this.timeoutTourExpired = setTimeout(() => {
        this.processTourExpire();
      }, 10000);
    }
  }

  ngOnDestroy() {
    this.emitRecalcTourSubscription?.unsubscribe();
    this.searchSubscription?.unsubscribe();
    this.recalcTouristsSubscription?.unsubscribe();

    this.tourCalendarComponentRef?.destroy();
    this.tourMenuComponentRef?.destroy();

    if (this.searchStopFunction) {
      this.searchStopFunction();
    }

    if (this.timeoutTourExpired) {
      clearTimeout(this.timeoutTourExpired);
    }
  }

  onMouseEnterOnPrice() {
    if (!this.tourExpired()) {
      return;
    }

    this.startHoverTimer();
  }

  onMouseLeaveOnPrice() {
    if (!this.tourExpired()) {
      return;
    }

    this.clearHoverTimer();
  }

  onDragStart() {
    if (this.getContentCreatorType() === ContentCreatorContentType.ManagerPricesCalendar) {
      const request = this.getCreateCalendarRequest();
      this.searchTourCalendar.getCalendar(request);
    }

    this.canShowRecalc = true;
    this.chatDDService.showArea({ showTourDropArea: true });
  }

  onDragEnd() {
    if (!this.canShowRecalc) {
      return;
    }
    this.chatDDService.closeArea();
  }

  onDragCanceled() {
    this.canShowRecalc = false;
    this.chatDDService.closeArea();
  }

  showCalendar() {
    if (this.tourSelectMode() !== SearchTourSelectMode.calendar) {
      return;
    }

    if (this.getContentCreatorType() === ContentCreatorContentType.ManagerPricesCalendar) {
      this.tourCalendarComponentRef = this.popupService.showPopup(SearchTourCalendarComponent);
      this.tourCalendarComponentRef.instance.tourId = this.tour().id;
      this.tourCalendarComponentRef.instance.hotelName = this.tour().hotel.name;
      this.tourCalendarComponentRef.instance.initCreateRequest = this.getCreateCalendarRequest();
      this.tourCalendarComponentRef.instance.notGDS =
        this.initSearchRequest()?.params?.params?.notGDS || false;
      this.tourCalendarComponentRef.instance.selectedTour.subscribe((tourId: string) => {
        this.contentCreatorModalControlService.showManagerOfferModalByTourId(tourId);
        this.popupService.closeAllModals();
      });
      this.tourCalendarComponentRef.instance.closed.subscribe(() => {
        this.popupService.closeAllModals();
        this.tourCalendarComponentRef.destroy();
      });
    } else {
      this.contentCreatorModalControlService.showManagerOfferModalByTourId(this.tour().id);
    }
  }

  showMenu(e: Event): void {
    e.stopPropagation();

    this.tourMenuComponentRef = this.popupService.showPopup(
      SearchTourMenuComponent,
      {},
      {
        anchorElement: this.tourMenuRef(),
      },
    );
    this.tourMenuComponentRef.setInput('tour', this.recalcTour() || this.tour());
  }

  private startHoverTimer() {
    this.timeoutResearchId = setTimeout(() => {
      this.researchTour();
      this.tourExpired.set(false);
    }, 1000);
  }

  private clearHoverTimer() {
    if (this.timeoutResearchId) {
      clearTimeout(this.timeoutResearchId);
      this.timeoutResearchId = null;
    }
  }

  private researchTour(): void {
    const params: Partial<InitSearchRequestParams> = {
      adults: this.tour().tourists.adults,
      childrenAges: this.tour().tourists.childAges,
      splitRooms: this.initSearchRequest()?.params?.params?.splitRooms || false,
    };
    this.recalcFn(params);
  }

  private getCreateCalendarRequest(): ManagerSearchTourCalendarRequest {
    const request = this.searchTourCalendar.getDefaultApiRequest(this.draggableData.tourId);
    if (this.initSearchRequest()) {
      const initSearchRequest = this.initSearchRequest();
      if (initSearchRequest.params?.params.airlines?.length) {
        request.airlineIds = initSearchRequest.params.params.airlines;
      }
      if (initSearchRequest.params?.params.meals?.length) {
        request.mealIds = initSearchRequest.params.params.meals;
      }
      if (initSearchRequest.params?.params.operators?.length) {
        request.operatorIds = initSearchRequest.params.params.operators;
      }
    }

    return request;
  }

  private recalcFn(partialParams: Partial<InitSearchRequestParams>) {
    const tour = this.tour();

    const params = {
      ...partialParams,
      ...{
        dateFrom: tour.date,
        dateTo: tour.date,
        nightsFrom: tour.nights,
        nightsTo: tour.nights,
        notGDS: partialParams.notGDS || true,
        combined: 0,
        countryId: tour.country.id,
        departCityId: tour.departCity.id,
        hotels: [tour.hotel.id],
      },
    };

    if (this.exactSearch()) {
      if (tour.operator.id) {
        params.operators = [tour.operator.id];
      }
      if (tour.meal.id) {
        params.meals = [tour.meal.id];
      }
    } else if (this.initSearchRequest()) {
      if (this.initSearchRequest().params.params.operators) {
        params.operators = this.initSearchRequest().params.params.operators;
      }
      if (this.initSearchRequest().params.params.meals) {
        params.meals = this.initSearchRequest().params.params.meals;
      }
    }

    const initSearchRequest: InitSearchRequest = this.initSearchRequest()
      ? { ...this.initSearchRequest() }
      : {
          id: WebsocketToursSearchService.generateId(),
          params: {
            params: params as any,
            options: {
              type: InitSearchRequestType.calc,
              groupResults: InitSearchRequestOptionsGroupResult.byHotel,
              allowParallel: true,
            },
          },
          method: InitSearchRequestMethod.search,
        };

    initSearchRequest.id = WebsocketToursSearchService.generateId();
    this.searchToursService
      .searchTours(initSearchRequest, { isMainSearch: false })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: SearchResult) => {
          if (!response.searchIsDone) {
            return;
          }
          if (response.hasTours) {
            const tour = response.tours.sort((a, b) => a.brandPrice.value - b.brandPrice.value)[0];

            this.tourState = TourState.recalced;
            this.recalcPrice.set(tour.brandPrice.value);
            this.recalcTour.set(tour);
            this.isSplit.set(tour?.rooms?.length > 1);
            this.draggableData = { tourId: tour.id };
          } else {
            this.tourState = TourState.notFound;
            this.recalcPrice.set(0);
            this.recalcTour.set(undefined);
          }

          this.cdRef.detectChanges();
        },
        error: () => {
          this.tourState = TourState.error;
          this.cdRef.detectChanges();
        },
      });
  }

  private getContentCreatorType(): ContentCreatorContentType {
    if (this.tour()?.rooms?.length > 1) {
      return ContentCreatorContentType.ManagerOffer;
    }

    return this.contentCreatorType();
  }

  private processTourExpire(): void {
    const now = new Date();
    const expiredAt = this.recalcTour()?.expiredAt || this.tour().expiredAt;

    if (expiredAt < now) {
      this.tourExpired.set(true);
    }
  }
}
