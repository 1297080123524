<div class="logo">
  <ng-container *ngIf="isDevVersion; else productionVersion">
    <div class="dev-logo" (click)="changeVersionToProduction()" title="Перейти на prod версию">
      <img src="assets/img/logo-dev.svg" alt="logo" />
      <span class="label-dev">DEV</span>
    </div>
  </ng-container>
  <ng-template #productionVersion>
    <img src="assets/img/logo.svg" alt="logo" />
  </ng-template>
</div>

<div class="top-nav left-nav" *ngIf="(zenModeOn$ | async) === false">
  <div class="nav-item border-left">
    <a (click)="showManagersList()">
      {{ 'LAYOUT.TOP.MENU.MANAGERS' | translate }}
    </a>
  </div>
  <div class="nav-item">
    <a (click)="tourDBOpened()" libExternalLink [externalURL]="adminUrl + '/TourDb?active'">
      {{ 'LAYOUT.TOP.MENU.TP_LIST' | translate }}
    </a>
  </div>
  <div class="nav-item">
    <app-network-status />
  </div>
</div>

<nav class="top-nav">
  <div class="nav-item">
    <app-search-form></app-search-form>
  </div>
  <div class="nav-item border-left padding-right" *ngIf="(zenModeOn$ | async) === false">
    <app-commission></app-commission>
  </div>

  <div class="nav-item border-left">
    <app-worker-status></app-worker-status>
  </div>

  <div class="nav-item" *ngIf="(zenModeOn$ | async) === false">
    <div class="icon clickable" (click)="showNotificationsPopup()">
      <img src="./assets/icons/top-menu/notifications.svg" alt="notifications" />
      <div class="count">
        <app-notifications-center-count></app-notifications-center-count>
      </div>
    </div>
  </div>
  <div class="nav-item border-right" *ngIf="(zenModeOn$ | async) === false">
    <div class="icon">
      <img src="./assets/icons/top-menu/right-side-menu/worker-avatar.svg" alt="avatar" />
    </div>
    <div class="icon arrow" [ngClass]="{ rotate: (popupOpen$ | async) }" (click)="toggleMenu()">
      <img src="./assets/icons/top-menu/right-side-menu/arrow-down.svg" alt="arrow" />
    </div>
  </div>

  <div class="nav-item border-left padding-right no-gap" *ngIf="zenModeOn$ | async">
    <button (click)="openZenmodeList()">
      <img class="icon clickable" src="assets/icons/zenmode/book.svg" alt="help" />
    </button>
  </div>

  <div class="nav-item border-right border-left" *ngIf="zenModeOn$ | async" id="help-intro">
    <button (click)="showZenModeIntro()">
      <img class="icon clickable" src="assets/icons/zenmode/help.svg" alt="help" />
    </button>
  </div>
  <div *ngIf="(isAvailableZenmode$ | async) || (zenModeOn$ | async)" class="nav-item last" id="toggle-intro">
    <app-zenmode-toggle></app-zenmode-toggle>
  </div>
</nav>
