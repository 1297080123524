import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { NetworkStatusService } from '../../shared/services/network-status.service';
import { NetworkStatusComponent } from './components/top-menu/network-status/network-status.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { AsideMenuComponent } from './components/aside-menu/aside-menu.component';
import { SharedModule } from '../../shared/shared.module';
import { MainComponent } from './components/main/main.component';
import { SearchFormComponent } from './components/search-form/search-form.component';
import { CommissionComponent } from './components/commission/commission.component';
import { CommissionService } from './components/commission/services/commission.service';
import { WorkersListComponent } from './components/workers-list/workers-list.component';
import { WorkersListService } from './components/workers-list/services/workers-list.service';
import { SlideToggleModule } from '../../ui-components/slide-toggle/slide-toggle.module';
import { SearchResultsComponent } from './components/search-form/search-results/search-results.component';
import { GetInitialsModule } from '../../helpers/pipes/getInitials/get-initials.module';
import { AlertOnTopModule } from '../alert-on-top/alert-on-top.module';
import { SharedUtilityModule } from '../../../../../../libs/shared/utility/src';
import { RecommendationsFacade } from '../../+state/recommendations/recommendations.facade';
import { UpdateWindowComponent } from './components/update-window/update-window.component';
import { NotificationsCenterCountComponent } from './components/notifications-center-count/notifications-center-count.component';
import { DealItemsCounterService } from '../deals/services/deal-items-counter.service';
import { AsideChatsModule } from '../aside-chats/aside-chats.module';
import { RightSideMenuComponent } from './components/right-side-menu/right-side-menu.component';
import { RightSideMenuItemComponent } from './components/right-side-menu/components/right-side-menu-item/right-side-menu-item.component';
import { WorkerNameComponent } from './components/workers-list/components/worker-name/worker-name.component';
import { ZenmodeWelcomePopupContainerModule } from '../zenmode/modules/zenmode-welcome-popup-container/zenmode-welcome-popup-container.module';
import { ZenmodePaginateModule } from '../zenmode/modules/zenmode-paginate/zenmode-paginate.module';
import { WorkerStatusComponent } from './components/worker-status/worker-status.component';
import { StatusIndicatorComponent } from './components/worker-status/components/status-indicator/status-indicator.component';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { SecondsToMinutesSecondsPipe } from 'app/helpers/pipes/seconds-to-minutes-seconds.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    TopMenuComponent,
    AsideMenuComponent,
    MainComponent,
    SearchFormComponent,
    CommissionComponent,
    WorkersListComponent,
    SearchResultsComponent,
    UpdateWindowComponent,
    NotificationsCenterCountComponent,
    RightSideMenuComponent,
    RightSideMenuItemComponent,
    WorkerNameComponent,
    WorkerStatusComponent,
    StatusIndicatorComponent,
  ],
  exports: [TopMenuComponent, AsideMenuComponent, SearchFormComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    MatDialogModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    SlideToggleModule,
    GetInitialsModule,
    AlertOnTopModule,
    SharedUtilityModule,
    MatProgressBarModule,
    AsideChatsModule,
    ZenmodeWelcomePopupContainerModule,
    ZenmodePaginateModule,
    MatMenuModule,
    MatButtonModule,
    NgClickOutsideDirective,
    SecondsToMinutesSecondsPipe,
    NetworkStatusComponent,
  ],
  providers: [
    CommissionService,
    WorkersListService,
    RecommendationsFacade,
    DealItemsCounterService,
    NetworkStatusService,
  ],
})
export class LayoutModule {}
