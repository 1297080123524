import { Injectable } from '@angular/core';
import { ChatContactExpand, CrmCardViewItem } from '@api-clients/crm-api-client';
import { ChatRecommendationDeleteReason } from '@api-clients/crm-api-client/dist/models/chat-recommendation-delete-reason';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DealViewService } from 'app/modules/deals/modules/deal-view/services/deal-view.service';
import { catchError, concatMap, EMPTY, forkJoin, map, Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { debounceTime, filter, take, tap } from 'rxjs/operators';
import { AmplitudeTrackService } from '../../core/services/amplitude/amplitude-track.service';
import { CHAT_RECOMMENDATION_GET } from '../../core/services/amplitude/amplitudeEvents';
import { ChatRecommendation } from '../../modules/chats/interfaces/chat.interface';
import { ChatContactsService } from '../../modules/chats/services/chat-contacts.service';
import { ChatRecommendationsApiService } from '../../modules/chats/services/chat-recommendations-api.service';
import * as ChatTimelineActions from '../chat-timeline/chat-timeline.actions';
import { DealsFacade } from '../deals/deals.facade';
import * as ChatsActions from './chats.actions';
import { ChatsFacade } from './chats.facade';

@Injectable()
export class ChatEffects {
  loadChatContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.loadChatContacts),
      switchMap(() =>
        this.chatContactsService.getContactsListAll().pipe(
          map(response => ChatsActions.loadChatContactsSuccess({ chatContacts: response.list })),
          catchError(error => {
            console.log('loadChatContacts error: ', error);
            return of(
              ChatsActions.loadChatContactsFailure({
                error: 'An unknown error occurred',
              }),
            );
          }),
        ),
      ),
    ),
  );

  loadChatContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.loadChatContact),
      concatMap(action =>
        this.chatContactsService.getContactById(action.contactId).pipe(
          map(contact => ChatsActions.loadChatContactSuccess({ chatContact: contact })),
          catchError(error => {
            console.log('loadChatContact error: ', error);
            return of(
              ChatsActions.loadChatContactFailure({
                error: 'Failed to load contact',
              }),
            );
          }),
        ),
      ),
    ),
  );

  loadChatRecommendation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.loadChatRecommendation),
      switchMap(() => {
        // Загружаем список сделок
        this.dealsFacade.loadAll();

        // Дожидаемся загрузки списка сделок и получаем сформированный zenmodeList
        return this.getPreviousRecommendationAndZenmodeDealIds().pipe(
          switchMap(([previousRecommendation, zenmodeDealIds]) => {
            if (!zenmodeDealIds.length) {
              return EMPTY;
            }

            // Ничего не надо менять если сделка есть и она активна
            if (
              previousRecommendation &&
              !previousRecommendation.isDeletedOrSent &&
              !previousRecommendation.isDeleted
            ) {
              return EMPTY;
            }

            return this.chatRecommendationsApiService.get(zenmodeDealIds).pipe(
              map(response => response.recommendations[0]),
              switchMap((recommendation: ChatRecommendation) =>
                forkJoin({
                  contact: this.chatContactsService.getContactById(recommendation.chatId, [
                    ChatContactExpand.LastMessage,
                    ChatContactExpand.TourPackagesStats,
                    ChatContactExpand.DealInfo,
                    ChatContactExpand.HasMobileApplication,
                    ChatContactExpand.Recommendation,
                  ]),
                  recommendation: of(recommendation),
                }),
              ),
              map(({ contact, recommendation }) => {
                this.amplitudeTrackService.trackEvent(CHAT_RECOMMENDATION_GET, {
                  'Recommendation ID': recommendation.id,
                });

                return ChatsActions.loadChatRecommendationSuccess({
                  ...recommendation,
                  chatContact: contact,
                  isDeletedOrSent: recommendation.messages.every(msg => msg.isDeleted || msg.isSent),
                  deletedOrSentMessages: recommendation.messages.filter(msg => msg.isDeleted || msg.isSent),
                });
              }),
              catchError(error => {
                console.error('loadChatRecommendation error: ', error);
                return of(
                  ChatsActions.loadChatRecommendationFailure({
                    error: 'Failed to load chat recommendation',
                  }),
                );
              }),
            );
          }),
        );
      }),
    ),
  );

  // Проверка, что рекомендация все еще находится в zenmodeList и удаление, если ее там нем
  checkChatRecommendation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.checkChatRecommendation),
      concatMap(() => {
        return this.getPreviousRecommendationAndZenmodeDealIds().pipe(
          switchMap(([previousRecommendation, zenmodeDealIds]) => {
            if (previousRecommendation) {
              const isRecommendationInZenmode = zenmodeDealIds.includes(
                previousRecommendation.dealId as number,
              );
              // Если есть активная рекомендация, но ее больше нет в списке зенмода - удаляем ее,
              // т.к. скорее всего клиенту отправили сообщение или перенесли его
              // и рекомендация больше не активна
              if (!isRecommendationInZenmode && !previousRecommendation.isDeletedOrSent) {
                return of(
                  ChatsActions.markRecommendationAsDeleted({ recommendation: previousRecommendation }),
                );
              }
            }
            return EMPTY;
          }),
        );
      }),
    ),
  );

  markRecommendationMessageAsDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.markRecommendationMessageAsDeleted),
      concatMap(message =>
        this.chatRecommendationsApiService.markMessageAsDeleted(message.id as number).pipe(
          map(() => ChatsActions.markRecommendationMessageAsDeletedSuccess(message)),
          catchError(error => {
            console.log('markRecommendationMessageAsDeleted error: ', error);
            return of(
              ChatsActions.markRecommendationMessageAsDeletedFailure({
                error: 'Failed to mark recommendation message as deleted',
              }),
            );
          }),
        ),
      ),
    ),
  );

  markRecommendationMessageAsSent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.markRecommendationMessageAsSent),
      concatMap(({ message, changedText }) =>
        this.chatRecommendationsApiService.markMessageAsSent(message.id as number, changedText).pipe(
          map(() => ChatsActions.markRecommendationMessageAsSentSuccess(message)),
          catchError(error => {
            console.log('markRecommendationMessageAsSent error: ', error);
            return of(
              ChatsActions.markRecommendationMessageAsSentFailure({
                error: 'Failed to mark recommendation message as sent',
              }),
            );
          }),
        ),
      ),
    ),
  );

  markRecommendationAsDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.markRecommendationAsDeleted),
      concatMap(({ recommendation }) =>
        this.chatRecommendationsApiService
          .markAsDeleted(recommendation.id, ChatRecommendationDeleteReason.NotInZenmode)
          .pipe(
            map(() => ChatsActions.markRecommendationAsDeletedSuccess(recommendation)),
            catchError(error => {
              console.log('markRecommendationAsDeleted error: ', error);
              return of(
                ChatsActions.markRecommendationAsDeletedFailure({
                  error: 'Failed to mark recommendation as deleted',
                }),
              );
            }),
          ),
      ),
    ),
  );

  getNewChatRecommendationWhenAllMessagesDeletedOrSent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ChatsActions.markRecommendationMessageAsDeletedSuccess,
        ChatsActions.markRecommendationMessageAsSentSuccess,
        ChatsActions.markRecommendationAsDeletedSuccess,
      ),
      withLatestFrom(this.chatsFacade.chatRecommendation$),
      // Проверяем, были ли все сообщения удалены или отправлены
      filter(
        ([, chatRecommendation]) =>
          chatRecommendation.isDeleted ||
          chatRecommendation.deletedOrSentMessages.length === chatRecommendation.messages.length,
      ),
      // Запрос новых рекомендаций только через 5 минут
      debounceTime(300000),
      // Загружаем новую рекомендацию
      map(() => ChatsActions.loadChatRecommendation()),
    ),
  );

  updateChatContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.updateChatContact),
      map(() => ChatsActions.sortChatContacts()),
    ),
  );

  sortChatContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.sortChatContacts),
      withLatestFrom(this.chatsFacade.chatContactsAll$),
      map(([, chatContacts]) => {
        const sortedContacts = [...chatContacts].sort((a, b) => {
          const aDateDesc = a.lastMessage?.receivedAt || '';
          const bDateDesc = b.lastMessage?.receivedAt || '';

          if (a.lastMessage && !b.lastMessage) {
            return -1;
          } else if (!a.lastMessage && b.lastMessage) {
            return 1;
          }

          const aDate = new Date(aDateDesc).getTime();
          const bDate = new Date(bDateDesc).getTime();

          return bDate - aDate;
        });

        return ChatsActions.sortChatContactsSuccess({ chatContacts: sortedContacts });
      }),
    ),
  );

  chooseChatContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.chooseChatContact),
      concatMap(({ chatContact }) => {
        const contactId = chatContact.contact.id;
        const crmCardId = chatContact.contact.crmCardId;
        if (!crmCardId) {
          // Группы либо логин в телеге
          return of(ChatsActions.readChat({ contactId, fromPlace: 'hermes_chat' }));
        }

        return of(
          ChatTimelineActions.resetChatTimelineState(),
          ChatsActions.readChat({ contactId, fromPlace: 'hermes_chat' }),
          ChatsActions.loadCrmCardViewItemForChat({ crmCardId }),
        );
      }),
    ),
  );

  loadDealViewForChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.loadCrmCardViewItemForChat),
      switchMap(action =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        this.dealViewService.getDealViewForChat(action.crmCardId).pipe(
          map((crmCardViewItem: CrmCardViewItem) =>
            ChatsActions.loadCrmCardViewItemForChatSuccess({ crmCardViewItem }),
          ),
          catchError(error => {
            console.log('loadDealViewForChat error: ', error);
            return of(
              ChatsActions.loadDealViewForChatFailure({
                error: 'Failed to load deal view for chat',
              }),
            );
          }),
        ),
      ),
    ),
  );

  loadDealViewNextTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.loadDealViewNextTask),
      switchMap(action =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        this.dealViewService.getDealViewForChat(action.crmCardId, ['next-task']).pipe(
          switchMap((crmCardViewItem: CrmCardViewItem) => {
            const { deal } = crmCardViewItem;

            if (!deal) {
              return [
                ChatsActions.loadDealViewNextTaskSuccess({ nextTask: null }),
                ChatsActions.setChatContactDealToNull({
                  crmCardId: crmCardViewItem.card.id,
                }),
                ChatsActions.updateCurrentChatContactCrmCardDeal({
                  deal: null,
                }),
              ];
            }

            return [
              ChatsActions.loadDealViewNextTaskSuccess({ nextTask: crmCardViewItem.nextTask }),
              ChatsActions.updateChatContactStage({
                crmCardId: crmCardViewItem.card.id,
                stage: crmCardViewItem.deal.stage,
              }),
              ChatsActions.updateCurrentChatContactCrmCardStage({
                stage: crmCardViewItem.deal.stage,
              }),
            ];
          }),
          catchError(error => {
            console.log('loadDealViewNextTask error: ', error);
            return of(
              ChatsActions.loadDealViewNextTaskFailure({
                error: 'Failed to load deal view next task',
              }),
            );
          }),
        ),
      ),
    ),
  );

  readChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.readChat),
      switchMap(({ contactId, fromPlace }) => {
        return this.chatContactsService.readChat(contactId, fromPlace).pipe(
          map(() => ChatsActions.readChatSuccess({ contactId })),
          catchError(error =>
            of(
              ChatsActions.readChatFailure({
                message: 'Read chat failure',
                error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  searchContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatsActions.searchContacts),
      switchMap(action =>
        this.chatContactsService.searchContacts(action.searchQuery).pipe(
          map(contacts => {
            const chatContactsList = contacts.chats;
            return ChatsActions.searchContactsSuccess({ contacts: chatContactsList });
          }),
          catchError(error => of(ChatsActions.searchContactsFailure({ error: String(error) }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private chatsFacade: ChatsFacade,
    private dealsFacade: DealsFacade,
    private chatContactsService: ChatContactsService,
    private chatRecommendationsApiService: ChatRecommendationsApiService,
    private dealViewService: DealViewService,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {}

  getPreviousRecommendationAndZenmodeDealIds(): Observable<[ChatRecommendation | null, number[]]> {
    return this.dealsFacade.dealsList$.pipe(
      filter(dealsList => dealsList && dealsList.length > 0),
      // Ожидаем, пока список сделок не перестанет изменяться в течение 1 секунды.
      // Т.к. там сортировка идет отдельно через эффекты и нельзя использовать первый же результат
      // списка сделок и генерировать на основе него zenmodeList
      debounceTime(1000),
      take(1),
      tap(() => {
        // Шаг 3: Сгенерировать zenmodeList
        this.dealsFacade.generateZenmodeList();
      }),
      // Шаг 4: Дождаться генерации zenmodeList
      switchMap(() => this.dealsFacade.zenmodeList$),
      filter(zenmodeList => zenmodeList && zenmodeList.length > 0),
      take(1),
      // Нам нужна предыдущая рекомендация
      withLatestFrom(this.chatsFacade.chatRecommendation$),
      // Формируем список подходящих сделок
      map(([zenmodeList, previousRecommendation]) => {
        const dealIds = zenmodeList
          .filter(card => {
            // @ts-ignore
            const lastActionDate = card.lastAction?.actionDate.slice(0, 10);
            return (
              // Т.к. рекомендации показываются в чатах, нам важно чтобы было был телефон чата,
              // потому что без него не будет контакта клиента (в случаях если менеджер только звонил).
              card.card.whatsappPhone &&
              // Нет новых сообщений
              card.deal.conversationStatus !== 1 &&
              // Нет действий сегодня
              lastActionDate !== new Date().toISOString().slice(0, 10)
            );
          })
          .map(card => card.deal.id);
        // Возвращаем все и id сделок
        return [previousRecommendation, dealIds];
      }),
    );
  }
}
