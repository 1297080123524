import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PhoneItem, WhatsappMessage } from '@api-clients/crm-api-client';
import { WhatsappMessageExpand } from '@api-clients/crm-api-client/models/whatsapp-message-expand';
import { BehaviorSubject, combineLatest, EMPTY, expand, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, filter, first, map, takeUntil, tap } from 'rxjs/operators';
import { ChatMessagesFacade } from '../../+state/chat-messages/chat-messages.facade';
import { ChatsFacade } from '../../+state/chats/chats.facade';
import { ChatRecommendation } from '../chats/interfaces/chat.interface';
import { ChatScrollerService } from '../deals/modules/deal-view/services/chat-scroller.service';
import { WhatsappApiService } from '../whatsapp/services/whatsapp-api.service';
import { WhatsappService } from '../whatsapp/services/whatsapp.service';
import { ChatTimelineFilterService } from './components/chat-time-line-filters/services/chat-timeline-filter.service';
import {
  ChatTimeLineDayItem,
  ChatTimeLineItem,
  ChatTimelineData,
  FilterListEnum,
  ImageItem,
} from './interfaces/chat-timeline.interface';
import { ChatTimelineService } from './services/chat-timeline.service';
import { isPhoneOrChatIdChanged } from '../../+state/chat-messages/chat-messages.helper';
import { MessageSyncService } from './services/message-sync.service';

const WHATSAPP_PAGE_MESSAGE_COUNT = 50;

@Component({
  selector: 'app-chat-timeline',
  templateUrl: './chat-timeline.component.html',
  styleUrls: ['./chat-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatTimelineComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
  @Input() chatTimelineData: ChatTimelineData;
  @Input() currentPhoneOrChatId: PhoneItem | string;
  @Input() isNoTasks: boolean;
  @ViewChildren('messageItem') messageItems: QueryList<ElementRef>;
  public chatTimeLineDayItems: ChatTimeLineDayItem[];
  public images: ImageItem[];
  public isMaximized: boolean;
  public currentImageIdx = 0;
  public isLoading = false;
  public isMoreMessagesLoading = false;
  public currentPage = 1;
  public isLastPage = false;
  public isShowWhatsappChat = true;
  public isFirstMessagesInitialized = false;
  public isChatTimelineInitialized = false;
  public chatRecommendation$: Observable<ChatRecommendation | null>;

  private newWhatsappMessages: ChatTimeLineItem[] = [];
  private messages: WhatsappMessage[] = [];
  private messagesExpands = [
    'quoted-message',
    'hotel-meta',
    'tour-meta',
    'group-message-sender',
  ] as WhatsappMessageExpand[];

  private isChatInitialized$ = new BehaviorSubject<boolean>(false);
  private scrollToMessageReplaySubject$ = new ReplaySubject<string>(1);
  private scrollToMessageWithTourReplaySubject$ = new ReplaySubject<string>(1);
  private pendingScrollMessageId: string | null = null;

  private destroy$ = new Subject<void>();

  constructor(
    private chatTimelineService: ChatTimelineService,
    private whatsappApiService: WhatsappApiService,
    private whatsappService: WhatsappService,
    private chatsFacade: ChatsFacade,
    private cdRef: ChangeDetectorRef,
    private chatTimelineFilterService: ChatTimelineFilterService,
    private chatScrollerService: ChatScrollerService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private messageSyncService: MessageSyncService,
    private chatMessagesFacade: ChatMessagesFacade,
  ) {
    this.initializeScrollHandlers();

    // Слушаем что пришло в параметрах и если есть messageTourId, то скроллим к сообщению с этим туром
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.messageTourId) {
        this.chatTimelineService.scrollToMessageWithTour(params.messageTourId as string);
      }
    });
  }

  private initializeScrollHandlers(): void {
    // Прокрутка должна быть только после того, как подгрузятся основные сообщения
    this.chatTimelineService.scrollToMessage$
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.scrollToMessageReplaySubject$);

    this.chatTimelineService.scrollToMessageWithTour$
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.scrollToMessageWithTourReplaySubject$);

    combineLatest([this.scrollToMessageReplaySubject$, this.isChatInitialized$])
      .pipe(
        filter(([messageId, initialized]) => initialized && messageId !== null),
        map(([messageId]) => messageId),
        takeUntil(this.destroy$),
      )
      .subscribe(messageId => {
        this.scrollToMessageReplaySubject$.next(null);
        this.findAndScrollToMessage(message => message.id === messageId);
      });

    combineLatest([this.scrollToMessageWithTourReplaySubject$, this.isChatInitialized$])
      .pipe(
        filter(([tourId, initialized]) => initialized && tourId !== null),
        map(([tourId]) => tourId),
        takeUntil(this.destroy$),
      )
      .subscribe(tourId => {
        this.scrollToMessageWithTourReplaySubject$.next(null);
        // При загрузке чата могут быть еще дополнительные скроллы,
        // поэтому ждем немного, чтобы избежать конфликтов
        this.findAndScrollToMessage(message => message?.meta?.tour && message.meta.tour.id === tourId);
      });
  }

  findAndScrollToMessage(searchFunction: (message: WhatsappMessage) => boolean): void {
    let attempts = 0;
    const maxAttempts = 5;
    of(null)
      .pipe(
        expand(() => {
          if (++attempts > maxAttempts) {
            alert('Сообщение не найдено после нескольких попыток загрузки. Возможно оно слишком старое');
            return EMPTY;
          }
          // Ищем сообщение по условию searchFunction и прокручиваем к нему, если оно найдено
          const message = this.messages.find(searchFunction);
          if (message) {
            this.scrollToMessage(message.id);
            return EMPTY;
          } else {
            return this.loadMoreMessages();
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  loadMoreMessages(): Observable<WhatsappMessage[]> {
    if (this.currentPhoneOrChatId && !this.isLastPage && !this.isMoreMessagesLoading) {
      this.isMoreMessagesLoading = true;
      const contactId = this.whatsappService.generateContactIdByPhoneItemOrChatId(this.currentPhoneOrChatId);
      this.currentPage += 1;

      return this.whatsappApiService
        .getWhatsappMessagesByContactId(
          contactId,
          this.currentPage,
          WHATSAPP_PAGE_MESSAGE_COUNT,
          this.messagesExpands,
        )
        .pipe(
          takeUntil(this.destroy$),
          tap((messages: WhatsappMessage[]) => {
            this.isMoreMessagesLoading = false;
            if (!messages.length) {
              this.isLastPage = true;
              this.cdRef.detectChanges();
              return;
            }

            // Перед текущими сообщениями добавляются новые сообщения
            this.messages = messages.concat(this.messages.slice(0));
            this.chatTimelineData.lastWhatsappMessages = this.messages;
            this.chatTimeLineDayItems = this.chatTimelineService.generateChatTimeLineDayItems(
              this.chatTimelineData,
            );

            // Перемещаем скролл к самому новому сообщению
            // this.messageItems?.last?.nativeElement?.scrollIntoView({ behavior: 'smooth' });
            // setTimeout(() => {
            // }, 0);
            this.chatScrollerService.restoreScrollPosition();
            this.cdRef.detectChanges();
          }),
          catchError(error => {
            this.isMoreMessagesLoading = false;
            console.error('Error loading more messages:', error);
            return of([] as WhatsappMessage[]);
          }),
        );
    }
    return of([] as WhatsappMessage[]);
  }

  private checkTodayMessages(messages: WhatsappMessage[]) {
    const today = new Date().toDateString();
    if (messages.some(message => new Date(message.createdAt).toDateString() === today)) {
      this.chatMessagesFacade.setHasTodayMessages(true);
    }
  }

  initChatTimeline() {
    this.chatMessagesFacade.setHasTodayMessages(false);
    // Показываем рекомендации, если они есть
    this.chatRecommendation$ = this.chatsFacade.chatRecommendation$.pipe(
      // Берем только те рекомендации, которые соответствуют текущему контакту
      map(recommendation => {
        if (recommendation && !recommendation.isDeletedOrSent && this.currentPhoneOrChatId) {
          // Если строка, то это chatId
          const chatId =
            typeof this.currentPhoneOrChatId === 'string'
              ? this.currentPhoneOrChatId
              : this.whatsappService.generateContactIdByPhoneItem(this.currentPhoneOrChatId);
          return recommendation.chatContact.contact.id === chatId ? recommendation : null;
        }
        return null;
      }),
      takeUntil(this.destroy$),
    );

    // Сначала отображаем имеющиеся сообщения
    this.chatTimeLineDayItems = this.chatTimelineService.generateChatTimeLineDayItems(this.chatTimelineData);
    this.isLoading = true;
    this.isChatInitialized$.next(false);
    this.cdRef.detectChanges();

    this.currentPage = 1;
    if (this.currentPhoneOrChatId) {
      const contactId = this.whatsappService.generateContactIdByPhoneItemOrChatId(this.currentPhoneOrChatId);

      this.whatsappApiService
        .getWhatsappMessagesByContactId(
          contactId,
          this.currentPage,
          WHATSAPP_PAGE_MESSAGE_COUNT,
          this.messagesExpands,
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (messages: WhatsappMessage[]) => {
            this.messages = messages;
            const chatTimelineData: ChatTimelineData = {
              ...this.chatTimelineData,
              lastWhatsappMessages: messages,
            };
            if (messages.length < WHATSAPP_PAGE_MESSAGE_COUNT) {
              this.isLastPage = true;
            }
            this.chatTimelineData = chatTimelineData;
            this.chatTimeLineDayItems =
              this.chatTimelineService.generateChatTimeLineDayItems(chatTimelineData);
            this.isChatTimelineInitialized = true;
            this.isLoading = false;
            // Проверяем есть ли сегодняшние сообщения в загруженных сообщениях
            this.checkTodayMessages(messages);

            this.cdRef.detectChanges();
            this.isChatInitialized$.next(true);
          },
          () => {
            this.chatTimeLineDayItems = this.chatTimelineService.generateChatTimeLineDayItems(
              this.chatTimelineData,
            );
            this.isChatTimelineInitialized = true;
            this.isLoading = false;
            this.cdRef.detectChanges();
            this.isChatInitialized$.next(true);
          },
        );
    } else {
      this.chatTimeLineDayItems = this.chatTimelineService.generateChatTimeLineDayItems(
        this.chatTimelineData,
      );
      this.isChatTimelineInitialized = true;
      this.isLoading = false;
      this.cdRef.detectChanges();
      this.isChatInitialized$.next(true);
    }
  }

  ngOnInit(): void {
    this.chatScrollerService
      .onScrollTopReached()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loadMoreMessages().pipe(first(), takeUntil(this.destroy$)).subscribe();
      });

    // Проверяем есть ли сегодняшние сообщения в реалтайм общении
    this.chatMessagesFacade.chatMessages$
      .pipe(
        tap(messages => this.checkTodayMessages(messages)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.initChatTimeline();
    this.messageSyncService.messageDeleted$.pipe(takeUntil(this.destroy$)).subscribe(messageId => {
      // Проверяем, существует ли сообщение в локальном массиве
      const messageIndex = this.messages.findIndex(message => message.id === messageId);

      // Если сообщение найдено, устанавливаем флаг isDeleted
      if (messageIndex !== -1) {
        this.messages[messageIndex] = {
          ...this.messages[messageIndex],
          isDeleted: true,
        };
        this.chatTimelineData.lastWhatsappMessages = this.messages;
        this.chatTimeLineDayItems = this.chatTimelineService.generateChatTimeLineDayItems(
          this.chatTimelineData,
        );
        this.cdRef.detectChanges();
      }
    });
    this.chatTimelineFilterService.chatTimelineFilters$.pipe(takeUntil(this.destroy$)).subscribe(filters => {
      const activeFilters = [...filters];
      if (activeFilters) {
        this.isShowWhatsappChat =
          activeFilters.includes(FilterListEnum.message) ||
          activeFilters.includes(FilterListEnum.messageOffer) ||
          activeFilters.includes(FilterListEnum.all) ||
          activeFilters.length === 0;
      }

      this.cdRef.detectChanges();
    });
  }

  ngAfterViewChecked() {
    if (this.pendingScrollMessageId) {
      const element: HTMLElement = document.getElementById(this.pendingScrollMessageId);
      if (element) {
        element.scrollIntoView({ block: 'center' });
        this.blinkElement(element);
        this.pendingScrollMessageId = null;
        this.cdRef.detectChanges();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.chatTimelineData || changes.currentPhoneOrChatId) {
    //   if (
    //     // add crmCardId to chatTimelineData
    //     // (!changes.crmCardViewItem?.firstChange &&
    //     //   changes.crmCardViewItem?.previousValue.crmCardId !==
    //     //     changes.crmCardViewItem?.currentValue.crmCardId) ||
    //     !changes.currentPhoneOrChatId?.firstChange &&
    //     changes.currentPhoneOrChatId?.previousValue.phone !== changes.currentPhoneOrChatId?.currentValue.phone
    //   ) {
    //     this.initChatTimeline();
    //   }
    // }

    if (changes.chatTimelineData || changes.currentPhoneOrChatId) {
      if (changes.currentPhoneOrChatId?.firstChange) {
        return;
      }

      const prevValue = changes.currentPhoneOrChatId?.previousValue;
      const currentValue = changes.currentPhoneOrChatId?.currentValue;
      if (isPhoneOrChatIdChanged(prevValue, currentValue)) {
        this.initChatTimeline();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.isChatInitialized$.complete();
    this.scrollToMessageReplaySubject$.complete();
    this.scrollToMessageWithTourReplaySubject$.complete();
  }

  private blinkElement(element: HTMLElement) {
    this.renderer.addClass(element, 'blink-animation');
    // Удаляем класс после завершения анимации
    setTimeout(() => {
      this.renderer.removeClass(element, 'blink-animation');
    }, 700);
  }

  onMaximized(event: string) {
    this.images = this.chatTimelineService.generateImages(this.messages);
    // + надо добавить вновь пришедшие сообщения
    this.images = this.images.concat(
      this.chatTimelineService.generateImages(
        this.newWhatsappMessages.map(item => item.data as WhatsappMessage),
      ),
    );
    this.isMaximized = true;
    this.images.forEach((value, index) => {
      if (value.id === event) {
        this.currentImageIdx = index;
      }
    });

    this.cdRef.detectChanges();
  }

  closeImageViewer() {
    this.isMaximized = false;
    this.cdRef.detectChanges();
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.chatTimelineData || changes.currentPhone) {
  //     // TODO: OPTIMIZE
  //     // if (
  //     //   // add crmCardId to chatTimelineData
  //     //   // (!changes.crmCardViewItem?.firstChange &&
  //     //   //   changes.crmCardViewItem?.previousValue.crmCardId !==
  //     //   //     changes.crmCardViewItem?.currentValue.crmCardId) ||
  //     //   !changes.currentPhone?.firstChange &&
  //     //   changes.currentPhone?.previousValue.phone !== changes.currentPhone?.currentValue.phone
  //     // ) {
  //     //   this.initChatTimeline();
  //     // }
  //     this.initChatTimeline();
  //   }
  // }

  scrollToMessage(messageId: string) {
    this.pendingScrollMessageId = messageId;
    this.cdRef.detectChanges();
  }

  whatsappMessagesChanged(whatsappMessages: ChatTimeLineItem[]) {
    this.newWhatsappMessages = whatsappMessages;
    this.isFirstMessagesInitialized = true;
    this.cdRef.detectChanges();
  }
}
