<div
  *ngIf="crmCardViewItem && form"
  [formGroup]="form"
  class="deal-view-content-wrap"
  [ngClass]="{ 'wide-padding': isZenmodeOn$ | async }"
>
  <aside class="deal-view-aside rounded-border aside-content">
    @if ((isTourSelectionOpen$ | async) === false) {
      <div class="deal-view-content">
        <app-worker-info
          *ngIf="!isCurrentWorkerEqualsToResponsibleWorker(crmCardViewItem?.deal?.worker?.employeeId)"
          [worker]="crmCardViewItem?.deal?.worker"
        >
        </app-worker-info>
        <div class="avatar-wrap aside-padding">
          <app-client-avatar
            [clientAvatar]="crmCardViewItem?.card?.avatar"
            [mode]="avatarMode"
            [isReturnTourist]="crmCardViewItem?.deal?.isReturnTourist"
            [isNew]="getIsNewDeal()"
          >
          </app-client-avatar>

          <div class="aside-header">
            <div class="client-info">
              <div *ngIf="crmCardViewItem?.card" [ngClass]="{ focused: isNameEditing }" class="client-name">
                <input
                  #cardNameInput
                  (focusin)="inputFocusOn($event)"
                  [readonly]="inputReadonly"
                  formControlName="name"
                  type="text"
                  id="client-name"
                />
                <button *ngIf="!isNameEditing; else editingName" (click)="manuallyEditInput()" class="edit-btn">
                  <img src="../../../../../assets/icons/edit.svg" alt="" />
                </button>

                <ng-template #editingName>
                  <div class="controls">
                    <button (click)="saveName()" class="edit-btn bordered">
                      <img src="../../../../../assets/icons/deal-view/save-name.svg" alt="save" />
                    </button>
                    <button (click)="cancelNameEditing()" class="edit-btn non-bordered">
                      <img src="../../../../../assets/icons/deal-view/cancel-name.svg" alt="close" />
                    </button>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>

          <div class="controls-toggle">
            <button (click)="toggleTab()" class="rounded-border control-btn">
              <ng-container [ngSwitch]="activeTab">
                <img
                  *ngSwitchCase="TAB_TYPES.BASE"
                  src="../../../../../assets/icons/deal-view/settings.svg"
                  alt="settings"
                />
                <img
                  *ngSwitchCase="TAB_TYPES.SETTINGS"
                  src="../../../../../assets/icons/deal-view/close.svg"
                  alt="close"
                />
              </ng-container>
            </button>
          </div>
        </div>

        <div class="aside-content">
          <div class="inner-content" [ngSwitch]="activeTab">
            <ng-container *ngSwitchCase="TAB_TYPES.BASE">
              <app-contacts
                [clientName]="
                crmCardViewItem.card?.name ? crmCardViewItem.card.name : crmCardViewItem.card.phone
              "
                [parentForm]="form"
                [phoneItems]="crmCardViewItem?.card?.phones"
                (phonesChanged)="change($event)"
                (selectedPhoneChanged)="changeSelectedPhone($event)"
                [selectedPhone]="selectedPhone"
                [cardId]="crmCardViewItem?.card?.id"
                [dealId]="crmCardViewItem?.deal?.id"
                [status]="crmCardViewItem?.deal?.status"
                [currentStage]="crmCardViewItem?.deal?.stage"
              >
              </app-contacts>

              <app-base-info
                [tourPackage]="crmCardViewItem.tourPackages[0]"
                [card]="crmCardViewItem.card"
                [dealInfo]="crmCardViewItem.deal"
                [searchOption]="searchOption"
                [savedVacationDateTime]="crmCardViewItem.cardAdditionalInfo?.['nextRestDate']"
                [tags]="crmCardViewItem.tags"
              ></app-base-info>
            </ng-container>

            <ng-container *ngSwitchCase="TAB_TYPES.SETTINGS">
              <app-statistics
                [deal]="crmCardViewItem"
                [hidden]="activeTab !== TAB_TYPES.SETTINGS"
                [clientSourceId]="crmCardViewItem?.deal?.clientSourceId"
              >
              </app-statistics>
            </ng-container>
          </div>
        </div>

        <app-current-call></app-current-call>
      </div>
    }

    @if ((tourSelectionTab$ | async) === tourSelectionTabs.SEARCH_TOURS) {
      <button (click)="closeTourSelection()"
              type="button" class="tour-selection__back-button">
        <img src="assets/icons/tour-selection/chevron-left-normal.svg" />
      </button>
      <app-favorites-hotels-tab-content [crmCardItem]="crmCardViewItem?.card"
                                        [crmCardLastSearchRequest]="crmCardViewItem?.lastSearchRequest" />
    }

    @if ((tourSelectionTab$ | async) === tourSelectionTabs.HOT_OFFERS) {
      <button (click)="closeTourSelection()"
              type="button" class="tour-selection__back-button">
        <img src="assets/icons/tour-selection/chevron-left-normal.svg" />
      </button>
      <app-hot-offers-list />
    }
  </aside>

  <div class="main-content">
    <div *ngIf="isLoading || !isInitialScrollDone" class="skeleton"></div>
    <div class="task-block" #taskBlock>
      <app-excluded-from-conversion *ngIf="crmCardViewItem?.card?.isConversionExclude" />
      <app-no-active-deals
        *ngIf="crmCardViewItem?.deal === null"
        [name]="crmCardViewItem?.card?.name"
        [phone]="crmCardViewItem?.card?.phone"
      />
      <app-no-active-tasks
        *ngIf="
          crmCardViewItem?.deal?.status !== POST_SALE_STATUS &&
          crmCardViewItem?.deal &&
          !crmCardViewItem?.nextTask &&
          !(crmCardViewItem?.tasks?.length > 0) &&
          !crmCardViewItem?.card?.isConversionExclude
        "
        [dealId]="crmCardViewItem.deal?.id"
        [crmCardId]="crmCardViewItem.card?.id"
        [phoneItem]="selectedPhone"
        [dealStage]="crmCardViewItem.deal.stage"
        [dealStatus]="crmCardViewItem.deal.status"
      />
      <app-create-post-sale-task
        *ngIf="
          crmCardViewItem?.deal &&
          nextTask === null &&
          !crmCardViewItem?.card?.isConversionExclude &&
          crmCardViewItem.deal.status === POST_SALE_STATUS
        "
        [dealId]="crmCardViewItem.deal?.id"
        [crmCardId]="crmCardViewItem.card?.id"
        [phoneItem]="selectedPhone"
        [dealStage]="crmCardViewItem.deal.stage"
        [dealStatus]="crmCardViewItem.deal.status"
      />
      <app-task-alert
        *ngIf="
          crmCardViewItem?.deal &&
          nextTask &&
          ((nextTask.type !== 'missed' && nextTask.type !== 'urgent') || crmCardViewItem?.deal.status === 0)
        "
        [crmCardId]="crmCardViewItem.card?.id"
        [dealId]="crmCardViewItem?.deal?.id"
        [clientAvatarUrl]="crmCardViewItem.card.avatar"
        [clientName]="crmCardViewItem.card.name"
        [phoneItem]="crmCardViewItem.card.phones[0]"
        [dealStage]="crmCardViewItem.deal.stage"
        [dealStatus]="crmCardViewItem.deal.status"
        [task]="nextTask"
        [isFirst]="true"
        [isMine]="workerId === nextTask?.worker?.id"
        class="task-alert"
      />
      <app-task-close-urgent
        *ngIf="
          crmCardViewItem?.deal &&
          crmCardViewItem?.deal.status !== 0 &&
          nextTask &&
          (nextTask.type === 'missed' || nextTask.type === 'urgent')
        "
        [crmCardId]="crmCardViewItem.card?.id"
        [dealId]="crmCardViewItem?.deal?.id"
        [phoneItem]="crmCardViewItem.card.phones[0]"
        [dealStage]="crmCardViewItem.deal.stage"
        [dealStatus]="crmCardViewItem.deal.status"
        [task]="nextTask"
        [isFirst]="true"
        [isMine]="workerId === nextTask?.worker?.id"
        class="task-alert"
      />
    </div>
    <div
      #chatScroller
      id="chatScroller"
      class="deal-view-content"
      appContentAdjust
      [autoScrollBottom]="isAutoScrollToChatBottom"
      [mainContentSelector]="'.main-content'"
      [headerSelector]="'.task-block'"
      [footerSelector]="'.chat-footer'"
      (initialScrollDone)="onInitialScrollDone()"
      (scroll)="onScroll($event)"
      [ngClass]="{ small: isSmall, large: isLarge }"
    >
      <!-- <div class="view-content-header">
        <h4 class="view-content-header-title">{{ 'PAGES.DEALS.COMMUNICATION_HISTORY.TITLE' | translate }}</h4>
        <div class="right-controls">
          <button (click)="manualRefreshDealView()" class="btn --transparent refresh-btn">
            <img src="../../../../../assets/icons/refresh.svg" alt="refresh" />
          </button>
        </div>
      </div> -->
      <app-deal-view-content
        (refreshDealView)="manualRefreshDealView()"
        [crmCardViewItem]="crmCardViewItem"
        class="deal-view-content-overlay"
        [style.height.%]="100"
        [currentPhoneItem]="selectedPhone"
        [isNoTasks]="isSmall"
        [isPostSaleDeal]="crmCardViewItem?.deal?.status === POST_SALE_STATUS"
      >
        <app-deal-view-indicator
          *ngIf="(isZenmodeOn$ | async) && indicatorData?.messageParts?.length"
          class="indicator"
          [indicatorData]="indicatorData"
        ></app-deal-view-indicator>
      </app-deal-view-content>
      <!-- TODO: extract to ScrollToBottomButton component -->
      <div
        *ngIf="showScrollButton"
        (click)="onScrollToBottomButtonClick()"
        style="
          width: 50px;
          height: 50px;
          border: 1px solid #f4f8fe;
          border-radius: 50%;
          background-color: white;
          position: absolute;
          bottom: 80px;
          right: 16px;
          z-index: 8;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        "
      >
        <img src="assets/icons/deal-view/arrow-down.svg" alt="" style="flex: 0 0 0" />
      </div>
    </div>
    <div class="deal-view-chat-footer">
      <app-chat-timeline-footer
        [dealId]="crmCardViewItem?.deal?.id"
        [dealStatus]="crmCardViewItem?.deal?.status"
        [crmCardId]="crmCardViewItem?.card?.id"
        [lastSearchRequest]="crmCardViewItem.searchRequests[0]"
        [currentPhoneOrChatId]="selectedPhone"
      >
      </app-chat-timeline-footer>
    </div>
  </div>
</div>

<app-preloader *ngIf="isLoading || !isInitialScrollDone" />
