<div>
  <div class="container" [ngClass]="{ highlighted: isHighlighted }" *ngIf="task">
    <div class="task-and-comment">
      <div class="avatar-and-comment">
        <div class="avatar">
          <ng-container *ngIf="clientAvatarUrl; else noAvatar">
            <img [src]="clientAvatarUrl" alt="" width="24" height="24" />
          </ng-container>
          <ng-template #noAvatar>
            <svg
              class="customer-info__no-avatar"
              width="24"
              height="24"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="48" height="48" rx="24" fill="#E1E9F5" />
              <path
                d="M24.0035 11.5156C25.4281 11.5156 26.8206 11.9379 28.0051 12.729C29.1895 13.5202 30.1127 14.6447 30.6578 15.9603C31.203 17.2759 31.3456 18.7236 31.0677 20.1203C30.7898 21.5169 30.1038 22.7999 29.0965 23.8068C28.0892 24.8137 26.8058 25.4995 25.4087 25.7773C24.0115 26.0551 22.5633 25.9125 21.2472 25.3676C19.9311 24.8226 18.8062 23.8998 18.0148 22.7157C17.2234 21.5317 16.8009 20.1397 16.8009 18.7156C16.8066 16.8078 17.5673 14.9798 18.9168 13.6308C20.2663 12.2817 22.095 11.5213 24.0035 11.5156ZM24.0035 43.1956C19.0412 43.1911 14.2737 41.2641 10.7028 37.8196C11.7648 35.1607 13.5988 32.8804 15.9685 31.2724C18.3382 29.6644 21.1352 28.8024 23.9993 28.7972C26.8634 28.792 29.6635 29.644 32.039 31.2434C34.4145 32.8429 36.2566 35.1165 37.3283 37.7716C33.7655 41.2494 28.9832 43.1961 24.0035 43.1956Z"
                fill="white"
              />
            </svg>
          </ng-template>
          <span *ngIf="clientName">{{ clientName }}</span>
        </div>
        <app-task-comment
          [taskComment]="task.managerComment"
          [speechBubbleBottomLeft]="true"
          [isCopyable]="true"
        ></app-task-comment>
      </div>
      <div class="task">
        <span class="task-name">{{
          'PAGES.DEALS.CRM_TASK_TYPES_WITH_POST_SALE.' + task.type | translate
        }}</span>
        <div class="task-due-date">
          <span>{{ formattedDate }}</span>
          <span>{{ formattedTime }}</span>
        </div>
      </div>
    </div>
    <div class="shortcuts">
      <!-- TODO: REFACTOR -->
      <ng-container *ngIf="dealStatus === NEW_CLIENT_STATUS; else otherDealStatus">
        <button (click)="showSendSelectionPopup(task)" type="button" class="btn secondary emphasis --edge">
          Отправить подборку
        </button>
      </ng-container>
      <ng-template #otherDealStatus>
        <button
          *ngIf="dealStatus !== NEW_CLIENT_STATUS"
          (click)="showNextStagePopup(task)"
          type="button"
          class="btn secondary emphasis --edge"
        >
          Перенести
        </button>
      </ng-template>
      <button (click)="showRecallPopup(task)" type="button" class="btn secondary subtle">Отложить</button>
      <button (click)="showReassignPopup(task)" type="button" class="btn secondary subtle">Переназначить</button>
      <button (click)="showAppointmentPopup(task)" type="button" class="btn secondary subtle">Встреча</button>
      <ng-container *ngIf="dealStatus === NEW_CLIENT_STATUS">
        <button (click)="showNonConversionPopup()" type="button" class="btn secondary subtle">
          Неконверсионный
        </button>
      </ng-container>
      <button
        (click)="showCompleteDealPopup(task)"
        type="button"
        class="btn secondary subtle --close-deal --edge"
      >
        Закрыть сделку
      </button>
    </div>
  </div>
</div>
