import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  DestroyRef,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { ChatMessagesFacade } from '../../../../+state/chat-messages/chat-messages.facade';
import { ChatMessagesState } from '../../../../+state/chat-messages/chat-messages.state';
import { ScreenTypes } from '../../../../core/services/amplitude/amplitudeEventData';
import { PopupService } from '../../../../shared/services/popup-service.service';
import { ContentCreatorModalControlService } from '../../../content-creator/services/content-creator-modal-control.service';
import { SearchTourCalendarComponent } from '../../../search/components/calendar/search-tour-calendar.component';
import { SearchTourCalendarService } from '../../../search/services/search-tour-calendar.service';
import { WhatsappService } from '../../../whatsapp/services/whatsapp.service';
import { ManagerOffersListService } from './services/manager-offers-list.service';
import { ApiTourWithManager } from './types/api-tour-with-manager.type';

@Component({
  selector: 'app-manager-offers',
  templateUrl: './manager-offers.component.html',
  styleUrls: ['./manager-offers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ManagerOffersComponent implements OnInit {
  public readonly dateFormat = 'dd.MM.YY';
  public managerOfferTours$: Observable<ApiTourWithManager[]>;
  public dealId: number;
  public crmId: number;

  public isLoading$ = new BehaviorSubject<boolean>(true);
  private tourCalendarComponentRef: ComponentRef<SearchTourCalendarComponent>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { dealId: number; crmId: number },
    private dialogRef: MatDialogRef<ManagerOffersComponent>,
    private managerOffersListService: ManagerOffersListService,
    private popupService: PopupService,
    private contentCreatorModalControlService: ContentCreatorModalControlService,
    private searchTourCalendarService: SearchTourCalendarService,
    private whatsappService: WhatsappService,
    private chatMessagesFacade: ChatMessagesFacade,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.dealId = this.data.dealId;
    this.crmId = this.data.crmId;
    this.managerOfferTours$ = this.managerOffersListService
      .getListItemDetails(this.dealId)
      .pipe(tap(() => this.isLoading$.next(false)));
  }

  public showCalendar(tour: ApiTourWithManager): void {
    this.tourCalendarComponentRef = this.popupService.showPopup(SearchTourCalendarComponent);
    this.tourCalendarComponentRef.instance.tourId = `${tour.id}`;
    this.tourCalendarComponentRef.instance.hotelName = tour.hotel.name;
    // Потом можно принести и gds, пока берем только питание
    const calendarInitCreateRequest = this.searchTourCalendarService.getDefaultApiRequest(`${tour.id}`);
    calendarInitCreateRequest.mealIds = [tour.mealId];
    this.tourCalendarComponentRef.instance.initCreateRequest = calendarInitCreateRequest;
    // this.tourCalendarComponentRef.instance.notGDS = this.initSearchRequest().params.params.notGDS || false;
    this.tourCalendarComponentRef.instance.selectedTour.subscribe((tourId: string) => {
      // Прежде чем открыть создатель контента, мы должны быть уверены, что открыта карточка клиента
      // Иначе некому будет отправлять сообщение
      this.chatMessagesFacade.chatMessagesState$
        .pipe(first(), takeUntilDestroyed(this.destroyRef))
        .subscribe((messagesState: ChatMessagesState) => {
          if (messagesState.contactPhoneItemOrChatId) {
            this.contentCreatorModalControlService.showManagerOfferModalByTourId(tourId);
          } else {
            this.whatsappService.navigateToChat(this.crmId, ScreenTypes.MANAGER_OFFERS, `${tour.id}`);
          }
          this.popupService.closeAllModals();
        });
    });
    this.tourCalendarComponentRef.instance.closed.subscribe(() => {
      this.popupService.closeAllModals();
      this.tourCalendarComponentRef.destroy();
    });
  }

  public navigateToMessage(tour: ApiTourWithManager): void {
    this.whatsappService.navigateToMessageWithTour(this.crmId, ScreenTypes.MANAGER_OFFERS, `${tour.id}`);
    this.closeModal();
  }

  public closeModal(): void {
    this.dialogRef.close();
  }
}
