import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { FlightVariantItem } from '@api-clients/api-client';
import { PluralizePipe } from '../../../../../../../helpers/pipes/plural/pluralize.pipe';

@Component({
  selector: 'app-search-tour-detail-flight-direction',
  templateUrl: './search-tour-calendar-tour-detail-flight-direction.component.html',
  styleUrl: './search-tour-calendar-tour-detail-flight-direction.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [PluralizePipe],
  imports: [DatePipe, MatTooltip],
})
export class SearchTourDetailsFlightDirectionComponent {
  direction = input<FlightVariantItem>();

  constructor(private readonly pluralPipe: PluralizePipe) {}

  get transferLabel(): string {
    const label = this.pluralPipe.transform(
      this.direction().transfersNumber,
      'пересадка,пересадки,пересадок',
    );
    return this.direction().transfersNumber === 0 ? 'Прямой' : `${this.direction().transfersNumber} ${label}`;
  }

  get airlineName(): string {
    return this.direction().segments[0].airline;
  }

  get number(): string {
    return this.direction().segments[0].number;
  }

  get departureDate(): string {
    return this.direction().segments[0].departure;
  }

  get arrivalDate(): string {
    return this.direction().segments[this.direction().segments.length - 1].arrival;
  }

  get departureAirport(): string {
    return this.direction().segments[0].departureAirport;
  }

  get arrivalAirport(): string {
    return this.direction().segments[this.direction().segments.length - 1].arrivalAirport;
  }
}
