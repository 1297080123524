import 'reflect-metadata';
import '../polyfills';

import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

// NG Translate
import { Router } from '@angular/router';

import { FullCalendarModule } from '@fullcalendar/angular';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular-ivy';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedUtilityModule } from '../../../../libs/shared/utility/src';
import { AppConfig } from '../environments/environment';
import { logout } from './+state/root.reducer';
import { FEATURE_KEY, tourSelectionReducer } from './+state/tour-selection/tour-selection.reducer';
import { workerConfigReducer } from './+state/worker-config/wokrer-config.reducers';
import { workerConfigInitialState } from './+state/worker-config/wokrer-config.state';
import { WorkerConfigEffects } from './+state/worker-config/worker-config.effects';
import { WorkerConfigFacade } from './+state/worker-config/worker-config.facade';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppInitService } from './core/services';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { AsideChatsModule } from './modules/aside-chats/aside-chats.module';
import { CallListModule } from './modules/call-list/call-list.module';
import { CallReviewModule } from './modules/call-review/call-review.module';
import { ComplaintModule } from './modules/complaint/complaint.module';
import { DealsModule } from './modules/deals/deals.module';
import { SearchFormService } from './modules/deals/modules/deal-view/modules/favorites-hotels/components/search-form/search-form.service';
import { SearchResultComponent } from './modules/deals/modules/deal-view/modules/favorites-hotels/components/search-result/components/search-result.component';
import { SearchResultFiltersService } from './modules/deals/modules/deal-view/modules/favorites-hotels/components/search-result/services/search-result-filters.service';
import { SearchResultUiService } from './modules/deals/modules/deal-view/modules/favorites-hotels/components/search-result/services/search-result-ui.service';
import { SearchToursProgressService } from './modules/deals/modules/deal-view/modules/favorites-hotels/services/search-tours-progress.service';
import { SearchUiStateService } from './modules/deals/modules/deal-view/modules/favorites-hotels/services/search-ui-state.service';
import { HotOffersService } from './modules/deals/modules/deal-view/modules/hot-offers/hot-offers.service';
import { AudioModule } from './modules/deals/modules/deals-list/modules/deals-list-content/deals-item/audio/audio.module';
import { DealTasksService } from './modules/deals/services/deal-tasks.service';
import { HelpPageModule } from './modules/help-page/help-page.module';
import { HistoryModule } from './modules/history/history.module';
import { LayoutModule } from './modules/layout/layout.module';
import { LoginModule } from './modules/login/login.module';
import { NotificationsCenterModule } from './modules/notifications-center/notifications-center.module';
import { NotifyListComponent } from './modules/notify-list/notify-list.component';
import { RecommendationsModule } from './modules/recommendations/recommendations.module';
import { GoogleMapsLoaderService } from './modules/search/services/google-maps-loader.service';
import { SearchResultService } from './modules/search/services/search-result.service';
import { SearchTourFlightsService } from './modules/search/services/search-tour-flights.service';
import { SettingsModule } from './modules/settings/settings.module';
import { SMSModule } from './modules/sms/sms.module';
import { TaskListComponent } from './modules/task-list/task-list.component';
import { WhatsappModule } from './modules/whatsapp/whatsapp.module';
import { ZenmodeModule } from './modules/zenmode/zenmode.module';
import { NotifyModule } from './shared/notify/notify.module';
import { TourPackageCreateService } from './shared/services/tour-package-create.service';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeRu);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function configServiceFactory(appInitService: AppInitService) {
  return () => appInitService.init();
}

export const metaReducers: MetaReducer<any>[] = [logout];

export function errorHandlerFactory() {
  if (AppConfig.production) {
    return Sentry.createErrorHandler();
  }
  return new ErrorHandler();
}

@NgModule({
  declarations: [AppComponent, TaskListComponent, NotifyListComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    LayoutModule,
    LoginModule,
    CallReviewModule,
    DealsModule,
    WhatsappModule,
    ComplaintModule,
    HelpPageModule,
    HistoryModule,
    SettingsModule,
    SMSModule,
    NotifyModule,
    FullCalendarModule,
    NgxPermissionsModule.forRoot(),
    EffectsModule.forRoot([]),
    StoreModule.forRoot({ router: routerReducer }, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument(),
    StoreModule.forFeature('worker-config', workerConfigReducer, {
      initialState: workerConfigInitialState,
    }),
    StoreModule.forFeature(FEATURE_KEY, tourSelectionReducer),
    EffectsModule.forFeature([WorkerConfigEffects]),
    SharedUtilityModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AudioModule,
    ClipboardModule,
    CallListModule,
    RecommendationsModule,
    ZenmodeModule,
    NotificationsCenterModule,
    AsideChatsModule,
    SearchResultComponent,
  ],
  providers: [
    DealTasksService,
    WorkerConfigFacade,
    [DatePipe],
    { provide: LOCALE_ID, useValue: 'ru-RU' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configServiceFactory,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    SearchResultService,
    GoogleMapsLoaderService,
    SearchResultUiService,
    SearchResultFiltersService,
    SearchTourFlightsService,
    HotOffersService,
    TourPackageCreateService,
    SearchUiStateService,
    SearchFormService,
    SearchToursProgressService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
