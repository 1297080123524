<div class="popup-container" (clickOutside)="close.emit()" [delayClickOutsideInit]="true">
  <div (click)="close.emit()" class="close-btn">
    <img src="assets/icons/whatsapp/close.svg">
  </div>

  @if (googleMapsReady$ | async) {
    <ul class="list">
      <li class="list-item" (click)="showSearchTours()">
        Поиск туров
      </li>
      <li class="list-item" (click)="showHotOffers()">
        @if (minPrice$ | async; as price) {
          Горящие туры <span class="price-text">от {{ price | number:'1.0-0' }} т</span>
        } @else {
          Горящие туры
        }
      </li>
    </ul>
  } @else {
    <div class="search-not-ready">
      Поисковый модуль загружается...<br>
      Пожалуйста, подождите
    </div>
  }
</div>
