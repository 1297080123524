import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ChatContactExpand,
  ChatContactListRequest,
  ChatContactsListItem,
  ChatContactsListItemDetailsDealInfo,
  ChatContactsListResponse,
  ChatContactsSearchRequest,
  ChatContactsSearchResponse,
  ContactChatReadRequest,
  CrmTaskItem,
  DefaultResponse,
} from '@api-clients/crm-api-client';
import { map, Observable } from 'rxjs';
import { AppConfig } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatContactsService {
  constructor(private http: HttpClient) {}

  getContactsListAll(): Observable<ChatContactsListResponse> {
    const body: ChatContactListRequest = {
      expand: [
        ChatContactExpand.LastMessage, // сообщение будет обновлено при загрузке чата
        ChatContactExpand.TourPackagesStats,
        ChatContactExpand.DealInfo,
        ChatContactExpand.HasMobileApplication,
        ChatContactExpand.Recommendation,
      ],
      contactIds: [],
      query: '',
    };
    return this.http.post<ChatContactsListResponse>(`${AppConfig.crmApiUrl}/chat/contacts/list`, body);
  }

  getContactById(contactId: string, expands?: ChatContactExpand[]): Observable<ChatContactsListItem> {
    const body: ChatContactListRequest = {
      expand: expands || [ChatContactExpand.LastMessage],
      contactIds: [contactId],
      query: '',
    };
    return this.http.post<ChatContactsListResponse>(`${AppConfig.crmApiUrl}/chat/contacts/list`, body).pipe(
      map((response: ChatContactsListResponse) => {
        return response.list[0];
      }),
    );
  }

  getContactDealInfoById(contactId: string): Observable<ChatContactsListItemDetailsDealInfo> {
    const body: ChatContactListRequest = {
      expand: [ChatContactExpand.DealInfo],
      contactIds: [contactId],
      query: '',
    };
    return this.http.post<ChatContactsListResponse>(`${AppConfig.crmApiUrl}/chat/contacts/list`, body).pipe(
      map((response: ChatContactsListResponse) => {
        return response.list[0].crmDetails?.dealInfo;
      }),
    );
  }

  getContactNextTaskById(contactId: string): Observable<CrmTaskItem> {
    const body: ChatContactListRequest = {
      expand: [ChatContactExpand.NextTask],
      contactIds: [contactId],
      query: '',
    };
    return this.http.post<ChatContactsListResponse>(`${AppConfig.crmApiUrl}/chat/contacts/list`, body).pipe(
      map((response: ChatContactsListResponse) => {
        return response.list[0].crmDetails?.nextTask;
      }),
    );
  }

  readChat(contactId: string, fromPlace: string): Observable<DefaultResponse> {
    const body: ContactChatReadRequest = {
      contactId,
      fromPlace,
    };
    return this.http.post<DefaultResponse>(`${AppConfig.crmApiUrl}/chat/contacts/read-chat`, body);
  }

  /**
   * Поисковый запрос (номер телефона | имя контакта в чате | имя в карточке)
   */
  searchContacts(searchQuery: string): Observable<ChatContactsSearchResponse> {
    const body: ChatContactsSearchRequest = {
      searchQuery,
    };
    return this.http.post<ChatContactsSearchResponse>(`${AppConfig.crmApiUrl}/chat/contacts/search`, body);
  }
}
