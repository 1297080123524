import { CrmTaskType } from '@api-clients/crm-api-client';

export enum CurrentStageEnum {
  missed = 'missed',
  coldTouch = 'coldTouch',
  new = 'new',
  other = 'other',
  meeting = 'meeting',
  sendOffer = 'sendOffer',
  feedback = 'feedback',
  finishingTouch = 'finishingTouch',
  readyForBooking = 'readyForBooking',
  waitConfirm = 'waitConfirm',
  waitPayment = 'waitPayment',
  waitDocs = 'waitDocs',
  waitFlight = 'waitFlight',
  onRest = 'onRest',
  review = 'review',
  autoTouch = 'autoTouch',
}

export enum DealStatusEnum {
  NEW = 0,
  WORK_IN_PROGRESS = 1,
  POST_SALE = 2,
  NON_CONVERSION = 8,
}

export type Stage = string | CurrentStageEnum;

export const stageColorMap: Record<CurrentStageEnum, string> = {
  [CurrentStageEnum.missed]: '#FDA033', // Пропущено
  [CurrentStageEnum.coldTouch]: '#A8D5FF', // Холодные касания
  [CurrentStageEnum.new]: '#48C262', // Новый
  [CurrentStageEnum.sendOffer]: '#007CF0', // Отправить подборку
  [CurrentStageEnum.feedback]: '#007CF0', // Обратная связь
  [CurrentStageEnum.meeting]: '#9B51E0', // Встреча
  [CurrentStageEnum.finishingTouch]: '#007CF0', // Завершающий этап
  [CurrentStageEnum.autoTouch]: '#BB9EE6', // Автокасания
  [CurrentStageEnum.readyForBooking]: '#354A9C', // Готов к бронированию
  [CurrentStageEnum.waitConfirm]: '#A9D998', // Ожидание подтверждения
  [CurrentStageEnum.waitPayment]: '#A9D998', // Ожидание оплаты
  [CurrentStageEnum.waitDocs]: '#A9D998', // Ожидание документов
  [CurrentStageEnum.waitFlight]: '#A9D998', // Ожидание рейса
  [CurrentStageEnum.onRest]: '#A9D998', // На отдыхе
  [CurrentStageEnum.review]: '#A9D998',
  [CurrentStageEnum.other]: '#FFFFFF',
};

export const statusColorMap: Record<DealStatusEnum, string> = {
  [DealStatusEnum.NEW]: '#48C262', // Новая сделка
  [DealStatusEnum.WORK_IN_PROGRESS]: '#007CF0', // В работе
  [DealStatusEnum.POST_SALE]: '#A9D998', // После продажи
  [DealStatusEnum.NON_CONVERSION]: '#121B2C', // Не конверсионный
};

export const hintBeforeTaskAssigned = {
  id: 'hintBeforeTaskAssigned',
  icon: 'assets/icons/hint/cursor.svg',
  langKey: 'PAGES.DEALS.SALES_FUNNEL.TOOLTIP.HINTS.SELECT_STAGE',
};

export const hintAfterTaskAssigned = {
  id: 'hintAfterTaskAssigned',
  icon: 'assets/icons/hint/return.svg',
  langKey: 'PAGES.DEALS.SALES_FUNNEL.TOOLTIP.HINTS.RETURN_TO_STAGE',
};

export interface Hint {
  icon: string;
  langKey: string;
}

export interface TaskCreatedEvent {
  sendBookingInstructions: boolean;
  taskType: CrmTaskType;
}
