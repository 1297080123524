@if (showLoader()) {
  <div class="container-loader">
    <div class="container-loader-label">Загружаем авиаперелеты</div>
    <app-line-progress [animationDuration]="'10s'" />
  </div>
}

@if (showNoResults()) {
  <div class="container-no-results">
    Перелеты не найдены. Перейдите на сайт ТО для получения информации
  </div>
}

@if (showErrors()) {
  <div class="container-errors">
    Ошибка при получение авиаперелетов
  </div>
}


@if (showVariants()) {
  <div class="container-variants">
    <app-search-tour-detail-flight-direction [direction]="variant().forward" />
    <app-search-tour-detail-flight-direction [direction]="variant().backward" />
  </div>
}
