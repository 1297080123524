import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  input,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  CreateWhatsappScheduledMessageRequest,
  PhoneItem,
  SearchRequest,
  SmsTemplate,
  WhatsappMessageTemplate,
  WhatsappMessageTemplateTypeEnum,
  WhatsappSendMessageRequest,
} from '@api-clients/crm-api-client';
import { Store } from '@ngrx/store';
import { ChatTimelineFacade } from 'app/+state/chat-timeline/chat-timeline.facade';
import { ChatsFacade } from 'app/+state/chats/chats.facade';
import { ChatScrollerService } from 'app/modules/deals/modules/deal-view/services/chat-scroller.service';
import { FeatureToggleService } from 'app/modules/deals/modules/deals-list/modules/deals-list-content/helpers/feature-toggle.service';
import { Observable, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { clearCurrentReplyMessage } from '../../../../../../+state/chat-timeline/chat-timeline.actions';
import { ChatTimelineState } from '../../../../../../+state/chat-timeline/chat-timeline.reducer';
import { selectCurrentReplyMessage } from '../../../../../../+state/chat-timeline/chat-timeline.selectors';
import { AmplitudeTrackService } from '../../../../../../core/services/amplitude/amplitude-track.service';
import {
  AmplitudeEventData,
  ScreenTypes,
} from '../../../../../../core/services/amplitude/amplitudeEventData';
import {
  QUICK_MESSAGE_SELECT_PREDEFINED,
  QUICK_MESSAGE_SELECT_USERDEFINED,
  SEND_MESSAGE_FORM_COPY_PASTE_FILES,
  SEND_MESSAGE_FORM_DRAG_DROP_FILES,
} from '../../../../../../core/services/amplitude/amplitudeEvents';
import {
  Notify,
  NotifyService,
  NotifyTypeEnum,
} from '../../../../../../shared/notify/services/notify.service';
import { OpenManagerSearchService } from '../../../../../../shared/services/open-manager-search.service';
import { PopupService } from '../../../../../../shared/services/popup-service.service';
import { ChatTimelineFilterService } from '../../../../../chat-timeline/components/chat-time-line-filters/services/chat-timeline-filter.service';
import { ContentCreatorContentType } from '../../../../../content-creator/interfaces/content-creator.interface';
import { ContentCreatorModalControlService } from '../../../../../content-creator/services/content-creator-modal-control.service';
import { WhatsappTemplatesApiService } from '../../../../../settings/components/whatsapp-templates-settings/services/whatsapp-templates-api.service';
import { WhatsappApiService } from '../../../../services/whatsapp-api.service';
import { WhatsappService } from '../../../../services/whatsapp.service';
import { textOrFileRequired } from '../../validators/text-or-file.validator';
import { AttachedFilesPreviewComponent } from '../attached-files-preview/attached-files-preview.component';
import { SendToursPopupComponent } from './components/send-tours-popup.component';
import { i18nEmoji } from './emoji.model';
import { ChatInputFocusService } from './services/chat-input-focus.service';

export interface WhatsappTemplate {
  key: string;
  value: string;
}

export interface PhoneWithFormattedPhone {
  code?: string;
  phone?: string;
  formattedPhone?: string;
}

@Component({
  selector: 'app-whatsapp-send-message',
  templateUrl: './whatsapp-send-message.component.html',
  styleUrls: ['./whatsapp-send-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatsappSendMessageComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() phoneOrChatId: PhoneItem | string;
  @Input() phones: PhoneItem[];
  @Input() cardId: number;
  @Input() dealId: number;
  @Input() dealStatus: number;
  @Input() lastSearchRequest: SearchRequest;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('sendMessageField') sendMessageFieldRef: ElementRef;
  @ViewChild('textArea') textAreaElement: ElementRef;

  public currentPhoneItem: PhoneItem | null;

  public currentPhoneOrChatId: string;

  public isMessageSending = false;
  public isEmojiPickerVisible: boolean;
  public isAttachPopupShow = false;
  public isTemplatePopupShow = false;
  public sendMessageForm: UntypedFormGroup = null;
  public searchLink: string;
  public autoCompleteOptionSelected = false;
  public triggerTextareaResize = new EventEmitter();
  public templateTooltip =
    'Нажмите чтобы вставить шаблон.\n\nИли нажмите и удерживайте, чтобы открыть список шаблонов';
  public readonly screenType = input<ScreenTypes>(ScreenTypes.CHAT);
  public i18nEmoji = i18nEmoji;
  public whatsappTemplates$: Observable<WhatsappMessageTemplate[]>;
  public attachedFiles: File[] = [];
  public previewPopupComponentRef: ComponentRef<AttachedFilesPreviewComponent> = null;
  public whatsappTemplates: WhatsappMessageTemplate[] = [];
  public lastTag: string;
  public isTextControlDisabled = false;
  public shouldFocus$: Observable<boolean>;
  public hasScheduledMessages$: Observable<boolean> = this.chatTimelineFacade.hasScheduledMessages$;
  public isSchedulingMessage$: Observable<boolean> = this.chatTimelineFacade.isSchedulingMessage$;
  public isSchedulingMessage = false;
  public isScheduleMessageOverlayOpen$: Observable<boolean> =
    this.chatTimelineFacade.isScheduleMessageOverlayOpen$;
  public selectedDate: string;
  public selectedTime: string;

  public shouldDisplayHotOffers$ = this.featureToggleService.shouldDisplayHotOffers$;

  private destroy$: Subject<void> = new Subject();
  private NEW_STATUS = 0;
  private isOpenQuickAnswersOnNewDealsFeatureEnabled = false;
  private sendToursComponentRef: ComponentRef<SendToursPopupComponent>;

  constructor(
    private whatsappApiService: WhatsappApiService,
    private whatsappTemplatesApiService: WhatsappTemplatesApiService,
    private whatsappService: WhatsappService,
    private fb: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef,
    private notifyService: NotifyService,
    private openManagerSearchService: OpenManagerSearchService,
    private chatTimelineFilterService: ChatTimelineFilterService,
    private amplitudeTrackService: AmplitudeTrackService,
    private router: Router,
    private popupService: PopupService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private store: Store<ChatTimelineState>,
    private chatScrollerService: ChatScrollerService,
    private chatInputFocusService: ChatInputFocusService,
    private featureToggleService: FeatureToggleService,
    private contentCreatorModalControlService: ContentCreatorModalControlService,
    private chatsFacade: ChatsFacade,
    private chatTimelineFacade: ChatTimelineFacade,
  ) {
    this.whatsappTemplates$ = this.whatsappTemplatesApiService.getTemplates();
    iconRegistry.addSvgIcon(
      'template',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/whatsapp/template.svg'),
    );
    iconRegistry.addSvgIcon(
      'whatsapp_send',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/whatsapp/send-message.svg'),
    );
    iconRegistry.addSvgIcon(
      'whatsapp_record',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/whatsapp/record-audio.svg'),
    );
  }

  get shouldOpenQuickAnswersOnNewDeals() {
    return this.isOpenQuickAnswersOnNewDealsFeatureEnabled && this.dealStatus === this.NEW_STATUS;
  }

  ngOnInit() {
    this.initForm();
    console.log('this.phoneOrChatId: ', this.phoneOrChatId);
    this.onRecipientPhoneOrChatIdChange(this.phoneOrChatId);

    this.shouldFocus$ = this.chatInputFocusService.shouldFocus$;
    this.isSchedulingMessage$.pipe(takeUntil(this.destroy$)).subscribe(isScheduling => {
      this.isSchedulingMessage = isScheduling;
      this.cdRef.detectChanges();
    });
    this.isScheduleMessageOverlayOpen$.pipe(takeUntil(this.destroy$)).subscribe(isOpen => {
      if (!isOpen) {
        this.clearScheduleMessageOverlayInputs();
        this.clearChatInput();
      }
    });
    this.whatsappTemplates$.pipe(first()).subscribe(templates => {
      this.whatsappTemplates = templates;
      this.cdRef.detectChanges();
    });
    this.searchLink = this.openManagerSearchService.getUrlToOpenManagerSearch(this.cardId, false);
    this.cdRef.detectChanges();

    this.store
      .select(selectCurrentReplyMessage)
      .pipe(
        takeUntil(this.destroy$),
        map(currentReplyMessage => currentReplyMessage?.id || ''),
      )
      .subscribe(quotedMessageId => {
        this.sendMessageForm.patchValue({
          quotedMessageId,
        });
      });

    this.featureToggleService.shouldOpenQuickAnswersOnNewDeals$.pipe(first()).subscribe(featureEnabled => {
      if (featureEnabled) {
        this.isOpenQuickAnswersOnNewDealsFeatureEnabled = featureEnabled;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.phoneOrChatId) {
      const prevValue = changes.phoneOrChatId?.previousValue;
      const currentValue = changes.phoneOrChatId?.currentValue;

      if (currentValue !== prevValue && prevValue != null) {
        this.initForm();
        this.onRecipientPhoneOrChatIdChange(this.phoneOrChatId);
      }

      // todo: уточнить, подойдет ли данный формат для этого компонента
      // if (isPhoneOrChatIdChanged(prevValue, currentValue)) {
      //   this.initForm();
      //   this.onRecipientPhoneOrChatIdChange(this.phoneOrChatId);
      // }
    }
  }

  ngAfterViewInit(): void {
    if (this.shouldOpenQuickAnswersOnNewDeals) {
      this.autocompleteTrigger.openPanel();
    }
  }

  initForm() {
    this.sendMessageForm = this.fb.group(
      {
        phoneOrChatId: ['', Validators.required],
        phone: [''], // оставил, только для обновления номера whatsapp в карточке
        text: [''],
        file: [],
        quotedMessageId: [''],
      },
      { validators: textOrFileRequired },
    );
    this.isTextControlDisabled = this.sendMessageForm.get('text').disabled;
  }

  updateRecipientPhoneOrChatIdInForm(phoneOrChatId: string, phoneItem: PhoneWithFormattedPhone | null) {
    console.log('updateRecipientPhoneOrChatIdInForm', { phoneOrChatId, phoneItem });
    if (!this.sendMessageForm) {
      return;
    }

    this.sendMessageForm.get('phoneOrChatId').setValue(phoneOrChatId);

    if (phoneItem) {
      // обновляем телефон, только чтобы номер whatsapp в карточке
      this.updatePhoneInForm(phoneItem);
    } else {
      // в группах телефон убираем
      this.sendMessageForm.get('phone').setValue('');
    }
  }

  // todo: переделать обновления номера в карточке и можно будет выпилить `phone` из формы
  updatePhoneInForm(phoneItem: PhoneWithFormattedPhone) {
    if (!this.sendMessageForm) {
      return;
    }

    const phoneNumberAsString = this.getPhoneNumberAsString(phoneItem);
    const currentPhone = this.sendMessageForm.get('phone').value;
    if (phoneNumberAsString === currentPhone) {
      return;
    }

    if (currentPhone !== '' && phoneNumberAsString && phoneNumberAsString !== '' && this.cardId) {
      this.whatsappApiService
        .changeWhatsappPhone(phoneNumberAsString, this.cardId)
        // todo: отписка
        .subscribe();
    }

    this.sendMessageForm.get('phone').setValue(phoneNumberAsString);
  }

  getPhoneNumberAsString(phoneItem: PhoneWithFormattedPhone): string {
    let phoneNumberAsString: string;
    if (phoneItem) {
      phoneNumberAsString = `${phoneItem.code}${phoneItem.phone}`.replace(/[^0-9]/g, '');
    } else {
      phoneNumberAsString = '';
    }
    return phoneNumberAsString;
  }

  getTextControlPlaceholderString(): string {
    return this.isTextControlDisabled ? '' : 'Введите текст сообщения';
  }

  public addEmoji(event) {
    const text = this.sendMessageForm.get('text').value as string;
    const emoji = event.emoji.native as string;

    const textareaElement: HTMLTextAreaElement = this.textAreaElement.nativeElement;
    const selectionStartPosition = textareaElement.selectionStart ?? 0;
    const selectionEndPosition = textareaElement.selectionEnd ?? 0;

    const updatedText = text.slice(0, selectionStartPosition) + emoji + text.slice(selectionEndPosition);

    this.sendMessageForm.get('text').setValue(updatedText);

    setTimeout(() => {
      const newCursorPosition = selectionStartPosition + emoji.length;
      textareaElement.setSelectionRange(newCursorPosition, newCursorPosition);
      textareaElement.focus();
    }, 0);
  }

  showNotify(): void {
    const notify: Notify = {
      title: 'Не заполенное поле!',
      text: 'Пожалуйста введите текст, чтобы отправить сообщение.',
      type: NotifyTypeEnum.error,
    };
    this.notifyService.create(notify);
  }

  resetForm() {
    this.sendMessageForm.get('text').setValue('');
    this.sendMessageForm.get('file').setValue(null);
    this.textAreaElement.nativeElement.style.height = '35px';
    this.clearCurrentReplyMessage();
    this.hideEmojiPicker();
    this.chatsFacade.clearSearch();
  }
  sendMessageWithFile() {
    for (const file of this.attachedFiles) {
      this.sendMessageForm.patchValue({
        file,
      });
      this.sendMessage();
    }
  }

  onSubmit() {
    if (this.attachedFiles?.length > 0) {
      this.sendMessageWithFile();
    } else {
      this.sendMessage();
    }
  }
  onRecord(wavBlob: Blob) {
    // Не меняйте название файла. Т.к. на беке файлы с таким названием конвертируются в правильный формат
    const audioFile = new File([wavBlob], 'audio_record.wav', { type: 'audio/wav' });

    this.sendMessageForm.patchValue({
      file: audioFile,
    });

    this.sendMessage();
    this.enableTextInput();
  }

  public onRemoveRecording() {
    this.enableTextInput();
  }

  public disableTextInput() {
    this.toggleTextInput(false);
  }

  public enableTextInput() {
    this.toggleTextInput(true);
  }

  private toggleTextInput(enable: boolean) {
    const textControl = this.sendMessageForm.get('text');
    if (!textControl) return;

    this.isTextControlDisabled = !enable;

    return enable ? textControl.enable() : textControl.disable();
  }

  sendMessage() {
    if (
      this.sendMessageForm.invalid ||
      (this.sendMessageForm.get('text').value === '' && this.sendMessageForm.get('file').value == null)
    ) {
      this.showNotify();
      return;
    }

    const messageSendRequest: WhatsappSendMessageRequest = {
      ...this.sendMessageForm.getRawValue(),
      text:
        typeof this.sendMessageForm.getRawValue().text === 'string'
          ? this.sendMessageForm.getRawValue().text
          : this.sendMessageForm.getRawValue().text.value,
      fromPlace: this.screenType(),
    };

    this.resetForm();
    if (!messageSendRequest.file && !messageSendRequest.text) {
      return;
    }
    this.isMessageSending = true;

    if (messageSendRequest) {
      this.chatTimelineFilterService.setWhatsappFilterOn();

      const chatId = this.currentPhoneItem ? null : this.currentPhoneOrChatId;
      const pendingMessage = this.whatsappService.showPendingMessage(
        messageSendRequest,
        this.currentPhoneItem,
        chatId,
      );

      this.chatScrollerService.notifyScrollBottomNeeded();

      const formData = new FormData();

      console.log(
        'messageSendRequest.phoneOrChatId.toString(): ',
        messageSendRequest.phoneOrChatId.toString(),
      );
      formData.append('phoneOrChatId', messageSendRequest.phoneOrChatId.toString());
      formData.append('text', messageSendRequest.text);
      formData.append('file', messageSendRequest.file);
      formData.append('fromPlace', messageSendRequest.fromPlace);
      formData.append('quotedMessageId', messageSendRequest.quotedMessageId || '');
      // temporaryId есть только в новом роуте, при изменении все само оживет
      formData.append('temporaryId', pendingMessage.message.id || null);

      const data = this.cardId && this.dealId ? { cardId: this.cardId, dealId: this.dealId } : {};
      this.whatsappApiService
        .messageSend(formData, data)
        .pipe(first())
        .subscribe(
          () => {
            this.isMessageSending = false;
            this.hidePreviewPopup();
            this.cdRef.detectChanges();
          },
          error => {
            // eslint-disable-next-line no-console
            console.error('error: ', error);
          },
        );
    }
  }

  clearCurrentReplyMessage() {
    this.store.dispatch(clearCurrentReplyMessage());
  }

  hideEmojiPicker() {
    this.isEmojiPickerVisible = false;
    this.cdRef.detectChanges();
  }

  hidePopups() {
    this.hidePreviewPopup();
    this.autocompleteTrigger.closePanel();
    this.isTemplatePopupShow = false;
    this.isEmojiPickerVisible = false;
    this.isAttachPopupShow = false;
    this.cdRef.detectChanges();
  }

  toggleEmojiPicker() {
    this.closeAttachPopup();
    this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
    this.cdRef.detectChanges();
  }

  onEnter(event) {
    if (!this.autoCompleteOptionSelected) {
      this.onSubmit();
    }
    event.preventDefault();
  }

  onFileSelected(event) {
    this.closeAttachPopup();
    this.closeScheduleMessageOverlay();
    if (event.target.files.length >= 1) {
      this.attachedFiles = [...this.attachedFiles, ...event.target.files];
      this.cdRef.detectChanges();
      this.showPreviewPopup();
    }
  }

  onPaste(event: ClipboardEvent) {
    if (event.clipboardData) {
      const { items } = event.clipboardData;
      const files = [];
      for (let i = 0; i < items.length; i++) {
        if (items[i].kind === 'file' && items[i].type.indexOf('image') !== -1) {
          const blob = items[i].getAsFile();
          if (blob) {
            files.push(blob);
          }
        }
      }
      if (files.length > 0) {
        this.amplitudeTrackService.trackEvent(SEND_MESSAGE_FORM_COPY_PASTE_FILES, {
          filesCount: files.length,
        });
        this.attachedFiles = [...this.attachedFiles, ...files];
        this.cdRef.detectChanges();
        this.showPreviewPopup();
      }
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer) {
      const { files } = event.dataTransfer;

      if (files.length) {
        this.amplitudeTrackService.trackEvent(SEND_MESSAGE_FORM_DRAG_DROP_FILES, {
          filesCount: files.length,
        });
        for (let i = 0; i < files.length; i++) {
          this.attachedFiles.push(files[i]);
        }
        this.cdRef.detectChanges();
      }
      this.showPreviewPopup();
    }
  }

  onCtrlEnter() {
    this.sendMessageForm?.get('text').setValue(`${this.sendMessageForm?.get('text').value}\n`);
  }

  onRecipientPhoneOrChatIdChange(phoneOrChatId: PhoneWithFormattedPhone | string) {
    if (typeof phoneOrChatId === 'string') {
      // 77000000000 | 77000000000@c.us | 1234567899999@g.us
      this.currentPhoneOrChatId = phoneOrChatId;
      this.currentPhoneItem = null;

      this.updateRecipientPhoneOrChatIdInForm(this.currentPhoneOrChatId, null);

      return;
    }

    if (!phoneOrChatId || !phoneOrChatId.phone || !phoneOrChatId.code) {
      // todo: почему получатель не сбрасывается? о_0
      //  по идее, если вдруг по какой то причине нет номера - стоит сбросить получателя
      // this.currentPhoneOrChatId = '';
      // this.currentPhoneItem = null;
      // this.updateRecipientPhoneOrChatIdInForm('', null);
      return;
    }

    this.currentPhoneOrChatId = this.getPhoneNumberAsString(phoneOrChatId);

    this.currentPhoneItem = {
      phone: phoneOrChatId.phone,
      code: phoneOrChatId.code,
    };

    this.updateRecipientPhoneOrChatIdInForm(
      this.currentPhoneOrChatId,
      phoneOrChatId, // только для обновления номера whatsapp в карточке
    );
  }

  closeAttachPopup() {
    this.isAttachPopupShow = false;
  }

  toggleAttachPopup() {
    if (this.previewPopupComponentRef) {
      return;
    }
    this.hideEmojiPicker();
    this.isAttachPopupShow = !this.isAttachPopupShow;
  }

  toggleSearchTours(): void {
    if (this.sendToursComponentRef) {
      return;
    }

    this.hideEmojiPicker();
    if (this.isAttachPopupShow) {
      this.toggleAttachPopup();
    }

    this.sendToursComponentRef = this.popupService.showPopup(
      SendToursPopupComponent,
      {},
      {
        hasBackdrop: false,
        position: {
          bottom: 65,
          left: 590,
        },
      },
    );

    this.sendToursComponentRef.instance.close.subscribe(() => {
      this.popupService.closeAllModals();
      this.sendToursComponentRef.destroy();
      this.sendToursComponentRef = undefined;
    });
  }

  showGptModal() {
    this.contentCreatorModalControlService.showModal(null, [ContentCreatorContentType.Gpt]);
  }

  openTemplatePopup() {
    setTimeout(() => {
      this.autocompleteTrigger.openPanel();
    });
  }

  addTemplateTextToForm(template: SmsTemplate) {
    this.sendMessageForm?.get('text').setValue(template.text);
    this.triggerTextareaResize.emit();
    this.hidePopups();
  }

  setText(text: string) {
    this.sendMessageForm?.get('text').setValue(text);
    this.triggerTextareaResize.emit();
    this.hidePopups();
    this.enableTextInput();
  }

  whatsappTemplateSelected(event: MatAutocompleteSelectedEvent) {
    // Временно ставится флаг что сработал автокомплит
    // чтобы избежать авто отправки шаблона на кнопку Enter
    this.autoCompleteOptionSelected = true;
    this.triggerTextareaResize.emit();
    const selectedOption: WhatsappMessageTemplate = event.option.value as WhatsappMessageTemplate;

    this.sendMessageForm.get('text').patchValue(selectedOption.value);

    const eventData: AmplitudeEventData = {
      'Deal ID': this.dealId,
      'Message text': selectedOption.value,
      'Screen type': this.screenType(),
    };

    if (selectedOption.type === WhatsappMessageTemplateTypeEnum.UserDefined) {
      this.amplitudeTrackService.trackEvent(QUICK_MESSAGE_SELECT_USERDEFINED, eventData);
    }

    if (selectedOption.type === WhatsappMessageTemplateTypeEnum.Predefined) {
      eventData['Message name'] = selectedOption.alias;
      this.amplitudeTrackService.trackEvent(QUICK_MESSAGE_SELECT_PREDEFINED, eventData);
    }

    setTimeout(() => {
      this.autoCompleteOptionSelected = false;
    }, 0);
  }

  showPreviewPopup() {
    const anchorElement = new ElementRef(document.querySelector('#chatScroller'));
    this.previewPopupComponentRef = this.popupService.showPopup(
      AttachedFilesPreviewComponent,
      {
        files: this.attachedFiles,
      },
      {
        anchorElement,
        hasBackdrop: false,
        overlayWidth: anchorElement.nativeElement.getBoundingClientRect().width,
        overlayHeight: anchorElement.nativeElement.getBoundingClientRect().height,
      },
    );

    this.previewPopupComponentRef.instance.addMoreFiles.subscribe(() => {
      this.attachedFiles = this.previewPopupComponentRef.instance.files;
      this.cdRef.detectChanges();
    });
    this.previewPopupComponentRef.instance.close.subscribe(() => {
      this.hidePreviewPopup();
    });
  }

  hidePreviewPopup() {
    this.attachedFiles = [];
    this.previewPopupComponentRef?.destroy();
    this.previewPopupComponentRef = null;
    this.popupService.closeAllModals();
    this.cdRef.detectChanges();
  }

  get isSendButtonDisabled() {
    return !this.phoneOrChatId || this.isMessageSending || !this.sendMessageForm?.valid;
  }

  get isSendPhotosButtonDisabled() {
    return !this.phoneOrChatId || this.isMessageSending || this.attachedFiles.length === 0;
  }

  getWidth(): string {
    return `${this.textAreaElement?.nativeElement?.offsetWidth}px`;
  }

  onInput(event: InputEvent) {
    const text = (event.target as HTMLTextAreaElement)?.value;

    const regex = /(^|\s)\/([\wа-яА-ЯёЁ]*)$/;

    const matches = text.match(regex);
    if (matches) {
      const [, , lastTag] = matches;
      this.lastTag = lastTag;
      this.openTemplatePopup();
    } else {
      this.lastTag = '';
      this.autocompleteTrigger.closePanel();
    }
  }

  public openScheduledMessagesList() {
    this.chatTimelineFacade.openScheduledMessagesList();
  }

  // Устанавливаем текущую дату и время в инпуты
  setToday() {
    const today = new Date();
    this.selectedDate = today.toISOString().split('T')[0]; // Устанавливаем текущую дату
    this.selectedTime = this.getFormattedTime(today); // Устанавливаем текущее время
  }

  // Устанавливаем дату завтрашнего дня и текущее время
  setTomorrow() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.selectedDate = tomorrow.toISOString().split('T')[0]; // Устанавливаем завтрашнюю дату
    this.selectedTime = this.getFormattedTime(tomorrow); // Устанавливаем текущее время
  }

  // Форматируем время в виде 'HH:MM'
  private getFormattedTime(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  public scheduleMessage() {
    const request: CreateWhatsappScheduledMessageRequest = {
      phone: this.getPhoneNumberAsString(this.currentPhoneItem),
      text: this.sendMessageForm.get('text').value,
      scheduledAt: `${this.selectedDate} ${this.selectedTime}:00`,
    };
    this.chatTimelineFacade.createScheduledMessage(request);
    console.log('Запланировано сообщение на:', this.selectedDate, this.selectedTime);
  }

  public openScheduleMessageOverlay() {
    this.chatTimelineFacade.openScheduleMessageOverlay();
  }

  public closeScheduleMessageOverlay() {
    this.chatTimelineFacade.closeScheduleMessageOverlay();
    this.clearScheduleMessageOverlayInputs();
    this.triggerTextareaResize.emit();
  }

  private clearScheduleMessageOverlayInputs() {
    this.selectedDate = '';
    this.selectedTime = '';
  }

  private clearChatInput() {
    this.sendMessageForm.get('text').setValue('');
  }

  onResize() {
    if (this.sendMessageForm?.get('text').value !== '/') {
      this.hidePopups();
    }
  }

  get text(): string | null {
    return this.sendMessageForm?.get('text').value || null;
  }

  navigateToQuickAnswers(crmId: number) {
    this.router.navigate(['/quick-answers'], { queryParams: { fromCardView: crmId } }).then(() => {});
  }

  ngOnDestroy(): void {
    this.clearCurrentReplyMessage();
    this.sendToursComponentRef?.destroy();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
