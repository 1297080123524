import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import {
  WhatsappMessage,
  WhatsappMessageMetaHotel,
  WhatsappMessageMetaTour,
  WhatsappMessageStatusList,
  WhatsappScheduledMessage,
} from '@api-clients/crm-api-client';
import { TranslateModule } from '@ngx-translate/core';
import { ElectronService, WorkerStateService } from '../../../../../../core/services';
import { AmplitudeTrackService } from '../../../../../../core/services/amplitude/amplitude-track.service';
import { ScreenTypes } from '../../../../../../core/services/amplitude/amplitudeEventData';
import {
  MESSAGE_MENU_REPEAT_SEARCH_OPEN,
  TOUR_PACKAGE_CREATE_BUTTON_CLICK,
  WHATSAPP_MESSAGE_DELETE_CLICK,
} from '../../../../../../core/services/amplitude/amplitudeEvents';
import { ContentCreatorContentType } from '../../../../../content-creator/interfaces/content-creator.interface';
import { ContentCreatorModalControlService } from '../../../../../content-creator/services/content-creator-modal-control.service';
import { DeleteMessageConfirmModalService } from '../../../../services/delete-message-confirm-modal.service';
import { DealViewService } from '../../../../../deals/modules/deal-view/services/deal-view.service';
import { ChatTimeLineItem, ChatTimelineItemTypeEnum } from '../../../../interfaces/chat-timeline.interface';
import { ChatTimelineFacade } from 'app/+state/chat-timeline/chat-timeline.facade';
import { TourPackageCreateService } from 'app/shared/services/tour-package-create.service';

@Component({
  selector: 'app-message-menu',
  standalone: true,
  imports: [NgIf, NgClass, TranslateModule, MatMenuModule],
  templateUrl: './message-menu.component.html',
  styleUrls: ['./message-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageMenuComponent implements OnInit {
  @Input() chatTimeLineItem: ChatTimeLineItem;
  @Output() reply = new EventEmitter<void>();

  public isMessage: boolean;
  public isScheduledMessage: boolean;
  public isPendingMessageStatus = false;
  public isDeleted = false;
  public hotel: WhatsappMessageMetaHotel | null = null;
  public tour: WhatsappMessageMetaTour | null = null;

  private replyMenuOpened = false;
  private nonDeletableStatuses = new Set([
    WhatsappMessageStatusList.Unknown,
    WhatsappMessageStatusList.InFlight,
  ]);

  // Чтобы не тянуть лишние подписки из FeatureToggleService пока захардкодим так
  // Ватрасова Галина galinapoedem12 (1087)
  // Обидник Сергей serj4 (5)
  public isContentCreatorMenuEnabled = [1087, 5].includes(this.workerStateService.employeeId);

  constructor(
    private contentCreatorModalControlService: ContentCreatorModalControlService,
    private workerStateService: WorkerStateService,
    private dealViewService: DealViewService,
    private electron: ElectronService,
    private amplitudeTrackService: AmplitudeTrackService,
    private chatTimelineFacade: ChatTimelineFacade,
    private deleteMessageConfirmModalService: DeleteMessageConfirmModalService,
    private tourPackageCreateService: TourPackageCreateService,
  ) {}

  ngOnInit(): void {
    this.hotel = this.chatTimeLineItem.data?.meta?.hotel;
    this.tour = this.chatTimeLineItem.data?.meta?.tour;
    this.isMessage = this.chatTimeLineItem.type === ChatTimelineItemTypeEnum.message;
    this.isScheduledMessage = this.chatTimeLineItem.type === ChatTimelineItemTypeEnum.scheduledMessage;
    this.isDeleted = this.chatTimeLineItem.data.isDeleted;
    const messageStatus = this.chatTimeLineItem.data?.isFromMe ? this.chatTimeLineItem.data.status : null;
    this.isPendingMessageStatus = messageStatus === WhatsappMessageStatusList.Unknown;
  }

  public getReplyButtonIconSrc() {
    return this.replyMenuOpened
      ? 'assets/icons/arrow-down-outline-opened.svg'
      : 'assets/icons/arrow-down-outline.svg';
  }

  public toggleReplyMenu() {
    this.replyMenuOpened = !this.replyMenuOpened;
  }

  public showReply() {
    this.reply.emit();
  }

  public isMenuItemVisible(menuItem: string): boolean {
    switch (menuItem) {
      case 'reply-to-message':
        return this.isMessage;
      case 'deleteMessage':
        return this.isDeletable;
      case 'calendar':
        return !!this.hotel;
      case 'photos':
        return !!(this.hotel && this.hotel.photosCount);
      case 'videos':
        return !!(this.hotel && this.hotel.videosCount);
      case 'audioPresentation':
        return !!(this.hotel && this.audioPresentationsCount);
      case 'reviews':
        return !!(this.hotel && this.hotel.reviewsWithTextCount);
      case 'managerComments':
        return !!(this.hotel && this.hotel.managerCommentsCount);
      case 'gpt':
        return !!(this.isMessage && this.chatTimeLineItem.data.text);
      case 'repeatSearch':
        return !!(this.tour && this.tour.urlCheckPrice);
      case 'createTourPackage':
        return !!(this.tour && this.tour.id);
      case 'deleteScheduledMessage':
        return this.isScheduledMessage;
    }
    return false;
  }

  get isDeletable(): boolean {
    return (
      this.isMessage &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      !this.nonDeletableStatuses.has(this.chatTimeLineItem.data.status)
    );
  }

  get photosCount(): number {
    const count = this.hotel?.photosCount || 0;
    return count > 100 ? 100 : count;
  }

  get audioPresentationsCount(): number {
    if (!this.hotel || !this.tour) {
      return 0;
    }
    return this.tour.children
      ? this.hotel.audioPresentationsWithChildrenForEmployeeCount
      : this.hotel.audioPresentationsForEmployeeCount -
          this.hotel.audioPresentationsWithChildrenForEmployeeCount;
  }

  public deleteScheduledMessage() {
    this.chatTimelineFacade.deleteScheduledMessage({
      id: (this.chatTimeLineItem.data as WhatsappScheduledMessage).id,
    });
  }

  public showContentCreatorModalPricesCalendar() {
    this.contentCreatorModalControlService.showModal(this.chatTimeLineItem, [
      ContentCreatorContentType.PricesCalendar,
    ]);
  }

  public showContentCreatorModalPhotos() {
    this.contentCreatorModalControlService.showModal(this.chatTimeLineItem, [
      ContentCreatorContentType.Photos,
    ]);
  }

  public showContentCreatorModalVideos() {
    this.contentCreatorModalControlService.showModal(this.chatTimeLineItem, [
      ContentCreatorContentType.Videos,
    ]);
  }

  public showContentCreatorModalAudioPresentation() {
    this.contentCreatorModalControlService.showModal(this.chatTimeLineItem, [
      ContentCreatorContentType.AudioPresentation,
    ]);
  }

  public showContentCreatorModalReviews() {
    this.contentCreatorModalControlService.showModal(this.chatTimeLineItem, [
      ContentCreatorContentType.Reviews,
    ]);
  }

  public showContentCreatorModalManagerComments() {
    this.contentCreatorModalControlService.showModal(this.chatTimeLineItem, [
      ContentCreatorContentType.ManagerComments,
    ]);
  }

  public showContentCreatorModalGpt() {
    this.contentCreatorModalControlService.showModal(this.chatTimeLineItem, [ContentCreatorContentType.Gpt]);
  }

  public repeatSearch() {
    let url = this.tour.urlCheckPrice;
    if (url.includes('crm_id=')) {
      // Заменим параметр crmId на актуальный
      url = url.replace(/crm_id=\d+/, `crm_id=${this.dealViewService.lastCrmId}`);
    } else {
      url += `&crm_id=${this.dealViewService.lastCrmId}`;
    }
    this.electron.shell.openExternal(url).then();
    this.amplitudeTrackService.trackEvent(MESSAGE_MENU_REPEAT_SEARCH_OPEN, {
      'Screen type': ScreenTypes.CHAT,
      'Crm card ID': this.dealViewService.lastCrmId,
      'Tour ID': this.tour?.id,
    });
  }

  public deleteMessage() {
    if (this.isWhatsappMessage(this.chatTimeLineItem.data)) {
      this.amplitudeTrackService.trackEvent(WHATSAPP_MESSAGE_DELETE_CLICK);

      this.deleteMessageConfirmModalService.showModal(this.chatTimeLineItem.data.id);
    }
  }

  public createTourPackage(tourId: string) {
    this.tourPackageCreateService.createTourPackage(tourId);
    this.amplitudeTrackService.trackEvent(TOUR_PACKAGE_CREATE_BUTTON_CLICK);
  }

  private isWhatsappMessage(data: any): data is WhatsappMessage {
    return this.chatTimeLineItem.type === ChatTimelineItemTypeEnum.message;
  }
}
