import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import { SearchResultSortDirection as SortDirection } from '../../../favorites-hotels.model';
import { FavoriteHotelsSortPipe } from '../favorite-hotels-sort.pipe';

@Component({
  selector: 'app-favorites-hotels-sorts-dropdown',
  templateUrl: './favorite-hotels-sorts-dropdown.component.html',
  styleUrl: './favorite-hotels-sorts-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FavoriteHotelsSortPipe],
})
export class FavoritesHotelsSortsDropdownComponent {
  activeSort: SortDirection;
  selected = output<SortDirection>();

  get variants(): SortDirection[] {
    return [
      SortDirection.priceAsc,
      SortDirection.hotelRating,
      SortDirection.salesCount,
      SortDirection.conversion,
    ];
  }

  selectVariant(sort: SortDirection) {
    this.selected.emit(sort);
  }
}
