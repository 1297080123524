import { SearchResultSortDirection, SearchResultGroup } from '../favorites-hotels.model';

export function sortResultGroup(
  sortDirection: SearchResultSortDirection,
  a: SearchResultGroup,
  b: SearchResultGroup,
) {
  switch (sortDirection) {
    case SearchResultSortDirection.salesCount:
      let salesCountA = a.hotel.salesCount;
      let salesCountB = b.hotel.salesCount;
      if (a.tours.length === 0) {
        salesCountA = 0;
      }
      if (b.tours.length === 0) {
        salesCountB = 0;
      }

      return salesCountB - salesCountA;

    case SearchResultSortDirection.priceAsc:
      const bigPrice = 9999999;

      const ascTourPriceA = a.tours.length ? a.tours[0].price.value : bigPrice;
      const ascTourPriceB = b.tours.length ? b.tours[0].price.value : bigPrice;
      return ascTourPriceA - ascTourPriceB;

    case SearchResultSortDirection.hotelRating:
      let bookingRatingA = a.hotel.bookingRating;
      let bookingRatingB = b.hotel.bookingRating;
      if (a.tours.length === 0) {
        bookingRatingA = 0;
      }
      if (b.tours.length === 0) {
        bookingRatingB = 0;
      }
      return bookingRatingB - bookingRatingA;

    case SearchResultSortDirection.discountDesc:
      const discountA = a.tours.length ? a.tours[0].discount || 0 : 0;
      const discountB = b.tours.length ? b.tours[0].discount || 0 : 0;
      return discountB - discountA;

    case SearchResultSortDirection.conversion:
      let conversionA = 0;
      let conversionB = 0;
      if (a.tours.length) {
        if (a.tours[0].tourists.childAges?.length) {
          conversionA = a.hotel.conversionWithChildren || 0;
        } else {
          conversionA = a.hotel.conversionOnlyAdults || 0;
        }
      }
      if (b.tours.length) {
        if (b.tours[0].tourists.childAges?.length) {
          conversionB = b.hotel.conversionWithChildren || 0;
        } else {
          conversionB = b.hotel.conversionOnlyAdults || 0;
        }
      }
      return conversionB - conversionA;

    default:
      return 0;
  }
}
