import { ChatContactsListItem, CrmCardViewItem } from '@api-clients/crm-api-client';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ChatListExclusiveFilterKey,
  ChatListInclusiveFilterKeys,
  ChatListStageFilterKeys,
  ChatListTouchTodayFilterKeys,
  ChatWithDealListItem,
  ChatsWithDealsResult,
  InclusiveFiltersCounters,
} from '../../modules/chats/chats';
import { dealsQuery } from '../deals/deals.selectors';
import { ChatsState, FEATURE_KEY } from './chats.reducer';

// Feature Selector
export const selectChatState = createFeatureSelector<ChatsState>(FEATURE_KEY);

// Selectors
export const selectAllChatContacts = createSelector(
  selectChatState,
  (state: ChatsState) => state.chatContacts,
);

export const selectAllChatContactsWithDeals = createSelector(
  selectChatState,
  dealsQuery.getAllDeals,
  (state: ChatsState, deals: CrmCardViewItem[]): ChatsWithDealsResult => {
    const dealMap = new Map(deals.map(deal => [deal.card.id, deal]));

    const chatsWithDeals = state.chatContacts.map(chat => {
      // Находим CrmCardViewItem по `card.id` или `deal.id`
      const deal =
        deals.find(
          d => d.card.id === chat.contact.crmCardId || d.deal?.id === chat?.crmDetails?.dealInfo?.id,
        ) || null;

      if (deal) {
        dealMap.delete(deal.card.id); // Удаляем записи, сопоставленные c каким-либо чатом
      }

      return {
        chat,
        deal,
      } as ChatWithDealListItem;
    });

    const unmatchedDeals = Array.from(dealMap.values()); // Оставшиеся не сопоставленные карточки

    return {
      chats: chatsWithDeals,
      deals: unmatchedDeals, // Только CrmCardViewItem без чатов
    };
  },
);

export const selectCurrentSort = createSelector(selectChatState, (state: ChatsState) => state.sort);

export const selectChatFilters = createSelector(selectChatState, (state: ChatsState) => state.filters);

export const selectCurrentStageFilter = createSelector(selectChatFilters, filters => filters.stage);

export const selectCurrentExclusiveFilter = createSelector(selectChatFilters, filters => filters.exclusive);

export const selectCurrentInclusiveFilters = createSelector(selectChatFilters, filters => filters.inclusive);

export const selectHasAnyInclusiveFiltersEnabled = createSelector(
  selectChatFilters,
  filters => !!filters.inclusive.length,
);

export const selectCurrentTempInclusiveFiltersForCounters = createSelector(
  selectChatFilters,
  filters => filters.tempInclusiveForCounters,
);

export const selectIsTouchTodayFilterEnabled = createSelector(
  selectChatState,
  (state: ChatsState) => state.filters.touchToday,
);

export const selectStageFilteredChatsWithDeals = createSelector(
  selectAllChatContactsWithDeals,
  selectCurrentStageFilter,
  (
    { chats, deals }: ChatsWithDealsResult,
    currentStageFilter: ChatListStageFilterKeys,
  ): ChatsWithDealsResult => {
    const filteredChats = chats.filter(({ chat }) => {
      if (chat.recommendation && currentStageFilter !== ChatListStageFilterKeys.IN_PROGRESS) {
        return false;
      }
      return filterByStage(chat.crmDetails?.dealInfo, currentStageFilter);
    });

    const filteredDeals = deals.filter(deal => filterByStage(deal, currentStageFilter));

    return {
      chats: filteredChats,
      deals: filteredDeals,
    };
  },
);

export const selectStageFilteredChatsWithDealsCountBy = (filter: ChatListStageFilterKeys) =>
  createSelector(selectAllChatContactsWithDeals, ({ chats, deals }: ChatsWithDealsResult) => {
    const filteredChatsCount = chats.filter(({ chat }) =>
      filterByStage(chat.crmDetails?.dealInfo, filter),
    ).length;
    const filteredDealsCount = deals.filter(deal => filterByStage(deal, filter)).length;

    return filteredChatsCount + filteredDealsCount;
  });

export const selectTouchTodayFilteredChatsWithDeals = (
  key: ChatListTouchTodayFilterKeys,
  forCount: boolean = false,
) =>
  createSelector(
    selectStageFilteredChatsWithDeals,
    selectIsTouchTodayFilterEnabled,
    (chatsWithDeals: ChatsWithDealsResult, isTouchTodayFilterEnabled: boolean): ChatsWithDealsResult => {
      if (!isTouchTodayFilterEnabled && !forCount) {
        return chatsWithDeals;
      }

      const filterByTouchToday = (deal: CrmCardViewItem) => {
        if (deal) {
          switch (key) {
            case ChatListTouchTodayFilterKeys.LEFT_TO_TOUCH:
              return !deal.experimentalData?.isContactedToday;
            case ChatListTouchTodayFilterKeys.TOUCHED_TODAY:
              return deal.experimentalData?.isContactedToday;
          }
        }
        return false;
      };

      return {
        chats: chatsWithDeals.chats.filter(chat => filterByTouchToday(chat.deal)),
        deals: chatsWithDeals.deals.filter(filterByTouchToday),
      };
    },
  );

export const selectTouchTodayFilteredChatContactsCountBy = (key: ChatListTouchTodayFilterKeys) =>
  createSelector(
    selectTouchTodayFilteredChatsWithDeals(key, true),
    (touchTodayFilteredChatsWithDeals: ChatsWithDealsResult) =>
      touchTodayFilteredChatsWithDeals.chats.length + touchTodayFilteredChatsWithDeals.deals.length,
  );

export const selectTouchTodayAllCount = createSelector(
  selectTouchTodayFilteredChatContactsCountBy(ChatListTouchTodayFilterKeys.LEFT_TO_TOUCH),
  selectTouchTodayFilteredChatContactsCountBy(ChatListTouchTodayFilterKeys.TOUCHED_TODAY),
  (leftToTouch: number, touched: number) => leftToTouch + touched,
);

export const selectInclusiveFilteredChatsWithDeals = createSelector(
  selectTouchTodayFilteredChatsWithDeals(ChatListTouchTodayFilterKeys.LEFT_TO_TOUCH),
  selectCurrentInclusiveFilters,
  (
    chatsWithDeals: ChatsWithDealsResult,
    inclusiveFilters: ChatListInclusiveFilterKeys[],
  ): ChatsWithDealsResult => {
    return {
      chats: chatsWithDeals.chats.filter(chat => {
        return inclusiveFilters.every(filterKey => {
          return chat.deal ? filterByInclusiveFilterKey(chat.deal, filterKey) : false;
        });
      }),
      deals: chatsWithDeals.deals.filter(deal => {
        return inclusiveFilters.every(filterKey => {
          return deal ? filterByInclusiveFilterKey(deal, filterKey) : false;
        });
      }),
    };
  },
);

export const selectExclusiveFilteredChatsWithDeals = createSelector(
  selectInclusiveFilteredChatsWithDeals,
  selectCurrentExclusiveFilter,
  (
    chatsWithDeals: ChatsWithDealsResult,
    currentExclusiveFilter: ChatListExclusiveFilterKey,
  ): ChatsWithDealsResult => {
    return {
      chats: chatsWithDeals.chats.filter(chat => {
        return filterChatsByExclusiveFilterKey(chat, currentExclusiveFilter);
      }),
      deals: chatsWithDeals.deals.filter(deal => {
        return filterDealsByExclusiveFilterKey(deal, currentExclusiveFilter);
      }),
    };
  },
);

export const selectExclusiveFilteredChatsWithDealsCountBy = (filter: ChatListExclusiveFilterKey) =>
  createSelector(selectInclusiveFilteredChatsWithDeals, (chatsWithDeals: ChatsWithDealsResult) => {
    const filteredChats = chatsWithDeals.chats.filter(chat => {
      return filterChatsByExclusiveFilterKey(chat, filter);
    });

    const filteredDeals = chatsWithDeals.deals.filter(deal => {
      return filterDealsByExclusiveFilterKey(deal, filter);
    });

    return filteredChats.length + filteredDeals.length;
  });

export const selectInclusiveFilterCounters = createSelector(
  selectStageFilteredChatsWithDeals,
  selectCurrentTempInclusiveFiltersForCounters,
  (chatsWithDeals: ChatsWithDealsResult, tempInclusiveFilters: ChatListInclusiveFilterKeys[]) => {
    // Функция для подсчета счетчиков на основе фильтров
    const countFilters = (items: (ChatWithDealListItem | CrmCardViewItem)[]): InclusiveFiltersCounters => {
      const counters: InclusiveFiltersCounters = {
        [ChatListInclusiveFilterKeys.IS_FORGOTTEN]: 0,
        [ChatListInclusiveFilterKeys.IS_NEARBY_DATES]: 0,
        [ChatListInclusiveFilterKeys.IS_RETURN_TOURIST]: 0,
        [ChatListInclusiveFilterKeys.IS_MOBILE_APP]: 0,
        [ChatListInclusiveFilterKeys.IS_HIGH_CHECK]: 0,
      };

      items.forEach(item => {
        const deal = 'chat' in item ? item.deal : item; // Определяем, является ли элемент ChatWithDealListItem или CrmCardViewItem сразу

        if (deal) {
          if (deal.isForgotten) counters[ChatListInclusiveFilterKeys.IS_FORGOTTEN]++;
          if (deal.lastSearchRequest?.isNearbyDates) counters[ChatListInclusiveFilterKeys.IS_NEARBY_DATES]++;
          if (deal.deal?.isReturnTourist) counters[ChatListInclusiveFilterKeys.IS_RETURN_TOURIST]++;
          if (deal.card?.hasMobileApplication) counters[ChatListInclusiveFilterKeys.IS_MOBILE_APP]++;
          if (deal.experimentalData?.isHighCheck) counters[ChatListInclusiveFilterKeys.IS_HIGH_CHECK]++;
        }
      });

      return counters;
    };
    // Шаг 1: фильтруем чаты и сделки по выбранным, но не примененным фильтрам
    const filteredChats =
      tempInclusiveFilters.length > 0
        ? chatsWithDeals.chats.filter(chat => {
            return tempInclusiveFilters.every(
              filterKey => chat.deal && filterByInclusiveFilterKey(chat.deal, filterKey),
            );
          })
        : chatsWithDeals.chats;

    const filteredDeals =
      tempInclusiveFilters.length > 0
        ? chatsWithDeals.deals.filter(deal => {
            return tempInclusiveFilters.every(
              filterKey => deal && filterByInclusiveFilterKey(deal, filterKey),
            );
          })
        : chatsWithDeals.deals;

    // Шаг 2: теперь подсчитываем количество чатов и сделок для каждого фильтра
    const chatCounters = countFilters(filteredChats);
    const dealCounters = countFilters(filteredDeals);

    // Шаг 3: объединяем счетчики
    return Object.keys(chatCounters).reduce((acc, key) => {
      acc[key] = chatCounters[key] + dealCounters[key];
      return acc;
    }, {} as InclusiveFiltersCounters);
  },
);

export const selectHasForgotten = createSelector(
  selectInclusiveFilteredChatsWithDeals,
  (chatsWithDeals: ChatsWithDealsResult) => {
    const { chats, deals } = chatsWithDeals;

    const hasForgottenChat = chats.some(chat => !chat.chat.contact.isGroup && chat.deal?.isForgotten);

    const hasForgottenDeal = deals.some(deal => deal.isForgotten);

    return hasForgottenChat || hasForgottenDeal;
  },
);

export const selectHasGroups = createSelector(
  selectStageFilteredChatsWithDeals,
  (chatsWithDeals: ChatsWithDealsResult) =>
    chatsWithDeals.chats.some((chat: ChatWithDealListItem) => {
      return chat.chat.contact.isGroup;
    }),
);

export const selectHasUnreadGroups = createSelector(
  selectStageFilteredChatsWithDeals,
  (chatsWithDeals: ChatsWithDealsResult) =>
    chatsWithDeals.chats.some((chat: ChatWithDealListItem) => {
      return chat.chat.contact.isGroup && chat.chat.contact.unreadMessageCount > 0;
    }),
);

export const selectHasUnreadChats = createSelector(
  selectInclusiveFilteredChatsWithDeals,
  (chatsWithDeals: ChatsWithDealsResult) =>
    chatsWithDeals.chats.some(
      (chat: ChatWithDealListItem) => !chat.chat.contact.isGroup && chat.chat.contact.unreadMessageCount > 0,
    ),
);

export const selectCurrentChatContact = createSelector(
  selectChatState,
  (state: ChatsState) => state.currentChatContact,
);

export const selectChatRecommendation = createSelector(
  selectChatState,
  (state: ChatsState) => state.chatRecommendation,
);

export const selectCurrentChatContactCrmCard = createSelector(
  selectChatState,
  (state: ChatsState) => state.currentChatContactCrmCard,
);

export const selectCurrentChatContactCrmCardLoading = createSelector(
  selectChatState,
  (state: ChatsState) => state.currentChatContactCrmCardLoading,
);

export const selectCurrentChatContactNextTaskLoading = createSelector(
  selectChatState,
  (state: ChatsState) => state.currentChatContactNextTaskLoading,
);

export const selectChatContactsLoading = createSelector(
  selectChatState,
  (state: ChatsState) => state.loading,
);

export const selectChatContactsError = createSelector(selectChatState, (state: ChatsState) => state.error);

export const selectSearchResults = createSelector(
  selectChatState,
  (state: ChatsState) => state.searchResults,
);

export const selectIsSearching = createSelector(selectChatState, (state: ChatsState) => state.isSearching);

function filterByStage<T extends { categories?: string[]; status?: number }>(
  item: T,
  currentStageFilter: ChatListStageFilterKeys,
): boolean {
  const categories = item?.categories || [];

  switch (currentStageFilter) {
    case ChatListStageFilterKeys.ALL:
      return true;
    case ChatListStageFilterKeys.IN_PROGRESS:
    case ChatListStageFilterKeys.FIRST_DAY:
    case ChatListStageFilterKeys.SECOND_DAY:
    case ChatListStageFilterKeys.THIRD_DAY:
    case ChatListStageFilterKeys.FOURTH_TO_SEVENTH_DAYS:
    case ChatListStageFilterKeys.MORE_THAN_SEVEN_DAYS:
      return categories.includes(currentStageFilter as string) && filterInProgressWithExclusions(categories);
    case ChatListStageFilterKeys.POST_SALE:
      return item.status === 2;
    default:
      return categories.includes(currentStageFilter);
  }
}

function filterInProgressWithExclusions(categories: string[]): boolean {
  const excludedFilters = [
    ChatListStageFilterKeys.MEETING,
    ChatListStageFilterKeys.READY_FOR_BOOKING,
    ChatListStageFilterKeys.POSTPONED,
    ChatListStageFilterKeys.COLD_TOUCH,
    ChatListStageFilterKeys.AUTO_TOUCH,
  ];
  return (
    categories.includes(ChatListStageFilterKeys.IN_PROGRESS as string) &&
    excludedFilters.every(excluded => !categories.includes(excluded as string))
  );
}

function filterByInclusiveFilterKey(deal: CrmCardViewItem, filterKey: ChatListInclusiveFilterKeys) {
  switch (filterKey) {
    case ChatListInclusiveFilterKeys.IS_FORGOTTEN:
      return deal?.isForgotten;
    case ChatListInclusiveFilterKeys.IS_NEARBY_DATES:
      return deal?.lastSearchRequest?.isNearbyDates;
    case ChatListInclusiveFilterKeys.IS_RETURN_TOURIST:
      return deal?.deal?.isReturnTourist;
    case ChatListInclusiveFilterKeys.IS_MOBILE_APP:
      return deal?.card?.hasMobileApplication;
    case ChatListInclusiveFilterKeys.IS_HIGH_CHECK:
      return deal?.experimentalData?.isHighCheck;
    default:
      return true;
  }
}

function filterChatsByExclusiveFilterKey(
  chat: ChatWithDealListItem,
  key: ChatListExclusiveFilterKey,
): boolean {
  switch (key) {
    case 'all':
      return !chat.chat.contact.isGroup;
    case 'forgotten':
      return !chat.chat.contact.isGroup && chat.deal?.isForgotten;
    case 'unread':
      return !chat.chat.contact.isGroup && chat.chat.contact.unreadMessageCount > 0;
    case 'groups':
      return chat.chat.contact.isGroup;
    default:
      return false;
  }
}

function filterDealsByExclusiveFilterKey(deal: CrmCardViewItem, key: ChatListExclusiveFilterKey): boolean {
  switch (key) {
    case 'all':
      return true;
    case 'forgotten':
      return deal?.isForgotten;
    default:
      return false;
  }
}
