import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { SearchResultSortDirection } from '../../favorites-hotels.model';
import { FavoritesHotelsSortsComponent } from '../sorts/favorite-hotels-sorts.component';

@Component({
  selector: 'app-search-tours-filters-and-sort',
  templateUrl: './search-tours-filters-and-sort.component.html',
  styleUrl: './search-tours-filters-and-sort.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FavoritesHotelsSortsComponent],
})
export class SearchToursFiltersAndSortComponent {
  searchResultVisible = input<boolean>();
  initSortDirection = input<SearchResultSortDirection>();

  selectedSort = output<SearchResultSortDirection>();
  onSearchUpdated = output<string>();
}
