import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { ElectronService } from '../../../../../../../../../libs/shared/utility/src/lib/services/electron.service';

@Component({
  selector: 'app-search-hotel-booking-rating',
  templateUrl: './search-hotel-booking-rating.component.html',
  styleUrl: './search-hotel-booking-rating.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DecimalPipe, MatTooltip],
})
export class SearchBookingRatingComponent {
  rating = input.required<number>();
  link = input<string>();

  constructor(private readonly electronService: ElectronService) {}

  openLink(): void {
    const link = this.link();
    const absoluteLink = `https://booking.com${link}`;
    this.electronService.shell
      .openExternal(absoluteLink, {
        activate: true,
      })
      .then();
  }
}
