import {
  ChatContactsListItem,
  ChatRecommendationMessage,
  CrmCardViewItem,
  CrmTaskItem,
  DealItem,
} from '@api-clients/crm-api-client';
import { createAction, props } from '@ngrx/store';
import { IStatuses } from '../../../app/models/whatsapp';
import { ChatRecommendation } from '../../modules/chats/interfaces/chat.interface';
import {
  ChatListInclusiveFilterKeys,
  ChatListExclusiveFilterKey,
  ChatListStageFilterKeys,
  ChatListSortType,
} from '../../modules/chats/chats';

export const loadChatContacts = createAction('[Chats] Load Chat Contacts');
export const loadChatContactsSuccess = createAction(
  '[Chats] Load Chat Contacts Success',
  props<{
    chatContacts: ChatContactsListItem[];
  }>(),
);
export const loadChatContactsFailure = createAction(
  '[Chats] Load Chat Contacts Failure',
  props<{
    error: any;
  }>(),
);
export const chooseChatContact = createAction(
  '[Chats] Choose Chat Contact',
  props<{
    chatContact: ChatContactsListItem;
  }>(),
);

export const updateChatContact = createAction(
  '[Chats] Update Chat Contact',
  props<{
    chatContact: ChatContactsListItem;
  }>(),
);

export const setChatContactDealToNull = createAction(
  '[Chats] Set Chat Contact Deal To Null',
  props<{
    crmCardId: number;
  }>(),
);

export const updateChatContactStage = createAction(
  '[Chats] Update Chat Contact Stage',
  props<{
    crmCardId: number;
    stage: string;
  }>(),
);

export const loadChatContact = createAction(
  '[Chats] Load Chat Contact',
  props<{
    contactId: string;
  }>(),
);

export const loadChatContactSuccess = createAction(
  '[Chats] Load Chat Contact Success',
  props<{
    chatContact: ChatContactsListItem;
  }>(),
);

export const loadChatContactFailure = createAction(
  '[Chats] Load Chat Contact Failure',
  props<{
    error: string;
  }>(),
);

export const resetCurrentChatContact = createAction('[Chats] Reset Current Chat Contact');

export const loadChatRecommendation = createAction('[Chats] Load Chat Recommendation');

export const loadChatRecommendationSuccess = createAction(
  '[Chats] Load Chat Recommendation Success',
  props<ChatRecommendation>(),
);

export const loadChatRecommendationFailure = createAction(
  '[Chats] Load Chat Recommendation Failure',
  props<{
    error: string;
  }>(),
);

export const checkChatRecommendation = createAction('[Chats] Check Chat Recommendation');

export const markRecommendationMessageAsDeleted = createAction(
  '[Chats] Mark Recommendation Message As Deleted',
  props<ChatRecommendationMessage>(),
);

export const markRecommendationMessageAsDeletedSuccess = createAction(
  '[Chats] Mark Recommendation Message As Deleted Success',
  props<ChatRecommendationMessage>(),
);

export const markRecommendationMessageAsDeletedFailure = createAction(
  '[Chats] Mark Recommendation Message As Deleted Failure',
  props<{
    error: string;
  }>(),
);

export const markRecommendationMessageAsSent = createAction(
  '[Chats] Mark Recommendation Message As Sent',
  props<{
    message: ChatRecommendationMessage;
    changedText: string | null;
  }>(),
);

export const markRecommendationMessageAsSentSuccess = createAction(
  '[Chats] Mark Recommendation Message As Sent Success',
  props<ChatRecommendationMessage>(),
);

export const markRecommendationMessageAsSentFailure = createAction(
  '[Chats] Mark Recommendation Message As Sent Failure',
  props<{
    error: string;
  }>(),
);

export const markRecommendationAsDeleted = createAction(
  '[Chats] Mark Recommendation As Deleted',
  props<{ recommendation: ChatRecommendation }>(),
);

export const markRecommendationAsDeletedSuccess = createAction(
  '[Chats] Mark Recommendation As Deleted Success',
  props<ChatRecommendation>(),
);

export const markRecommendationAsDeletedFailure = createAction(
  '[Chats] Mark Recommendation As Deleted Failure',
  props<{
    error: string;
  }>(),
);

export const loadChatContactDealInfo = createAction(
  '[Chats] Load Chat Contact Deal Info',
  props<{
    contactId: string;
  }>(),
);

export const loadCrmCardViewItemForChat = createAction(
  '[Chats] Load CrmCardViewItem For Chat',
  props<{
    crmCardId: number;
  }>(),
);

export const loadCrmCardViewItemForChatSuccess = createAction(
  '[Chats] Load CrmCardViewItem For Chat Success',
  props<{
    crmCardViewItem: CrmCardViewItem;
  }>(),
);

export const setCrmCardViewItemForChat = createAction(
  '[Chats] Set CrmCardViewItem For Chat',
  props<{
    crmCardViewItem: CrmCardViewItem;
  }>(),
);

export const loadDealViewForChatFailure = createAction(
  '[Chats] Load Deal View For Chat Failure',
  props<{
    error: string;
  }>(),
);

export const loadDealViewNextTask = createAction(
  '[Chats] Load Deal View Next Task',
  props<{
    crmCardId: number;
  }>(),
);

export const loadDealViewNextTaskSuccess = createAction(
  '[Chats] Load Deal View Next Task Success',
  props<{
    nextTask: CrmTaskItem;
  }>(),
);

export const loadDealViewNextTaskFailure = createAction(
  '[Chats] Load Deal View Next Task Failure',
  props<{
    error: string;
  }>(),
);

export const updateCurrentChatContactCrmCardDeal = createAction(
  '[Chats] Update Current Chat Contact Crm Card Deal',
  props<{
    deal: DealItem;
  }>(),
);

export const updateCurrentChatContactCrmCardStage = createAction(
  '[Chats] Update Current Chat Contact Crm Card Stage',
  props<{
    stage: string;
  }>(),
);

export const sortChatContacts = createAction('[Chats] Sort Chat Contacts');

export const sortChatContactsSuccess = createAction(
  '[Chats] Sort Chat Contacts Success',
  props<{
    chatContacts: ChatContactsListItem[];
  }>(),
);

export const refreshMessageStatuses = createAction(
  '[Chats] Refresh Message Statuses',
  props<{
    statuses: IStatuses;
  }>(),
);

export const readChat = createAction(
  '[Chats] Read Chat',
  props<{
    contactId: string;
    fromPlace: string;
  }>(),
);

export const readChatSuccess = createAction(
  '[Chats] Read Chat Success',
  props<{
    contactId: string;
  }>(),
);

export const readChatFailure = createAction(
  '[Chats] Read Chat Failure',
  props<{
    message: string;
    error: any;
  }>(),
);

export const resetCurrentChatState = createAction('[Chats] Reset Chat State');

export const searchContacts = createAction('[Chat List] Search Contacts', props<{ searchQuery: string }>());

export const searchContactsSuccess = createAction(
  '[Chat List] Search Contacts Success',
  props<{ contacts: ChatContactsListItem[] }>(),
);

export const searchContactsFailure = createAction(
  '[Chat List] Search Contacts Failure',
  props<{ error: any }>(),
);

export const clearSearchResults = createAction('[Chat List] Clear Search Results');

export const setExclusiveFilter = createAction(
  '[Chat List] Set Exclusive Filter',
  props<{ filter: ChatListExclusiveFilterKey }>(),
);

export const setInclusiveFilters = createAction(
  '[Chat List] Set Inclusive Filters',
  props<{ filters: ChatListInclusiveFilterKeys[] }>(),
);

export const resetInclusiveFilters = createAction('[Chat List] Reset Inclusive Filters');

export const setTempInclusiveFiltersForCounters = createAction(
  '[Chat List] Set Temp Inclusive Filters For Counters',
  props<{ filters: ChatListInclusiveFilterKeys[] }>(),
);

export const resetTempInclusiveFiltersForCounters = createAction(
  '[Chat List] Reset Temp Inclusive Filters For Counters',
);

export const setStageFilter = createAction(
  '[Chat List] Set Stage Filter',
  props<{ stage: ChatListStageFilterKeys }>(),
);
export const resetStageFilter = createAction('[Chat List] Reset Stage Filter');

export const setSort = createAction('[Chat List] Set Sort', props<{ sort: ChatListSortType }>());
export const resetSort = createAction('[Chat List] Reset Sort');

export const toggleTouchTodayFilter = createAction('[Chat List] Toggle Touch Today Filter');
export const resetTouchTodayFilter = createAction('[Chat List] Reset Touch Today Filter');
