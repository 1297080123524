import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AppConfig } from '../../../../../../../environments/environment';
import { apiResponsePipe } from '../../../../../../api-response.pipe';
import { WorkerStateService } from '../../../../../../core/services';
import {
  HotOfferListItem,
  HotOfferListItemCountry,
  HotOfferListItemCountryHotel,
  HotOfferListItemTour,
  HotOffersApplyFilterDepartDateRangeEvent,
  HotOffersApplyFilterEvent,
  HotOffersApplyFilterType,
  HotOffersFilterType,
  HotOffersFilterValue,
  HotOffersToursFilters,
  HotOffersToursSortVariant as SortVariants,
} from './hot-offers.model';

@Injectable({ providedIn: 'root' })
export class HotOffersService {
  private activeSortSub = new BehaviorSubject<SortVariants>(SortVariants.BY_PRICE);
  activeSort$ = this.activeSortSub.asObservable();

  private toursByDepartCitiesSub = new BehaviorSubject<HotOfferListItem[]>([]);
  toursByDepartCities$ = this.toursByDepartCitiesSub.asObservable();

  private activeDepartCitySub = new BehaviorSubject<HotOfferListItem | null>(null);
  activeDepartCity$ = this.activeDepartCitySub.asObservable();

  private departDatesFilterFlushSub = new Subject<void>();
  departDatesFilterFlush$ = this.departDatesFilterFlushSub.asObservable();

  private hasActiveFiltersSub = new BehaviorSubject<boolean>(false);
  hasActiveFilters$ = this.hasActiveFiltersSub.asObservable();

  private minPriceSub = new BehaviorSubject<number>(0);
  minPrice$ = this.minPriceSub.asObservable();

  private filters: HotOffersToursFilters = {
    countries: {},
    nights: {},
    departDates: {},
    ratings: {},
    hotelStars: {},
  };
  departDateFromMonth: number;
  departDateToMonth: number;

  workerCityId: number;

  private cacheApiResponse?: HotOfferListItem[] = null;
  private allToursByDepartCities: HotOfferListItem[] = [];

  private countriesByDepartCityIdSubs: BehaviorSubject<HotOfferListItemCountry[]>[] = [];
  private hotelsByDepartCityCountryIdSubs: BehaviorSubject<HotOfferListItemCountryHotel[]>[] = [];
  private toursByDepartCityHotelIdSubs: BehaviorSubject<HotOfferListItemTour[]>[] = [];
  private toursCountByDepartCityCountryIdSubs: BehaviorSubject<number>[] = [];

  constructor(
    private workerState: WorkerStateService,
    private readonly http: HttpClient,
  ) {}

  initState$(): Observable<boolean> {
    if (!this.workerCityId) {
      this.workerCityId = this.workerState.currentWorkerValue.departCityId;
    }

    if (this.cacheApiResponse) {
      this.allToursByDepartCities = this.cacheApiResponse;
      this.toursByDepartCitiesSub.next(this.allToursByDepartCities);

      const departCity = this.allToursByDepartCities.find(v => v.departCityId === this.workerCityId);
      this.activeDepartCitySub.next(departCity);
      return of(true);
    } else {
      return this.http
        .get(`${AppConfig.apiUrl}/hot-offers/list`)
        .pipe(apiResponsePipe)
        .pipe(
          tap((toursByDepartCities: HotOfferListItem[]) => {
            this.cacheApiResponse = toursByDepartCities;
            this.allToursByDepartCities = toursByDepartCities;

            this.createObservables(toursByDepartCities);
            this.createFilters(toursByDepartCities);
            this.calcMinPrice(toursByDepartCities);

            this.toursByDepartCitiesSub.next(toursByDepartCities);

            const departCity = toursByDepartCities.find(v => v.departCityId === this.workerCityId);
            this.activeDepartCitySub.next(departCity);

            this.updateState(departCity.departCityId);
          }),
          switchMap(() => of(true)),
          catchError(() => {
            return of(false);
          }),
        );
    }
  }

  flushData(): void {
    this.toursByDepartCitiesSub.value.forEach(v => this.flushFilters(v.departCityId));
    this.cacheApiResponse = undefined;
  }

  changeDepartCity(departCityId: number): void {
    const departCity = this.toursByDepartCitiesSub.value.find(v => v.departCityId === departCityId);
    this.workerCityId = departCityId;
    this.activeDepartCitySub.next(departCity);
    this.updateState(departCityId);
  }

  changeSort(sort: SortVariants): void {
    this.activeSortSub.next(sort);
    this.allToursByDepartCities.forEach(v => {
      this.updateState(v.departCityId);
    });
  }

  toursCountByDepartCityCountryIdS(departCityId: number, countryId: number): Observable<number> {
    const key = `${departCityId}-${countryId}`;
    return this.toursCountByDepartCityCountryIdSubs[key].asObservable();
  }

  countriesByDepartCityId$(departCityId: number): Observable<HotOfferListItemCountry[]> {
    return this.countriesByDepartCityIdSubs[departCityId].asObservable();
  }

  countriesByDepartCityIdValue(departCityId: number): HotOfferListItemCountry[] {
    return this.countriesByDepartCityIdSubs[departCityId].value;
  }

  hotelsByCountryId$(departCityId: number, countryId: number): Observable<HotOfferListItemCountryHotel[]> {
    const key = `${departCityId}-${countryId}`;
    return this.hotelsByDepartCityCountryIdSubs[key].asObservable();
  }

  hotelsByCountryIdValues(departCityId: number, countryId: number): HotOfferListItemCountryHotel[] {
    const key = `${departCityId}-${countryId}`;
    return this.hotelsByDepartCityCountryIdSubs[key].value;
  }

  filtersByType$(departCityId: number, type: HotOffersFilterType): Observable<HotOffersFilterValue[]> {
    if (type === HotOffersFilterType.COUNTRIES) {
      return this.filters.countries[departCityId].asObservable();
    }
    if (type === HotOffersFilterType.NIGHTS) {
      return this.filters.nights[departCityId].asObservable();
    }
    if (type === HotOffersFilterType.DEPART_DATE) {
      return this.filters.departDates[departCityId].asObservable();
    }
    if (type === HotOffersFilterType.BOOKING_RATING) {
      return this.filters.ratings[departCityId].asObservable();
    }
    if (type === HotOffersFilterType.HOTEL_STARS) {
      return this.filters.hotelStars[departCityId].asObservable();
    }
  }

  activeFiltersByType$(departCityId: number, type: HotOffersFilterType): Observable<any> {
    const isCheckedFn = (values: HotOffersFilterValue[]) => values.filter(value => value.isChecked);

    return this.filtersByType$(departCityId, type).pipe(map(isCheckedFn));
  }

  removeDepartDatesFilter(departCityId: number) {
    this.filters.departDates[departCityId].value.forEach(v => {
      v.isChecked = false;
    });

    this.calcFilterValuesCount(departCityId);
    this.updateState(departCityId);

    this.departDatesFilterFlushSub.next();
  }

  flushFilters(departCityId: number) {
    const keys = Object.keys(this.filters);
    for (const key of keys) {
      for (const value of this.filters[key][departCityId].value) {
        value.isChecked = false;
      }
    }

    this.calcFilterValuesCount(departCityId);
    this.updateState(departCityId);
    this.departDatesFilterFlushSub.next();
  }

  applyFilter(event: HotOffersApplyFilterType) {
    function isHotOffersApplyFilterEvent(e: HotOffersApplyFilterType): e is HotOffersApplyFilterEvent {
      return e.eventType === 'HotOffersApplyFilterEvent';
    }

    function isHotOffersApplyFilterDepartDateRangeEvent(
      e: HotOffersApplyFilterType,
    ): e is HotOffersApplyFilterDepartDateRangeEvent {
      return e.eventType === 'HotOffersApplyFilterDepartDateRangeEvent';
    }

    const departCityId = event.departCityId;
    if (isHotOffersApplyFilterEvent(event)) {
      if (event.type === HotOffersFilterType.COUNTRIES) {
        const index = this.filters.countries[departCityId].value.findIndex(v => v.value === event.value);
        this.filters.countries[departCityId].value[index].isChecked = event.isChecked;
      }

      if (event.type === HotOffersFilterType.BOOKING_RATING) {
        const index = this.filters.ratings[departCityId].value.findIndex(v => v.value === event.value);
        this.filters.ratings[departCityId].value[index].isChecked = event.isChecked;
      }

      if (event.type === HotOffersFilterType.NIGHTS) {
        const index = this.filters.nights[departCityId].value.findIndex(v => v.value === event.value);
        this.filters.nights[departCityId].value[index].isChecked = event.isChecked;
      }

      if (event.type === HotOffersFilterType.DEPART_DATE) {
        const index = this.filters.departDates[departCityId].value.findIndex(v => v.value === event.value);
        this.filters.departDates[departCityId].value[index].isChecked = event.isChecked;
      }

      if (event.type === HotOffersFilterType.HOTEL_STARS) {
        const index = this.filters.hotelStars[departCityId].value.findIndex(v => v.value === event.value);
        this.filters.hotelStars[departCityId].value[index].isChecked = event.isChecked;
      }
    } else if (isHotOffersApplyFilterDepartDateRangeEvent(event)) {
      this.filters.departDates[departCityId].value.forEach(v => {
        v.isChecked = false;
        v.isChecked = event.values.indexOf(v.value as string) !== -1;
      });
    }

    this.calcFilterValuesCount(departCityId);
    this.updateState(departCityId);
  }

  private updateHasActiveFilters(departCityId: number) {
    let hasFilters = false;

    const keys = Object.keys(this.filters);
    for (const key of keys) {
      for (const value of this.filters[key][departCityId].value) {
        if (value.isChecked) {
          hasFilters = true;
          break;
        }
      }
    }

    this.hasActiveFiltersSub.next(hasFilters);
  }

  private updateState(departCityId: number) {
    let countries: HotOfferListItemCountry[] = [];

    // Начинаем обход с самого нижнего элементы фильтра - туров
    let toursByDepartCity = this.allToursByDepartCities.find(v => v.departCityId === departCityId);
    toursByDepartCity = JSON.parse(JSON.stringify(toursByDepartCity));
    const selectedNights = this.getSelectedNights(departCityId);
    const selectedDepartDates = this.getSelectedDepartDates(departCityId);
    const selectedCountryIds = this.getSelectedCountryIds(departCityId);

    countries = toursByDepartCity.countries.filter(country => {
      if (selectedCountryIds.length && selectedCountryIds.indexOf(country.countryId) === -1) {
        return false;
      }

      let hotels = country.hotels.filter(hotel => {
        const tours = hotel.tours.filter(v => {
          if (selectedNights.length && selectedNights.indexOf(v.nights) === -1) {
            return false;
          }
          if (selectedDepartDates.length && selectedDepartDates.indexOf(v.date) === -1) {
            return false;
          }

          return true;
        });

        if (tours.length) {
          const tourKey = `${departCityId}-${hotel.hotel.id}`;
          if (this.activeSortSub.value === SortVariants.BY_DISCOUNT) {
            tours.sort((t1, t2) => (t1.discount > t2.discount ? -1 : 1));
          } else {
            tours.sort((t1, t2) => (t1.price.value > t2.price.value ? 1 : -1));
          }

          hotel.tours = tours;
          this.toursByDepartCityHotelIdSubs[tourKey].next(tours);

          return true;
        }

        return false;
      });

      const selectedHotelRatings = this.getSelectedHotelRatings(departCityId);
      if (selectedHotelRatings.length) {
        const minRating = Math.min(...selectedHotelRatings);
        hotels = hotels.filter(hotel => {
          const hotelRating = this.convertHotelRating(hotel.hotel.bookingRating);
          return hotelRating >= minRating;
        });
      }

      const selectedHotelStars = this.getSelectedHotelStars(departCityId);
      if (selectedHotelStars.length) {
        hotels = hotels.filter(hotel => {
          return selectedHotelStars.indexOf(+hotel.hotel.stars) !== -1;
        });
      }

      let toursInCountry = 0;
      hotels.forEach(hotel => {
        const tourKey = `${departCityId}-${hotel.hotel.id}`;
        toursInCountry += this.toursByDepartCityHotelIdSubs[tourKey].value.length;
      });
      this.toursCountByDepartCityCountryIdSubs[`${departCityId}-${country.countryId}`].next(toursInCountry);

      if (hotels.length) {
        hotels = this.sortHotels(hotels);
        country.hotels = hotels;

        const key = `${departCityId}-${country.countryId}`;
        this.hotelsByDepartCityCountryIdSubs[key].next(hotels);

        return true;
      }

      return false;
    });

    this.countriesByDepartCityIdSubs[departCityId].next(countries);
    this.updateHasActiveFilters(departCityId);
  }

  private sortHotels(hotels: HotOfferListItemCountryHotel[]): HotOfferListItemCountryHotel[] {
    const sort = this.activeSortSub.value;

    if (sort === SortVariants.BY_PRICE) {
      return hotels.sort(this.sortByPriceFn);
    }
    if (sort === SortVariants.BY_DISCOUNT) {
      return hotels.sort(this.sortByDiscountFn);
    }
    if (sort === SortVariants.BY_SALES_COUNT) {
      return hotels.sort(this.sortBySalesCountFn);
    }
    if (sort === SortVariants.BY_RATING) {
      return hotels.sort(this.sortByRatingFn);
    }
    return hotels;
  }

  private sortByPriceFn(h1: HotOfferListItemCountryHotel, h2: HotOfferListItemCountryHotel): number {
    const h1Tours = h1.tours.sort((t1, t2) => (t1.price.value > t2.price.value ? 1 : -1));
    const h2Tours = h2.tours.sort((t1, t2) => (t1.price.value > t2.price.value ? 1 : -1));
    const tour1Price = h1Tours[0].price.value;
    const tour2Price = h2Tours[0].price.value;

    if (tour1Price === tour2Price) {
      return 0;
    }

    return tour1Price < tour2Price ? -1 : 1;
  }

  private sortByDiscountFn(h1: HotOfferListItemCountryHotel, h2: HotOfferListItemCountryHotel): number {
    const h1Tours = h1.tours.sort((t1, t2) => (t1.discount < t2.discount ? 1 : -1));
    const h2Tours = h2.tours.sort((t1, t2) => (t1.discount < t2.discount ? 1 : -1));
    const tour1Percent = h1Tours[0].discount;
    const tour2Percent = h2Tours[0].discount;

    if (tour1Percent === tour2Percent) {
      return 0;
    }

    return tour1Percent < tour2Percent ? 1 : -1;
  }

  private sortBySalesCountFn(h1: HotOfferListItemCountryHotel, h2: HotOfferListItemCountryHotel): number {
    if (h1.hotel.salesCount === h2.hotel.salesCount) {
      return 0;
    }

    return h1.hotel.salesCount > h2.hotel.salesCount ? -1 : 1;
  }

  private sortByRatingFn(h1: HotOfferListItemCountryHotel, h2: HotOfferListItemCountryHotel): number {
    if (h1.hotel.bookingRating === h2.hotel.bookingRating) {
      return 0;
    }

    return h1.hotel.bookingRating > h2.hotel.bookingRating ? -1 : 1;
  }

  private createObservables(toursByDepartCities: HotOfferListItem[]): void {
    toursByDepartCities.forEach(v => {
      const departCityId = v.departCityId;

      this.countriesByDepartCityIdSubs[departCityId] = new BehaviorSubject<HotOfferListItemCountry[]>(
        v.countries,
      );

      v.countries.forEach(countryItem => {
        const countryKey = `${departCityId}-${countryItem.countryId}`;
        const hotels = countryItem.hotels.sort(this.sortByDiscountFn);

        this.hotelsByDepartCityCountryIdSubs[countryKey] = new BehaviorSubject(hotels);

        let toursCountInCountry = 0;
        countryItem.hotels.forEach(hotelItem => {
          const tourKey = `${departCityId}-${hotelItem.hotel.id}`;
          const tours = hotelItem.tours;
          tours.sort((t1, t2) => (t1.discount < t2.discount ? 1 : -1));
          // Внутри туров по отелю сортировка всегда по возрастанию цены
          this.toursByDepartCityHotelIdSubs[tourKey] = new BehaviorSubject(tours);

          toursCountInCountry += tours.length;
        });

        this.toursCountByDepartCityCountryIdSubs[countryKey] = new BehaviorSubject(toursCountInCountry);
      });
    });
  }

  private getSelectedCountryIds(departCityId: number): number[] {
    const selectedCountryIds: number[] = [];
    this.filters.countries[departCityId].value.forEach(filter => {
      if (filter.isChecked) {
        selectedCountryIds.push(filter.value as number);
      }
    });

    return selectedCountryIds;
  }

  private getSelectedHotelStars(departCityId: number): number[] {
    const selectedHotelStars: number[] = [];
    this.filters.hotelStars[departCityId].value.forEach(filter => {
      if (filter.isChecked) {
        selectedHotelStars.push(filter.value as number);
      }
    });

    return selectedHotelStars;
  }

  private getSelectedHotelRatings(departCityId: number): number[] {
    const selectedHotelRatings: number[] = [];
    this.filters.ratings[departCityId].value.forEach(filter => {
      if (filter.isChecked) {
        selectedHotelRatings.push(filter.value as number);
      }
    });

    return selectedHotelRatings;
  }

  private getSelectedNights(departCityId: number): number[] {
    const selectedNights: number[] = [];
    this.filters.nights[departCityId].value.forEach(filter => {
      if (filter.isChecked) {
        selectedNights.push(filter.value as number);
      }
    });

    return selectedNights;
  }

  private getSelectedDepartDates(departCityId: number): string[] {
    const selectedDepartDates: string[] = [];
    this.filters.departDates[departCityId].value.forEach(filter => {
      if (filter.isChecked) {
        selectedDepartDates.push(filter.value as string);
      }
    });

    return selectedDepartDates;
  }

  private convertHotelRating(hotelRating: number): number {
    let value = 0;
    if (hotelRating >= 9) {
      value = 9;
    }
    if (hotelRating >= 8 && hotelRating < 9) {
      value = 8;
    }
    if (hotelRating >= 7 && hotelRating < 8) {
      value = 7;
    }

    return value;
  }

  private createNightsFilterValues(availableNights: any): HotOffersFilterValue[] {
    const nightsFilters = Object.keys(availableNights).map(nights => {
      const nightsValue = Number(nights);
      const filterValue: HotOffersFilterValue = {
        type: HotOffersFilterType.NIGHTS,
        value: nightsValue,
        label: nightsValue.toString(),
        labelFilter: nightsValue.toString(),
        count: availableNights[nights],
        isChecked: false,
      };

      return filterValue;
    });

    nightsFilters.sort((f1, f2) => {
      return f1.value < f1.value ? 1 : -1;
    });

    return nightsFilters;
  }

  private createDepartDatesFilterValues(availableDepartDates: any): HotOffersFilterValue[] {
    const departCitiesFilters = Object.keys(availableDepartDates).map(departDate => {
      const filterValue: HotOffersFilterValue = {
        type: HotOffersFilterType.DEPART_DATE,
        value: departDate,
        label: departDate,
        labelFilter: departDate,
        count: availableDepartDates[departDate],
        isChecked: false,
      };

      return filterValue;
    });

    departCitiesFilters.sort((f1, f2) => {
      const depart1 = new Date(f1.value).getTime();
      const depart2 = new Date(f2.value).getTime();

      return depart2 < depart1 ? 1 : -1;
    });

    return departCitiesFilters;
  }

  private createHotelStarsFilters(availableHotelStars: any): HotOffersFilterValue[] {
    const hotelStarsFilters = Object.keys(availableHotelStars).map(hotelStars => {
      const hotelStarsValue = Number(hotelStars);
      const filterValue: HotOffersFilterValue = {
        type: HotOffersFilterType.HOTEL_STARS,
        value: hotelStarsValue,
        label: `${hotelStarsValue}`,
        labelFilter: `${hotelStarsValue} <img src="assets/icons/hot-offers/hotel-stars-full.svg">`,
        count: availableHotelStars[hotelStars],
        isChecked: false,
      };

      return filterValue;
    });
    hotelStarsFilters.sort((f1, f2) => {
      return f1.value < f1.value ? 1 : -1;
    });

    return hotelStarsFilters;
  }

  private createHotelRatingsFilters(availableRatings: any): HotOffersFilterValue[] {
    const hotelRatingsFilters = Object.keys(availableRatings).map(hotelRating => {
      const hotelRatingValue = Number(hotelRating);
      const filterValue: HotOffersFilterValue = {
        type: HotOffersFilterType.BOOKING_RATING,
        value: hotelRatingValue,
        label: `${hotelRatingValue}+`,
        labelFilter: `${hotelRatingValue}+`,
        count: availableRatings[hotelRating],
        isChecked: false,
      };

      return filterValue;
    });
    hotelRatingsFilters.sort((f1, f2) => {
      return f1.value < f1.value ? 1 : -1;
    });

    return hotelRatingsFilters;
  }

  private calcMinPrice(toursByDepartCities: HotOfferListItem[]): void {
    const departCity = toursByDepartCities.find(v => v.departCityId === this.workerCityId);

    let minPrice = Number.MAX_SAFE_INTEGER;
    departCity.countries.forEach(country => {
      country.hotels.forEach(hotel => {
        hotel.tours.forEach(tour => {
          if (tour.brandPrice.value < minPrice) {
            minPrice = tour.brandPrice.value;
          }
        });
      });
    });

    this.minPriceSub.next(minPrice);
  }

  private createFilters(toursByDepartCities: HotOfferListItem[]): void {
    toursByDepartCities.forEach(toursByDepartCity => {
      const departCityId = toursByDepartCity.departCityId;

      const availableNights = {};
      const availableDepartDates = {};
      const availableRatings = {};
      const availableHotelStars = {};

      const countriesFilters: HotOffersFilterValue[] = [];

      toursByDepartCity.countries.forEach(toursByDepartCityAndCountry => {
        let toursByCountry = 0;
        toursByDepartCityAndCountry.hotels.forEach(toursByHotel => {
          toursByCountry += toursByHotel.tours.length;

          toursByHotel.tours.forEach(tour => {
            if (!availableNights.hasOwnProperty(tour.nights)) {
              availableNights[tour.nights] = 1;
            } else {
              availableNights[tour.nights]++;
            }

            if (!availableDepartDates.hasOwnProperty(tour.date)) {
              availableDepartDates[tour.date] = 1;
            } else {
              availableDepartDates[tour.date]++;
            }

            const hotelRating = this.convertHotelRating(toursByHotel.hotel.bookingRating);
            if (hotelRating > 0) {
              if (!availableRatings.hasOwnProperty(hotelRating)) {
                availableRatings[hotelRating] = 1;
              } else {
                availableRatings[hotelRating]++;
              }
            }

            const hotelStars = toursByHotel.hotel.stars;
            if (!availableHotelStars.hasOwnProperty(hotelStars)) {
              availableHotelStars[hotelStars] = 1;
            } else {
              availableHotelStars[hotelStars]++;
            }
          });
        });

        const countryName = toursByDepartCityAndCountry.countryName;
        const countryCode = toursByDepartCityAndCountry.countryCode;

        countriesFilters.push({
          type: HotOffersFilterType.COUNTRIES,
          value: toursByDepartCityAndCountry.countryId,
          label: `<img style="width: 20px" src="https://ht.kz/img/flag/${countryCode}.png"> ${countryName}`,
          labelFilter: countryName,
          count: toursByCountry,
          isChecked: false,
        });
      });

      const nightsFilters = this.createNightsFilterValues(availableNights);
      const departCitiesFilters = this.createDepartDatesFilterValues(availableDepartDates);
      const hotelRatingsFilters = this.createHotelRatingsFilters(availableRatings);
      const hotelStarsFilters = this.createHotelStarsFilters(availableHotelStars);

      this.filters.countries[departCityId] = new BehaviorSubject(countriesFilters);
      this.filters.nights[departCityId] = new BehaviorSubject(nightsFilters);
      this.filters.departDates[departCityId] = new BehaviorSubject(departCitiesFilters);
      this.filters.ratings[departCityId] = new BehaviorSubject(hotelRatingsFilters);
      this.filters.hotelStars[departCityId] = new BehaviorSubject(hotelStarsFilters);

      const arrDepartDates = Object.keys(availableDepartDates)
        .map(v => new Date(v))
        .sort((v1, v2) => v1.getTime() - v2.getTime());

      this.departDateFromMonth = arrDepartDates[0].getMonth() + 1;
      this.departDateToMonth = arrDepartDates[arrDepartDates.length - 1].getMonth() + 1;
    });
  }

  private calcFilterValuesCount(departCityId: number) {
    const toursByDepartCity = this.allToursByDepartCities.find(v => v.departCityId === departCityId);

    const flushFilterCount = (v: HotOffersFilterValue) => {
      v.count = 0;
      return v;
    };

    const filters: { [key: number]: HotOffersFilterValue[] } = {};
    filters[HotOffersFilterType.COUNTRIES] = this.filters.countries[departCityId].value.map(flushFilterCount);
    filters[HotOffersFilterType.NIGHTS] = this.filters.nights[departCityId].value.map(flushFilterCount);
    filters[HotOffersFilterType.DEPART_DATE] =
      this.filters.departDates[departCityId].value.map(flushFilterCount);
    filters[HotOffersFilterType.BOOKING_RATING] =
      this.filters.ratings[departCityId].value.map(flushFilterCount);
    filters[HotOffersFilterType.HOTEL_STARS] =
      this.filters.hotelStars[departCityId].value.map(flushFilterCount);

    const selectedNights = this.getSelectedNights(departCityId);
    const selectedDepartDates = this.getSelectedDepartDates(departCityId);
    const selectedCountryIds = this.getSelectedCountryIds(departCityId);
    const selectedHotelRatings = this.getSelectedHotelRatings(departCityId);
    const selectedHotelStars = this.getSelectedHotelStars(departCityId);

    toursByDepartCity.countries.forEach(toursByDepartCityAndCountry => {
      toursByDepartCityAndCountry.hotels.forEach(toursByDepartCityAndCountryAndHotel => {
        const hotelStars = +toursByDepartCityAndCountryAndHotel.hotel.stars;
        const hotelRating = +toursByDepartCityAndCountryAndHotel.hotel.bookingRating;

        toursByDepartCityAndCountryAndHotel.tours.forEach(tour => {
          // tslint:disable-next-line:forin
          for (const filterType in filters) {
            let isInc = true;

            // tslint:disable-next-line:forin
            for (const appleFilterType in filters) {
              if (
                appleFilterType === HotOffersFilterType.NIGHTS &&
                selectedNights.length &&
                filterType !== appleFilterType &&
                selectedNights.indexOf(tour.nights) === -1
              ) {
                isInc = false;
              }
              if (
                appleFilterType === HotOffersFilterType.DEPART_DATE &&
                selectedDepartDates.length &&
                filterType !== appleFilterType &&
                selectedDepartDates.indexOf(tour.date) === -1
              ) {
                isInc = false;
              }
              if (
                appleFilterType === HotOffersFilterType.COUNTRIES &&
                selectedCountryIds.length &&
                filterType !== appleFilterType &&
                selectedCountryIds.indexOf(tour.country.id) === -1
              ) {
                isInc = false;
              }
              if (
                appleFilterType === HotOffersFilterType.BOOKING_RATING &&
                selectedHotelRatings.length &&
                filterType !== appleFilterType
              ) {
                const minRating = Math.min(...selectedHotelRatings);
                const convertedHotelRating = this.convertHotelRating(hotelRating);
                if (convertedHotelRating < minRating) {
                  isInc = false;
                }
              }
              if (
                appleFilterType === HotOffersFilterType.HOTEL_STARS &&
                selectedHotelStars.length &&
                filterType !== appleFilterType &&
                selectedHotelStars.indexOf(hotelStars) === -1
              ) {
                isInc = false;
              }
            }

            if (isInc) {
              let index = -1;
              if (filterType === HotOffersFilterType.NIGHTS) {
                index = filters[filterType].findIndex(v => v.value === tour.nights);
              }
              if (filterType === HotOffersFilterType.DEPART_DATE) {
                index = filters[filterType].findIndex(v => v.value === tour.date);
              }
              if (filterType === HotOffersFilterType.COUNTRIES) {
                index = filters[filterType].findIndex(v => v.value === tour.country.id);
              }
              if (filterType === HotOffersFilterType.BOOKING_RATING) {
                const convertedHotelRating = this.convertHotelRating(hotelRating);
                index = filters[filterType].findIndex(v => v.value === convertedHotelRating);
              }
              if (filterType === HotOffersFilterType.HOTEL_STARS) {
                index = filters[filterType].findIndex(v => v.value === hotelStars);
              }
              if (index !== -1) {
                filters[filterType][index].count++;
              }
            }
          }
        });
      });
    });

    this.filters.countries[departCityId].next(filters[HotOffersFilterType.COUNTRIES]);
    this.filters.nights[departCityId].next(filters[HotOffersFilterType.NIGHTS]);
    this.filters.departDates[departCityId].next(filters[HotOffersFilterType.DEPART_DATE]);
    this.filters.ratings[departCityId].next(filters[HotOffersFilterType.BOOKING_RATING]);
    this.filters.hotelStars[departCityId].next(filters[HotOffersFilterType.HOTEL_STARS]);
  }
}
