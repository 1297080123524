<div class="needs-container" [formGroup]="form">
  <div class="needs-header">
    <p class="title">
      {{ 'PAGES.DEALS.CLIENT_CARD.NEEDS.TITLE' | translate }}
    </p>
    <span class="clear" *ngIf="!isEditingMode && form.get('needs')?.value?.length > 0" (click)="clearNeeds()">
      {{ 'PAGES.DEALS.CLIENT_CARD.NEEDS.CLEAR' | translate }}
      <img src="assets/icons/deal-view/trash-blue.svg" alt="clear" />
    </span>

    <div class="edit-controls" *ngIf="isEditingMode">
      <button class="save" (click)="save()">
        <img src="assets/icons/deal-view/save.svg" alt="save" />
      </button>
      <button class="cancel" (click)="cancelEditing()">
        <img src="assets/icons/deal-view/cancel.svg" alt="cancel" />
      </button>
    </div>
  </div>

  <textarea
    #textAreaElement
    appResizableTextarea
    (focus)="enableEditingMode()"
    class="inner-info"
    [ngClass]="{ 'editing-mode': isEditingMode }"
    placeholder="{{ 'PAGES.DEALS.CLIENT_CARD.NEEDS.PLACEHOLDER' | translate }}"
    formControlName="needs"
    type="text"
    rows="3"
  >
  </textarea>
  <span class="last-info-update">
    Дата последнего обновления: {{ lastInfoUpdate | date: 'dd.MM.yyyy H:mm' }}
  </span>
  @if (tags() && tags().length > 0) {
    <div class="tag-list">
      Клиент выбирал:<br />
      @for (tag of tags(); track tag) {
        <span class="tag"
          >{{ tag.tagName }}
          @if (tag.tagValue > 1) {
            <span class="counter" title="Количество выборов тега">{{ tag.tagValue }}</span>
          }
        </span>
      }
    </div>
  }
</div>
