import { PhoneItem, WhatsappMessage } from '@api-clients/crm-api-client';

export interface ChatMessagesState {
  messages: WhatsappMessage[];
  contactPhoneItemOrChatId: PhoneItem | string;
  hasTodayMessages: boolean;
}

export const chatMessagesInitialState: ChatMessagesState = {
  messages: [],
  contactPhoneItemOrChatId: null,
  hasTodayMessages: false,
};
