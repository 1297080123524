import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HotelRoom } from '@api-clients/api-client';
import { switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DraggableDirective } from '../../../../../shared/directives/draggable.directive';
import { SafeHtmlPipe } from '../../../../../shared/pipes/safe-html.pipe';
import { AlertLabelComponent } from '../../../../../ui-components/alert-label/alert-label.component';
import { LineProgressComponent } from '../../../../../ui-components/line-progress/line-progress.component';
import { SearchTourRoomsService } from '../../../services/search-tour-rooms.service';
import { WebsocketToursSearchService } from '../../../services/search/websocket-tours-search.service';
import {
  InitSearchRequest,
  InitSearchRequestMethod,
  InitSearchRequestOptionsGroupResult,
  InitSearchRequestParams,
  InitSearchRequestType,
  SearchResultsResponseTour,
} from '../../../services/search/websocket-tours.model';
import { SearchTourRoomComponent } from './room/search-tour-room.component';

export interface RoomTours {
  room: HotelRoom;
  tours: SearchResultsResponseTour[];
}

@Component({
  selector: 'app-search-tour-rooms',
  templateUrl: './search-tour-rooms.component.html',
  styleUrl: './search-tour-rooms.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DraggableDirective,
    LineProgressComponent,
    SafeHtmlPipe,
    SearchTourRoomComponent,
    AlertLabelComponent,
  ],
  providers: [SearchTourRoomsService],
})
export class SearchTourRoomsComponent implements OnInit {
  initSearchRequest = input.required<InitSearchRequest>();
  tour = input.required<SearchResultsResponseTour>();

  closeModal = output<void>();

  showSearchProgress = signal<boolean>(true);
  showError = signal<boolean>(false);
  showRooms = signal<boolean>(false);

  rooms = signal<HotelRoom[]>([]);
  roomTours = signal<RoomTours[]>([]);

  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly searchService: WebsocketToursSearchService,
    private readonly tourRoomsService: SearchTourRoomsService,
  ) {}

  ngOnInit(): void {
    const initSearchRequest = this.getInitSearchRequest();

    this.tourRoomsService
      .getHotelRooms(this.tour().hotel.id)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(rooms => this.rooms.set(rooms)),
        switchMap(() =>
          this.searchService.searchTours(initSearchRequest, {
            isMainSearch: false,
            addToursInMainResult: false,
          }),
        ),
      )
      .subscribe({
        next: searchResponse => {
          if (searchResponse.hasTours) {
            const roomTours = this.roomTours().reduce((acc, roomTour) => {
              acc[roomTour.room.id] = roomTour;
              return acc;
            }, {});
            searchResponse.tours.forEach(tour => {
              const roomId = tour.rooms[0]?.id;
              if (roomId) {
                if (!roomTours.hasOwnProperty(roomId)) {
                  const roomInformation = this.rooms().find(roomInformation => roomInformation.id === roomId);
                  roomTours[roomId] = {
                    room: roomInformation,
                    tours: [],
                  };
                }
                const currentTours: SearchResultsResponseTour[] = roomTours[roomId].tours;
                currentTours.push(tour);

                roomTours[roomId].tours = this.filterUniqueTours(currentTours).sort(
                  (a, b) => a.brandPrice.value - b.brandPrice.value,
                );
              }
            });

            const arrRoomTours: RoomTours[] = Object.values(roomTours);
            arrRoomTours.sort((a, b) => a.tours[0].brandPrice.value - b.tours[0].brandPrice.value);

            this.roomTours.set(arrRoomTours);
          }

          if (searchResponse.searchIsDone) {
            this.showSearchProgress.set(false);
            this.showRooms.set(true);
          }
        },
        error: () => {
          this.showSearchProgress.set(false);
          this.showError.set(true);
        },
      });
  }

  private getInitSearchRequest(): InitSearchRequest {
    let initSearchRequest = { ...this.initSearchRequest() };
    if (initSearchRequest.hasOwnProperty('params')) {
      initSearchRequest.params.params.hotels = [this.tour().hotel.id];
      initSearchRequest.params.params.nightsFrom = this.tour().nights;
      initSearchRequest.params.params.nightsTo = this.tour().nights;
      initSearchRequest.params.params.dateFrom = this.tour().date;
      initSearchRequest.params.params.dateTo = this.tour().date;

      initSearchRequest.params.options.groupResults = InitSearchRequestOptionsGroupResult.noGroup;
      initSearchRequest.params.options.type = InitSearchRequestType.calc;
      initSearchRequest.params.options.allowParallel = true;
    } else {
      const tour = this.tour();
      const params: InitSearchRequestParams = {
        adults: 2,
        childrenAges: [],
        onlyHotels: false,
        dateFrom: tour.date,
        dateTo: tour.date,
        nightsFrom: tour.nights,
        nightsTo: tour.nights,
        notGDS: true,
        combined: 0,
        countryId: tour.country.id,
        departCityId: tour.departCity.id,
        hotels: [tour.hotel.id],
      };
      initSearchRequest = {
        id: WebsocketToursSearchService.generateId(),
        params: {
          params,
          options: {
            type: InitSearchRequestType.calc,
            groupResults: InitSearchRequestOptionsGroupResult.noGroup,
            allowParallel: true,
          },
        },
        method: InitSearchRequestMethod.search,
      };
    }

    initSearchRequest.id = WebsocketToursSearchService.generateId();

    return initSearchRequest;
  }

  filterUniqueTours(tours: SearchResultsResponseTour[]): SearchResultsResponseTour[] {
    const seenIds = new Set<string>();
    return tours.filter(tour => {
      if (seenIds.has(tour.id)) {
        return false;
      } else {
        seenIds.add(tour.id);
        return true;
      }
    });
  }
}
