@if (activeSort$ | async; as activeSort) {
  <div class="sorts-container" >
    <div class="title">Сортировка</div>
    <div class="active-sort" (clickOutside)="closeVariants()">
      <div class="active-sort-container" (click)="toggleVariants()">
        <span class="active-sort-name">{{ activeSort | hotOffersSorts }}</span>
        <img [class.rotate]="showVariants"
          class="chevron-icon"
          src="assets/icons/hot-offers/drop-down-chevron.svg">
        </div>
      </div>
      <div class="tours-sort-variants" [class.show]="showVariants">
        <div class="tours-sort-btn-label">Сортировать по</div>
        <button type="button" class="tours-sort-btn"
          (click)="sortTours(toursSortVariant.BY_DISCOUNT)"
          [class.active]="activeSort === toursSortVariant.BY_DISCOUNT">
        По скидке
      </button>
      <button type="button" class="tours-sort-btn"
        (click)="sortTours(toursSortVariant.BY_PRICE)"
        [class.active]="activeSort === toursSortVariant.BY_PRICE">
        По цене
      </button>
      <button type="button" class="tours-sort-btn"
        (click)="sortTours(toursSortVariant.BY_RATING)"
        [class.active]="activeSort === toursSortVariant.BY_RATING">
        По рейтингу
      </button>
      <button type="button" class="tours-sort-btn"
        (click)="sortTours(toursSortVariant.BY_SALES_COUNT)"
        [class.active]="activeSort === toursSortVariant.BY_SALES_COUNT">
        По популярности
      </button>
    </div>
  </div>
}
