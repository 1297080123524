import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  computed,
  ElementRef,
  forwardRef,
  input,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { SearchFormParamsRegion } from '@api-clients/api-client/dist/models';
import { SearchFormParamsCountry } from '@api-clients/api-client/models/search-form-params-country';
import { Subscription } from 'rxjs';
import { PopupService } from '../../../../../../../../../shared/services/popup-service.service';
import { SearchFormCountriesDropdownComponent } from './dropdown/search-form-countries-dropdown.component';

@Component({
  selector: 'app-search-form-countries',
  templateUrl: './search-form-countries.component.html',
  styleUrls: ['./search-form-countries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchFormCountriesComponent),
      multi: true,
    },
  ],
})
export class SearchFormCountriesComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('countries', { read: ElementRef }) countriesRef: ElementRef;

  countries = input<SearchFormParamsCountry[]>();
  regions = input<SearchFormParamsRegion[]>();
  selectedCountryId = signal<number>(0);
  selectedRegionIds = signal<number[]>([]);
  selectedRegionsLabel = computed(() => {
    const selectedRegionIds = this.selectedRegionIds();
    const regions = this.regions();

    return regions
      .filter(region => selectedRegionIds.indexOf(region.id) !== -1)
      .map(region => region.name)
      .join(', ');
  });
  activeCountry = computed<SearchFormParamsCountry>(() => {
    const selectedCountryId = this.selectedCountryId();
    const countries = this.countries();

    if (selectedCountryId) {
      return countries.find(v => v.id === selectedCountryId);
    }

    return null;
  });

  form: FormGroup;

  private componentRef: ComponentRef<SearchFormCountriesDropdownComponent>;
  private formValueChangesSubscription: Subscription;

  constructor(
    private readonly fb: FormBuilder,
    private readonly cdRef: ChangeDetectorRef,
    private readonly popupService: PopupService,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      countryId: new FormControl<number>(1, { validators: [Validators.required] }),
      regionIds: new FormControl<number[]>([]),
    });

    this.formValueChangesSubscription = this.form.valueChanges.subscribe(value => {
      this.onChange(value);
      this.onTouched();
    });
  }

  ngOnDestroy() {
    this.destroyDropdownComponent();
    this.formValueChangesSubscription?.unsubscribe();
  }

  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  writeValue(value: any): void {
    if (value.countryId) {
      const countryExistInDirection = this.countries().find(v => v.id === value.countryId);
      if (countryExistInDirection) {
        this.form.setValue(value, { emitEvent: false });
        this.selectedCountryId.set(value.countryId);
        this.selectedRegionIds.set(value.regionIds);
      } else {
        const country = this.countries()[0];
        this.form.setValue(
          {
            countryId: country.id,
            regionIds: [],
          },
          { emitEvent: false },
        );
        this.selectedCountryId.set(country.id);
        this.selectedRegionIds.set([]);
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  openDropdown() {
    this.destroyDropdownComponent();

    this.componentRef = this.popupService.showPopup(
      SearchFormCountriesDropdownComponent,
      {},
      {
        anchorElement: this.countriesRef,
      },
    );

    this.componentRef.instance.activeCountryId = this.selectedCountryId();
    this.componentRef.instance.countries = this.countries();
    this.componentRef.instance.regions = this.regions();
    this.componentRef.instance.selectedRegionIds = this.selectedRegionIds();
    this.componentRef.instance.selected.subscribe(selectedDirection => {
      const countryId = selectedDirection.country.id;
      const isChangeCountryId = this.selectedCountryId() !== countryId;

      this.selectedCountryId.update(() => countryId);

      let regionIds = [];
      if (isChangeCountryId) {
        this.selectedRegionIds.update(() => []);
      } else {
        regionIds = selectedDirection.regions.map(region => region.id);
        this.selectedRegionIds.update(() => regionIds);
      }

      const formValue = {
        countryId,
        regionIds,
      };

      this.form.setValue(formValue);
      this.cdRef.detectChanges();
    });

    this.componentRef.instance.closed.subscribe(() => {
      this.popupService.closeAllModals();
    });
  }

  private destroyDropdownComponent() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
