import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  computed,
  ElementRef,
  forwardRef,
  input,
  OnDestroy,
  signal,
  viewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchFormParamsCountry } from '@api-clients/api-client/models/search-form-params-country';
import { PopupService } from '../../../../../../../../../../../shared/services/popup-service.service';
import { MultipleSearchFormCountriesDropdownComponent } from './dropdown/multiple-search-form-countries-dropdown.component';

@Component({
  selector: 'app-multiple-search-form-countries',
  templateUrl: './multiple-search-form-countries.component.html',
  styleUrl: './multiple-search-form-countries.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MultipleSearchFormCountriesComponent),
    },
    PopupService,
  ],
})
export class MultipleSearchFormCountriesComponent implements OnDestroy, ControlValueAccessor {
  countries = input.required<SearchFormParamsCountry[]>();
  selectedCountries = signal<SearchFormParamsCountry[]>([]);
  selectedCountriesLabel = computed(() => {
    const selectedCountries = this.selectedCountries();
    if (selectedCountries.length) {
      return selectedCountries.map(country => country.name).join(', ');
    }

    return 'выберите страны для поиска';
  });
  countriesRef = viewChild<ElementRef>('countriesList');

  private dropdownComponentRef: ComponentRef<MultipleSearchFormCountriesDropdownComponent>;

  constructor(private popupService: PopupService) {}

  ngOnDestroy(): void {
    this.dropdownComponentRef?.destroy();
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: number[]): void {
    if (value.length) {
      const selectedCountries = [];
      this.countries().forEach(country => {
        if (value.includes(country.id)) {
          selectedCountries.push(country);
        }
      });

      this.selectedCountries.set(selectedCountries);
    }
  }

  openDropdown(): void {
    const selectedCountryIds = this.selectedCountries().map(country => country.id);

    this.dropdownComponentRef = this.popupService.showPopup(
      MultipleSearchFormCountriesDropdownComponent,
      {},
      {
        hasBackdrop: false,
        anchorElement: this.countriesRef(),
      },
    );

    this.dropdownComponentRef.setInput('countries', this.countries());
    this.dropdownComponentRef.setInput('initSelectedCountryIds', selectedCountryIds);
    this.dropdownComponentRef.instance.closeAndEmitSelectedCountryIds.subscribe(selectedCountryIds => {
      const selectedCountries = [];
      if (selectedCountryIds.length) {
        this.countries().forEach(country => {
          if (selectedCountryIds.includes(country.id)) {
            selectedCountries.push(country);
          }
        });
      }

      this.onChange(selectedCountryIds);
      this.selectedCountries.set(selectedCountries);

      this.popupService.closeAllModals();
    });
  }
}
