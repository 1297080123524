import { Injectable } from '@angular/core';
import { ElectronService } from '../../../../../../libs/shared/utility/src/lib/services/electron.service';
import { ConfigsService } from '../../core/services';

@Injectable({ providedIn: 'root' })
export class TourPackageCreateService {
  constructor(
    private readonly configsService: ConfigsService,
    private readonly electronService: ElectronService,
  ) {}

  createTourPackage(tourId: string): void {
    const dbUrl = this.configsService.adminUrl;

    const link = `${dbUrl}/site/reserve?tour=${tourId}&price=`;
    this.electronService.shell
      .openExternal(link, {
        activate: true,
      })
      .then();
  }
}
