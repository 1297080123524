export const MANAGER_STATUS_SET = 'MANAGER_STATUS_SET';
export const SEARCH_HISTORY_LEAD_OPEN = 'SEARCH_HISTORY_LEAD_OPEN';
export const MAIN_SEARCH_USE = 'MAIN_SEARCH_USE';
export const MANAGERS_LIST_OPEN = 'MANAGERS_LIST_OPEN';
export const NOTIFICATIONS_CENTER_OPEN = 'NOTIFICATIONS_CENTER_OPEN';
export const ALL_NOTIFICATIONS_CLEAR = 'ALL_NOTIFICATIONS_CLEAR';
export const ONE_NOTIFICATIONS_DELETE = 'ONE_NOTIFICATIONS_DELETE';
export const SMS_MODAL_MESSAGE_SEND = 'SMS_MODAL_MESSAGE_SEND';
export const CLIENT_READINESS_FILTER_SET = 'CLIENT_READINESS_FILTER_SET';
export const DEAL_WAIT_ANSWER = 'DEAL_WAIT_ANSWER_TOUCHED';
export const DEAL_VIEW_OPENED = 'DEAL_VIEW_OPENED';
export const PHONE_NUMBER_ADDED = 'CLIENT_NUMBER_ADD';
export const PHONE_NUMBER_REMOVED = 'CLIENT_NUMBER_REMOVE';
export const SMS_TEMPLATE_SEND = 'SMS_TEMPLATE_SEND';
export const TOUR_DB_OPENED = 'TOUR_DB_OPENED';
export const BOOKING_HISTORY_OPEN = 'BOOKING_HISTORY_OPEN';
export const BOOKING_PAGE_IN_TOUR_PACKAGE_DATABASE_OPEN = 'BOOKING_PAGE_IN_TOUR_PACKAGE_DATABASE_OPEN';
export const CHAT_FILTER_SET = 'CHAT_FILTER_SET';
export const AUDIO_CALL_PLAY = 'AUDIO_CALL_PLAY';
export const AUDIO_MESSAGE_PLAY = 'AUDIO_MESSAGE_PLAY';
export const AUDIO_RECORDING_PLAY = 'AUDIO_RECORDING_PLAY';
export const AUDIO_PRESENTATION_PLAY = 'AUDIO_PRESENTATION_PLAY';
export const AUTO_TOUCHES_STATUS_SET = 'AUTO_TOUCHES_STATUS_SET';
export const MARKED_AS_NON_CONVERSION_CALL = 'MARKED_AS_NON_CONVERSION_CALL';
export const DEAL_LEAVE_AS_IS = 'DEAL_LEAVE_AS_IS';
export const DEAL_OPEN_IN_SITE = 'DEAL_OPEN_IN_SITE';
export const DEAL_SEARCH_FOR_TOURS = 'DEAL_SEARCH_FOR_TOURS';
export const NEW_QUICK_MESSAGE = 'QUICK_MESSAGE_NEW';
export const QUICK_MESSAGE_EDIT = 'QUICK_MESSAGE_EDIT';
export const QUICK_MESSAGE_DELETE = 'QUICK_MESSAGE_DELETE';
export const QUICK_MESSAGE_SELECT_USERDEFINED = 'Quick message select user-defined';
export const QUICK_MESSAGE_SELECT_PREDEFINED = 'Quick message select predefined';
export const SALES_FUNNEL_DEAL_FINISHED = 'SALES_FUNNEL_DEAL_FINISHED';
export const SALES_FUNNEL_DEAL_AUTO_TOUCH_ENABLED = 'SALES_FUNNEL_DEAL_AUTO_TOUCH_ENABLED';
export const CLICK_TO_CALL = 'CLICK_TO_CALL';
export const DEAL_UPDATED = 'DEAL_UPDATED';

export const SALES_FUNNEL_TASK_RECALL = 'SALES_FUNNEL_TASK_RECALL';
export const SALES_FUNNEL_TASK_REASSIGN = 'SALES_FUNNEL_TASK_REASSIGN';
export const ASIDE_CHATS_SCROLL_ENABLED_SET = 'ASIDE_CHATS_SCROLL_ENABLED_SET';

export const SEND_MESSAGE_FORM_COPY_PASTE_FILES = 'send_message_form_copy_paste_files';
export const SEND_MESSAGE_FORM_DRAG_DROP_FILES = 'send_message_form_drag_drop_files';
export const ZENMODE_TOGGLE = 'zenmode_toggle';
export const ZENMODE_CARD_MISSED = 'zenmode_card_missed';
export const ZENMODE_NEXT_CARD_PRESSED = 'zenmode_next_card_pressed';
export const ZENMODE_PREVIOUS_CARD_RETURNED = 'zenmode_previous_card_returned';
export const ZENMODE_MANUAL_TUTORIAL_LAUNCHED = 'zenmode_manual_tutorial_launched';
export const ZENMODE_START_SCREEN_TIPS_DISABLED = 'zenmode_start_screen_tips_disabled';
export const ZENMODE_EXIT_BEFORE_LOADING_COMPLETE = 'zenmode_exit_before_loading_complete';
export const DEAL_OPERATOR_CLAIM_OPENED = 'deal_operator_claim_opened';
export const UPDATE_VACATION_SCHEDULE = 'UPDATE_VACATION_SCHEDULE';

export const NEW_URGENT_NOTIFICATION_SHOWED = 'new_urgent_notification_showed';
export const NEW_REMINDER_SHOWED = 'new_reminder_showed';
export const OPEN_CHAT_CLICKED = 'open_chat_clicked';

export const CALL_TO_TEXT_CONVERT_CLICK = 'Call to text convert button click';
export const VOICE_MESSAGE_TO_TEXT_CONVERT_CLICK = 'Voice message to text convert button click';
export const AUDIO_RECORDING_TO_TEXT_CONVERT_CLICK = 'Audio recording to text convert button click';
export const AUDIO_PRESENTATION_TO_TEXT_CONVERT_CLICK = 'Audio presentation to text convert button click';

export const CONTENT_CREATOR_OPEN = 'Content creator open';
export const GPT_COMPLETE_REQUEST = 'GPT complete request';
export const MESSAGE_MENU_REPEAT_SEARCH_OPEN = 'Message menu repeat search open';

export const DEAL_TASK_EDIT_CLICK = 'Deal task edit click';
export const MANAGER_OFFERS_LIST_CLICK = 'Manager offers list button click';
export const DEALS_LIST_FILTER_SET = 'Deals list filter set';
export const DEALS_LIST_SORTING_TYPE_SET = 'Deals list sorting type set';

export const DEALS_MENU_STAGE_OPEN = 'Funnel stage open';
export const DEALS_MENU_STATUS_OPEN = 'Funnel status open';

export const TOUR_PACKAGE_REVIEW_OPEN = 'Tour package review open';
export const TOUR_PACKAGE_CREATE_BUTTON_CLICK = 'Tour package button create click';

export const WHATSAPP_MESSAGE_DELETE_CLICK = 'Whatsapp message delete click';
export const WHATSAPP_MESSAGE_DELETE_CONFIRM = 'Whatsapp message delete confirm';

export const DELAYED_MESSAGE_PLANNED = 'Delayed message planned';
export const DELAYED_MESSAGE_RESCHEDULED = 'Delayed message rescheduled ';
export const DELAYED_MESSAGE_DELETED = 'Delayed message deleted ';

export const CHAT_RECOMMENDATION_GET = 'Chat recommendation get';
export const CHAT_RECOMMENDATION_VIEW = 'Chat recommendation view';
export const CHAT_RECOMMENDATION_MESSAGE_OPEN_EDIT_MODAL = 'Chat recommendation message open edit modal';
export const CHAT_RECOMMENDATION_MESSAGE_EDIT = 'Chat recommendation message edit';
export const CHAT_RECOMMENDATION_MESSAGE_DELETE = 'Chat recommendation message delete';
export const CHAT_RECOMMENDATION_MESSAGE_SEND = 'Chat recommendation message send';

export const CHAT_LIST_FILTER_SET = 'Chat list filter set';

export type AmplitudeEventsType =
  | typeof MANAGER_STATUS_SET
  | typeof SEARCH_HISTORY_LEAD_OPEN
  | typeof MAIN_SEARCH_USE
  | typeof MANAGERS_LIST_OPEN
  | typeof NOTIFICATIONS_CENTER_OPEN
  | typeof ALL_NOTIFICATIONS_CLEAR
  | typeof ONE_NOTIFICATIONS_DELETE
  | typeof SMS_MODAL_MESSAGE_SEND
  | typeof CLIENT_READINESS_FILTER_SET
  | typeof DEAL_VIEW_OPENED
  | typeof PHONE_NUMBER_ADDED
  | typeof PHONE_NUMBER_REMOVED
  | typeof SMS_TEMPLATE_SEND
  | typeof BOOKING_HISTORY_OPEN
  | typeof BOOKING_PAGE_IN_TOUR_PACKAGE_DATABASE_OPEN
  | typeof CHAT_FILTER_SET
  | typeof AUDIO_CALL_PLAY
  | typeof AUDIO_MESSAGE_PLAY
  | typeof AUDIO_RECORDING_PLAY
  | typeof AUDIO_PRESENTATION_PLAY
  | typeof AUTO_TOUCHES_STATUS_SET
  | typeof MARKED_AS_NON_CONVERSION_CALL
  | typeof DEAL_LEAVE_AS_IS
  | typeof DEAL_OPEN_IN_SITE
  | typeof DEAL_SEARCH_FOR_TOURS
  | typeof NEW_QUICK_MESSAGE
  | typeof QUICK_MESSAGE_EDIT
  | typeof QUICK_MESSAGE_DELETE
  | typeof QUICK_MESSAGE_SELECT_USERDEFINED
  | typeof QUICK_MESSAGE_SELECT_PREDEFINED
  | typeof SALES_FUNNEL_DEAL_FINISHED
  | typeof SALES_FUNNEL_DEAL_AUTO_TOUCH_ENABLED
  | typeof DEAL_WAIT_ANSWER
  | typeof DEAL_UPDATED
  | typeof ASIDE_CHATS_SCROLL_ENABLED_SET
  | typeof SALES_FUNNEL_TASK_RECALL
  | typeof SALES_FUNNEL_TASK_REASSIGN
  | typeof SEND_MESSAGE_FORM_COPY_PASTE_FILES
  | typeof SEND_MESSAGE_FORM_DRAG_DROP_FILES
  | typeof TOUR_DB_OPENED
  | typeof ZENMODE_TOGGLE
  | typeof ZENMODE_CARD_MISSED
  | typeof ZENMODE_NEXT_CARD_PRESSED
  | typeof ZENMODE_PREVIOUS_CARD_RETURNED
  | typeof ZENMODE_MANUAL_TUTORIAL_LAUNCHED
  | typeof ZENMODE_START_SCREEN_TIPS_DISABLED
  | typeof ZENMODE_EXIT_BEFORE_LOADING_COMPLETE
  | typeof UPDATE_VACATION_SCHEDULE
  | typeof DEAL_OPERATOR_CLAIM_OPENED
  | typeof CLICK_TO_CALL
  | typeof NEW_URGENT_NOTIFICATION_SHOWED
  | typeof NEW_REMINDER_SHOWED
  | typeof OPEN_CHAT_CLICKED
  | typeof VOICE_MESSAGE_TO_TEXT_CONVERT_CLICK
  | typeof AUDIO_RECORDING_TO_TEXT_CONVERT_CLICK
  | typeof AUDIO_PRESENTATION_TO_TEXT_CONVERT_CLICK
  | typeof CALL_TO_TEXT_CONVERT_CLICK
  | typeof CONTENT_CREATOR_OPEN
  | typeof GPT_COMPLETE_REQUEST
  | typeof MESSAGE_MENU_REPEAT_SEARCH_OPEN
  | typeof MANAGER_OFFERS_LIST_CLICK
  | typeof DEAL_TASK_EDIT_CLICK
  | typeof DEALS_LIST_FILTER_SET
  | typeof DEALS_LIST_SORTING_TYPE_SET
  | typeof DEALS_MENU_STAGE_OPEN
  | typeof DEALS_MENU_STATUS_OPEN
  | typeof TOUR_PACKAGE_REVIEW_OPEN
  | typeof TOUR_PACKAGE_CREATE_BUTTON_CLICK
  | typeof WHATSAPP_MESSAGE_DELETE_CLICK
  | typeof WHATSAPP_MESSAGE_DELETE_CONFIRM
  | typeof DELAYED_MESSAGE_PLANNED
  | typeof DELAYED_MESSAGE_RESCHEDULED
  | typeof DELAYED_MESSAGE_DELETED
  | typeof CHAT_RECOMMENDATION_GET
  | typeof CHAT_RECOMMENDATION_VIEW
  | typeof CHAT_RECOMMENDATION_MESSAGE_OPEN_EDIT_MODAL
  | typeof CHAT_RECOMMENDATION_MESSAGE_EDIT
  | typeof CHAT_RECOMMENDATION_MESSAGE_DELETE
  | typeof CHAT_RECOMMENDATION_MESSAGE_SEND
  | typeof CHAT_LIST_FILTER_SET;
