import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CrmCardViewItem, PhoneItem, WhatsappMessage } from '@api-clients/crm-api-client/dist/models';
import { Subscription } from 'rxjs';
import { DealViewItem } from '@api-clients/crm-api-client/dist/models/deal-view-item';
import { ChatMessagesPayload } from '../../../../../+state/chat-messages/chat-messages.interface';
import { DealViewService } from '../../../../deals/modules/deal-view/services/deal-view.service';
import { WhatsappApiService } from '../../../services/whatsapp-api.service';
import { ChatMessagesFacade } from '../../../../../+state/chat-messages/chat-messages.facade';
import { WhatsappChatService } from '../../../services/whatsapp-chat.service';
import { WhatsappContactsFacade } from '../../../../../+state/whatsapp-contacts/whatsapp-contacts.facade';
import { Chat, SelectedChat } from '../../../../../+state/whatsapp-contacts/whatsapp-contacts.interfaces';

@Component({
  selector: 'app-whatsapp-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('ChatScreen') private chatScreenElement: ElementRef;
  public dataFromModalWindow = null;
  public chatListSub: Subscription;
  public currentCardView: CrmCardViewItem | DealViewItem;
  public currentContactId: string;
  public currentCrmId: number;
  public isLoading = true;
  public loadedMessages: WhatsappMessage[] = [];
  public messages: WhatsappMessage[] = [];
  public chatMessagesSub: Subscription;
  public isChatEmpty = false;
  public isFirstInit = true;
  @Input() loadedChats: Chat[];
  @Input() selectedChat: SelectedChat;
  public modalSelectedChat: SelectedChat;
  public messageTheLastOfTheGroupId: string;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) data,
    @Optional() public dialogRef: MatDialogRef<ChatComponent>,
    private dealViewService: DealViewService,
    private whatsappChatService: WhatsappChatService,
    private whatsappApiService: WhatsappApiService,
    private cdRef: ChangeDetectorRef,
    private chatMessagesFacade: ChatMessagesFacade,
    private whatsappContactsFacade: WhatsappContactsFacade,
  ) {
    this.dataFromModalWindow = data || null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selectedChat &&
      !changes.selectedChat.isFirstChange() &&
      changes.selectedChat.previousValue !== changes.selectedChat.currentValue
    ) {
      const prevValue = changes.selectedChat.previousValue;
      const currentValue = changes.selectedChat.currentValue;
      if (prevValue.crmCardId !== currentValue.crmCardId || !currentValue.crmCardId) {
        this.currentCardView = null;
      }
      // см. isPhoneOrChatIdChanged - косвенно связанные вещи, изменение чата

      this.loadSelectedChat();
    }
  }

  ngOnInit(): void {
    if (this.isModal) {
      this.modalSelectedChat = this.dataFromModalWindow.selectedChat;
      this.loadSelectedChat();
    } else if (this.selectedChat) {
      this.loadSelectedChat();
    }
    this.chatMessagesSub = this.chatMessagesFacade.chatMessages$.subscribe((messages: WhatsappMessage[]) => {
      if (!messages.length && !this.isFirstInit) {
        this.isFirstInit = true;
      }
      if (messages && messages.length) {
        this.messages = messages;
        this.isFirstInit = false;
        this.isChatEmpty = false;
        this.cdRef.detectChanges();
      }
    });
  }

  loadSelectedChat() {
    this.currentCrmId = this.selectedChat?.crmCardId || this.modalSelectedChat.crmCardId;
    this.currentContactId = this.selectedChat?.contactId || this.modalSelectedChat.contactId;
    this.loadedMessages = [];

    this.getMessagesByContactId(this.currentContactId);
    this.dealViewService
      .getDealView(this.currentCrmId, ['questionnaire', 'next-task', 'search-request-list'])
      .subscribe(item => {
        this.currentCardView = item;
        this.isLoading = false;
        this.cdRef.detectChanges();
      });
  }

  clearContactUnreadMessages() {
    this.whatsappApiService.clearContactUnreadMessages(this.currentContactId).subscribe(result => {
      if (result) {
        this.whatsappContactsFacade.clearContactUnreadMessagesByContactId(this.currentContactId);
        this.whatsappContactsFacade.countChatsWithUnreadMessages();
      }
    });
  }

  scrollToMessage(elementName) {
    const element: HTMLElement = document.getElementById(elementName);
    element.scrollIntoView();
  }

  get isModal() {
    return !!this.dataFromModalWindow;
  }

  get isFromDealView() {
    if (this.dataFromModalWindow) {
      return this.dataFromModalWindow.isFromDealView;
    }

    return false;
  }
  closePopup(): void {
    this.dialogRef.close();
  }

  public setChatMessagesState() {
    const chatMessagesStatePayload: ChatMessagesPayload = {
      messages: [],
      contactPhoneItemOrChatId: this.currentPhoneItem,
    };

    this.chatMessagesFacade.setChatMessagesState(chatMessagesStatePayload);
  }

  getMessagesByContactId(contactId: string) {
    this.isLoading = true;
    this.isChatEmpty = false;
    this.whatsappApiService.getWhatsappMessagesByContactId(contactId).subscribe(messages => {
      if (!messages.length) {
        this.isChatEmpty = true;
      }
      this.loadedMessages = messages;
      this.isLoading = false;
      this.cdRef.detectChanges();
      this.setChatMessagesState();
    });
  }

  ngOnDestroy(): void {
    if (this.chatListSub) {
      this.chatListSub.unsubscribe();
    }
    this.chatMessagesSub.unsubscribe();
    this.chatMessagesFacade.resetChat();
  }

  getQuotedMessage(messageId: string) {
    for (const message of this.loadedMessages) {
      if (message.id === messageId) {
        return message;
      }
    }
  }

  get currentPhoneItem(): PhoneItem {
    if (this.selectedChat) {
      return this.selectedChat.phone;
    }
    if (this.modalSelectedChat) {
      return this.modalSelectedChat.phone;
    }
    return this.currentCardView.card.mobilePhones[0];
  }

  get phones(): PhoneItem[] {
    return this.currentCardView?.card?.mobilePhones
      ? this.currentCardView?.card?.mobilePhones
      : [this.currentPhoneItem];
  }
}
