import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ChatMessagesEffects } from '../../+state/chat-messages/chat-messages.effects';
import { ChatTimelineEffects } from '../../+state/chat-timeline/chat-timeline.effects';
import * as fromChatTimeline from '../../+state/chat-timeline/chat-timeline.reducer';
import { ChatEffects } from '../../+state/chats/chats.effects';
import { chatReducer, FEATURE_KEY } from '../../+state/chats/chats.reducer';
import { SharedUtilityModule } from '../../../../../../libs/shared/utility/src';
import { SharedModule } from '../../shared/shared.module';
import { MessageRecommendationComponent } from '../chats/components/message-recommendation/message-recommendation.component';
import { AudioModule } from '../deals/modules/deals-list/modules/deals-list-content/deals-item/audio/audio.module';
import { ChatFooterModule } from '../whatsapp/modules/chat-footer/chat-footer.module';
import { ChatTimelineComponent } from './chat-timeline.component';
import { ChatTimeLineFiltersComponent } from './components/chat-time-line-filters/chat-time-line-filters.component';
import { FilterItemComponent } from './components/chat-time-line-filters/components/filter-item/filter-item.component';
import { ChatTimeLineItemComponent } from './components/chat-time-line-item/chat-time-line-item.component';
import { ChatTimelineItemCallComponent } from './components/chat-time-line-item/components/chat-timeline-item-call/chat-timeline-item-call.component';
import { ChatTimelineItemMessageComponent } from './components/chat-time-line-item/components/chat-timeline-item-message/chat-timeline-item-message.component';
import { ChatTimelineItemScheduledMessageComponent } from './components/chat-time-line-item/components/chat-timeline-item-scheduled-message/chat-timeline-item-scheduled-message.component';
import { ChatTimelineItemReservationComponent } from './components/chat-time-line-item/components/chat-timeline-item-reservation/chat-timeline-item-reservation.component';
import { ChatTimelineItemSearchHistoryComponent } from './components/chat-time-line-item/components/chat-timeline-item-search-history/chat-timeline-item-search-history.component';
import { ChatTimelineItemSmsComponent } from './components/chat-time-line-item/components/chat-timeline-item-sms/chat-timeline-item-sms.component';
import { MessageMenuComponent } from './components/chat-time-line-item/components/message-menu/message-menu.component';
import { QuotedMessageComponent } from './components/chat-time-line-item/components/quoted-message/quoted-message.component';
import { ChatTimelineDayComponent } from './components/chat-timeline-day/chat-timeline-day.component';
import { ChatTimelineFooterComponent } from './components/chat-timeline-footer/chat-timeline-footer.component';
import { ReplyToPreviewComponent } from './components/chat-timeline-footer/components/reply-to-preview/reply-to-preview.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { LabelDateComponent } from './components/label-date/label-date.component';
import { WhatsappTimelineChatComponent } from './components/whatsapp-timeline-chat/whatsapp-timeline-chat.component';
import { FilterChatItemsPipe } from './pipes/filter-chat-items.pipe';
import { FormatChatMessagePipe } from './pipes/format-chat-message.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SecondsToMinutesSecondsPipe } from 'app/helpers/pipes/seconds-to-minutes-seconds.pipe';
import { WhatsappDatePipe } from './pipes/whatsapp-date.pipe';
import { BasicLoaderComponent } from 'app/ui-components/basic-loader/basic-loader.component';

@NgModule({
  declarations: [
    ChatTimelineComponent,
    ChatTimelineDayComponent,
    ChatTimeLineItemComponent,
    ChatTimelineItemCallComponent,
    ChatTimelineItemSearchHistoryComponent,
    ChatTimelineItemReservationComponent,
    ChatTimelineItemSmsComponent,
    ChatTimelineItemMessageComponent,
    ChatTimelineItemScheduledMessageComponent,
    ReplyToPreviewComponent,
    ChatTimelineFooterComponent,
    WhatsappTimelineChatComponent,
    ChatTimeLineFiltersComponent,
    FilterItemComponent,
    QuotedMessageComponent,
    LabelDateComponent,
    FilterChatItemsPipe,
    SafeHtmlPipe,
    FormatChatMessagePipe,
    WhatsappDatePipe,
  ],
  imports: [
    CommonModule,
    ChatFooterModule,
    /**
     * TODO: вернуть в модуль чатов, когда раскатим их на всех.
     * Пока что оставляем в модуле таймлайна, чтобы он не падал у тех у кого не включены чаты.
     * Т.к. там есть обращение к рекомендации, а она пока что только в чатах.
     */
    StoreModule.forFeature(FEATURE_KEY, chatReducer),
    EffectsModule.forFeature([ChatEffects]),
    TranslateModule,
    SharedModule,
    AudioModule,
    SharedUtilityModule,
    MatTooltipModule,
    MatMenuModule,
    MatIconModule,
    StoreModule.forFeature(fromChatTimeline.chatTimelineFeatureKey, fromChatTimeline.reducer),
    EffectsModule.forFeature([ChatTimelineEffects]),
    EffectsModule.forFeature([ChatMessagesEffects]),
    ImageViewerComponent,
    MessageMenuComponent,
    SecondsToMinutesSecondsPipe,
    BasicLoaderComponent,
    MessageRecommendationComponent,
  ],
  exports: [ChatTimelineComponent, ChatTimelineFooterComponent, ChatTimeLineFiltersComponent],
  providers: [TranslatePipe],
})
export class ChatTimelineModule {}
