import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { Observable } from 'rxjs';
import { PluralizePipe } from '../../../../../../../../../../helpers/pipes/plural/pluralize.pipe';
import { AlertLabelComponent } from '../../../../../../../../../../ui-components/alert-label/alert-label.component';
import { LineProgressComponent } from '../../../../../../../../../../ui-components/line-progress/line-progress.component';
import { ContentCreatorContentType } from '../../../../../../../../../content-creator/interfaces/content-creator.interface';
import { SearchHotelComponent } from '../../../../../../../../../search/components/hotel/search-hotel.component';
import { SearchTourSelectMode } from '../../../../../../../../../search/search.model';
import { SearchResultService } from '../../../../../../../../../search/services/search-result.service';
import { ToursStorageService } from '../../../../../../../../../search/services/search/tours-storage.service';
import { InitSearchRequest } from '../../../../../../../../../search/services/search/websocket-tours.model';
import { ChatDragAndDropService } from '../../../../../../services/chat-drag-and-drop.service';
import {
  FavoriteHotelsDefaultSortDirection,
  SearchResultSortDirection,
  HotelTagWithPriority,
  SearchResultGroup,
} from '../../../../favorites-hotels.model';
import { convertToEnglishLayout } from '../../../../functions/filter-hotels-by-search-query.functions';
import { sortResultGroup } from '../../../../functions/sort-result-group.functions';
import { SearchHotelTagsService } from '../../../../services/search-hotel-tags.service';
import { SearchToursProgressService } from '../../../../services/search-tours-progress.service';
import { FavoritesHotelsSortsComponent } from '../../../sorts/favorite-hotels-sorts.component';
import { SearchResultFiltersService } from '../../services/search-result-filters.service';

@Component({
  selector: 'app-search-result-tours',
  templateUrl: './search-result-tours.component.html',
  styleUrl: './search-result-tours.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    VirtualScrollerModule,
    SearchHotelComponent,
    AsyncPipe,
    LineProgressComponent,
    NgIf,
    FavoritesHotelsSortsComponent,
    PluralizePipe,
    AlertLabelComponent,
  ],
  // todo вообще может выкинуть это?
  providers: [ChatDragAndDropService],
})
export class SearchResultToursComponent implements OnInit {
  @Input() resultGroups$: Observable<SearchResultGroup[]>;
  @Input() initSearchRequest: InitSearchRequest;
  @Input() mapVisibleHotelIds$: Observable<number[]>;

  mapVisibleHotelIds = signal<number[]>([]);
  allGroups = signal<SearchResultGroup[]>([]);
  visibleGroups = computed(() => {
    const searchText = this.searchQuery();
    const sortDirection = this.groupsSort();
    const allGroups = this.allGroups();
    const mapVisibleHotelIds = this.mapVisibleHotelIds();

    let resultInsideMaps: SearchResultGroup[] = [];
    let resultOutsideMaps: SearchResultGroup[] = [];
    if (mapVisibleHotelIds.length) {
      resultInsideMaps = allGroups.filter(result => mapVisibleHotelIds.includes(result.hotel.id));
      resultOutsideMaps = allGroups.filter(result => !mapVisibleHotelIds.includes(result.hotel.id));
    } else {
      resultInsideMaps = allGroups;
    }

    resultInsideMaps = resultInsideMaps.sort((a, b) => sortResultGroup(sortDirection, a, b));
    resultOutsideMaps = resultOutsideMaps.sort((a, b) => sortResultGroup(sortDirection, a, b));

    let results = resultInsideMaps.concat(resultOutsideMaps);
    if (searchText.length) {
      const searchTextEnglish = convertToEnglishLayout(searchText.toLowerCase());
      results = results.filter(result => {
        const hotelName = result.hotel.name.toLowerCase();
        return hotelName.includes(searchText.toLowerCase()) || hotelName.includes(searchTextEnglish);
      });
    }

    return results;
  });
  searchQuery = signal<string>('');
  groupsSort = signal<SearchResultSortDirection>(FavoriteHotelsDefaultSortDirection);
  tags = signal<Map<number, HotelTagWithPriority[]>>(new Map<number, HotelTagWithPriority[]>());
  hasFilters = signal<boolean>(false);
  showToursNotFound = signal<boolean>(false);

  searchInProgress$ = this.searchStateService.searchInProgress$;

  readonly contentCreatorType = ContentCreatorContentType;
  readonly tourSelectMode = SearchTourSelectMode.calendar;

  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly searchStateService: SearchToursProgressService,
    private readonly searchResultService: SearchResultService,
    private readonly searchTagsService: SearchHotelTagsService,
    private readonly searchResultFiltersService: SearchResultFiltersService,
    private readonly searchToursStorage: ToursStorageService,
  ) {}

  ngOnInit() {
    this.resultGroups$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(groups => {
      this.allGroups.set(groups);
    });

    this.mapVisibleHotelIds$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(hotelIds => {
      this.mapVisibleHotelIds.set(hotelIds);
    });

    this.searchTagsService.tags$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(tags => {
      this.tags.set(tags);
    });

    this.searchResultFiltersService.hasFilters$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(hasFilters => {
        this.hasFilters.set(hasFilters);
      });

    this.searchStateService.searchStarted$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.showToursNotFound.set(false);
    });
    this.searchStateService.searchCompleted$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.initSearchRequest) {
        const toursCount = this.searchToursStorage.getToursCount(this.initSearchRequest.id);
        if (!toursCount) {
          this.showToursNotFound.set(true);
        }
      }
    });
  }

  onSearchUpdated(sq: string) {
    this.searchQuery.set(sq);
  }

  selectedSort(sort: SearchResultSortDirection) {
    this.groupsSort.set(sort);
  }

  showHotelOnMap(hotelId: number): void {
    this.searchResultService.showHotelOnMap(hotelId);
  }
}
