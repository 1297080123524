import { Injectable } from '@angular/core';
import {
  CrmCardViewItem,
  NotificationListResponse,
  NotificationType,
  SipStateListEvent,
  WhatsappNewMessage,
} from '@api-clients/crm-api-client';

import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { ChatsFacade } from '../../../../+state/chats/chats.facade';
import { CurrentOutcomeCallFacade } from '../../../../+state/current-outcome-call/current-outcome-call.facade';
import { DealsFacade } from '../../../../+state/deals/deals.facade';
import { CardChangedEvent, DealChangedEvent } from '../../../../+state/deals/deals.interface';
import { ManagerStateFacade } from '../../../../+state/manager-state/manager-state.facade';
import {
  ManagerSipStateEnum,
  ManagerSipStateInterface,
} from '../../../../+state/manager-state/manager-state.state';
import { WorkerConfigFacade } from '../../../../+state/worker-config/worker-config.facade';
import { ElectronService } from '../../../../../../../../libs/shared/utility/src/lib/services/electron.service';
import {
  CallNotification,
  ClientPaidOnlineNotify,
  CloseNotificationEvent,
  CreateNotificationClientPaidOnline,
  CreateNotificationEvent,
  CreateNotificationFromWhatsappEvent,
  CreateNotificationSaleEvent,
  CurrentNotification,
  EventsType,
  MessageNotify,
  Notify,
  SaleNotify,
  SetSipStatesEvent,
  ShowNotifyEvent,
  UpdateNotificationEvent,
} from '../../../../models/ipcEvent';
import { INewMessages, INewWhatsappCounter, IStatuses } from '../../../../models/whatsapp';
import {
  DealViewService,
  ON_START_CONVERSATION,
} from '../../../../modules/deals/modules/deal-view/services/deal-view.service';
import { WorkersListService } from '../../../../modules/layout/components/workers-list/services/workers-list.service';
import { WhatsappService } from '../../../../modules/whatsapp/services/whatsapp.service';
import { NotifyApiService } from '../../notifications/notify-api.service';
import {
  IOpenCrmEvent,
  OpenContentCreatorForClientByTourIdEvent,
  OriginateByPhoneNumberCallback,
} from '../interfaces/websocket.interface';
import { OnEndCallData, OnEndConversationData, POST_SALE_STATUS } from './websocket-helper.model';

const HIDE_CURRENT_OUTCOME_CALL_TIMEOUT = 15000;

@Injectable({
  providedIn: 'root',
})
export class WebsocketHelperService {
  constructor(
    private notifyApiService: NotifyApiService,
    private electronService: ElectronService,
    private dealViewService: DealViewService,
    private managerStateFacade: ManagerStateFacade,
    private workerConfigFacade: WorkerConfigFacade,
    private whatsappService: WhatsappService,
    private dealsFacade: DealsFacade,
    private chatsFacade: ChatsFacade,
    private router: Router,
    private workersListService: WorkersListService,
    private currentOutcomeCallFacade: CurrentOutcomeCallFacade,
  ) {}

  public notifyById(data: Notify): void {
    console.log('calls.notifyById: ', data);
    this.notifyApiService
      .getNotifyData(data.notificationId)
      .subscribe((notificationListResponse: NotificationListResponse) => {
        const notification = notificationListResponse.list[0];

        console.log('notification: ', notification);
        if (notification.type === NotificationType.DealAssigned) {
          this.dealsFacade.loadAll();
        }

        const currentNotification: CurrentNotification = {
          event: notification.type,
          notification,
          countdown: data.countdown,
        };

        const eventData: ShowNotifyEvent = {
          event: 'show-notify-event',
          notification: currentNotification,
        };
        this.electronService.ipcRenderer.send('send-to-notification', eventData);
      });
  }

  public saleNotify(data: SaleNotify): void {
    // TODO: REFACTOR!
    const salesNotifyDisabled = localStorage.getItem('salesNotifyDisabled') === 'true';
    if (!salesNotifyDisabled) {
      console.log('calls.saleNotify: ', data);
      const eventData: CreateNotificationSaleEvent = {
        event: EventsType.CreateSale,
        notification: data,
      };
      this.electronService.ipcRenderer.send('send-to-notification', eventData);
    } else {
      console.log('calls.saleNotify not sent to notifications, because of settings');
    }
  }

  public clientPaidOnline(data: ClientPaidOnlineNotify): void {
    console.log('calls.clientPaidOnline: ', data);
    const eventData: CreateNotificationClientPaidOnline = {
      event: EventsType.CreateClientPaidOnline,
      notification: data,
    };
    this.electronService.ipcRenderer.send('send-to-notification', eventData);
  }

  public onEndCall(event: OnEndCallData): void {
    console.log('calls.onEndCall: ', event);
    this.electronService.ipcRenderer.send('focus-on-window');
    if (!event.dealView.card.isConversionExclude && event.dealView.deal.status !== POST_SALE_STATUS) {
      this.dealViewService.showPopupAndBlockWindow();
      this.dealViewService.resetCurrentCallId(event.dealView);
    }
  }

  public onEndConversation(event: OnEndConversationData): void {
    console.log('calls.onEndConversation: ', event);
    this.dealViewService.resetCurrentCallId(event.dealView);
  }

  public onStartConversation(data: CrmCardViewItem): void {
    console.log('calls.onStartConversation: ', data);
    if (data?.card?.id) {
      this.dealViewService.showDealView(data, { from: ON_START_CONVERSATION });
    } else {
      this.dealViewService.showSkeletonAndSetWaitedCallId(data.currentCall.id);
    }
  }

  public closeInboxNotify(userData: any): void {
    console.log('calls.closeInboxNotify: ', userData);
    const eventData: CloseNotificationEvent = {
      event: 'close-notification',
      userCurrentPhone: userData.phone,
    };
    this.electronService.ipcRenderer.send('send-to-notification', eventData);
  }

  public updateUserData(userData: CrmCardViewItem): void {
    console.log('calls.updateUserData: ', userData);
    const eventData: UpdateNotificationEvent = {
      event: 'update-notification',
      userData,
    };
    this.electronService.ipcRenderer.send('send-to-notification', eventData);
    this.dealViewService.updateDealView(userData);
  }

  public showNewInboxCallNotify(notification: CallNotification): void {
    console.log('calls.showNewInboxCallNotify: ', notification);
    this.managerStateFacade.managerSipState$.pipe(take(1)).subscribe(state => {
      if (state.state !== ManagerSipStateEnum.manual_busy) {
        const eventData: CreateNotificationEvent = {
          event: 'create-notification',
          notification,
        };
        console.log('call notify was sent to notifications window');
        this.electronService.ipcRenderer.send('send-to-notification', eventData);
      }
    });
  }

  public resetManualBusy(): void {
    console.log('calls.resetManualBusy');
    const payload: ManagerSipStateInterface = {
      state: ManagerSipStateEnum.online,
      value: true,
    };
    this.managerStateFacade.setManagerSipState(payload);
  }

  public openWhatsAppWindow(message: IOpenCrmEvent): void {
    console.log('calls.openWhatsAppWindow: ', message);
    this.whatsappService.navigateToChat(message.crmId, message.place);
  }

  public newWhatsappCounter(newWhatsappCounter: INewWhatsappCounter) {
    this.chatsFacade.updateUnreadChatsCounter(newWhatsappCounter.list[0].unreadInstanceChatsCount);
  }

  public openContentCreatorForClientByTourId(event: OpenContentCreatorForClientByTourIdEvent): void {
    console.log('calls.openContentCreatorForClientByTourId: ', event);
    this.whatsappService.navigateToChat(event.crmId, event.place, event.tourId);
  }

  public newWhatsappStatus(messagesWithNewStatus: IStatuses): void {
    this.whatsappService.refreshStatusOfMessage(messagesWithNewStatus);
  }

  public newWhatsappMessage(newMessages: INewMessages): void {
    console.log('calls.newWhatsappMessage:', newMessages);
    if (!newMessages.messages || newMessages.messages.length === 0) {
      return;
    }
    this.whatsappService.determineMessageBelongsCurrentChat(newMessages);
    const message = newMessages.messages[0]?.message;
    const crmCardId = newMessages.messages[0]?.contact?.crmCardId;
    if (message && crmCardId) {
      this.dealsFacade.updateLastWhatsappMessage({
        crmCardId,
        lastWhatsappMessage: message,
      });

      if (!message.isFromMe) {
        this.dealsFacade.newMessageReceived({ cardId: crmCardId });
      }
    }
    newMessages.messages.forEach(newMessageInfo => this.handleNewMessageInfo(newMessageInfo, newMessages));
    this.chatsFacade.updateChatContactsList(newMessages);
  }

  private handleNewMessageInfo(newMessageInfo: WhatsappNewMessage, newMessages: INewMessages): void {
    if (newMessageInfo.message.isFromMe) {
      return;
    }

    combineLatest([this.managerStateFacade.managerSipState$, this.dealsFacade.zenmodeOn$])
      .pipe(take(1))
      .subscribe(([state, isZenModeOn]) => {
        if (state.state !== ManagerSipStateEnum.manual_busy && !isZenModeOn) {
          this.handleNotification(newMessageInfo, newMessages);
        }
      });
  }

  private handleNotification(newMessageInfo: WhatsappNewMessage, newMessages: INewMessages): void {
    this.workerConfigFacade.whatsappNotificationsEnabled$
      .pipe(take(1))
      .subscribe(isWhatsappNotificationsEnabled => {
        if (!isWhatsappNotificationsEnabled || this.isMessageFromCurrentChat(newMessages)) {
          return;
        }

        const shortMessage = this.whatsappService.parseShortMessage(newMessageInfo.message, 100);
        const messageForNotification: MessageNotify = {
          crmCardId: newMessageInfo.contact.crmCardId,
          clientName: newMessageInfo.contact.name,
          shortMessage,
          messageId: newMessageInfo.message.id,
          phone: newMessageInfo.contact.phone,
          contactId: newMessageInfo.contact.id,
        };
        const eventData: CreateNotificationFromWhatsappEvent = {
          event: 'create-notification-from-whatsapp',
          notification: messageForNotification,
        };

        this.electronService.ipcRenderer.send('send-to-notification', eventData);
      });
  }

  private getCurrentChatCrmCardIdFromUrl(): {
    crmCardId: string | null;
    contactChatId: string | null
  } {
    const tree = this.router.parseUrl(this.router.url);
    const chatGroupChatIdSegment = tree.root?.children?.primary.segments[1];
    const chatCrmCardIdSegment = tree.root?.children?.primary.segments[2];

    return {
      crmCardId: chatCrmCardIdSegment?.path ?? null,
      contactChatId: chatGroupChatIdSegment?.path ?? null,
    };
  }

  public isMessageFromCurrentChat(newMessages: any): boolean {
    const currentChat = this.getCurrentChatCrmCardIdFromUrl();
    const firstMessage = newMessages.messages[0];
    if (firstMessage && firstMessage.contact.isGroup) {
      return firstMessage.contact.id === currentChat.contactChatId;
    }

    return firstMessage
      && firstMessage.contact.crmCardId
      && firstMessage.contact.crmCardId.toString() === currentChat.crmCardId;
  }

  public getCurrentSipStates(sipState: SipStateListEvent[]): void {
    this.workersListService.sipState = sipState;
    const eventData: SetSipStatesEvent = {
      event: 'set-sip-states',
      sipList: sipState,
    };
    this.electronService.ipcRenderer.send('send-to-notification', eventData);
  }

  public dealChanged(data: DealChangedEvent): void {
    console.log('calls.dealChanged', data);
    this.dealsFacade.dealChanged(data.detail);
  }

  public cardChanged(data: CardChangedEvent): void {
    console.log('calls.cardChanged', data);
    this.dealsFacade.cardChanged(data.detail);
  }

  public showOutcomeCallInfo(data: OriginateByPhoneNumberCallback): void {
    this.currentOutcomeCallFacade.updateCurrentOutcomeCallInfo({
      success: data.success,
      message: data.message,
    });

    setTimeout(() => {
      this.currentOutcomeCallFacade.clearCurrentOutcomeCall();
    }, HIDE_CURRENT_OUTCOME_CALL_TIMEOUT);
  }

  public showFakeNotify(currentNotification: CurrentNotification) {
    const eventData: ShowNotifyEvent = {
      event: 'show-notify-event',
      notification: currentNotification,
    };
    this.electronService.ipcRenderer.send('send-to-notification', eventData);
  }
}
