import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-search-result-label-combi',
  template: `комби`,
  styles: `
    :host {
      display: block;
      text-transform: uppercase;
      font-size: 8px;
      border-radius: 8px;
      padding: 2px 4px;
      background-color: #ff7a00;
      color: #fff;
      font-weight: 600;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SearchResultLabelCombiComponent {}
