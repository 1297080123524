import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  DeleteMessage,
  DeletePendingMessage,
  RefreshChatMessagesStatus,
  ResetChat,
  SetChatMessages,
  SetHasTodayMessages,
  ShowPendingMessage,
  UpdateChatMessages,
} from './chat-messages.actions';
import {
  ChatMessagesPayload,
  DeletePendingMessagePayload,
  RefreshChatMessagesStatusPayload,
  ShowPendingMessagePayload,
  UpdateChatMessagesPayload,
} from './chat-messages.interface';
import * as ChatMessageSelectors from './chat-messages.selectors';

@Injectable()
export class ChatMessagesFacade {
  chatMessages$ = this.store.pipe(select(ChatMessageSelectors.getChatMessages));
  chatMessagesState$ = this.store.pipe(select(ChatMessageSelectors.getChatMessagesState));
  chatHasTodayMessages$ = this.store.pipe(select(ChatMessageSelectors.getHasTodayMessages));

  constructor(private store: Store<ChatMessagesPayload>) {}

  setChatMessagesState(payload: ChatMessagesPayload) {
    this.store.dispatch(new SetChatMessages(payload));
  }

  setHasTodayMessages(hasTodayMessages: boolean) {
    this.store.dispatch(new SetHasTodayMessages(hasTodayMessages));
  }

  updateChatMessages(payload: UpdateChatMessagesPayload) {
    this.store.dispatch(new UpdateChatMessages(payload));
  }
  showPendingMessage(payload: ShowPendingMessagePayload) {
    this.store.dispatch(new ShowPendingMessage(payload));
  }
  deletePendingMessage(payload: DeletePendingMessagePayload) {
    this.store.dispatch(new DeletePendingMessage(payload));
  }

  refreshStatus(payload: RefreshChatMessagesStatusPayload) {
    this.store.dispatch(new RefreshChatMessagesStatus(payload));
  }
  resetChat() {
    this.store.dispatch(new ResetChat());
  }

  deleteMessage(messageId: string) {
    this.store.dispatch(new DeleteMessage(messageId));
  }
}
