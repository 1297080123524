import { Injectable } from '@angular/core';
import { InitSearchRequest } from '../../../../../../search/services/search/websocket-tours.model';
import {
  FavoriteHotelsDefaultSortDirection,
  SearchMode,
  SearchResultGroup,
  SearchResultSortDirection,
} from '../favorites-hotels.model';

export interface SearchUiState {
  crmId: number;
  searchMode: SearchMode;
  initSearchRequest: InitSearchRequest;
  sortDirection: SearchResultSortDirection;
  resultGroups: SearchResultGroup[];
  createdAt?: Date;
}

@Injectable({ providedIn: 'root' })
export class SearchUiStateService {
  private state: { [key: number]: SearchUiState } = {};

  getStateByCrmId(crmId: number): SearchUiState | undefined {
    if (this.state[crmId]) {
      const createdAt = this.state[crmId].createdAt;
      if (!createdAt) {
        return undefined;
      }

      const now = new Date();

      const thirtyMinutesInMs = 30 * 60 * 1000;
      const diffInMs = now.getTime() - createdAt.getTime();

      if (diffInMs >= thirtyMinutesInMs) {
        this.removeStateByCrmId(crmId);
        return undefined;
      }

      return this.state[crmId];
    }
    return undefined;
  }

  setStateByCrmId(state: SearchUiState): void {
    state.createdAt = new Date();

    this.state[state.crmId] = state;
  }

  removeStateByCrmId(crmId: number): void {
    if (this.state[crmId]) {
      this.state[crmId] = { ...this.state[crmId], ...this.getDefaultState() };
    }
  }

  setSortDirection(crmId: number, sortDirection: SearchResultSortDirection): void {
    this.state[crmId].sortDirection = sortDirection;
  }

  setResultGroups(crmId: number, resultGroups: SearchResultGroup[]): void {
    this.state[crmId].resultGroups = resultGroups;
  }

  getDefaultState(): Partial<SearchUiState> {
    return {
      initSearchRequest: undefined,
      resultGroups: [],
      sortDirection: FavoriteHotelsDefaultSortDirection,
    };
  }
}
