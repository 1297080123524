import {
  ChatContactsListItem,
  ChatRecommendationMessage,
  CrmCardViewItem,
} from '@api-clients/crm-api-client';
import { createReducer, on } from '@ngrx/store';
import { ChatRecommendation } from '../../modules/chats/interfaces/chat.interface';
import * as ChatsActions from './chats.actions';
import {
  ChatListExclusiveFilterKey,
  ChatListInclusiveFilterKeys,
  ChatListSortType,
  ChatListStageFilterKeys,
} from '../../modules/chats/chats';

export const FEATURE_KEY = 'chats';

// TODO: Refactor state interface to contacts, chats, groups, selectedItem, and perform objects mapping to own interfaces
export interface ChatsState {
  currentChatContact: ChatContactsListItem;
  currentChatContactCrmCard: CrmCardViewItem;
  currentChatContactCrmCardLoading: boolean;
  currentChatContactNextTaskLoading: boolean;
  chatContacts: ChatContactsListItem[];
  chatRecommendation: ChatRecommendation;
  loading: boolean;
  error: string;
  searchResults: ChatContactsListItem[];
  isSearching: boolean;
  filters: {
    exclusive: ChatListExclusiveFilterKey;
    inclusive: ChatListInclusiveFilterKeys[];
    tempInclusiveForCounters: ChatListInclusiveFilterKeys[];
    stage: ChatListStageFilterKeys;
    touchToday: boolean;
  };
  sort: ChatListSortType;
}

export const initialState: ChatsState = {
  currentChatContact: null,
  currentChatContactCrmCard: null,
  currentChatContactCrmCardLoading: false,
  currentChatContactNextTaskLoading: false,
  chatContacts: [],
  chatRecommendation: null,
  loading: false,
  error: '',
  searchResults: [],
  isSearching: false,
  filters: {
    exclusive: 'all',
    inclusive: [],
    tempInclusiveForCounters: [],
    stage: ChatListStageFilterKeys.ALL,
    touchToday: false,
  },
  sort: ChatListSortType.LAST_TOUCH,
};

const updateRecommendationMessage = (
  state: ChatsState,
  message: ChatRecommendationMessage,
  isDeleted: boolean,
) => {
  // Надо пометить сообщение в chatRecommendation как isDeleted или isSent
  if (state.chatRecommendation) {
    const attribute = isDeleted ? 'isDeleted' : 'isSent';
    const updatedMessages = state.chatRecommendation.messages.map(msg =>
      msg.id === message.id ? { ...msg, [attribute]: true } : msg,
    );

    return {
      ...state,
      chatRecommendation: {
        ...state.chatRecommendation,
        messages: updatedMessages,
        isDeletedOrSent: updatedMessages.every(msg => msg.isDeleted || msg.isSent),
      },
    };
  }
  return state;
};

export const chatReducer = createReducer(
  initialState,
  on(ChatsActions.loadChatContacts, state => ({
    ...state,
    loading: true,
  })),
  on(ChatsActions.loadChatContactsSuccess, (state, { chatContacts }) => ({
    ...state,
    loading: false,
    chatContacts,
  })),
  on(ChatsActions.loadChatContactsFailure, (state, { error }) => ({ ...state, loading: false, error })),
  on(ChatsActions.loadChatRecommendationSuccess, (state, chatRecommendation) => ({
    ...state,
    chatRecommendation,
  })),
  on(ChatsActions.loadChatRecommendationFailure, (state, { error }) => ({ ...state, error })),
  on(ChatsActions.markRecommendationMessageAsDeleted, (state, message) => {
    return updateRecommendationMessage(state, message, true);
  }),
  on(ChatsActions.markRecommendationMessageAsSent, (state, { message }) => {
    return updateRecommendationMessage(state, message, false);
  }),
  on(ChatsActions.markRecommendationAsDeleted, (state, { recommendation }) => {
    // Обновим только если это текущая рекомендация
    if (state.chatRecommendation && state.chatRecommendation.id === recommendation.id) {
      return {
        ...state,
        chatRecommendation: {
          ...state.chatRecommendation,
          isDeleted: true,
          isDeletedOrSent: true,
        },
      };
    }
    return state;
  }),
  on(
    ChatsActions.markRecommendationMessageAsDeletedSuccess,
    ChatsActions.markRecommendationMessageAsSentSuccess,
    (state, message) => {
      // Надо добавить сообщение в deletedOrSentMessages
      if (state.chatRecommendation) {
        return {
          ...state,
          chatRecommendation: {
            ...state.chatRecommendation,
            deletedOrSentMessages: [...state.chatRecommendation.deletedOrSentMessages, message],
          },
        };
      }
    },
  ),
  on(ChatsActions.chooseChatContact, (state, { chatContact }) => ({
    ...state,
    currentChatContact: chatContact,
  })),
  on(ChatsActions.resetCurrentChatContact, state => ({
    ...state,
    currentChatContact: null,
  })),
  on(ChatsActions.updateChatContact, (state, { chatContact }) => ({
    ...state,
    chatContacts: state.chatContacts.map(contact =>
      contact.contact.id === chatContact.contact.id ? chatContact : contact,
    ),
  })),
  on(ChatsActions.setChatContactDealToNull, (state, { crmCardId }) => ({
    ...state,
    chatContacts: state.chatContacts.map(contact => {
      if (contact.contact.crmCardId !== crmCardId) {
        return contact;
      }

      return {
        ...contact,
        crmDetails: null,
      };
    }),
  })),
  on(ChatsActions.updateChatContactStage, (state, { crmCardId, stage }) => ({
    ...state,
    chatContacts: state.chatContacts.map(contact => {
      if (contact.contact.crmCardId !== crmCardId) {
        return contact;
      }

      const updatedDealInfo = {
        ...contact.crmDetails.dealInfo,
        stage,
      };

      const updatedCrmDetails = {
        ...contact.crmDetails,
        dealInfo: updatedDealInfo,
      };

      return {
        ...contact,
        crmDetails: updatedCrmDetails,
      };
    }),
  })),
  on(ChatsActions.updateCurrentChatContactCrmCardDeal, (state, { deal }) => {
    const updatedCurrentChatContactCrmCard = {
      ...state.currentChatContactCrmCard,
      deal,
    };

    return {
      ...state,
      currentChatContactCrmCard: updatedCurrentChatContactCrmCard,
    };
  }),
  on(ChatsActions.updateCurrentChatContactCrmCardStage, (state, { stage }) => {
    const updatedDeal = {
      ...state.currentChatContactCrmCard.deal,
      stage,
    };

    const updatedCurrentChatContactCrmCard = {
      ...state.currentChatContactCrmCard,
      deal: updatedDeal,
    };

    return {
      ...state,
      currentChatContactCrmCard: updatedCurrentChatContactCrmCard,
    };
  }),
  on(ChatsActions.loadChatContactSuccess, (state, { chatContact }) => ({
    ...state,
    chatContacts: [chatContact, ...state.chatContacts],
  })),
  on(ChatsActions.sortChatContactsSuccess, (state, { chatContacts }) => ({
    ...state,
    chatContacts,
  })),
  on(ChatsActions.refreshMessageStatuses, (state, { statuses }) => ({
    ...state,
    // TODO: Extract ot Effects
    chatContacts: state.chatContacts.map(contact => {
      if (contact.lastMessage) {
        const update = statuses.messages.find(({ id }) => id === contact.lastMessage.id);
        if (update) {
          return {
            ...contact,
            lastMessage: {
              ...contact.lastMessage,
              status: update.status,
            },
          };
        }
      }
      return contact;
    }),
  })),
  on(ChatsActions.resetCurrentChatState, state => ({
    ...state,
    currentChatContact: null,
    currentChatContactCrmCard: null,
    currentChatContactCrmCardLoading: false,
    currentChatContactNextTaskLoading: false,
    chatContacts: [],
    chatRecommendation: null,
    loading: false,
    error: '',
    searchResults: [],
    isSearching: false,
    filters: {
      exclusive: 'all' as ChatListExclusiveFilterKey,
      inclusive: [],
      tempInclusiveForCounters: [],
      stage: ChatListStageFilterKeys.ALL,
      touchToday: false,
    },
  })),
  on(ChatsActions.loadCrmCardViewItemForChat, state => ({
    ...state,
    currentChatContactCrmCardLoading: true,
  })),
  on(ChatsActions.loadCrmCardViewItemForChatSuccess, (state, { crmCardViewItem }) => ({
    ...state,
    currentChatContactCrmCard: crmCardViewItem,
    currentChatContactCrmCardLoading: false,
  })),
  on(ChatsActions.setCrmCardViewItemForChat, (state, { crmCardViewItem }) => ({
    ...state,
    currentChatContactCrmCard: crmCardViewItem,
  })),
  on(ChatsActions.loadDealViewNextTask, state => ({
    ...state,
    currentChatContactNextTaskLoading: true,
  })),
  on(ChatsActions.loadDealViewNextTaskSuccess, (state, { nextTask }) => ({
    ...state,
    currentChatContactCrmCard: { ...state.currentChatContactCrmCard, nextTask },
    currentChatContactNextTaskLoading: false,
  })),
  on(ChatsActions.readChatSuccess, (state, { contactId }) => ({
    ...state,
    chatContacts: state.chatContacts.map(contact =>
      contact.contact.id === contactId
        ? {
            ...contact,
            contact: {
              ...contact.contact,
              unreadMessageCount: 0,
            },
          }
        : contact,
    ),
  })),
  on(ChatsActions.searchContacts, state => ({
    ...state,
    loading: true,
    isSearching: true,
  })),
  on(ChatsActions.searchContactsSuccess, (state, { contacts }) => ({
    ...state,
    searchResults: contacts,
    loading: false,
    error: '',
  })),
  on(ChatsActions.searchContactsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(ChatsActions.clearSearchResults, state => ({
    ...state,
    searchResults: [],
    isSearching: false,
    error: '',
  })),
  on(ChatsActions.setExclusiveFilter, (state, { filter }) => ({
    ...state,
    filters: {
      ...state.filters,
      exclusive: filter,
    },
  })),
  on(ChatsActions.setStageFilter, (state, { stage }) => ({
    ...state,
    filters: {
      ...state.filters,
      stage,
    },
  })),
  on(ChatsActions.resetStageFilter, state => ({
    ...state,
    filters: {
      ...state.filters,
      stage: ChatListStageFilterKeys.ALL,
    },
  })),
  on(ChatsActions.setInclusiveFilters, (state, { filters }) => ({
    ...state,
    filters: {
      ...state.filters,
      inclusive: filters,
    },
  })),
  on(ChatsActions.resetInclusiveFilters, state => ({
    ...state,
    filters: {
      ...state.filters,
      inclusive: [],
    },
  })),
  on(ChatsActions.setTempInclusiveFiltersForCounters, (state, { filters }) => ({
    ...state,
    filters: {
      ...state.filters,
      tempInclusiveForCounters: filters,
    },
  })),
  on(ChatsActions.resetTempInclusiveFiltersForCounters, state => ({
    ...state,
    filters: {
      ...state.filters,
      tempInclusiveForCounters: [],
    },
  })),
  on(ChatsActions.setSort, (state, { sort }) => ({
    ...state,
    sort,
  })),
  on(ChatsActions.resetSort, state => ({
    ...state,
    sort: ChatListSortType.LAST_TOUCH,
  })),
  on(ChatsActions.toggleTouchTodayFilter, state => ({
    ...state,
    filters: {
      ...state.filters,
      touchToday: !state.filters.touchToday,
    },
  })),
  on(ChatsActions.resetTouchTodayFilter, state => ({
    ...state,
    filters: {
      ...state.filters,
      touchToday: false,
    },
  })),
);
