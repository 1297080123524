@if (!showLoadingFormParams()) {
  <div class="title-container">
    <h3 class="title">
      Поиск туров
      <!--      <span class="title-search-mode"-->
      <!--            [class.active]="activeSearchMode() === searchModeVariant.singleCountrySearch"-->
      <!--            (click)="changeSearchMode(searchModeVariant.singleCountrySearch)">Поиск туров</span>-->
      <!--      / <span class="title-search-mode"-->
      <!--            [class.active]="activeSearchMode() === searchModeVariant.multipleCountrySearch"-->
      <!--            (click)="changeSearchMode(searchModeVariant.multipleCountrySearch)">Мульти поиск туров</span>-->
    </h3>

    <div class="form-params-control">
      <button type="button"
              class="btn-search-form"
              matTooltip="Форма поиска"
              (click)="toggleSearchForm()"></button>
    </div>
  </div>

  @if (!hasEmployeeToken()) {
    <div class="no-employee-token">
      <app-alert-label [type]="hasEmployeeTokenAlertType">
        Для корректной работы поиска необходимо повторно авторизоваться в Hermes
      </app-alert-label>
    </div>
  }

  @if (formParams()) {
    @if (activeSearchMode() === searchModeVariant.singleCountrySearch) {
      <app-single-search [formParams]="formParams()"
                         [crmCardItem]="crmCardItem"
                         [crmCardLastSearchRequest]="crmCardLastSearchRequest"
                         [uiState]="uiState()" />
    }
    @if (activeSearchMode() === searchModeVariant.multipleCountrySearch) {
      <app-multiple-search [formParams]="formParams()"
                           [crmCardItem]="crmCardItem"
                           [crmCardLastSearchRequest]="crmCardLastSearchRequest" />
    }
  }
} @else {
  <div class="title-container">
    <h3 class="title">Поиск туров</h3>
    <div class="form-params-control">
      <button type="button"
              class="btn-search-form"
              matTooltip="Форма поиска"
              (click)="toggleSearchForm()"></button>
    </div>
  </div>
  <div class="skeleton-countries-templates">
    <app-favorite-hotels-tab-skeleton />
  </div>
  <div class="skeleton-container">
    <app-favorite-hotels-tab-skeleton />
    <app-favorite-hotels-tab-skeleton />
    <app-favorite-hotels-tab-skeleton />
  </div>
}

