import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ElementRef,
  input,
  OnDestroy,
  viewChild,
} from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { ElectronService } from '../../../../../../../../../libs/shared/utility/src/lib/services/electron.service';
import { PopupService } from '../../../../../shared/services/popup-service.service';
import { TourPackageCreateService } from '../../../../../shared/services/tour-package-create.service';
import { InitSearchRequest, SearchResultsResponseTour } from '../../../services/search/websocket-tours.model';
import { SearchTourFlightsPopupComponent } from '../flights/search-tour-flights-popup.component';
import { SearchTourRoomsComponent } from '../room-variants/search-tour-rooms.component';

@Component({
  selector: 'app-search-tour-menu',
  templateUrl: './search-tour-menu.component.html',
  styleUrl: './search-tour-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatTooltip],
  providers: [PopupService],
})
export class SearchTourMenuComponent implements OnDestroy {
  tour = input.required<SearchResultsResponseTour>();
  initSearchRequest = input<InitSearchRequest>();

  flightsRef = viewChild<ElementRef>('flights');

  private flightsComponentRef: ComponentRef<SearchTourFlightsPopupComponent>;
  private roomsComponentRef: ComponentRef<SearchTourRoomsComponent>;

  constructor(
    private readonly popupService: PopupService,
    private readonly tourPackageCreateService: TourPackageCreateService,
    private readonly electronService: ElectronService,
  ) {}

  ngOnDestroy(): void {
    this.flightsComponentRef?.destroy();
    this.roomsComponentRef?.destroy();
  }

  createTourPackage(): void {
    this.tourPackageCreateService.createTourPackage(this.tour().id);
  }

  openBookLink(): void {
    this.electronService.shell
      .openExternal(this.tour().bookURL, {
        activate: true,
      })
      .then();
  }

  showRooms(): void {
    this.roomsComponentRef = this.popupService.showPopup(SearchTourRoomsComponent);
    this.roomsComponentRef.setInput('tour', this.tour());
    this.roomsComponentRef.setInput('initSearchRequest', this.initSearchRequest());
    this.roomsComponentRef.instance.closeModal.subscribe(() => {
      this.popupService.closeAllModals();
      this.roomsComponentRef.destroy();
    });
  }

  showFlights(): void {
    this.flightsComponentRef = this.popupService.showPopup(
      SearchTourFlightsPopupComponent,
      {},
      {
        anchorElement: this.flightsRef(),
      },
    );
    this.flightsComponentRef.setInput('tour', this.tour());
    this.flightsComponentRef.instance.closePopup.subscribe(() => {
      this.popupService.closeAllModals();
      this.flightsComponentRef.destroy();
    });
  }
}
