import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, interval, merge, of } from 'rxjs';
import { catchError, mapTo, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkStatusService {
  private offline$ = new BehaviorSubject<boolean>(!navigator.onLine);
  private slowConnection$ = new BehaviorSubject<boolean>(false);

  constructor() {
    const online$ = fromEvent(window, 'online').pipe(mapTo(false));
    const offline$ = fromEvent(window, 'offline').pipe(mapTo(true));

    merge(online$, offline$, of(!navigator.onLine)).subscribe(isOffline => {
      this.offline$.next(isOffline);
    });

    this.checkConnectionSpeed();
  }

  private checkConnectionSpeed(): void {
    const slowSpeedThreshold = 500;

    interval(30000)
      .pipe(
        switchMap(() => this.measureConnectionSpeed()),
        catchError(() => of(10000)),
      )
      .subscribe(time => {
        const isSlow = time > slowSpeedThreshold;
        this.slowConnection$.next(isSlow);
      });
  }

  private measureConnectionSpeed(): Promise<number> {
    const url = 'https://ht.kz/img/header-bg/main-light-house-1920.jpg';
    const startTime = performance.now();

    return fetch(url, { method: 'GET', cache: 'no-cache', mode: 'no-cors' })
      .then(() => performance.now() - startTime)
      .catch(() => 10000);
  }

  get isOffline$() {
    return this.offline$.asObservable();
  }

  get isSlowConnection$() {
    return this.slowConnection$.asObservable();
  }
}
