@if (isReady) {
  <div class="title-container">
    <h3 class="title" i18n="@@hot-offers-list/title">Топ горящих предложений</h3>
    <button type="button"
            class="filters-btn has-active-filters"
            matTooltip="Форма поиска горящих"
            [class.has-active-filters]="hasActiveFilters$ | async"
            (click)="toggleFilters()">
    </button>
  </div>
  <app-hot-offers-sorts />
  @if (departCityId) {
    <app-hot-offers-countries
      [departCityId]="departCityId" />
  }
}


@if (loading) {
  <div class="title-container">
    <h3 class="title" i18n="@@hot-offers-list/title">Топ горящих предложений</h3>
  </div>
  <div class="loader-container">
    <img class="loader" src="assets/icons/preloader.svg" alt="" />
  </div>
}


@if (showError) {
  <div class="error-container">
    <app-alert-label [type]="alertType">
      Не смогли загрузить горящие предложения
    </app-alert-label>
  </div>
}
