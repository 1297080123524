<div class="airline">
  <div class="airline-code">{{ airlineName }}</div>
  <div class="transfers">{{ number }} / {{ transferLabel }}</div>
</div>

<div class="direction">
  <div class="pointer start">
    <span class="airport">{{ departureAirport }}</span>
    <span class="date-time">{{ departureDate | date:'dd.MM HH:mm' }}</span>
  </div>

  <div class="pointer end">
    <span class="airport">{{ arrivalAirport }}</span>
    <span class="date-time">{{ arrivalDate | date:'dd.MM HH:mm' }}</span>
  </div>
</div>
