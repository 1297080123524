<div class="header">
  Редактировать готовое сообщение
  <app-close-modal-button [dialogRef]="dialogRef" />
</div>
<div class="message-preview">
  @if (messageText) {
    <div class="message-text">{{ messageText }}</div>
  }
</div>
<div class="message-edit">
  <emoji-mart
    class="emoji-mart"
    *ngIf="isEmojiPickerVisible"
    (emojiSelect)="addEmoji($event)"
    [i18n]="i18nEmoji"
    [style]="{ position: 'absolute', bottom: '10px', left: '40px' }"
    [enableSearch]="false"
    [emojiTooltip]="false"
    [showPreview]="false"
    [darkMode]="false"
    title="Выберите эмодзи"
  >
  </emoji-mart>
  <button class="btn-toggle-emoji-picker" (click)="toggleEmojiPicker()" title="Вставить emoji">
    <img class="icon" src="assets/icons/content-creator/smile.svg" />
  </button>

  <textarea
    #messageTextArea
    class="message-textarea"
    rows="1"
    spellcheck="true"
    appResizableTextarea
    [maxHeightInPx]="250"
    matInput
    [(ngModel)]="messageText"
    placeholder="Введите текст сообщения..."
  ></textarea>
  <button class="btn-send" (click)="sendMessage()" title="Отправить">
    <img class="icon" src="assets/icons/content-creator/send-message.svg" />
  </button>
</div>
