<div class="tour-menu">
  <ul class="tour-menu-list">
    <li class="tour-menu-list-item" (click)="createTourPackage()">Создать турпакет</li>
    @if (tour().bookURL) {
      <li class="tour-menu-list-item" (click)="openBookLink()">Забронировать у туроператора</li>
    }

    <li #flights class="tour-menu-list-item" (click)="showFlights()">
      <img src="assets/icons/search/flights.svg"> Информацию по перелетам
    </li>
    <li class="tour-menu-list-item" (click)="showRooms()">
      <img src="assets/icons/search/rooms.svg"> Варианты номеров
    </li>
  </ul>
</div>
